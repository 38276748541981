import React, { useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const PREFIX = 'CustomAppTargeting';

const classes = {
  container: `${PREFIX}-container`,
  addBtn: `${PREFIX}-addBtn`,
  bundlesWrap: `${PREFIX}-bundlesWrap`,
  delete: `${PREFIX}-delete`,
  divider: `${PREFIX}-divider`,
  dividerList: `${PREFIX}-dividerList`,
  dropdown: `${PREFIX}-dropdown`,
  header: `${PREFIX}-header`,
  textarea: `${PREFIX}-textarea`,
  labelText: `${PREFIX}-labelText`,
  listLabel: `${PREFIX}-listLabel`,
  manage: `${PREFIX}-manage`,
  select: `${PREFIX}-select`
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.container}`]: {
    backgroundColor: theme.palette.background.overlay,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(8),
    paddingTop: theme.spacing(22),
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 6,
  },

  [`& .${classes.addBtn}`]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.bundlesWrap}`]: {
    maxHeight: 370,
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  [`& .${classes.delete}`]: {
    color: theme.palette.text.overlay,
  },

  [`& .${classes.divider}`]: {
    backgroundColor: '#e0e0e0',
  },

  [`& .${classes.dividerList}`]: {
    backgroundColor: '#e0e0e054',
  },

  [`& .${classes.dropdown}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',

    '& .MuiInputBase-input': {
      color: '#b0babf',
    },

    '& .MuiInputBase-root': {
      color: theme.palette.text.overlay,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border.overlay,
    },
  },

  [`& .${classes.header}`]: {
    color: theme.palette.text.overlay,
  },

  [`& .${classes.textarea}`]: {
    color: '#b0babf',
  },

  [`& .${classes.labelText}`]: {
    color: '#b0babf',
    fontSize: '0.75rem',
  },

  [`& .${classes.listLabel}`]: {
    color: '#b0babf',
    fontSize: '1rem',
  },

  [`&.${classes.manage}`]: {
    paddingTop: theme.spacing(8),
  },

  [`& .${classes.select}`]: {
    color: '#b0babf',

    ['& .MuiSelect-icon']: {
      color: theme.palette.border.overlay,
    },
  }
}));

const customizedTypes = [
  {
    ui: 'app_name',
    key: 'app_name',
  },
  {
    ui: 'app_id',
    key: 'app_id_list',
  },
  {
    ui: 'app_bundle_id',
    key: 'app_bundle_list',
  },
  {
    ui: 'publisher_id',
    key: 'publisher_id',
  },
];

const CustomAppTargeting = props => {
  const { customTargeting, setCustomTargeting, setIsCustomTargeting } = props;

  const [bundles, setBundles] = useState([]);
  const [action, setAction] = useState('');
  const [appType, setAppType] = useState('');
  const [appList, setAppList] = useState('');

  useEffect(() => {
    if (customTargeting && Object.keys(customTargeting).length > 0) {
      const bundlesArray = Object.keys(customTargeting).reduce(
        (acc, curr) => {
          if (customTargeting[curr] && customTargeting[curr].values) {
            const bundleData = {
              [curr]: {
                ...customTargeting[curr]
              },
            }

            return [ ...acc, bundleData];
          }

          return acc;
        }, []);

      if (bundlesArray) {
        setBundles(bundlesArray);
      }
    }
  }, [customTargeting]);

  const selectedTypes = useMemo(() => (
    bundles.map(b => Object.keys(b)[0])
  ), [bundles]);

  const formattedBundles = useMemo(() => {
    if (bundles && bundles.length > 0) {
      return bundles.reduce((acc, curr) => {
        const bundleType = Object.keys(curr)[0];
        const type = customizedTypes.filter(t => t.ui === bundleType);
        const bundleKey = type.length > 0 ? type[0].key : bundleType;
        const bundleData = {
          [bundleKey]: {
            ...curr[bundleType]
          }
        };

        return {
          ...acc,
          ...bundleData,
        };
      }, {});
    }
  }, [bundles]);

  const isAddDisabled = useMemo(() => {
    return action === '' || appType === '' || appList === '';
  }, [action, appType, appList]);

  const listLabel = useMemo(
    () => (appType ? `${appType.split('_').join(' ')}s` : ''),
    [appType]
  );

  const handleAddTargeting = () => {
    const type = customizedTypes.filter(t => t.ui === appType.toLowerCase());
    const listNewline = appList.trim().split('\n');
    const listComma = appList.trim().split(',');
    const blacklist = action === 'exclude';
    let values;

    if (listNewline.length === 1 && listComma.length === 1) {
      values = listNewline;
    } else if (listNewline.length > 1) {
      values = listNewline;
    } else if (listComma.length > 1) {
      values = listComma;
    }

    setBundles(prev => [
      ...prev,
      {
        [type[0].key]: {
          values,
          blacklist,
        },
      },
    ]);

    setAction('');
    setAppType('');
    setAppList('');
  };

  const handleDeleteBundle = index => {
    setBundles(prev => prev.filter((p, i) => i !== index));
  };

  const handleSaveCustomTargeting = () => {
    setCustomTargeting(formattedBundles);
    setIsCustomTargeting(false);
  };

  const renderBundles = (key, index) => {
    const data = bundles[index];
    const bundleType = Object.keys(data)[0];
    const bundleAction = data[bundleType].blacklist ? 'exclude' : 'include';
    const type = customizedTypes.filter(t => t.key === bundleType);
    const bundleKey = type.length > 0 ? type[0].ui : bundleType;
    const list = data[bundleType].values;

    return (
      <Box
        key={`${bundleType}-${index}`}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box width="90%">
          <Grid container item xs={12}>
            <Box mt={1} width="100%">
              <Grid
                container
                alignItems="flex-start"
                justifyContent="space-evenly"
                spacing={2}
              >
                <Grid item xs={2}>
                  <FormControl variant="standard" className={classes.dropdown}>
                    <Select
                      className={classes.select}
                      color="secondary"
                      defaultValue={bundleAction}
                      variant="outlined"
                      SelectDisplayProps={{
                        'aria-label': 'include/exclude',
                      }}
                      fullWidth
                    >
                      <MenuItem value="include">Include</MenuItem>

                      <MenuItem value="exclude">Exclude</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <FormControl variant="standard" className={classes.dropdown}>
                    <Select
                      className={classes.select}
                      color="secondary"
                      variant="outlined"
                      defaultValue={bundleKey}
                      SelectDisplayProps={{ 'aria-label': 'App Type' }}
                      onChange={event => setAppType(event.target.value)}
                      fullWidth
                    >
                      <MenuItem
                        disabled={selectedTypes.includes('app_name')}
                        value={'app_name'}
                      >
                        App Name
                      </MenuItem>

                      <MenuItem
                        disabled={selectedTypes.includes('app_id_list')}
                        value={'app_id'}
                      >
                        App ID
                      </MenuItem>

                      <MenuItem
                        disabled={selectedTypes.includes('app_bundle_list')}
                        value={'app_bundle_id'}
                      >
                        App Bundle ID
                      </MenuItem>

                      <MenuItem
                        disabled={selectedTypes.includes('publisher_id')}
                        value={'publisher_id'}
                      >
                        Publisher ID
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={7}>
                  <FormControl variant="standard" className={classes.dropdown}>
                    <TextField
                      color="secondary"
                      variant="outlined"
                      value={list?.join(', ')}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>

        <Box className={classes.actions}>
          <IconButton
            className={classes.delete}
            aria-label="delete"
            onClick={() => handleDeleteBundle(index)}
            size="large">
            <DeleteOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    );
  };

  return (
    <StyledBox
      className={clsx(
        classes.container,
        props.isManage ? classes.manage : {}
      )}
    >
      <Box>
        <Typography className={classes.header} variant="h3">
          Custom App Targeting
        </Typography>

        <Box my={3}>
          <Divider className={classes.divider} />
        </Box>

        <Box className={classes.bundlesWrap} width="100%">
          {bundles &&
            bundles.length > 0 &&
            bundles.map((c, i) => renderBundles(c, i))}

          {bundles && bundles.length > 0 && (
            <Box my={3}>
              <Divider className={classes.dividerList} />
            </Box>
          )}
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
        >
          <Box width="90%">
            <Grid container item xs={12}>
              <Box mt={1} width="100%">
                <Grid
                  container
                  alignItems="flex-start"
                  justifyContent="space-evenly"
                  spacing={2}
                >
                  <Grid item xs={2}>
                    <FormControl variant="standard" className={classes.dropdown}>
                      <Select
                        className={classes.select}
                        color="secondary"
                        variant="outlined"
                        value={action}
                        SelectDisplayProps={{
                          'aria-label': 'include/exclude',
                        }}
                        onChange={event => setAction(event.target.value)}
                        fullWidth
                      >
                        <MenuItem value="include">Include</MenuItem>

                        <MenuItem value="exclude">Exclude</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <FormControl variant="standard" className={classes.dropdown}>
                      <Select
                        className={classes.select}
                        color="secondary"
                        variant="outlined"
                        value={appType}
                        SelectDisplayProps={{ 'aria-label': 'App Type' }}
                        onChange={event => setAppType(event.target.value)}
                        fullWidth
                      >
                        <MenuItem
                          disabled={selectedTypes.includes('app_name')}
                          value={'App_Name'}
                        >
                          App Name
                        </MenuItem>

                        <MenuItem
                          disabled={selectedTypes.includes('app_id_list')}
                          value={'App_ID'}
                        >
                          App ID
                        </MenuItem>

                        <MenuItem
                          disabled={selectedTypes.includes('app_bundle_list')}
                          value={'App_Bundle_ID'}
                        >
                          App Bundle ID
                        </MenuItem>

                        <MenuItem
                          disabled={selectedTypes.includes('publisher_id')}
                          value={'Publisher_ID'}
                        >
                          Publisher ID
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={7}>
                    <FormControl variant="standard" className={classes.dropdown}>
                      <TextField
                        multiline
                        rows={5}
                        color="secondary"
                        variant="outlined"
                        label={
                          <Typography
                            className={classes.listLabel}
                            variant="body2"
                          >
                            {listLabel}
                          </Typography>
                        }
                        value={appList}
                        onChange={event => setAppList(event.target.value)}
                        fullWidth
                        helperText={
                          <Typography className={classes.labelText}>
                            Enter {listLabel || 'values'}, one per row or
                            separated by commas
                          </Typography>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Box>

          <Box pt={2}>
            <Button
              disabled={isAddDisabled}
              className={classes.addBtn}
              color="secondary"
              onClick={handleAddTargeting}
              startIcon={<AddCircleOutlineIcon />}
              variant="contained"
            >
              Add
            </Button>
          </Box>
        </Box>
      </Box>

      <Box>
        <Grid alignItems="center" container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={() => setIsCustomTargeting(false)}
              color="secondary"
              size="small"
              className="Button--small"
            >
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <Button
              onClick={handleSaveCustomTargeting}
              color="secondary"
              variant="contained"
              size="medium"
              className="Button--medium"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </StyledBox>
  );
};

CustomAppTargeting.propTypes = {
  isManage: PropTypes.bool,
  customTargeting: PropTypes.object,
  setIsCustomTargeting: PropTypes.func,
  setCustomTargeting: PropTypes.func,
};

export default CustomAppTargeting;
