import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Typography, Stack } from '@mui/material';
import { CloudFileUploadIcon } from '@v2/components/icons/CloudFileUploadIcon';
import { highlight } from './utils';
import { useAddZipCodes } from './hooks/useAddZipCodes';
import ContentEditable from 'react-contenteditable';

const StyledDropzone = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isDragActive',
})(({ theme, isDragActive }) => ({
  border: `${theme.spacing(0.375)} dashed ${isDragActive ? theme.palette.primary.main : theme.palette.blue[6]}`,
  backgroundColor: theme.palette.blue[11],
  borderRadius: theme.spacing(2.75),
  padding: theme.spacing(12),
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: theme.spacing(2.75),
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10,
  opacity: 0.95,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

const UploadText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '1.5rem',
  fontWeight: 700,
  lineHeight: '2rem',
}));

const Editor = styled(ContentEditable, {
  shouldForwardProp: prop => prop !== 'error',
})(({ theme, error }) => ({
  flex: 1,
  border: `${theme.spacing(0.125)} solid ${error ? theme.palette.red[1] : theme.palette.blue[6]}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2, 2.5),
  resize: 'vertical',
  outline: 'none',
  overflow: 'auto',
  span: {
    '&.errored': {
      color: theme.palette.red[1],
    },
  },
}));

const AddZipCodes = forwardRef(
  ({ value, onChange, onBlur, error, wrongZipCodes, ...props }, ref) => {
    const {
      editorRef,
      showDropzone,
      actualValue,
      handleZipCodeChange,
      getInputProps,
      getRootProps,
      isDragActive,
    } = useAddZipCodes({
      onChange,
      wrongZipCodes,
      value,
    });

    return (
      <Stack
        {...props}
        ref={ref}
        sx={{ position: 'relative', ...props.sx }}
        {...getRootProps()}
      >
        {showDropzone && (
          <StyledDropzone isDragActive={isDragActive}>
            <input {...getInputProps()} />
            <CloudFileUploadIcon sx={{ width: '120px', height: '93px' }} />
            <UploadText sx={{ mt: 4 }}>Upload Zip Codes</UploadText>
          </StyledDropzone>
        )}

        <Stack flex={1} sx={{ position: 'relative' }}>
          <Editor
            error={!!error || wrongZipCodes.length}
            onBlur={onBlur}
            ref={editorRef}
            onChange={handleZipCodeChange}
            html={highlight(actualValue)}
          />
        </Stack>
      </Stack>
    );
  },
);

AddZipCodes.displayName = 'AddZipCodes';

// Add PropTypes for type checking
AddZipCodes.propTypes = {
  ...Stack.propTypes,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      zip: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['valid', 'invalid']),
    }),
  ).isRequired,
  erroredZips: PropTypes.array,
  wrongZipCodes: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.object,
};

export default AddZipCodes;
