import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { LookerEmbedSDK } from '@looker/embed-sdk';
import { styled } from '@mui/material';
import AdvertiserContext from './AdvertiserContext';
import { useAPI } from './hooks/api';
import PropTypes from 'prop-types';

const IframeContainer = styled('div')(() => ({
  backgroundColor: '#f5f5f5',

  '& > iframe': {
    width: '100%',
    height: '100%',
  },
}));

export const EmbedLookerChart = ({
  dashboard,
  withAuth = true,
  ...rest
}) => {
  const adContext = useContext(AdvertiserContext);
  const [url, setUrl] = useState(null);
  const containerRef = useRef(null);
  const { useGet } = useAPI();

  const auth = useMemo(
    () => withAuth
      ? `${process.env.API_URL}/looker-reporting-embed-url`
      : undefined,
    [withAuth]
  );

  useEffect(() => {
    if (!adContext.id) return;

    LookerEmbedSDK.init(
      process.env.BASE_URL,
      auth
    );

    useGet(`/looker-reporting-embed-url/${dashboard}`).then(res => {
      setUrl(res.embedUrl);
    });

  }, [adContext?.id]);

  useEffect(() => {
    if (!url || !containerRef.current) return;

    if (containerRef.current) {
      containerRef.current.innerHTML = '';
    }

    LookerEmbedSDK.createDashboardWithUrl(url)
      .appendTo(containerRef.current)
      .build()
      .connect()
  }, [url, containerRef.current]);

  return <IframeContainer ref={containerRef} {...rest} />;
};

EmbedLookerChart.propTypes = {
  dashboard: PropTypes.string.isRequired,
  withAuth: PropTypes.bool,
};
