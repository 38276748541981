import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  List,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { Elements } from '@stripe/react-stripe-js';

import {
  formValuesToApi,
  getInitialValues,
  validationSchema
} from './formConfig';
import PaymentMethodCard from '../PaymentMethodCard';
import AdvertiserContext from '../../AdvertiserContext';
import CreditCardForm from '../../CreditCardForm';
import { OrgChips } from '../../OrgChips';
import { useAPI } from '../../hooks/api';
import { RHFCheckbox } from '../../RHFCheckbox';
import { RHFTextField } from '../../RHFTextField';
import { Themes } from '../../../constants';
import { states } from '../../../constants/usaStates';
import { useStripe, useUser } from '../../hooks';
import CreditCardStripeForm from '../../CreditCardStripeForm';

const PREFIX = 'AccountManage';

const classes = {
  submit: `${PREFIX}-submit`,
  textfield: `${PREFIX}-textfield`,
  headerSpacing: `${PREFIX}-headerSpacing`,
  headerContainer: `${PREFIX}-headerContainer`,
  billingNameContainer: `${PREFIX}-billingNameContainer`,
};

const StyledAccountManage = styled('div')(({ theme: { spacing, typography } }) => ({
  [`& .${classes.submit}`]: {
    ...typography.h4,
    color: '#fff',
    marginLeft: spacing(2),
    padding: spacing(2),
  },
  [`& .${classes.textfield}`]: {
    marginTop: spacing(3),
  },
  [`& .${classes.headerSpacing}`]: {
    marginTop: spacing(2),
  },
  [`& .${classes.headerContainer}`]: {
    minHeight: 48,
    alignItems: 'center',
  },
  [`& .${classes.billingNameContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    gap: spacing(2),
  },
}));

const AccountManage = ({ org, currentAccount, onClose }) => {
  const userContext = useUser();
  const adContext = useContext(AdvertiserContext);
  const { useGet, usePatch, usePost, useDelete } = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const stripeProps = useStripe();

  const [status, setStatus] = useState('');
  const isLoading = status === 'loading';

  const [cards, setCards] = useState([]);
  const [isEnterCard, setIsEnterCard] = useState(false);

  const CardForm = useMemo(() => org.payment_provider === 'STRIPE' ? CreditCardStripeForm : CreditCardForm, [org]);

  const allowedPaymentMethods = useMemo(() => {
    const items = [];
    if (org.allowed_funding_type_cc) items.push('CC');
    if (org.allowed_funding_type_invoice) items.push('INVOICE');

    if (items.length === 0) {
      if (adContext.theme === Themes.DEFAULT) items.push('CC');
      else if (adContext.theme === Themes.NBCU) items.push('INVOICE');
    }
    return items;
  }, [org]);

  const { control, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
    defaultValues: getInitialValues({ account: currentAccount, org, allowedPaymentMethods }),
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const fundingType = watch('billing_method');
  const isSameAddress = watch('isSameAddress');
  const selectedCard = watch('selectedCard');

  useEffect(() => {
    reset(getInitialValues({ account: currentAccount, org, allowedPaymentMethods }));
  }, [currentAccount]);

  useEffect(() => {
    if (!isSameAddress) return;

    setValue('company_name', org?.name || '');
    setValue('address1', org?.street_address || '');
    setValue('address2', org?.street_address_2 || '');
    setValue('city', org?.city || '');
    setValue('state', org?.state || '');
    setValue('zip', org?.postal_code || '');
  }, [isSameAddress])

  useEffect(() => {
    if (fundingType === 'CC') getProfilesRefresh();
  }, [fundingType]);

  const onSubmit = handleSubmit(async values => {
    const billingData = {
      ...formValuesToApi(values),
      parent_org: org.url,
      primary_contact: userContext?.user?.url,
    }
    setStatus('loading');
    try {
      const { data } = currentAccount?.id ?
        await usePatch(`/billing_accounts/${currentAccount.id}/`, billingData) :
        await usePost(`/billing_accounts/`, billingData);

      enqueueSnackbar(
        `Billing account ${data.name} ${currentAccount?.id ? 'updated!' : 'created!'}`, {
        autoHideDuration: 3000,
        preventDuplicate: true,
        variant: 'success',
      });
      onClose();
    } catch (e) {
      setStatus('error');
    }
  });

  async function getProfilesRefresh() {
    try {
      const res = await useGet(`/payment_profiles/refresh`);

      if (res && res.results && res.results.length > 0) {
        const reversed = res.results.reverse();

        setValue('selectedCard', reversed[0].url);
        setCards(reversed);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleCloseCreditCard = () => {
    setIsEnterCard(false);
    getProfilesRefresh();
  };

  useEffect(() => {
    if (errors.selectedCard) {
      enqueueSnackbar(`Valid Credit Card is required`, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  }, [errors, enqueueSnackbar]);

  const removePaymentMethod = async (method) => {
    if (cards.length <= 1) {
      enqueueSnackbar(`Cannot remove the last payment method.`, {
        autoHideDuration: 3000,
        preventDuplicate: true,
        variant: 'error',
      });
    } else {
      if (window.confirm(`Are you sure you want to remove this payment method?`)) {
        await useDelete(`/payment_profiles/${method.id}`);
        await getProfilesRefresh();
        enqueueSnackbar(`Payment Method Removed`, {
          autoHideDuration: 3000,
          preventDuplicate: true,
          variant: 'success',
        });
      }
    }
  }

  return (
    <Elements {...stripeProps}>
      <StyledAccountManage>
        <Box
          mb={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box width="50%">
            <Button
              startIcon={<ArrowBackIcon color="secondary" />}
              onClick={onClose}
            >
              <Typography>{currentAccount.name}</Typography>
            </Button>
          </Box>

          <Box width="100%" display="flex" justifyContent="flex-end">
            <OrgChips org={org} />
          </Box>
        </Box>
        <Grid
          container
          component="form"
          noValidate
          onSubmit={onSubmit}
          spacing={5}
        >
          <Grid item xs={6} gap="24px">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={classes.headerContainer}
            >
              <Typography variant="h4">General Info</Typography>

              <RHFCheckbox
                name="isSameAddress"
                label="Same address as Company"
                control={control}
              />
            </Box>

            <RHFTextField
              className={classes.textfield}
              name="account_name"
              label="Billing Account Name"
              control={control}
            />

            <RHFTextField
              className={classes.textfield}
              name="company_name"
              label="Billing Company Name"
              control={control}
              disabled={isSameAddress}
              InputProps={{
                endAdornment: isSameAddress && (
                  <InputAdornment position="end">
                    <LockOutlinedIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              className={classes.textfield}
              name="address1"
              label="Street Address"
              control={control}
              disabled={isSameAddress}
              InputProps={{
                endAdornment: isSameAddress && (
                  <InputAdornment position="end">
                    <LockOutlinedIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              className={classes.textfield}
              name="address2"
              label="Street Address 2"
              control={control}
              disabled={isSameAddress}
              InputProps={{
                endAdornment: isSameAddress && (
                  <InputAdornment position="end">
                    <LockOutlinedIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
            <RHFTextField
              className={classes.textfield}
              name="city"
              label="City"
              control={control}
              disabled={isSameAddress}
              InputProps={{
                endAdornment: isSameAddress && (
                  <InputAdornment position="end">
                    <LockOutlinedIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />

            <Grid spacing={4} container>
              <Grid item xs={6}>
                {isSameAddress ?
                  <RHFTextField
                    name="state"
                    label="State"
                    control={control}
                    className={classes.textfield}
                    disabled={isSameAddress}
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <LockOutlinedIcon fontSize="small" />
                        </InputAdornment>
                    }}
                  /> :
                  <RHFTextField
                    name="state"
                    label="State"
                    control={control}
                    select
                    className={classes.textfield}
                    disabled={isSameAddress}
                  >
                    {
                      states.map(s => (
                        <MenuItem key={s.abbreviation} value={s.abbreviation}>
                          {s.name}
                        </MenuItem>
                      ))
                    }
                  </RHFTextField>
                }
              </Grid>
              <Grid item xs={6}>
                <RHFTextField
                  name="zip"
                  label="Zip Code"
                  control={control}
                  className={classes.textfield}
                  disabled={isSameAddress}
                  InputProps={{
                    endAdornment: isSameAddress && (
                      <InputAdornment position="end">
                        <LockOutlinedIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} gap="24px">
            <Box display="flex" flexDirection={"column"}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.headerContainer}
              >
                <Typography variant="h4">Billing Contact</Typography>
              </Box>
              <Box className={classes.billingNameContainer}>
                <RHFTextField
                  className={classes.textfield}
                  name="secondary_first_name"
                  label="Billing Contact First Name"
                  control={control}
                />
                <RHFTextField
                  className={classes.textfield}
                  name="secondary_last_name"
                  label="Billing Contact Last Name"
                  control={control}
                />
              </Box>
              <RHFTextField
                className={classes.textfield}
                name="secondary_contact"
                label="Billing Contact Email"
                control={control}
              />
              {/*
            TODO: integrate
            <RHFTextField
              className={classes.textfield}
              name={"invoice_emails"}
              label={"Invoice Emails"}
              control={control}
              select
              multiple
              input={<TextField/>}
            />
            */}
            </Box>
            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={clsx(classes.headerContainer, classes.headerSpacing)}
              >
                <Typography variant="h4">Payment Methods</Typography>
              </Box>
              <RHFTextField
                className={classes.textfield}
                name="billing_method"
                label="Primary Payment Method"
                select
                control={control}
              >
                {allowedPaymentMethods.map(method => (
                  <MenuItem key={method} value={method}>
                    {method}
                  </MenuItem>
                ))}
              </RHFTextField>
              {
                fundingType === 'CC' && (
                  <Box mt={3}>
                    <List className={classes.cards} dense>
                      {cards.map(c => (
                        <PaymentMethodCard
                          key={c.id}
                          method={c}
                          onDelete={removePaymentMethod}
                          selectedMethod={selectedCard}
                          setSelectedMethod={setValue}
                        />
                      ))}
                    </List>
                    <Button
                      color="secondary"
                      startIcon={<AddIcon fontSize="small" />}
                      onClick={() => setIsEnterCard(true)}
                    >
                      <Typography className={classes.card}>
                        Add Credit Card
                      </Typography>
                    </Button>
                  </Box>
                )
              }
              {/*
            TODO: Secondary payment method is apparent in the figma design, however, tvs-be does not support it yet.
            <RHFTextField
              className={classes.textfield}
              name="secondary_payment_method"
              label="Secondary Payment Method"
              control={control}
            />
            */}
            </Box>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={7}>
              <RHFCheckbox
                name="hasAcceptedTerms"
                label={
                  <Typography variant="body2">
                    I authorize tvScientific to charge my selected payment method according to the <a href="https://tvscientific.com/platform-master-agreement-demand" target="_blank" rel="noopener noreferrer">payment terms</a>.
                  </Typography>
                }
                control={control}
              />
            </Grid>

            <Grid item xs={5}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Button
                  color="secondary"
                  disabled={isLoading}
                  disableElevation
                  disableRipple
                  type="reset"
                  onClick={onClose}
                >
                  Cancel
                </Button>

                <Button
                  className={classes.submit}
                  color="secondary"
                  disableRipple
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? 'Saving...' : 'Save Settings'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {isEnterCard && (
          <CardForm
            data={{ advertiserId: adContext.id }}
            onClose={handleCloseCreditCard}
            isOpen={isEnterCard}
          />
        )}
      </StyledAccountManage>
    </Elements>
  );
};

AccountManage.propTypes = {
  org: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { AccountManage };
