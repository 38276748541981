import React from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList } from 'react-window';

import VirtualizedListRows from './VirtualizedListRows';

const LISTBOX_PADDING = 8;

const ListboxComponent = React.forwardRef(({ children }, ref) => {
  const rowItems = [];
  children?.forEach(item => {
    const { children: itemChildren, ...itemRest } = item;
    rowItems.push(itemRest);
    rowItems.push(...(itemChildren || []));
  });

  const itemCount = rowItems.length;
  const displayCount = 20;
  const itemSize = 36;

  const getHeight = () => {
    if (itemCount >= displayCount) {
      return displayCount * itemSize;
    }
    return itemCount * itemSize;
  };

  return (
    <div ref={ref}>
      <FixedSizeList
        itemData={rowItems}
        height={getHeight() + 2 * LISTBOX_PADDING}
        width="100%"
        itemSize={itemSize}
        overscanCount={50}
        itemCount={itemCount}
      >
        {VirtualizedListRows}
      </FixedSizeList>
    </div>
  );
});

ListboxComponent.displayName = 'ListboxComponent';
ListboxComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
};

export default ListboxComponent;
