import { escapeRegExp, first } from 'lodash';

export const toHighlightedContent = (value, searchString) => {
  if (!searchString) return value;

  const searchReg = new RegExp(escapeRegExp(searchString.toLowerCase()), 'i');
  const searchIndexExec = searchReg.exec(value.toLowerCase());

  if (!searchIndexExec) {
    return [
      {
        value,
        stressed: false,
      },
    ];
  }

  return [
    {
      value: value.slice(0, searchIndexExec.index),
      stressed: true,
    },
    {
      value: value.slice(
        searchIndexExec.index,
        searchIndexExec.index + searchString.length,
      ),
      stressed: false,
    },
    {
      value: value.slice(searchIndexExec.index + searchString.length),
      stressed: true,
    },
  ].filter(v => v.value);
};

export const fromHighlightedContent = value => {
  if (Array.isArray(value)) {
    return value.reduce((acc, curr) => acc + curr.value, '');
  }

  return value;
};

export const makeEven = (data, minRowsAmount) => {
  const isGroup = data.length && data[0]?.groupName;
  const dataLength = isGroup
    ? data.reduce((acc, curr) => acc + curr.data.length, 0)
    : data.length;

  const actualAmount = Math.max(dataLength, minRowsAmount);

  return dataLength >= actualAmount
    ? data
    : isGroup
      ? [
          ...data.slice(0, data.length - 1),
          {
            ...data[data.length - 1],
            data: [
              ...data[data.length - 1].data,
              ...Array(actualAmount - dataLength).fill(null),
            ],
          },
        ]
      : [...data, ...Array(actualAmount - dataLength).fill(null)];
};

export const groupDataByFields = (data, field) => {
  const group = new Map();

  data.forEach(item => {
    const key = item[field];
    if (!group.has(key)) {
      group.set(key, []);
    }
    group.get(key).push(item);
  });

  return Array.from(group).map(([groupName, data]) => ({
    groupName,
    data,
  }));
};

export const transformFromGroupedData = (data, selected, settings) => {
  const dataList = data ?? [];
  const additionalProps = {
    ...(settings.includable && !settings.separateInclExcl
      ? {
          included: first(selected)?.included ?? false,
        }
      : {}),
  };

  const transformedData = dataList.map(item => ({
    ...item,
    ...additionalProps,
  }));

  if (transformedData.length) {
    if (transformedData[0].groupName) {
      return transformedData.reduce(
        (acc, curr) => [...acc, ...curr.data],
        [],
      );
    }

    return [...transformedData];
  }

  return [...transformedData];
};

export const transformToIncludable = (value, selected, settings) => {
  const additionalProps = {
    ...(settings.includable && !settings.separateInclExcl
      ? {
          included: first(selected)?.included ?? false,
        }
      : {}),
  };

  return {
    ...value,
    ...additionalProps,
  };
};
