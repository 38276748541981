import React, { memo, Suspense } from 'react';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '@v2/components/ui/ErrorBoundary';

import { useWizardNavigation } from '../../../../components/hooks';

import Loading from './Loading';

const WizardSectionPane = ({
  paneKey,
  renderInBackground = false,
  children,
}) => {
  const { isActivePane } = useWizardNavigation();
  const shouldRenderComponent = renderInBackground || isActivePane(paneKey);

  return (
    <div
      key={paneKey}
      style={isActivePane(paneKey) ? {} : { display: 'none' }}
    >
      {shouldRenderComponent && (
        <ErrorBoundary fallback={<div>Error loading pane {paneKey} </div>}>
          <Suspense fallback={<Loading paneKey={paneKey} />}>
            {children}
          </Suspense>
        </ErrorBoundary>
      )}
    </div>
  );
};

WizardSectionPane.propTypes = {
  paneKey: PropTypes.string,
  renderInBackground: PropTypes.bool,
  children: PropTypes.node,
};

export default memo(WizardSectionPane);
