import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';

import AdvertiserContext from './AdvertiserContext';
import { useLoader } from './hooks/loader';
import { useSaveExit } from './hooks/saveExit';
import AsyncButton from './AsyncButton';
import ReviewCampaign from './ReviewCampaign';
import Title from './Title';
import { Themes } from '../constants';

const PREFIX = 'LaunchCampaign';

const classes = {
  authorize: `${PREFIX}-authorize`,
  checkbox: `${PREFIX}-checkbox`,
  circle: `${PREFIX}-circle`,
  divider: `${PREFIX}-divider`,
  errors: `${PREFIX}-errors`,
  legalese: `${PREFIX}-legalese`,
  next: `${PREFIX}-next`,
  paper: `${PREFIX}-paper`,
  radioGroup: `${PREFIX}-radioGroup`,
  radios: `${PREFIX}-radios`,
  review: `${PREFIX}-review`,
  reviewWrap: `${PREFIX}-reviewWrap`,
  subheader: `${PREFIX}-subheader`,
  terms: `${PREFIX}-terms`,
  textField: `${PREFIX}-textField`,
  tooltip: `${PREFIX}-tooltip`,
  ul: `${PREFIX}-ul`,
  fullWidth: `${PREFIX}-fullWidth`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.authorize}`]: {
    fontSize: '0.7rem',
  },

  [`& .${classes.checkbox}`]: {
    color: `#47505d`,
    textAlign: 'left',
  },

  [`& .${classes.circle}`]: {
    backgroundColor: `#f7f7f7`,
    borderRadius: `50%`,
    color: `#1dafff`,
    height: `1.5rem`,
    textAlign: `center`,
    width: `1.5rem`,
    marginRight: theme.spacing(2),
  },

  [`& .${classes.divider}`]: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },

  [`& .${classes.errors}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.legalese}`]: {
    fontSize: `0.75rem`,
    marginTop: theme.spacing(3),
  },

  [`& .${classes.next}`]: {
    marginTop: theme.spacing(3),
    textAlign: `right`,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    width: '100%',
    minHeight: 377,
  },

  [`& .${classes.radioGroup}`]: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },

  [`& .${classes.radios}`]: {
    fontSize: `1rem`,
  },

  [`& .${classes.review}`]: {
    maxWidth: `26.688rem`,
    width: '100%',
  },

  [`& .${classes.reviewWrap}`]: {
    width: '100%',
  },

  [`& .${classes.subheader}`]: {
    ...theme.typography.h4,
    margins: 0,
  },

  [`& .${classes.terms}`]: {
    color: '#069de0',
  },

  [`& .${classes.textField}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.tooltip}`]: {
    fontSize: '0.625rem',
    color: '#47505d',
    backgroundColor: '#f2f3f5',
    padding: theme.spacing(1),
    maxWidth: 180,
  },

  [`& .${classes.ul}`]: {
    backgroundColor: 'inherit',
    padding: 0,
  },

  [`& .${classes.fullWidth}`]: {
    width: '100%',
  }
}));

// TODO: Error handling for creating campaign
// const errorMessages = {
//   authorize: 'You must authorize payment.',
// };

////////////////////////////////
// LAUNCH CAMPAIGN COMPONENT
////////////////////////////////
const LaunchCampaign = props => {


  const adContext = useContext(AdvertiserContext);
  const { isLoading, setIsLoading } = useLoader();
  const { saveAndExit } = useSaveExit();
  const { isPurchaseOrder } = props;

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isEnterPO, setIsEnterPO] = useState(false);
  const [poNumber, setPONumber] = useState('');
  const [errors, setErrors] = useState([]);

  const { trackingMethod, selectedEvents } = props.tracking;
  const hasTracking = trackingMethod && selectedEvents.length > 0;

  useEffect(() => {
    props.updateBreadcrumbs('launch', 0);
  }, []);

  useEffect(() => {
    saveAndExit(props.save, 'LaunchCampaign');
  }, [props.save]);

  const handleDeletePO = () => {
    setPONumber('');
    setIsEnterPO(false);
  };

  // TODO: Error handling for creating campaign
  // const handleErrors = error => {
  //   setErrors(prev => [
  //     ...prev,
  //     {
  //       type: error,
  //       message: errorMessages[error],
  //     },
  //   ]);
  // };

  const handleData = async () => {
    try {
      setIsLoading(true);
      setErrors([]);

      const campaignResponse = await props.handleCampaignData({
        active: true,
        nbcu_purchase_order: poNumber,
      });

      if (!campaignResponse || !campaignResponse.data || !campaignResponse.data.url) {
        throw new Error('Failed to create campaign');
      }

      const campaignUrl = campaignResponse.data.url;

      const adGroupsResponse = await props.handleAdGroupsData({ campaign: campaignUrl });

      if (!adGroupsResponse) {
        throw new Error('Failed to create ad groups');
      }

      if (props.isDisplay) {
        const displaysResponse = await props.handleDisplaysData({ campaign: campaignUrl });

        if (!displaysResponse) {
          throw new Error('Failed to create displays');
        }
      }

      props.setStep('PostLaunch');
    } catch (error) {
      setErrors([error.message]);
      console.error('Error in Launch Campaign:', error);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <Root>
      <Title>Launch Your Campaign</Title>

      {adContext.theme === Themes.NBCU && (
        <p>
          Review your campaign specs and select the authorization checkbox to
          launch your campaign.
        </p>
      )}

      <Divider className={classes.divider} />

      <Grid container justifyContent="space-between" spacing={1}>
        <Grid
          className={classes.review}
          container
          direction="column"
          item
          xs={7}
        >
          <Grid item>
            <h3 className={classes.subheader}>Review the Campaign Specs</h3>
          </Grid>

          <Grid className={clsx(classes.fullWidth, classes.reviewWrap)} item>
            <ReviewCampaign
              adGroups={props.adGroups}
              creatives={props.creatives}
              displays={props.displays}
              displayPercent={props.displayPercent}
            />
          </Grid>
        </Grid>

        {isPurchaseOrder && (
          <Grid container item xs={5}>
            <Grid item>
              <h3 className={classes.subheader}>Billing Information</h3>
            </Grid>

            <Grid className={classes.fullWidth} item>
              <Paper className={classes.paper} variant="outlined">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Box height="100%">
                    <Box height={260} width="100%" mt={1}>
                      <Typography variant="body2">
                        For P.O. number to appear on your invoice, enter it
                        here.
                      </Typography>

                      {isEnterPO ? (
                        <Box mt={3} display="flex">
                          <TextField
                            autoFocus
                            fullWidth
                            onChange={event =>
                              setPONumber(event.target.value)
                            }
                            label="Enter PO #"
                            value={poNumber}
                            variant="outlined"
                          />

                          <Box
                            display="flex"
                            width={55}
                            height={55}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <IconButton onClick={handleDeletePO} size="small">
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                      ) : (
                        <Box mt={3}>
                          <Button
                            color="secondary"
                            onClick={() => setIsEnterPO(true)}
                            startIcon={<AddIcon />}
                            size="small"
                          >
                            Add Purchase Order Number (PO #)
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Box>
                    <p className={classes.legalese}>
                      <b>Please note:</b> Your campaign is scheduled to go
                      live on {props.startDate}, but you can edit all aspects
                      of Campaigns and Ad Groups prior to, and after, the
                      campaign is live.
                    </p>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid className={classes.errors} container justifyContent="flex-end">
        <Grid container item spacing={3} xs={5}>
          {errors.length > 0 &&
            errors.map((e, i) => (
              <Grid key={`${e.type}-${i}`} item xs={12}>
                <Typography variant="subtitle2" color="error">
                  {e.message}
                </Typography>
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Box mb={1}>
        <Grid className={classes.next} container justifyContent="flex-end">
          <Grid
            container
            item
            xs={6}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Button
              color="secondary"
              className="Button-campaign-nav"
              onClick={() => {
                if (hasTracking) {
                  props.setStep('InstallTrackingCode');
                  props.updateBreadcrumbs(
                    'tracking',
                    75,
                    'launch',
                    'default'
                  );
                } else {
                  props.setStep('TrackingSetup');
                  props.updateBreadcrumbs('tracking', 0, 'launch', 'default');
                }
              }}
              startIcon={<ArrowBackIcon />}
              variant="outlined"
              disableElevation
            >
              Install Tracking Code
            </Button>
          </Grid>

          <Grid container item justifyContent="flex-end" xs={6}>
            {isPurchaseOrder && (
              <Grid item xs={4}>
                <div>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={isAuthorized}
                        onChange={() => setIsAuthorized(prev => !prev)}
                      />
                    }
                    label={
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title={
                          <Typography className={classes.tooltip}>
                            <b>Payment Terms</b>
                            <br />
                            tvScientific will invoice for the total usage of
                            the platform at the end of each calendar month, or
                            when your billing threshold is reached, whichever
                            comes first. Learn more about invoicing
                          </Typography>
                        }
                      >
                        <Typography className={classes.authorize}>
                          I authorize tvScientific to invoice me according to
                          the&nbsp;
                          <span className={classes.terms}>
                            payment terms.
                          </span>
                        </Typography>
                      </Tooltip>
                    }
                  />
                </div>
              </Grid>
            )}

            <Grid item xs={5}>
              <AsyncButton
                color="secondary"
                isDisabled={isPurchaseOrder && !isAuthorized}
                isLoading={isLoading}
                onClick={() => handleData()}
                classes="Launch-btn"
              >
                Launch
              </AsyncButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Root>
  );
};

LaunchCampaign.propTypes = {
  adGroups: PropTypes.array,
  billingMethod: PropTypes.string,
  creatives: PropTypes.array,
  displays: PropTypes.array,
  displayPercent: PropTypes.string,
  handleCampaignData: PropTypes.func,
  handleAdGroupsData: PropTypes.func,
  handleDisplaysData: PropTypes.func,
  isDisplay: PropTypes.bool,
  isPurchaseOrder: PropTypes.bool,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  setStep: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
  save: PropTypes.object,
  tracking: PropTypes.object,
};

export default LaunchCampaign;
