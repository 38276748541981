import moment from 'moment-timezone';
import _ from 'lodash';
import { useState, useEffect } from 'react';

export const useUtil = () => {
  const compareArrays = (data1, data2) => {
    if (data1.length !== data2.length) {
      return true;
    }

    let hasEdited = false;

    data1.forEach((d) => {
      if (!data2.includes(d)) {
        hasEdited = true;
      }
    });

    return hasEdited;
  };

  const compareTo30days = (value, unit) => {
    const day = 30;
    const week = 4.28571;
    const min = 43200;
    const hour = 720;

    switch (unit) {
      case 'DAY':
        return value <= day;
      case 'WEEK':
        return value <= week;
      case 'MIN':
        return value <= min;
      case 'HOUR':
        return value <= hour;
    }
  };

  const isValidBudget = (value) => {
    // Check budget is at least one cent
    const budget = parseFloat(value);

    if (!isNaN(budget)) {
      return budget >= .01;
    }

    return false;
  }

  const parseAddress = address => {
    const addressArray = address.split(', ');
    const { length } = addressArray;

    const zip = addressArray[length - 1];
    const state = addressArray[length - 2];
    const city = addressArray[length - 3];
    const streetAddress1 = addressArray[0];
    const streetAddress2 = addressArray[1] !== city ? addressArray[1] : '';

    return {
      city,
      state,
      postal_code: zip,
      street_address: streetAddress1,
      street_address_2: streetAddress2,
    }
  };

  const formatDateTime = (date, time) => {
    const hour = new Date(time).getHours();
    const minute = new Date(time).getMinutes();
    const dateTime = moment(date).hour(hour).minute(minute);

    return moment(dateTime).utc().format();
  };

  const deepCompare = (array1, array2) => {
    return _.isEqual(array1, array2);
  };

  const useDebouncedValue = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = _.debounce(() => {
        setDebouncedValue(value);
      }, delay);

      handler();

      return () => {
        handler.cancel();
      };
    }, [value, delay]);

    return debouncedValue;
  };

  const validateDecimals = (number, maxPlaces) => {
    if (!number) return false;
    if ((number % 1) == 0) return true;

    return number.toString().split('.')[1].length <= maxPlaces;
  }

  return {
    compareArrays,
    compareTo30days,
    deepCompare,
    formatDateTime,
    isValidBudget,
    parseAddress,
    useDebouncedValue,
    validateDecimals,
  };
};
