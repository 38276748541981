import React, { useState, useEffect } from 'react';

import apiInstance from '../connection/apiClient';
import { useBWSync, useUser } from '../components/hooks';
import { useAPI } from '../components/hooks/api';

const BWSyncContext = React.createContext(null);

BWSyncContext.displayName = 'BWSyncContext';

const BW_SYNC_INTERVAL = 15 * 60 * 1000;
const BW_SYNC_MAX_AGE_DIFF = BW_SYNC_INTERVAL - 30 * 1000;

const BW_SYNC_URLS = [
  '/campaigns',
  '/creative_lineitems',
  '/lineitems',
  '/static_display_lineitems',
];

const isBWSyncUrl = url => {
  return BW_SYNC_URLS.some(u => url.includes(u));
};

const getKeyForUrl = url => {
  const path = url.split('/v1')[1];
  const result = BW_SYNC_URLS.find(item => path.includes(item));
  return result.replaceAll('/', '');
};

const getSyncData = object => {
  const { in_sync: inSync, out_of_sync: outOfSync } = object;

  return { inSync, outOfSync };
};

export const useBWSyncInterceptors = () => {
  const { bwSync, setBWSync } = useBWSync();
  const { user } = useUser();
  const { useGet } = useAPI();
  const [syncInterval, setSyncInterval] = useState(null);
  const [isVisible, setIsVisible] = useState(true);

  const updateBWSync = (type, key, value) => {
    setBWSync(sync => ({
      ...sync,
      [type]: { ...sync[type], [key]: { value, lastUpdated: Date.now() } },
    }));
  };

  const handleRequest = config => {
    if (config.method == 'get' && isBWSyncUrl(config.url)) {
      return {
        ...config,
        params: {
          ...config.params,
          sync: 1,
        },
      };
    }

    return config;
  };

  const handleResponse = response => {
    if (response.config.method == 'get' && isBWSyncUrl(response.config.url)) {
      const bwSyncKey = getKeyForUrl(response.config.url);

      if ('results' in response.data) {
        response.data.results.forEach(item => {
          const syncData = getSyncData(item);
          updateBWSync(bwSyncKey, item.id, syncData);
        });
      } else {
        const syncData = getSyncData(response.data);
        updateBWSync(bwSyncKey, response.data.id, syncData);
      }
    }

    return response;
  };

  const syncItemsInSession = () => {
    Object.entries(bwSync).forEach(entry => {
      const values = Object.entries(entry[1]);
      const staleItems = values.filter(
        item => Date.now() - item[1].lastUpdated > BW_SYNC_MAX_AGE_DIFF
      );

      staleItems.forEach(item => {
        // NOTE: no need to do anything else, as the interceptors
        // will append the sync param and update the bwSync state
        useGet(`/${entry[0]}/${item[0]}`);
      });
    });
  };

  const setUpSyncInterval = () => {
    const interval = setInterval(syncItemsInSession, BW_SYNC_INTERVAL);
    setSyncInterval(interval);
  };

  const clearSyncInterval = () => {
    clearInterval(syncInterval);
    setSyncInterval(null);
  };

  useEffect(() => {
    const storedSync = sessionStorage.getItem('bwSync');
    if (storedSync) {
      setBWSync(JSON.parse(storedSync));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('bwSync', JSON.stringify(bwSync));
  }, [bwSync]);

  useEffect(() => {
    let requestInterceptor;
    let responseInterceptor;

    if (user?.is_tvsci_employee && !syncInterval) {
      requestInterceptor = apiInstance.interceptors.request.use(handleRequest);
      responseInterceptor =
        apiInstance.interceptors.response.use(handleResponse);

      setUpSyncInterval();

      return () => {
        apiInstance.interceptors.request.eject(requestInterceptor);
        apiInstance.interceptors.response.eject(responseInterceptor);
        clearSyncInterval();
      };
    }
  }, [user?.is_tvsci_employee]);

  useEffect(() => {
    if (!user?.is_tvsci_employee) {
      return;
    }

    if (isVisible) {
      if (!syncInterval) {
        setUpSyncInterval();
      }
      syncItemsInSession();
    } else {
      clearSyncInterval();
    }
  }, [user?.is_tvsci_employee, isVisible]);

  useEffect(() => {
    const handleVisibilityChange = event => {
      setIsVisible(!event.target.hidden);
    };

    document.addEventListener(
      'webkitvisibilitychange',
      handleVisibilityChange
    );

    return () => {
      document.removeEventListener(
        'webkitvisibilitychange',
        handleVisibilityChange
      );
    };
  }, []);
};
