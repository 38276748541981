import { Chip, Table, TableBody, TableCell, TablePagination, TableContainer, TableRow, Stack } from '@mui/material';
import moment from 'moment-timezone';
import { qcStatusTooltips, Scopes } from '../../constants';
import { Link } from 'react-router-dom';
import { formatToUSD } from '../util';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useBWSync, useCurrentSession, usePermissions } from '../hooks';
import CreativeTableHead from './CreativesTableHead';
import { useCreatives } from '../hooks/apis/creatives';
import TableFooter from '@mui/material/TableFooter';
import { CreativeQCAlerts } from '../CreativeQCAlerts';
import AdvertiserContext from '../AdvertiserContext';
import { useAPI } from '../hooks/api';

const creativeHeadCells = [{ id: 'id', label: 'ID', disablePadding: false }, {
  id: 'creativeName',
  label: 'Creative',
  disablePadding: false
}, { id: 'adGroupName', label: 'Ad Group', disablePadding: false }, {
  id: 'startDate',
  label: 'Uploaded',
  disablePadding: false
}, {
  id: 'spend',
  label: 'Spend',
  disablePadding: false
}, // { id: 'budgetRemaining', label: 'Budget remaining', disablePadding: false },
  { id: 'visits', label: 'Visits', disablePadding: false }, {
    id: 'costPerVisit',
    label: 'Cost per visit',
    disablePadding: false
  }];

export const creativeStatusIconRender = (status) => {
  if (status === true) {
    return <Chip label="Active" color="primary" />;
  }

  return <Chip label="Inactive" />;
};

const CreativesTable = ({ classes }) => {
  const { currentAdvertiser } = useCurrentSession();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const { creatives, count } = useCreatives({ page, params: { page_size: pageSize } });
  const { hasPermission } = usePermissions();
  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);
  const [CSOwner, setCSOwner] = useState(null);
  const { showBWSync, renderBWSyncStatus } = useBWSync();
  const adContext = useContext(AdvertiserContext);
  const { useGet } = useAPI();

  // Reset the current page if the advertiser changes
  useEffect(() => {
    setPage(1);
  }, [currentAdvertiser])

  useEffect(() => {
    if (adContext.id !== null) {
      getCSOwner();
    }
  }, [adContext]);

  const getCSOwner = async () => {
    try {
      const response = await useGet(adContext.cs_owner);
      setCSOwner(response);
    } catch (error) {
      console.error(error);
    }
  };

  const headers = useMemo(() => {
    const cells = [...creativeHeadCells];

    if (showQCFunctionality) {
      cells.push({ id: 'qcStatus', label: '', disablePadding: false });
    }

    if (showBWSync) {
      cells.push({
        id: 'adServerCheck', sortable: false, label: 'Ad Server Check', disablePadding: false
      });
    }

    cells.push({ id: 'status', label: 'Status', disablePadding: false });

    return cells;
  }, [showQCFunctionality]);

  return (<Stack spacing={2} sx={{ alignItems: 'flex-start' }}>

    {showQCFunctionality && (<CreativeQCAlerts
      email={CSOwner?.email || 'support@tvscientific.com'}
      items={creatives}
    />)}
    <TableContainer>
      <Table stickyHeader>
        <CreativeTableHead headers={headers} classes={classes} />

        <TableBody>
          {creatives?.map((creative, index) => {
            const labelId = `creative-table-checkbox-${index}`;
            const {
              stats_caches_by_lineitem_id: stats_caches, qc = {}
            } = creative;

            // Get the campaign Id of the creative to be used in the link below
            let campaignId = 'na';

            if (creative.lineitem_set[0] && creative.lineitem_set[0].campaign) {
              campaignId = creative.lineitem_set[0].campaign.split('/campaigns/')[1].slice(0, -1);
            }

            // Get the lineitme Id of the creative to be used in the link below
            let adGroupId = 'na';
            if (creative.lineitem_set[0] && creative.lineitem_set[0].url) {
              adGroupId = creative.lineitem_set[0].url.split('/lineitems/')[1].slice(0, -1);
            }

            const mediaUploadDate = creative.media_upload_date === null ? 'n/a' : moment(creative.media_upload_date).format('l');
            const mediaUploadDateTitle = creative.media_upload_date === null ? 'n/a' : moment(creative.media_upload_date).format('YYYY-MM-DD HH:mm');

            const stats = stats_caches[adGroupId];
            const QCStatusTooltip = qcStatusTooltips[qc.status] || (() => null);

            return (<TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={`${creative.id}-${index}`}
                data-testid="creative-table-row"
              >
                <TableCell>{creative.id}</TableCell>

                <TableCell
                  className={classes.tableCell}
                  id={labelId}
                  scope="row"
                >
                  <Link className={classes.tableLink}
                        to={`/campaigns/${campaignId}/adgroups/${adGroupId}/creative/${creative.id}`}>{creative.name}</Link>
                </TableCell>

                <TableCell className={classes.tableCell}
                           align="left">{creative.lineitem_set[0] && creative.lineitem_set[0].name ?
                  <Link className={classes.link}
                        to={`/campaigns/${campaignId}/adgroups`}>{creative.lineitem_set[0].name}</Link> : '-'}</TableCell>

                <TableCell className={classes.tableCell} align="left"
                           title={mediaUploadDateTitle}>{mediaUploadDate}</TableCell>
                <TableCell className={classes.stat} align="left">
                  {stats && stats.total_spend != null ? formatToUSD(stats.total_spend) : '-'}
                </TableCell>
                <TableCell className={classes.stat} align="left">
                  {stats && stats.visit_count != null ? stats.visit_count : '-'}
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  {stats && stats.cost_per_visit != null ? formatToUSD(stats.cost_per_visit) : '-'}
                </TableCell>

                {showQCFunctionality && (<TableCell align="center">
                    <QCStatusTooltip items={qc.messages} />
                  </TableCell>)}

                {showBWSync && (<TableCell
                    className={classes.tableCell}
                    align="left"
                  >
                    {renderBWSyncStatus('creatives', creative.id)}
                  </TableCell>)}

                <TableCell className={classes.stat} align="left">
                  {creativeStatusIconRender(creative.active)}
                </TableCell>
              </TableRow>);
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[
                10,
                15,
                25,
                50
              ]}
              colSpan={10}
              count={count}
              rowsPerPage={pageSize}
              page={page - 1}
              onPageChange={(e, page) => {
                setPage(page + 1);
              }}
              onRowsPerPageChange={(e) => {
                setPage(1);
                setPageSize(e.target.value);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  </Stack>);
};

CreativesTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default CreativesTable;
