import * as yup from 'yup';

export const getArrayValidation = () =>
  yup.object({
    items: yup.array(),
  });
export const getNonEmptyArrayValidation = () =>
  yup.object({
    items: yup.array().min(1),
  });
export const getObjectValidation = () =>
  yup.object({
    items: yup.object(),
  });
export const getNonEmptyArrayObjectValidation = () =>
  yup.object({
    items: yup
      .object()
      .test(
        'at-least-one-property-non-empty-array',
        'At least one property must be a non-empty array',
        function (value) {
          return Object.values(value).some(
            property => Array.isArray(property) && property.length > 0,
          );
        },
      ),
  });

export const getAdvancedInventoryObjectValidation = () =>
  yup.object().shape({
    items: yup.object().shape({
      device: yup.array().min(1),
      os: yup.array().min(1),
    }),
  });
