import { useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useAdvertiserStripeSetupIntent } from './apis/advertisers';

export const useStripe = (options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  suspense: true
}) => {
  const { data: advertiserStripeSetupIntent } = useAdvertiserStripeSetupIntent(options);

  return useMemo(
    () => {
      if (advertiserStripeSetupIntent) {
        const {
          publishable_key: publishableKey,
          client_secret: clientSecret,
        } = advertiserStripeSetupIntent;

        return {
          stripe: loadStripe(publishableKey),
          options: { clientSecret },
        };
      }

      return {
        stripe: null,
        options: null,
      };
    },
    [advertiserStripeSetupIntent],
  );
};
