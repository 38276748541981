import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  styled,
  Grid,
  Switch,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import { defaults } from 'lodash';
import PropTypes from 'prop-types';
import React, { forwardRef, Fragment, useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import FrequencyCapFieldsGroup from '@v2/components/campaign/FrequencyCapFieldsGroup/FrequencyCapFieldsGroup';

import {
  AdCapUnitTypes,
  EXPERIMENT_OPTIONS,
  INCREMENTAL_OPTIONS,
} from './constants';
import { validationSchema } from './formConfig';

const StyledFormBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(5),
}));

const StyledBlueIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.blue[6],
}));

const StyledBackIcon = styled(StyledBlueIcon)({
  cursor: 'pointer',
});

const StyledTitleDivider = styled('div')(({ theme }) => ({
  '&::after': {
    content: '""',
    backgroundColor: theme.palette.grey[8],
    display: 'block',
    height: theme.spacing(3),
    width: 1,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.grey[3],
  paddingLeft: theme.spacing(3),
}));

const StyledInfoIcon = styled(StyledBlueIcon)({
  fontSize: '1rem',
  marginLeft: 8,
});

const StyledFormDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.grey[6],
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2.5),
}));

const StyledMainDivider = styled(StyledFormDivider)(({ theme }) => ({
  borderColor: theme.palette.grey[3],
}));

const StyledSubFormBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(),
  paddingLeft: theme.spacing(10),
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[1],
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: 400,
    width: 184,
    padding: '4px 12px',
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,

    ['&::before']: {
      boxShadow: theme.shadows[1],
    },
  },
}));

const PercentNumberFormat = forwardRef(
  function PercentNumberFormat(props, ref) {
    const { name, onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        suffix="%"
      />
    );
  }
);
PercentNumberFormat.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
};

const defaultValues = {
  freqCaps: [],
  experimentPlan: '',
  experimentType: '',
  isCapAds: false,
  isExperiment: false,
  isIncremental: false,
};

function AdvancedDeliveryOptionsForm({ data = {}, onSubmit, onCancel }) {
  const mergedDefaultValues = useMemo(() => defaults(data, defaultValues), [data]);

  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    values: mergedDefaultValues,
    mode: 'onChange',
    resetOptions: {
      keepDirtyValues: true,
    },
    resolver,
  });

  const {
    control,
    formState,
    handleSubmit,
    setValue,
    watch,
  } = methods;

  const { isDirty, isValid } = formState;

  const [
    isCapAds,
    isExperiment,
    isIncremental,
    experimentPlan,
    experimentType,
  ] = watch([
    'isCapAds',
    'isExperiment',
    'isIncremental',
    'experimentPlan',
    'experimentType',
  ]);

  useEffect(() => {
    if (isExperiment && !experimentPlan) {
      setValue('experimentPlan', EXPERIMENT_OPTIONS[0].value);
    }
  }, [isExperiment, experimentPlan]);

  useEffect(() => {
    if (isIncremental && !experimentType) {
      setValue('experimentType', INCREMENTAL_OPTIONS[0].value);
    }
  }, [isIncremental, experimentType]);

  return (
    <FormProvider {...methods}>
      <Stack component="form" onSubmit={handleSubmit(onSubmit)} sx={{ padding: '60px 80px' }}>
        <StyledFormBox>
          <Stack direction="row" alignItems="center" spacing={1}>
            <StyledBackIcon
              icon={faArrowLeft}
              onClick={() => onCancel(methods)}
              size="xl"
            />

            <StyledTitleDivider />

            <Typography variant="h4">
              Advanced Campaign Delivery Options
            </Typography>
          </Stack>

          <StyledMainDivider />

          <Grid>
            <Grid item>
              <Controller
                name="isIncremental"
                control={control}
                render={({ field: { value, ...restField } }) => (
                  <StyledFormControlLabel
                    control={
                      <Switch
                        id="increment-switch"
                        checked={value}
                        {...restField}
                      />
                    }
                    label={
                      <>
                        Test incrementality
                        <StyledTooltip
                          arrow
                          placement="top"
                          title={
                            <>
                              <p>
                                This setting will randomly set aside a percentage of your campaign
                                audience as a holdout group to determine the effectiveness of your
                                campaign.
                              </p>
                              <p>
                                Instead of your assigned TV Ad, a randomly assigned PSA will be
                                delivered to this audience to measure the performance of your ad
                                compared to this control group.
                              </p>
                            </>
                          }
                        >
                          <StyledInfoIcon icon={faCircleInfo} />
                        </StyledTooltip>
                      </>
                    }
                  />
                )}
              />

              {isIncremental && (
                <StyledSubFormBox>
                  <Controller
                    name="experimentType"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Box>
                            <TextField
                              id="incremental-dropdown"
                              label="Holdout"
                              onChange={e => field.onChange(e.target.value)}
                              select
                              sx={theme => ({ width: theme.spacing(22) })}
                              value={field.value}
                            >
                              {INCREMENTAL_OPTIONS.map(({ label, value }) => (
                                <MenuItem
                                  key={value}
                                  data-testid={`${value}-option`}
                                  value={value}
                                >
                                  {label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                        }
                      />
                    )}
                  />
                </StyledSubFormBox>
              )}

              {(isIncremental || isExperiment) && <StyledFormDivider />}
            </Grid>

            <Grid item>
              <Controller
                name="isExperiment"
                control={control}
                render={({ field: { value, ...restField } }) => (
                  <StyledFormControlLabel
                    control={
                      <Switch
                        id="experiment-switch"
                        checked={value}
                        {...restField}
                      />
                    }
                    label={
                      <>
                        Run an A/B test
                        <StyledTooltip
                          arrow
                          placement="top"
                          title="Split testing allows you to test different ad groups in a way that isolates the exposed households by test group so there's no contamination."
                        >
                          <StyledInfoIcon icon={faCircleInfo} />
                        </StyledTooltip>
                      </>
                    }
                  />
                )}
              />

              {isExperiment && (
                <StyledSubFormBox>
                  <Controller
                    name="experimentPlan"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Box>
                            <TextField
                              data-testid="experiment-dropdown"
                              id="experiment-dropdown"
                              label="Splits"
                              onChange={e => field.onChange(e.target.value)}
                              select
                              sx={theme => ({ width: theme.spacing(22) })}
                              value={field.value}
                            >
                              {EXPERIMENT_OPTIONS.map(({ label, value }) => (
                                <MenuItem
                                  key={value}
                                  data-testid={`${value}-option`}
                                  value={value}
                                >
                                  {label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                        }
                      />
                    )}
                  />
                </StyledSubFormBox>
              )}

              {(isExperiment || isCapAds) && <StyledFormDivider />}
            </Grid>

            <Grid item>
              <Controller
                name="isCapAds"
                control={control}
                render={({ field: { value, ...restField } }) => (
                  <StyledFormControlLabel
                    control={
                      <Switch
                        id="adCap-switch"
                        checked={value}
                        {...restField}
                      />
                    }
                    label={
                      <>
                        Configure Campaign Frequency Cap
                        <StyledTooltip
                          arrow
                          placement="top"
                          title="Frequency caps can be set at various time frames to control the over-delivery of your ad to the same household or ensure maximum reach across your target audience."
                        >
                          <StyledInfoIcon icon={faCircleInfo} />
                        </StyledTooltip>
                      </>
                    }
                  />
                )}
              />

              {isCapAds && (
                <StyledSubFormBox>
                  <FrequencyCapFieldsGroup fieldName="freqCaps" />
                </StyledSubFormBox>
              )}
            </Grid>
          </Grid>
        </StyledFormBox>

        <Stack direction="row" justifyContent="space-between">
          <Button
            onClick={() => onCancel(methods)}
            color="secondary"
            variant="text"
            size="small"
          >
            Cancel
          </Button>
          <Button
            sx={{ marginLeft: 'auto' }}
            disabled={!isValid || !isDirty}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
}

export default AdvancedDeliveryOptionsForm;

AdvancedDeliveryOptionsForm.propTypes = {
  data: PropTypes.shape({
    isEditingFreqCaps: PropTypes.bool,
    freqCaps: PropTypes.arrayOf(
      PropTypes.shape({
        duration: PropTypes.number,
        fctype: AdCapUnitTypes,
        impressions: PropTypes.number,
        isEditing: PropTypes.bool,
      }),
    ),
    experimentPlan: PropTypes.oneOf(EXPERIMENT_OPTIONS.map(({ value }) => value)),
    experimentType: PropTypes.oneOf(INCREMENTAL_OPTIONS.map(({ value }) => value)),
    isCapAds: PropTypes.bool,
    isExperiment: PropTypes.bool,
    isIncremental: PropTypes.bool,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
