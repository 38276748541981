import PropTypes from 'prop-types';
import React, {
  forwardRef,
  startTransition,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import IncludeExcludeToggle from './IncludeExcludeToggle';
import SelectedSegmentItem from '../../ui/SelectedSegmentItem';
import { Controller } from 'react-hook-form';
import { HighlightedContent, toHighlightedContent } from '../Advanced';
import { useGeoSearchV2 } from './hooks/useGeoSearch';
import { useUtil } from '../../../../components/hooks/util';
import { omit } from 'lodash';
import { keysToSnakeCase } from '../../../../helpers';
import { findOption, getActualField } from './utils';

const StyledBox = styled(Stack)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
}));

const GeographyBox = styled(Stack)(({ theme }) => ({
  name: 'box-geography-selector',
  position: 'relative',
  width: '100%',
  backgroundColor: theme.palette.blue[11],
  borderRadius: theme.shape.borderRadius,
  '&:hover .close-icon': {
    display: 'block',
  },
}));

const TextFieldStyled = styled(TextField)(() => ({
  '& .MuiInputBase-input': {
    color: 'text.primary',
    fontSize: '1rem',
    fontWeight: 500,
    width: '100%',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'transparent',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'blue.6',
    },
    '&.Mui-focused': {
      backgroundColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderWidth: 1,
    },
  },
  '.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root':
    {
      paddingRight: 0,
    },
}));

const GeoTargeter = forwardRef(
  (
    {
      selectionType = 'include',
      onChangeSelectionType,
      onChange,
      focused = false,
      label,
      value,
      onBlur,
      onFocus,
      ...props
    },
    ref,
  ) => {
    const { useDebouncedValue } = useUtil();
    const [inputValue, setInputValue] = useState(getActualField(value));
    const debouncedInputValue = useDebouncedValue(inputValue, 50);
    const deferredValue = useDeferredValue(debouncedInputValue);

    const { data: searchResults = [], loading } =
      useGeoSearchV2(deferredValue);

    const transformedSearchResults = useMemo(
      () => keysToSnakeCase(searchResults),
      [searchResults],
    );

    const actualOptions = useMemo(
      () => [
        ...(value ? [value] : []),
        ...transformedSearchResults.filter(
          o => getActualField(o) !== getActualField(value),
        ),
      ],
      [transformedSearchResults, value],
    );

    const selectedValue = useMemo(
      () => findOption(actualOptions, value),
      [actualOptions, value],
    );

    useEffect(() => {
      setInputValue(getActualField(value));
    }, [value]);

    return (
      <StyledBox alignItems="center" spacing={1} ref={ref}>
        {focused ? (
          <GeographyBox alignItems="center">
            <Autocomplete
              {...props}
              value={selectedValue}
              disablePortal
              loading={loading}
              options={transformedSearchResults}
              autoComplete={false}
              autoSave="false"
              getOptionLabel={option => {
                return getActualField(option);
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <HighlightedContent
                    data={toHighlightedContent(
                      getActualField(option),
                      inputValue,
                    )}
                  />
                </Box>
              )}
              onChange={(_, newValue) => {
                startTransition(() => {
                  onChange(newValue, selectionType);
                });
              }}
              renderInput={params => {
                return (
                  <TextFieldStyled
                    inputProps={{
                      ...omit(params.inputProps, 'value'),
                    }}
                    value={inputValue}
                    autoFocus
                    fullWidth
                    placeholder="Geography selector"
                    onChange={e => {
                      setInputValue(e.target.value);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        paddingRight: 15,
                      },
                    }}
                    onBlur={onBlur}
                  />
                );
              }}
              sx={{ width: '100%' }}
            />
          </GeographyBox>
        ) : (
          <SelectedSegmentItem
            icon={
              <IncludeExcludeToggle
                value={selectionType}
                onChange={onChangeSelectionType}
              />
            }
            label={label ?? getActualField(value) ?? 'Geography selector'}
            onEdit={onFocus}
            placeholderText="Geography selector"
          />
        )}
      </StyledBox>
    );
  },
);

GeoTargeter.displayName = 'GeoTargeter';

GeoTargeter.propTypes = {
  ...Controller.propTypes,
  onChange: PropTypes.func,
  selectionType: PropTypes.oneOf(['include', 'exclude']),
  onChangeSelectionType: PropTypes.func,
  isFocused: PropTypes.bool,
  setIsFocused: PropTypes.func,
  value: PropTypes.object,
  label: PropTypes.string,
};

export default GeoTargeter;
