import { useContext } from 'react';
import UserContext from '../../providers/UserContext';

export const useUser = () => {
  const userContext = useContext(UserContext);

  if (userContext === undefined) {
    throw new Error('useUser UserContext was used outside of its Provider');
  }

  return userContext;
};
