import React, { Suspense } from 'react';
import AppHeader from '../../AppHeader';
import { Box, Container, Stack, Typography } from '@mui/material';
import LoadingSpinner from '../../ui/LoadingSpinner';
import { useHistory, useParams } from 'react-router-dom';
import {
  useGetPlatformInvite,
  useJoinPlatformInvite,
} from '../../hooks/apis/platformInvite';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '@v2/components/ui/ErrorBoundary';
import AppInviteError from '../AcceptInvitePage/AppInviteError';
import TvSciIcon from '../../../v2/icons/By Scientific-What is tvSciQ.svg';
import JoinForm from './JoinForm';
import { mapValuesToJoin, validateJoinPlatformInvite } from './utils';
import { RoutePaths } from '../../../constants';
import { useErrors } from '../../hooks/errors';

const JoinDetails = ({ inviteId }) => {
  const { platformInvite } = useGetPlatformInvite(inviteId);
  const { trigger } = useJoinPlatformInvite(inviteId);
  const history = useHistory();
  const { handleError } = useErrors();
  if (!platformInvite) {
    return null;
  }

  // If they haven't accepted the invite yet, redirect them back to the accept page
  if (platformInvite?.status === 'INVITED') {
    history.replace(`/accept-invite/${inviteId}`);
    return null;
  }

  validateJoinPlatformInvite(platformInvite);

  const handleSubmit = async values => {
    try {
      const result = await trigger(mapValuesToJoin(values));
      if (result?.next_step_url) {
        window.location.href = result.next_step_url;
      } else {
        history.push(RoutePaths.HOME);
      }
    } catch (e) {
      console.log('pjh error', e);
      handleError(e);
    }
  };

  if (!platformInvite) {
    return null;
  }

  return (
    <Stack spacing={8}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        width="100%"
        sx={{ borderBottom: '1px solid #B0BABF', paddingBottom: 2 }}
      >
        <Stack spacing={2}>
          <Typography variant="h3">Tell us about Galpin Motors</Typography>
          <Typography variant="body1">
            Please review and verify the information below, which is used to
            set up your account.
          </Typography>
        </Stack>
        <img src={TvSciIcon} alt="Powered by tvSciQ - What is StvSciQ?" />
      </Stack>

      <JoinForm onSubmit={handleSubmit} platformInvite={platformInvite} />
    </Stack>
  );
};

JoinDetails.propTypes = {
  inviteId: PropTypes.string.isRequired,
};

const JoinPage = () => {
  const { inviteId } = useParams();
  return (
    <AppHeader hidenav="true">
      <Container maxWidth="lg">
        <Box paddingTop={5}>
          <ErrorBoundary
            fallback={<AppInviteError isMissingCode={!inviteId} />}
          >
            <Suspense fallback={<LoadingSpinner />}>
              <JoinDetails inviteId={inviteId} />
            </Suspense>
          </ErrorBoundary>
        </Box>
      </Container>
    </AppHeader>
  );
};

export default JoinPage;
