import React, { Suspense, lazy, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormControl,
  ListItemText,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AdvertiserContext from '../../AdvertiserContext';
import DeleteDraftCampaign from '../../DeleteDraftCampaign';
import LoadingSpinner from '../../ui/LoadingSpinner';
import ManageBudget from '../../ManageBudget';
import ModalOverlay from '../../ModalOverlay';
import { useAPI } from '../../hooks/api';
import { useLoader, useFlags, useAdvertisers, useQuery } from '../../hooks';
import { useLogRocket } from '../../hooks/logRocket';
import { RoutePaths, Themes } from '../../../constants';
import { StatusList, StatusKeys, StatusNames } from './statuses';
import AlertBox from '../../AlertBox';
import { EmbedLookerChart } from '../../EmbedLookerChart';
import { useIntercom } from '../../../helpers/intercomWrapper';
import { getPrimaryOrgId } from '../../../helpers';
import CampaignsTable from './CampaignsTable';
import { classes, StyledAppHeader } from './styles';
import { generatePath } from 'react-router-dom';
import { ErrorBoundary } from '@v2/components/ui/ErrorBoundary';
import { useGetCampaigns } from '../../hooks/apis/campaigns';
import { useWizardRedirect } from '@hooks/wizardRedirect';
const ManageCampaign = lazy(() => import('../../ManageCampaign'));

const PaperStyled = styled(Paper)(() => ({
  padding: 0,
  overflow: 'hidden',
}));

const EmbedLookerChartStyled = styled(EmbedLookerChart)(() => ({
  // TODO: Dynamically stretch iframe height to fit content
  height: 750,
}));


const CampaignsPage = (props) => {
  const adContext = useContext(AdvertiserContext);
  const urlQuery = useQuery();
  const { currentAdvertiser } = useAdvertisers();
  const { flags, Flags } = useFlags();
  const { update: updateCampaignCache, remove: removeFromCampaignCache } = useGetCampaigns();
  const isVerticalCampaignFlowEnabled = flags[Flags.USER_GETS_VERTICAL_CAMPAIGN];

  const { useGet } = useAPI();
  const { isLoading: isPageLoading } = useLoader();

  const [alert, setAlert] = useState(null);
  const [isAlertOpen, setAlertOpen] = useState(true);
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [isCampaign, setIsCampaign] = useState(false);
  const [isDupeCampaign, setIsDupeCampaign] = useState(false);
  const [isEditBudget, setIsEditBudget] = useState(false);
  const [isDeleteDraft, setIsDeleteDraft] = useState(false);
  const [statuses, setStatuses] = useState(StatusKeys);
  const { tagUser } = useLogRocket();
  const { showArticle } = useIntercom();
  const { editCampaign } = useWizardRedirect();

  useEffect(() => {
    // Tag user sso id, name and email in Log Rocket
    tagUser();
    getAlerts()
  }, []);

  const handleManageBudget = campaign => {
    setCurrentCampaign(campaign);
    setIsEditBudget(true);
  };

  const handleDeleteDraft = campaign => {
    setCurrentCampaign(campaign);
    setIsDeleteDraft(true);
  };

  const handleDuplicateCampaign = async campaign => {
    setCurrentCampaign(campaign);
    setIsDupeCampaign(true);
    setIsCampaign(true);
  };

  const handleDraftCampaign = campaign => {
    if (isVerticalCampaignFlowEnabled) {
      return editCampaign({ campaignId: campaign});
    }

    props.history.push(
      generatePath(RoutePaths.OLD_CAMPAIGN_EDIT, { id: campaign }),
    );
  };

  const handleManageCampaign = campaign => {
    if (isVerticalCampaignFlowEnabled) {
      return editCampaign({ campaignId: campaign.id});
    }

    setCurrentCampaign(campaign);
    setIsCampaign(true);
  };

  const handleSaveCampaign = (campaign) => {
    updateCampaignCache(campaign);
    setIsDupeCampaign(false);
  };

  const handleDeleteCampaignComplete = (campaign) => {
    removeFromCampaignCache(campaign.id);
  }

  const handleStatusesChange = (event) => {
    const { value } = event.target;
    const nextStatuses = typeof value === 'string' ? value.split(',') : value;

    if (nextStatuses[nextStatuses.length - 1] === 'all') {
      setStatuses(statuses.length === StatusKeys.length ? [] : StatusKeys);

      return;
    }

    setStatuses(nextStatuses);
  };

  const handleCloseDeleteDraft = () => {
    setIsDeleteDraft(false);
  };

  const handleCloseBudget = () => {
    setIsEditBudget(false);
    setCurrentCampaign(null);
  };

  useEffect(() => {
    if (isVerticalCampaignFlowEnabled) {
      urlQuery.set('org', getPrimaryOrgId(currentAdvertiser.primary_org));
      urlQuery.set('adAccount', currentAdvertiser?.id);
    }
  }, [
    isVerticalCampaignFlowEnabled,
    urlQuery,
    currentAdvertiser?.id,
    currentAdvertiser?.primary_org
  ]);

  async function getAlerts() {
    const alerts = await useGet('/alerts')
    setAlert(alerts.activeAlert !== null ? alerts : null)
  }

  const goLiveMessage = {
    title: "NOTICE",
    message: "This Ad Account needs to be associated with a valid Billing Account before any Campaigns will go live! ",
    type: "MAJOR",
    closeable: false,
    isAlertOpen: true,
  }

  return (
    <StyledAppHeader history={props.history}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          {
            alert !== null && (
              <Grid item xs={12}>
                <AlertBox
                  {...alert.activeAlert}
                  isAlertOpen={isAlertOpen}
                  closeAlert={setAlertOpen}
                />
              </Grid>
            )
          }
          {
            !adContext?.billing_account_is_valid && (
              <Grid item xs={12}>
                <AlertBox
                  {...goLiveMessage}
                  isAlertOpen={isAlertOpen}
                  closeAlert={setAlertOpen}
                  action={
                    <Button color="inherit" size="small" onClick={() => showArticle('8516335')}>
                      Click Here for Help!
                    </Button>
                  }
                />
              </Grid>
            )
          }
          <Grid item xs={12}>
            <PaperStyled>
              <EmbedLookerChartStyled dashboard="CAMPAIGN" />
            </PaperStyled>
          </Grid>
        </Grid>
      </Container>

      <Box
        border={1}
        borderColor="grey.300"
        p={6}
        pt={4}
        m={4}
        borderRadius="20px"
        data-testid="campaigns-card"
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" component="div" data-testid="active-campaigns-header">
              Active Campaigns
            </Typography>
          </Grid>

          <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="status-multiple-checkbox-label">Status</InputLabel>
              <Select
                variant="standard"
                multiple
                color="secondary"
                id="status-multiple-checkbox"
                labelId="status-multiple-checkbox-label"
                label="Status"
                value={statuses}
                onChange={handleStatusesChange}
                renderValue={(selected) => {
                  const values = selected.map(item => StatusNames[item]).join(', ');
                  const text = selected.length === StatusList.length ? 'All' : values;

                  return `Showing [${text}] status`;
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250
                    }
                  }
                }}>
                <MenuItem value="all">
                  <Checkbox
                    checked={statuses.length === StatusList.length}
                    indeterminate={statuses.length > 0 && statuses.length < StatusList.length}
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {StatusList.map(({ name, value }) => (
                  <MenuItem key={value} value={value}>
                    <Checkbox checked={statuses.includes(value)} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <ErrorBoundary fallback={<h2>Could not fetch campaigns.</h2>}>
          <Suspense>
            <CampaignsTable
              statuses={statuses}
              handleManageBudget={handleManageBudget}
              handleDeleteDraft={handleDeleteDraft}
              handleDuplicateCampaign={handleDuplicateCampaign}
              handleDraftCampaign={handleDraftCampaign}
              handleManageCampaign={handleManageCampaign}
              urlQuery={urlQuery}
            />
          </Suspense>
        </ErrorBoundary>

        {isEditBudget &&
          <ManageBudget
            isModal
            isOpen={isEditBudget}
            currentCampaign={currentCampaign}
            onClose={handleCloseBudget}
            setHasSaved={handleSaveCampaign}
          />}

        {isCampaign &&
          <ModalOverlay
            className={classes.modal}
            isOpen={isCampaign}
            onClose={() => {
              setIsCampaign(false);
              setIsDupeCampaign(false);
            }}
          >
            <Suspense fallback={<LoadingSpinner />}>
              <ManageCampaign
                showTestIncrementality={
                  adContext.theme === Themes.DEFAULT
                  || (
                    adContext.theme === Themes.NBCU
                    && !!currentCampaign.experiment_type
                  )
                }
                showType={adContext.theme === Themes.DEFAULT}
                isDupeCampaign={isDupeCampaign}
                setHasSaved={handleSaveCampaign}
                setIsCampaign={setIsCampaign}
                setIsDupeCampaign={setIsDupeCampaign}
                currentCampaign={currentCampaign}
              />
            </Suspense>
          </ModalOverlay>}

        {isDeleteDraft &&
          <DeleteDraftCampaign
            isModal
            isOpen={isDeleteDraft}
            campaign={currentCampaign}
            onClose={handleCloseDeleteDraft}
            onDelete={handleDeleteCampaignComplete}
          />}

        {isPageLoading &&
          <Box width="100%" height="100%" className={classes.pageLoader}>
            <CircularProgress color="secondary" />
          </Box>}

      </Box>
    </StyledAppHeader>
  );
}

CampaignsPage.propTypes = {
  history: PropTypes.object,
};

export default CampaignsPage;
