import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useUpload } from './hooks/useUpload';
import { List } from './List';
import { Edit } from './Edit';
import { CancelButtonStyled } from '../../../styles';
import { CaptionBoxStyled, CaptionTypographyStyled } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoSquare } from '@fortawesome/pro-regular-svg-icons';
import { AdvancedBox } from '../../../AdvancedBox';
import PropTypes from 'prop-types';

export const Upload = ({ onClose, onSubmit, info, isDisplay, ...props }) => {
  const { files, disabled, drop, nextButtonContent, remove, cancel } =
    useUpload();

  return (
    <AdvancedBox
      {...props}
      info={info.info}
      onClose={onClose}
      header={<Typography variant="h4">{info.label}</Typography>}
    >
      <Stack flex={1} spacing={7.5}>
        <Stack flex={1}>
          <Stack>
            {files.length ? (
              <List files={files} onRemove={remove} onCancel={cancel} />
            ) : (
              <Edit isDisplay={isDisplay} onDrop={drop} />
            )}
          </Stack>
          {!isDisplay && (
            <CaptionBoxStyled>
              <CaptionTypographyStyled>
                <FontAwesomeIcon icon={faInfoSquare} /> We will check your file
                formatting to ensure it meets minimum quality requirements.
                Issues will be automatically fixed in most cases.
              </CaptionTypographyStyled>
            </CaptionBoxStyled>
          )}
        </Stack>
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <CancelButtonStyled variant="text" onClick={onClose}>
                Cancel
              </CancelButtonStyled>
            </Stack>
            <Button
              disabled={disabled}
              onClick={onSubmit}
              variant="contained"
            >
              {nextButtonContent}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </AdvancedBox>
  );
};

Upload.propTypes = {
  ...AdvancedBox.propTypes,
  info: PropTypes.shape({
    info: PropTypes.node,
    label: PropTypes.string,
  }),
};
