import React, { Fragment, Suspense, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '@v2/components/ui/ErrorBoundary';
import { WizardSectionPane } from '@v2/components/wizard/WizardSectionPane';

import { useWizardNavigation } from '../../../../components/hooks';

import Loading from './Loading';

const buildPaneKey = (sectionKey, paneKey) => `${sectionKey}::${paneKey}`;

const SectionContent = ({ paneKey, pane }) => {
  return (
    <WizardSectionPane
      key={paneKey}
      paneKey={paneKey}
      renderInBackground={
        pane.renderInBackground ?? pane.props.renderInBackground
      }
    >
      {/* TODO: dont like this */}
      <pane.type {...pane.props} paneKey={paneKey} />
    </WizardSectionPane>
  );
};

const SectionBuilder = ({ paneKey }) => {
  const { registerSectionPane } = useWizardNavigation();

  useEffect(() => {
    return registerSectionPane(paneKey);
  }, [paneKey]);

  return null;
};

SectionContent.propTypes = {
  paneKey: PropTypes.string.isRequired,
  pane: PropTypes.node.isRequired,
};

SectionBuilder.propTypes = {
  paneKey: PropTypes.string.isRequired,
};

const WizardSectionContents = ({ sectionKey, panes }) => {
  const { isActive, isNextAfterActive } = useWizardNavigation();

  const hasMounted = useRef(false);

  const shouldRenderSection = isActive(sectionKey) ||
    isNextAfterActive(sectionKey) ||
    hasMounted.current;

  useEffect(() => {
    if (isActive || isNextAfterActive) {
      hasMounted.current = true;
    }
  }, [isActive, isNextAfterActive]);

  return (
    <Fragment>
      {panes.map(paneComponent => {
        const key = buildPaneKey(sectionKey, paneComponent.key);
        return <SectionBuilder key={`${key}-builder`} paneKey={key} />;
      })}
      {shouldRenderSection ? (
        <div style={!isActive(sectionKey) ? { display: 'none' } : {}}>
          <ErrorBoundary
            fallback={<div>Error setting up {sectionKey} section</div>}
          >
            <Suspense fallback={<Loading sectionKey={sectionKey} />}>
              {panes.map(paneComponent => {
                const key = buildPaneKey(sectionKey, paneComponent.key);
                return (
                  <SectionContent
                    key={key}
                    pane={paneComponent}
                    paneKey={key}
                  />
                );
              })}
            </Suspense>
          </ErrorBoundary>
        </div>
      ) : undefined}
    </Fragment>
  );
};

WizardSectionContents.propTypes = {
  sectionKey: PropTypes.string.isRequired,
  panes: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default WizardSectionContents;
