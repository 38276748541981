import React from 'react';
import { Button, Stack } from '@mui/material';
import { isBetween } from './utils';
import PropTypes from 'prop-types';
import { useButtonSlider } from './hooks';
import { StyledButton, StyledButtonText, StyledSubmitButton } from './styles';
import { isEmpty } from 'lodash';

export const ButtonSlider = ({ onChange, value, options, multiple, ...props }) => {
  const {
    all,
    change,
    edited,
    submit,
    cancel,
    isSelecting
  } = useButtonSlider({ onChange, value, options, multiple });

  return (
    <Stack
      {...props}
      flexWrap="wrap"
      direction="row"
      data-testid={`button-slider`}
      spacing={2}
    >
      {options.map(option => {
        const key = Array.isArray(option.value)
          ? option.value.join('-')
          : option.value;

        return (
          <StyledButton
            active={
              Array.isArray(option.value)
                ? isEmpty(option.value) && isEmpty(edited)
                  ? true
                  : isBetween(option.value, edited)
                : option.value === edited
            }
            data-testid="button-slider-option"
            variant="outlined"
            key={key}
            onClick={() => {
              option.activable === false
                ? all(option.value)
                : change(option.value);
            }}
          >
            <StyledButtonText>{option.label}</StyledButtonText>
          </StyledButton>
        );
      })}
      <StyledSubmitButton
        variant="contained"
        data-testid={`button-slider-submit`}
        onClick={submit}
        disabled={isSelecting}
      >
        OK
      </StyledSubmitButton>
      <Button
        variant="text"
        data-testid={`button-slider-cancel`}
        onClick={cancel}
      >
        Cancel
      </Button>
    </Stack>
  );
};

ButtonSlider.propTypes = {
  ...Stack.propTypes,
  onChange: PropTypes.func,
  chunkBy: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
    PropTypes.string,
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.number,
        PropTypes.string,
      ]),
      activable: PropTypes.bool,
    }),
  ),
};
