import { useContext } from 'react';

import { PingContext } from '../../providers/PingContext';

export const usePingAuth = () => {
  const context = useContext(PingContext);

  if (context === undefined) {
    throw new Error(
      'usePingAuth PingContext was used outside of its Provider'
    );
  }

  return context;
};
