import {
  Button,
  Stack,
  Table,
  TableCell,
  TableRow,
  Tabs,
  TextField,
  Typography,
  styled,
} from '@mui/material';

export const AdvancedElementRowStyled = styled(TableRow, {
  shouldForwardProp: prop =>
    prop !== 'highlighted' && prop !== 'targeted' && prop !== 'rowType',
})(({ theme, highlighted, targeted, rowType }) => {
  const rowColor =
    rowType === 'remove' ? theme.palette.red[5] : theme.palette.blue[11];

  return {
    position: 'relative',
    height: theme.spacing(9.25),
    borderBottom: `1px solid ${theme.palette.grey[10]}`,
    background: targeted
      ? rowColor
      : highlighted
        ? theme.palette.common.offWhite
        : 'white',
    '&.hover-row': {
      cursor: 'pointer',
      '&:hover': {
        background: rowColor,

        '& .hover-icon': {
          display: 'block',
        },
      },
    },
  };
});

export const AdvancedTableStyled = styled(Table)({
  display: 'table',
});

export const TableGroupCellStyled = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey[11],
  padding: theme.spacing(0.25, 5.5),
}));

export const SelectionElementRowStyled = styled(AdvancedElementRowStyled, {
  shouldForwardProp: prop => prop !== 'featured',
})({});

export const TableCellStyled = styled(TableCell)(({ theme }) => ({
  height: theme.spacing(9.25),
}));

export const FirstCellStyled = styled(TableCellStyled)(({ theme }) => ({
  paddingLeft: theme.spacing(5.55),
  position: 'relative',
}));

export const LastCellStyled = styled(TableCellStyled)(({ theme }) => ({
  paddingRight: theme.spacing(5.55),
}));

export const AdvancedWrapperStyled = styled(Stack)(({ theme }) => ({
  border: `${theme.spacing(0.125)} solid ${theme.palette.grey[8]}`,
  borderRadius: theme.spacing(0.55),
}));

export const AdvancedListBoxStyled = styled(Stack)(({ theme }) => ({
  borderRight: `${theme.spacing(0.125)} solid ${theme.palette.grey[8]}`,
}));

export const AdvancedSelectedBoxStyled = styled(Stack)({});

export const AdvancedHeaderBoxStyled = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey[11],
  flexDirection: 'row',
  alignItems: 'center',
  height: theme.spacing(7.75),
  padding: theme.spacing(1.25, 3),
}));

export const TextFieldStyled = styled(TextField)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  backgroundColor: theme.palette.common.white,
  width: theme.spacing(37.5),
  borderRadius: theme.spacing(1),
}));

export const ActionButtonStyled = styled(Button, {
  shouldForwardProp: prop => prop !== 'actionType',
})(({ actionType, theme }) => {
  const actionTypeColor =
    actionType === 'add'
      ? theme.palette.primary.main
      : actionType === 'success'
        ? theme.palette.green[6]
        : theme.palette.error.main;
  return {
    color: actionTypeColor,
    fontSize: theme.spacing(1.5),
    fontWeight: 'bold',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    gap: theme.spacing(0.55),
    justifyContent: 'center',
    padding: 0,
    margin: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:disabled': {
      color: actionTypeColor,
      opacity: 0.33,
    },
  };
});

export const TabTypographyStyled = styled(Typography)(
  ({ internalOnly, theme, selected }) => ({
    color: internalOnly ?
      theme.palette.warning.main :
      theme.palette.common.black,
    fontWeight: selected ? 700 : 400,
    fontSize: theme.spacing(2.5),
  }),
);

export const TabsStyled = styled(Tabs)(({ theme }) => ({
  marginBottom: -2,
  '& .MuiTabs-indicator': {
    height: 4,
    backgroundColor: theme.palette.blue[12],
    borderRadius: 4,
  },
}));

export const ContextStyled = styled('span')(({ stressed }) => ({
  fontWeight: stressed ? 700 : 400,
}));

export const LegendTypographyStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[3],
  fontSize: theme.spacing(1.5),
}));
