import { yupResolver } from '@hookform/resolvers/yup';
import { defaultTo } from 'lodash';
import { useForm } from 'react-hook-form';
import {
  creativesSchema,
  fields,
  weightingRotationOptions,
} from '../constants';

export const useCreativeWrapper = ({
  creatives,
  onSubmit,
  weightingRotation,
}) => {
  const form = useForm({
    mode: 'all',
    resolver: yupResolver(creativesSchema),
    defaultValues: {
      ...Object.values(fields).reduce((acc, { path, defaultValue }) => {
        acc[path] = defaultValue;
        return acc;
      }),
      [fields.weightingRotation.path]:
        weightingRotation === weightingRotationOptions.weighted,
      [fields.creatives.path]: defaultTo(creatives, []),
    },
  });

  const handleSubmit = () => {
    onSubmit({
      creatives: form.getValues(fields.creatives.path),
      weightingRotation: form.getValues(fields.weightingRotation.path),
    });
  };

  return {
    form,
    submit: handleSubmit,
  };
};
