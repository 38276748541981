import React from 'react';

import {
  WizardContainer,
  WizardSectionsContainer,
} from '@v2/components/wizard/WizardContainer';
import { VerticalTimeline } from '@v2/components/wizard/VerticalTimeline';
import { SubwayMap } from '@v2/components/campaign/SubwayMap';

import { useCampaignWizardContext } from './CampaignWizardContext';
import { useWizardNavigation } from '../hooks';

function CampaignWizard() {
  const {
    sections,
    nodes,
    sectionKeys,
    addAdGroupSection,
    duplicateAdGroupSection,
    deleteAdGroupSection,
  } = useCampaignWizardContext();

  const { jumpTo, activeSectionKey } = useWizardNavigation();

  return (
    <WizardContainer>
      <VerticalTimeline>
        <SubwayMap
          subwayMapNodesData={nodes}
          sectionKeys={sectionKeys}
          activeSectionKey={activeSectionKey}
          activeNodeKey="" // TODO: get active node key from sections
          handleSelect={jumpTo}
          addAdGroup={addAdGroupSection}
          duplicateAdGroup={duplicateAdGroupSection}
          deleteAdGroup={deleteAdGroupSection}
        />
      </VerticalTimeline>

      <WizardSectionsContainer>
        {sections.map(
          ({
            component: SectionComponent,
            key: sectionKey,
            props: sectionProps,
          }) => (
            <SectionComponent
              key={sectionKey}
              sectionKey={sectionKey}
              {...sectionProps}
            />
          ),
        )}
      </WizardSectionsContainer>
    </WizardContainer>
  );
}

export default CampaignWizard;
