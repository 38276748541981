import { yupResolver } from '@hookform/resolvers/yup';
import { defaults } from 'lodash';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { formSchema, defaultValues as initialValues } from '../formConfig';
import { transformAdGroupToFields } from '../utils';

export const useAdGroupSettingsSectionForm = ({
  adGroup,
  bidStrategies,
  bundles,
  library,
  targeting,
  audiences,
  campaign,
}) => {
  const values = useMemo(
    () =>
      defaults(
        transformAdGroupToFields(adGroup, {
          customInventory: bundles,
          library,
          audiences,
          allowedTargeting: targeting,
          campaign,
        }),
        initialValues(),
      ),
    [adGroup, campaign, library, targeting, audiences, bundles],
  );

  const form = useForm({
    resolver: yupResolver(formSchema({ campaign, bidStrategies })),
    reValidateMode: 'onChange',
    mode: 'all',
    values,
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  return {
    form,
  };
};
