import { useTargetingKeys } from '../../../../../../components/hooks/apis/targetingKeys';
import { slice } from 'lodash';
import { useState } from 'react';
import { mapBundleTypeToDataItem } from '../../../../../../components/CampaignWizard/AdGroupSettingsSection/utils';
import { customInventoryTabValues } from '../../constants';

const types = {
  [customInventoryTabValues.appName]: 'app_name',
  [customInventoryTabValues.appListId]: 'app_id_list',
  [customInventoryTabValues.dealId]: 'deal_id',
};

export const useAdvancedCustomInventoryData = ({ tab, selected }) => {
  const [filter, setFilter] = useState('');
  const type = types[tab] ?? null;
  const { data } = useTargetingKeys({
    type,
    params: { search: filter },
  });

  return type
    ? {
        filter,
        setFilter,
        data: slice(data, 0, 50)
          .map(item => mapBundleTypeToDataItem(item, type))
          .filter(item => {
            if (!selected) {
              return true;
            }

            return !selected.some(
              selectedItem => selectedItem.id === item.id,
            );
          }),
      }
    : {};
};
