import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, DialogContentText } from '@mui/material';

export const termsAndConditionsDialog = ({ onSubmit }) => ({
  isOpen: true,
  title: 'We have updated our Terms & Conditions',
  content: (
    <>
      <DialogContentText>
        Please accept the updated Terms & Conditions to continue.
      </DialogContentText>
      <DialogContentText>
        If your Organization has additional agreements, those will take precedent as applicable.
      </DialogContentText>
    </>
  ),
  actions: (
    <>
      <Button
        variant="text"
        color="secondary"
        size="large"
        rel="noopener noreferrer"
        target="_blank"
        component={NavLink}
        to="/terms-and-conditions"
      >
        Read the updated Terms &amp; Conditions
      </Button>
      <Button
        variant="text"
        color="secondary"
        size="large"
        onClick={onSubmit}
      >
        Accept
      </Button>
    </>
  ),
});
