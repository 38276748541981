import LogRocket from 'logrocket';

import { useAuth } from './auth';

//////////////////////////
// useLOGROCKET HOOK
//////////////////////////
export const useLogRocket = () => {
    const { auth } = useAuth();
    const useLogRocket = (process.env.USE_LOG_ROCKET === 'true');

    const tagUser = async () => {
        if (!useLogRocket) {
            return;
        }

        const authUser = await auth.getUser();
        authUser && LogRocket.identify(authUser.sub, {
            name: authUser.name,
            email: authUser.email,
        });
    }

    return {
        tagUser,
    };
};
