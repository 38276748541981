import { useContext, useEffect } from 'react';
import { useUser } from './user';
import AdvertiserContext from '../AdvertiserContext';
import intercomWrapper from '../../helpers/intercomWrapper';

export const useIntercom = ({ advertiser }) => {
  const adContext = useContext(AdvertiserContext);
  const userContext = useUser();
  const { boot, update } = intercomWrapper.useIntercom();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const intercomEnvValue = urlParams.get('intercom-env');

    const isPeacockDomain = window.location.hostname.startsWith('peacock');
    const isStaging = intercomEnvValue === 'staging';

    //Set the intercom user
    if (userContext.user?.email) {
      const userHash = isPeacockDomain
        ? userContext.user.external_user_hash
        : (isStaging ? undefined : userContext.user.user_hash);

      const intercomUser = {
        email: userContext.user?.email,
        name: userContext.user?.name,
        userHash,

        customAttributes: {
          current_page_key: location.pathname,
        }
      };

      boot(intercomUser);
    }
  }, [userContext, adContext]);

  useEffect(() => {
    //Set the current advertiser
    if (advertiser && advertiser.id) {
      update({
        customAttributes:
          {
            current_advertiser_id: advertiser.id,
            current_advertiser_name: advertiser.name,
          }
      })
    }
  }, [advertiser]);

  useEffect(() => {
    //Set the current advertiser
    if (advertiser && advertiser.id) {
      update({
        customAttributes:
          {
            current_advertiser_id: advertiser.id,
            current_advertiser_name: advertiser.name,
            current_advertiser_role: advertiser.adv_role,
            feature_tvsciq: advertiser.tvsciq
          }
      })
    }
  }, [update, advertiser]);
};
