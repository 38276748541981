import { useFormContext } from 'react-hook-form';
import { fields } from '../../constants';

export const useListElement = ({ progress }) => {
  const form = useFormContext();
  const isComplete = parseInt(progress, 10) === 100; // Determine if upload is complete

  return {
    isComplete,
    form,
    weightedRotation: form.watch(fields.weightingRotation.path),
  };
};
