import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import { AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMegaphone,
  faMessageCaptions,
  faUserGroup,
  faCheck,
  faRocket,
} from '@fortawesome/pro-regular-svg-icons';

const iconMapping = {
  faMegaphone,
  faMessageCaptions,
  faUserGroup,
  faCheck,
  faRocket,
};

const StyledFontAwesomeIcon = styled(FontAwesomeIcon, {
  shouldForwardProp: prop => prop !== 'isActive',
})(
  ({ theme, isActive }) => ({
    color: isActive ? theme.palette.blue[6] : theme.palette.grey[4],
    height: 32,
    width: 32,
    marginRight: 20,
  }),
);

const StyledTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isActive',
})(({ theme, isActive }) => ({
  fontSize: 24,
  fontWeight: '600',
  color: isActive ? theme.palette.grey[0] : theme.palette.grey[3],
}));

const WizardSectionSummary = ({ icon, title, isActiveSection }) => {
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
    >
      {icon && (
        <StyledFontAwesomeIcon
          icon={iconMapping[icon]}
          isActive={isActiveSection}
        />
      )}
      <StyledTypography isActive={isActiveSection}>{title}</StyledTypography>
    </AccordionSummary>
  );
};

WizardSectionSummary.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  isActiveSection: PropTypes.bool,
};

export default WizardSectionSummary;
