import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { formatToUSD } from '../util';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CreativeTableHead from './CreativesTableHead';
import { creativeStatusIconRender } from './CreativesTable';
import TableFooter from '@mui/material/TableFooter';
import { useDisplays } from '../hooks/apis/displays';
import { useCurrentSession } from '../hooks';

const displayHeadCells = [
  { id: 'id', label: 'ID', disablePadding: false },
  { id: 'creativeName', label: 'Creative', disablePadding: false },
  { id: 'adGroupName', label: 'Ad Group', disablePadding: false },
  { id: 'startDate', label: 'Media Upload Date', disablePadding: false },
  { id: 'spend', label: 'Spend', disablePadding: false },
  // { id: 'budgetRemaining', label: 'Budget remaining', disablePadding: false },
  { id: 'visits', label: 'Visits', disablePadding: false },
  { id: 'costPerVisit', label: 'Cost per visit', disablePadding: false },
  { id: 'status', label: 'Status', disablePadding: false }
];

const DisplaysTable = ({ classes }) => {
  const { currentAdvertiser } = useCurrentSession();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const { displays, count } = useDisplays({ page, params: { page_size: pageSize } });

  // Reset the current page if the advertiser changes
  useEffect(() => {
    setPage(1);
  }, [currentAdvertiser])

  return (
    <TableContainer>
      <Table stickyHeader>
        <CreativeTableHead headers={displayHeadCells} classes={classes} />

        <TableBody>
          {displays?.map((display, index) => {
            const labelId = `creative-table-checkbox-${index}`;
            const {
              stats_caches_by_lineitem_id: stats_caches = {},
              staticdisplaylineitem_set = []
            } = display;
            // Get the lineitme Id of the creative to be used in the link below
            let adGroupId = 'na';

            const stats = stats_caches[adGroupId];
            const staticDisplay = staticdisplaylineitem_set[0] || {};

            // Get the campaign Id of the creative to be used in the link below
            let campaignId = 'na';

            if (staticDisplay.campaign) {
              campaignId = staticDisplay.campaign.split('/campaigns/')[1].slice(0, -1);
            }

            if (staticDisplay.url) {
              adGroupId = staticDisplay.url.split('/static_display_lineitems/')[1].slice(0, -1);
            }
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={`${display.id}-${index}`}
                data-testid="display-table-row"
              >
                <TableCell>{display.id}</TableCell>
                <TableCell
                  className={classes.tableCell}
                  id={labelId}
                  scope="row"
                  align="left"
                >
                  <Link className={classes.tableLink}
                        to={`/campaigns/${campaignId}/adgroups/${adGroupId}/displays/${display.id}`}>
                    {display.name}
                  </Link>
                </TableCell>

                <TableCell className={classes.tableCell} align="left">{staticDisplay.name ?
                  <Link className={classes.link} to={`/campaigns/${campaignId}/adgroups`}>
                    {staticDisplay.name}
                  </Link> : '-'}
                </TableCell>
                <TableCell className={classes.tableCell}
                           align="left">{moment(staticDisplay.start_date).format('l')}</TableCell>
                <TableCell className={classes.stat} align="left">
                  {stats && stats.total_spend != null ? formatToUSD(stats.total_spend) : '-'}
                </TableCell>
                <TableCell className={classes.stat} align="left">
                  {stats && stats.visit_count != null ? stats.visit_count : '-'}
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  {stats && stats.cost_per_visit != null ? formatToUSD(stats.cost_per_visit) : '-'}
                </TableCell>
                <TableCell className={classes.stat} align="left">
                  {creativeStatusIconRender(display.active)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[
                10,
                15,
                25,
                50
              ]}
              colSpan={8}
              count={count}
              rowsPerPage={pageSize}
              page={page - 1}
              onPageChange={(e, page) => {
                setPage(page + 1);
              }}
              onRowsPerPageChange={(e) => {
                setPage(1);
                setPageSize(e.target.value);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>);
};

DisplaysTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default DisplaysTable;
