import {
  CheckOutlined,
  CloseOutlined,
  RemoveCircleOutline,
} from '@mui/icons-material';
import { Stack } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';
import { ActionButtonStyled, AdvancedHeaderBoxStyled } from '../styles';
import { optionalOptionType, tableSettings } from '../types';

export const TableRightHeader = ({
  onRemoveAll,
  onExcludeAll,
  onIncludeAll,
  onRemoveAllHovered,
  settings,
  data = [],
}) => {
  return (
    <AdvancedHeaderBoxStyled>
      <Stack flex={1} alignItems="flex-start">
        <ActionButtonStyled
          disabled={!data.length}
          onClick={onRemoveAll}
          data-testid="advanced-remove-all"
          onMouseEnter={() => onRemoveAllHovered?.(true)}
          onMouseLeave={() => onRemoveAllHovered?.(false)}
          variant="text"
          color="primary"
        >
          <RemoveCircleOutline />
          Remove all
        </ActionButtonStyled>
      </Stack>
      {settings.includable && (
        <Stack direction="row" spacing={3}>
          <ActionButtonStyled
            data-testid="advanced-exclude-all"
            disabled={!data.length}
            onClick={onExcludeAll}
            variant="text"
            color="primary"
          >
            <CloseOutlined />
            Exclude all
          </ActionButtonStyled>
          <ActionButtonStyled
            data-testid="advanced-include-all"
            disabled={!data.length}
            actionType="success"
            onClick={onIncludeAll}
            variant="text"
            color="primary"
          >
            <CheckOutlined />
            Include all
          </ActionButtonStyled>
        </Stack>
      )}
    </AdvancedHeaderBoxStyled>
  );
};

TableRightHeader.propTypes = {
  data: PropTypes.arrayOf(optionalOptionType),
  onRemoveAll: PropTypes.func,
  onExcludeAll: PropTypes.func,
  onIncludeAll: PropTypes.func,
  onRemoveAllHovered: PropTypes.func,
  settings: tableSettings,
};
