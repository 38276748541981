import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  // InputBase, // TODO: TBD - Add Custom Tracking Event
  MenuItem,
  TextField,
  Typography,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
// import AddCircleIcon from '@mui/icons-material/AddCircle'; // TODO: TBD - Add Custom Tracking Event
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import { CheckCircleOutline as CheckCircleIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { useIntercom } from '../helpers/intercomWrapper';
import CallMadeIcon from '@mui/icons-material/CallMade';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from './ui/Accordians';
import { useTracking } from './hooks/tracking';
import InstallTrackingCode from './InstallTrackingCode';
import ModalWrapper from './ModalWrapper';
import AsyncButton from './AsyncButton';
import { useAPI } from './hooks/api';
import { useAdvertisers } from './hooks';
import { enqueueSnackbar } from 'notistack';

const PREFIX = 'WebsiteManageTracking';

const classes = {
  activeButton: `${PREFIX}-activeButton`,
  addEvent: `${PREFIX}-addEvent`,
  addEventBtn: `${PREFIX}-addEventBtn`,
  buttonGroup: `${PREFIX}-buttonGroup`,
  cancel: `${PREFIX}-cancel`,
  container: `${PREFIX}-container`,
  ecommerce: `${PREFIX}-ecommerce`,
  footer: `${PREFIX}-footer`,
  gradient: `${PREFIX}-gradient`,
  inactiveButton: `${PREFIX}-inactiveButton`,
  noEvents: `${PREFIX}-noEvents`,
  panelHeader: `${PREFIX}-panelHeader`,
  panels: `${PREFIX}-panels`,
};

const StyledModalWrapper = styled(ModalWrapper)(({
  theme: { palette, spacing }
}) => ({
  [`& .${classes.activeButton}`]: {
    fontSize: '1rem',
    backgroundColor: '#c3e7fa',
    color: '#181c20',
    fontWeight: 'normal',
    boxShadow: 'none !important',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    minWidth: 170,
    '&:hover': {
      backgroundColor: '#c3e7fa',
      cursor: 'unset',
    },
    '& .tvs-MuiSvgIcon-root': {
      marginRight: spacing(1),
    },
  },

  [`& .${classes.addEvent}`]: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    borderRadius: '10px',
    backgroundColor: '#fff',
    width: '93%',
    padding: spacing(1),
    boxShadow:
      '0 5px 5px -3px rgba(24, 28, 32, 0.07), 0 3px 14px 2px rgba(24, 28, 32, 0.12), 0 8px 10px 1px rgba(24, 28, 32, 0.1)',
  },

  [`& .${classes.addEventBtn}`]: {
    marginRight: spacing(1),
  },

  [`& .${classes.buttonGroup}`]: {
    marginTop: spacing(2),
    boxShadow: 'none',
    '& .tvs-MuiButton-root': {
      border: 'none',
    },
  },

  [`& .${classes.cancel}`]: {
    color: palette.grey.main,
  },

  [`& .${classes.container}`]: {
    overflow: 'hidden',
    position: 'relative',
    height: 'auto',
  },

  [`& .${classes.ecommerce}`]: {
    width: 220,
    marginRight: 5,

    '& .tvs-MuiSelect-root': {
      paddingTop: 14,
      paddingBottom: 12,
    },

    '& label': {
      marginLeft: 6,
    },

    '& fieldset': {
      border: 0,
      boxShadow: '0 5px 5px -3px rgb(24 28 32 / 7%), 0 3px 14px 2px rgb(24 28 32 / 12%), 0 2px 4px 1px rgb(24 28 32 / 10%)',
    },
  },

  [`& .${classes.footer}`]: {
    backgroundColor: '#e5e7eb',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },

  [`& .${classes.gradient}`]: {
    bottom: 101,
    pointerEvents: 'none',
  },

  [`& .${classes.inactiveButton}`]: {
    fontSize: '1rem',
    backgroundColor: '#ffffff',
    color: '#181c20',
    fontWeight: 'normal',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    minWidth: 170,
    '&:hover': {
      color: '#ffffff',
    },
  },

  [`& .${classes.noEvents}`]: {
    color: '#9aa0a6',
    fontSize: '1.0875rem',
    fontWeight: 600,
  },

  [`& .${classes.panelHeader}`]: {
    backgroundColor: '#e5e7eb',
  },

  [`& .${classes.panels}`]: {
    height: 720,
    overflow: 'auto',
    minHeight: 720,
  }
}));

// Edit sections for tracking
const trackingPanels = [
  { title: '1. Set Up Performance Tracking', id: 'method' },
  { title: '2. Select Tracking Event', id: 'events' },
  { title: '3. Install Tracking Code', id: 'code' },
];

const WebsiteManageTracking = ({ isOpen, data, onClose }) => {
  const { exposureLid, outcomeLid } = data;
  const { showArticle } = useIntercom();
  const { usePut, useGet, useDelete } = useAPI();
  const { currentAdvertiser } = useAdvertisers();
  const {
    ecommerce,
    ecommerceSites,
    handleSelectEvent,
    trackingMethod,
    selectedEvents,
    setEcommerce,
    setTrackingMethod,
    totalEvents,
  } = useTracking('pixel');

  // TODO: TBD - Add Custom Tracking Event
  // const [customEventName, setCustomEventName] = useState('');

  const [isUsingGA4, setIsUsingGA4] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [isApiKeySet, setIsApiKeySet] = useState(false);
  const [measurementId, setMeasurementId] = useState('');
  const [isGA4Saved, setIsGA4Saved] = useState(false);
  const [isShowTrackingScripts, setTrackingScripts] = useState(false);

  const [expanded, setExpanded] = useState({
    method: false,
    events: true,
    configure: false,
    code: false,
  });

  const onShopifyIntegrationGuideClick = useCallback((event) => {
    event.preventDefault();

    showArticle(9150998);
  }, []);

  const handleShowTrackingScripts = useCallback(() => {
    setTrackingScripts(true);
  }, []);

  const totalEventsFilterCallback = useCallback((event) => {
    if (ecommerce === 'shopify') {
      return event.name === 'Complete purchase';
    }

    return event;
  }, [ecommerce]);

  const handleExpanded = panel => event => {
    if (event && event.target != null) {
      setExpanded(x => ({
        ...x,
        [panel]: !x[panel],
      }));
    }
  };

  useEffect(() => {
    if (isUsingGA4 && !isApiKeySet) {
      showArticle(9583560);
    }
  }, [isUsingGA4, isApiKeySet, showArticle]);

  const shouldShowTrackingCode = useMemo(() => {
    if (!isUsingGA4) return true;
    return isGA4Saved || (!!measurementId && isApiKeySet);
  }, [isUsingGA4, isGA4Saved, isApiKeySet]);

  const handleSave = async () => {
    try {
      const payload = {
        measurement_id: measurementId,
        api_secret: apiKey,
      };
      await usePut(`/advertisers/${currentAdvertiser.id}/googleanalytics/`, payload);
      setIsGA4Saved(true);
      setIsApiKeySet(true)
      enqueueSnackbar('Google Analytics settings saved successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error saving Google Analytics settings:', error);
      enqueueSnackbar('Error saving Google Analytics settings', { variant: 'error' });
    }
  };

  const handleDelete = async () => {
    try {
      await useDelete(`/advertisers/${currentAdvertiser.id}/googleanalytics/`);
      setMeasurementId('');
      setApiKey('');
      setIsApiKeySet(false);
      setIsGA4Saved(false);
      enqueueSnackbar('Google Analytics settings deleted successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error deleting Google Analytics settings:', error);
      enqueueSnackbar('Error deleting Google Analytics settings', { variant: 'error' });
    }
  };

  useEffect(() => {
    const fetchGASettings = async () => {
      try {
        const response = await useGet(`/advertisers/${currentAdvertiser.id}/googleanalytics/`);
        setMeasurementId(response.measurement_id);
        setIsApiKeySet(response.is_api_secret_set);
      } catch (error) {
        console.error('Error fetching Google Analytics settings:', error);
      }
    };
    if (currentAdvertiser?.id) {
      fetchGASettings();
    }
  }, []);

  // TODO: TBD - Add Custom Tracking Event
  // const handleCustomEvent = () => {
  //   if (customEventName && customEventName !== '') {
  //     const eventData = {
  //       name: customEventName.trim(),
  //       slug: customEventName.trim().replace(/[ -]/g, '_'),
  //       value: '',
  //     };

  //     if (selectedEvents.find(event => event.name === eventData.name)) {
  //       setSelectedEvents(prev =>
  //         prev.filter(item => item.name !== eventData.name)
  //       );
  //       setTotalEvents(prev => prev.filter(item => item.name !== eventData.name));
  //     } else {
  //       setSelectedEvents(prev => [...prev, eventData]);
  //       setTotalEvents(prev => [...prev, eventData]);
  //       setCustomEventName('');
  //     }
  //   }
  // };

  const renderMethod = () => (
    <ButtonGroup
      variant="contained"
      color="primary"
      aria-label="contained primary button group"
      className={classes.buttonGroup}
    >
      <Button
        disableRipple
        color="secondary"
        size="large"
        className={
          trackingMethod === 'pixel'
            ? classes.activeButton
            : classes.inactiveButton
        }
        onClick={() => setTrackingMethod('pixel')}
      >
        Tracking Pixel
      </Button>
    </ButtonGroup>
  );

  const renderEvents = () => (
    <Grid container spacing={1}>
      <Grid container item justifyContent="flex-end">
        <Box mb={0} mr={3} mt={1}>
          <TextField
            color="secondary"
            className={classes.ecommerce}
            label="Ecommerce Platform"
            onChange={event => setEcommerce(event.target.value)}
            placeholder="Ecommerce Platform"
            select
            size="small"
            value={ecommerce}
            variant="outlined"
          >
            {ecommerceSites.map((e, i) => (
              <MenuItem key={e} value={e.replace(/\s+/g, '-').toLowerCase()}>
                {i === 0 ? <em>{e}</em> : e}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Grid>

      {ecommerce === 'shopify' && (
        <Grid container item justify="flex-start">
          <Grid container spacing={3} mb={6} mt={1}>
            <Grid item>
              <Typography variant="h4">Install the tvScientific Shopify App</Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="body1"
                color="#13171A"
                sx={{ fontWeight: '400' }}
              >
                The tvScientific Shopify App will automagically install all
                the required tracking scripts in all the right places. <br />
                For websites running Shopify Plus,&nbsp;

                <Link
                  component="button"
                  underline="none"
                  sx={{ verticalAlign: 'initial' }}
                  onClick={onShopifyIntegrationGuideClick}
                >
                  please see separate documentation
                </Link>
              </Typography>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  endIcon={<CallMadeIcon />}
                  href="https://apps.shopify.com/tvscientific"
                  target="_blank"
                  rel="noreferrer"
                  sx={{
                    '&:hover': {
                      color: '#fff',
                    },
                  }}
                >
                  Install our Shopify App
                </Button>
              </Grid>

              <Grid item>
                <Button
                  disableRipple
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={handleShowTrackingScripts}
                >
                  No thanks – show me the tracking scripts
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {isShowTrackingScripts && (
        <Grid container item>
          {totalEvents
            .filter(totalEventsFilterCallback)
            .map(t => (
              <Grid item xs={4} key={t.slug}>
                <Box m={1} mr={2} className={clsx('Button-choice', 'full')}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!selectedEvents.find(e => e.name === t.name)}
                        name={t.name}
                        onClick={() => handleSelectEvent(t)}
                      />
                    }
                    label={t.name}
                  />
                </Box>
              </Grid>
            ))
          }

          {/* TODO: TBD - Add Custom Tracking Event */}
          {/* <Grid id="new-event-container" item xs={4}>
          <Box className={classes.addEvent}>
            <IconButton
              className={classes.addEventBtn}
              onClick={handleCustomEvent}
              size="small"
            >
              <AddCircleIcon color="secondary" />
            </IconButton>

              <InputBase
                inputProps={{ 'aria-label': 'naked' }}
                onChange={event => setCustomEventName(event.target.value)}
                placeholder="Add tracking event"
                value={customEventName}
              />
            </Box>
          </Grid> */}
        </Grid>
      )}


      <Grid item xs={12}>
        <Box m={1} mr={2}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                onChange={(e) => {
                  setIsUsingGA4(e.target.checked);
                  if (!e.target.checked) {
                    setIsGA4Saved(false);
                  }
                }}
                checked={isUsingGA4}
              />
            }
            label={
              <Box display="flex" alignItems="center">
                <span>I am using Google Analytics 4 (GA4)</span>
                <Tooltip title="Please email your account manager or support@tvscientific.com with your Measurement Protocol API Secret from your Google Analytics account.">
                  <InfoIcon fontSize="small" style={{ marginLeft: '8px', color: '#1DAFFF' }} />
                </Tooltip>
              </Box>
            }
          />
        </Box>
      </Grid>

      {isUsingGA4 && (
        <Grid container spacing={2} alignItems="center">
          {isApiKeySet ? (
            <>
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <CheckCircleIcon color="secondary" style={{ marginRight: '8px' }} />
                  <Typography variant="body1">GA4 Measurement ID</Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <CheckCircleIcon color="secondary" style={{ marginRight: '8px' }} />
                  <Typography variant="body1">GA4 API Key</Typography>
                  <IconButton size="small" style={{ marginLeft: 'auto' }} onClick={handleDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={3}>
                <TextField
                  color="secondary"
                  fullWidth
                  label="GA Measurement ID"
                  variant="outlined"
                  value={measurementId.startsWith('G-') ? measurementId.slice(2) : measurementId}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const formattedValue = inputValue.startsWith('G-') ? inputValue : `G-${inputValue}`;
                    setMeasurementId(formattedValue);
                  }}
                  InputProps={{
                    startAdornment: <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>G-</span>,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  color="secondary"
                  fullWidth
                  label="GA4 API Key"
                  variant="outlined"
                  type="password"
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <AsyncButton
                  color="secondary"
                  disableElevation={true}
                  size="medium"
                  variant="contained"
                  onClick={handleSave}
                  fullWidth
                  isDisabled={!measurementId || !apiKey}
                >
                  Save
                </AsyncButton>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Grid>
  );

  const renderCode = () => {
    if (!shouldShowTrackingCode) {
      return (
        <Box mt={2}>
          <Typography variant="body1" color="error">
            Please save your Google Analytics 4 settings to view the tracking code installation instructions.
          </Typography>
        </Box>
      );
    }

    return (
      <InstallTrackingCode
        exposureLid={exposureLid}
        outcomeLid={outcomeLid}
        trackingMethod={trackingMethod}
        selectedEvents={selectedEvents}
        ecommerce={ecommerce}
        isUsingGA4={isUsingGA4}
        measurementId={measurementId}
      />
    );
  };

  // // Handle component renders
  const renderPanelDetails = id => {
    switch (id) {
      case 'method':
        return renderMethod();
      case 'events':
        return renderEvents();
      case 'code':
        return renderCode();
      default:
        return null;
    }
  };

  // Render an accordion panel for each editable section
  const renderPanel = panel => (
    <Accordion
      expanded={expanded[panel.id]}
      onChange={handleExpanded(panel.id)}
      TransitionProps={{ unmountOnExit: panel.id !== 'demo' }}
    >
      <AccordionSummary aria-controls="adgroup-panel" id={`edit-${panel.id}`}>
        <Grid
          className={classes.panelHeader}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography>{panel.title}</Typography>
          </Grid>

          <Grid item>
            {expanded[panel.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Box py={1} px={2} width="100%">
          {renderPanelDetails(panel.id)}
        </Box>
      </AccordionDetails>
    </Accordion>
  );

  useEffect(() => {
    setTrackingScripts(ecommerce !== 'shopify');
  }, [ecommerce]);

  return (
    <StyledModalWrapper isOpen={isOpen} onClose={onClose}>
      <Box flexGrow={1}>
        <Box mb={4}>
          <Typography variant="h3">Add Website Tracking Event</Typography>
        </Box>

        <Grid container spacing={3}>
          {trackingPanels.map((s, i) => (
            <Grid key={s.id} item xs={12}>
              {renderPanel(s, i)}
            </Grid>
          ))}
        </Grid>
      </Box>
    </StyledModalWrapper>
  );
};

WebsiteManageTracking.propTypes = {
  isOpen: PropTypes.bool,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WebsiteManageTracking;
