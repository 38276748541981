import { Button, Stack } from '@mui/material';
import { omit } from 'lodash';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Advanced } from '../../Advanced';
import { CancelButtonStyled } from '../styles';
import { useAdvancedTable } from './hooks/useAdvancedTable';
import { FormProvider } from 'react-hook-form';

export const AdvancedTable = ({
  tabs,
  onClose,
  onSubmit,
  selected,
  selectedFilter,
  total,
  form,
  onChangeTab,
  selectedTab,
  ...props
}) => {
  const { actionType } = useAdvancedTable({ selected, selectedTab, tabs });

  return (
    <FormProvider {...form}>
      <Stack
        component="form"
        onSubmit={form.handleSubmit(onSubmit)}
        spacing={6}
      >
        <Stack spacing={1.5}>
          <Advanced
            {...props}
            selectedTab={selectedTab}
            minRowsAmount={10}
            selected={selected}
            selectedFilter={selectedFilter}
            tabs={tabs}
            onChangeTab={v => {
              onChangeTab(tabs.find(tab => tab.value === v));
            }}
          />
        </Stack>
        <Stack justifyContent="space-between" direction="row">
          <Stack>
            <CancelButtonStyled variant="text" onClick={onClose}>
              Cancel
            </CancelButtonStyled>
          </Stack>
          <Stack>
            <Button
              type="submit"
              disabled={!form.formState.isValid}
              variant="contained"
            >
              {actionType} {total ?? selected.length} results
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </FormProvider>
  );
};

AdvancedTable.propTypes = {
  ...omit(Advanced.propTypes, ['selectedTab', 'onChangeTab']),
  onClose: PropTypes.func.isRequired,
  total: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};
