import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { Box, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const PREFIX = 'SelectedSegmentItem';

const classes = {
  iconContainer: `${PREFIX}-iconContainer`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.blue[11],
  padding: '16px 24px',
  gap: theme.spacing(2),
  borderRadius: 5,

  [`& .${classes.iconContainer}`]: {
    color: theme.palette.blue[4],
  },
}));

const StyledPenIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.blue[6],
  cursor: 'pointer',
  marginLeft: 'auto',
}));

const StyledPlaceholder = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[4],
}));

function SelectedSegmentItem({ icon, label, onEdit, placeholderText }) {
  const labelNode =
    label && label.length ? (
      <Typography>{label}</Typography>
    ) : (
      <StyledPlaceholder onClick={onEdit}>
        {placeholderText}
      </StyledPlaceholder>
    );

  return (
    <StyledBox>
      <div className={classes.iconContainer}>{icon}</div>
      {labelNode}
      {onEdit && <StyledPenIcon icon={faPen} onClick={onEdit} />}
    </StyledBox>
  );
}

SelectedSegmentItem.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  onEdit: PropTypes.func,
  placeholderText: PropTypes.string,
};

export default SelectedSegmentItem;
