import { faArrowLeft, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, IconButton, Stack, styled } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Info } from '../Info';

const AdvancedBoxStyled = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4.5, 0),
  flex: 1,
}));

const BackIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.blue[6],
}));

const ContentWrapperStyled = styled(Stack)(() => ({
  marginTop: 0,
  flex: 1,
}));

const HeaderWrapperStyled = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(2.5),
  width: '100%',
}));

const InfoBoxInfoIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(2.25),
  height: theme.spacing(2.25),
}));

const InfoBoxCloseStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[6],
}));

const InfoBoxStyled = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  color: theme.palette.grey[6],
}));

const WrapperBoxStyled = styled(Stack)({
  height: '100%',
});

export const AdvancedBox = ({
  header,
  children,
  onClose,
  onInfoClose,
  info,
  error,
  ...props
}) => {
  return (
    <WrapperBoxStyled display="flex" flexDirection="column" {...props}>
      {error && <Info type="error">{error}</Info>}
      {info && (
        <InfoBoxStyled
          sx={{
            backgroundColor: 'blue.10',
          }}
        >
          <Stack alignItems="center" direction="row" spacing={1.5} flex={1}>
            <InfoBoxInfoIconStyled
              sx={{
                color: 'blue.1',
              }}
              icon={faInfoCircle}
            />
            {info}
          </Stack>
          <Button
            variant="text"
            onClick={onInfoClose}
            endIcon={<InfoBoxCloseStyled icon={faClose} />}
          />
        </InfoBoxStyled>
      )}
      <AdvancedBoxStyled flex={1} spacing={4}>
        <Stack
          justifyItems="center"
          alignItems="center"
          direction="row"
          spacing={1.25}
        >
          <BackIcon onClick={onClose}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </BackIcon>
          <Divider flexItem orientation="vertical" />
          <HeaderWrapperStyled>{header}</HeaderWrapperStyled>
        </Stack>
        <ContentWrapperStyled display="flex" flex={1} flexDirection="column">
          {children}
        </ContentWrapperStyled>
      </AdvancedBoxStyled>
    </WrapperBoxStyled>
  );
};

AdvancedBox.propTypes = {
  ...Stack.propTypes,
  info: PropTypes.node,
  error: PropTypes.node,
  header: PropTypes.node,
  onClose: PropTypes.func,
  onInfoClose: PropTypes.func,
};
