import Papa from 'papaparse';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import sanitize from 'sanitize-html';

export const useAddZipCodes = ({ onChange, value, wrongZipCodes }) => {
  const editorRef = useRef();
  const [showDropzone, setShowDropzone] = useState(false);

  const handleZipCodeChange = e => {
    const zipCodeText = e.target.value;
    const san = sanitize(zipCodeText);

    const zipCodeArray = san
      .replace(/<\/?(span|br)(.*?)>/gi, '')
      .split(',')
      .map(zip => zip.trim());

    const codes = zipCodeArray.map(zip => ({ zip }));

    onChange(codes);
  };

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    if (file && file.type === 'text/csv') {
      Papa.parse(file, {
        complete: result => {
          const zipCodesFromFile = result.data.map(row => row[0]);
          onChange(zipCodesFromFile.map(zip => ({ zip })));
          setShowDropzone(false);
        },
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDragOver: event => event.preventDefault(),
    onDragEnter: () => setShowDropzone(true),
    onDragLeave: () => setShowDropzone(false),
    accept: '.csv',
  });

  const actualValue = useMemo(
    () =>
      value.map(v => ({
        ...v,
        valid: !wrongZipCodes.includes(v.zip),
      })),
    [value, wrongZipCodes],
  );

  return {
    editorRef,
    actualValue,
    getRootProps,
    getInputProps,
    isDragActive,
    handleZipCodeChange,
    showDropzone,
  };
};
