import React from 'react';
import {
  Grid,
  Icon,
  Paper,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { CampaignTypeForm } from '@v2/components/forms';
import { useWizardSelector } from './hooks/wizardSelector';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AppHeader from '@components/AppHeader';

export const WizardSelector = () => {
  const { select, theme, key } = useWizardSelector();
  return (
    <ThemeProvider theme={theme} key={key}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AppHeader>
          <Stack
            sx={{ px: 12, py: 6 }}
            spacing={8}
            justifyContent="space-between"
          >
            <Paper elevation={3} sx={{ px: 8, py: 6 }}>
              <Stack spacing={4}>
                <Typography variant="h4">
                  <Icon name="flaskGear" /> How do you want to build your
                  campaign?
                </Typography>
                <CampaignTypeForm onSubmit={select} />
              </Stack>
            </Paper>
          </Stack>
        </AppHeader>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
