import { isUndefined } from 'lodash';
import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { useWizardQuery } from './useWizardQuery';
import ModalContext from '@providers/ModalContext';
import UnsavedChangesPrompt from '@v2/components/ui/UnsavedChangesPrompt';

export const useWizardNavigation = ({ sections = [] }) => {
  const entitiesStatesRef = useRef(new Map());
  const {
    activeSection,
    activePane,
    goToSection,
    goToCampaign,
    campaignId,
    ...other
  } = useWizardQuery();

  const { setModal } = useContext(ModalContext);

  const currentSectionIndex = useMemo(
    () => sections.findIndex(s => s === activeSection),
    [sections, activeSection],
  );

  const nextSectionIndex = useMemo(
    () =>
      currentSectionIndex === sections.length - 1
        ? undefined
        : currentSectionIndex + 1,
    [currentSectionIndex],
  );

  const prevSectionIndex = useMemo(
    () => (currentSectionIndex === 0 ? undefined : currentSectionIndex - 1),
    [currentSectionIndex],
  );

  const goTo = useCallback(
    ({ campaignId, sectionId, paneId }, { checkDirty = true } = {}) => {
      const goer = () =>
        campaignId
          ? goToCampaign(campaignId, sectionId, paneId)
          : goToSection(sectionId, paneId);

      if (checkDirty) {
        const { dirty: dirtySection } =
          entitiesStatesRef.current.get(activeSection) || {};

        const { dirty: dirtyPane } =
          entitiesStatesRef.current.get(activePane) || {};

        if (
          (sectionId !== activeSection && dirtySection) ||
          (sectionId === activeSection && dirtyPane)
        ) {
          setModal({
            isOpen: true,
            component: () => (
              <UnsavedChangesPrompt
                closeModal={() => setModal(null)}
                onConfirm={() => {
                  goer();
                  // This needs to happen last, otherwise the modal will unmount and the onConfirm won't finish
                  setModal(null);
                }}
                workflowName={'this section'}
              />
            ),
          });

          return;
        }
      }

      goer();
    },
    [goToSection, goToCampaign],
  );

  const hasPrev = useMemo(
    () => !isUndefined(prevSectionIndex) && prevSectionIndex !== -1,
    [prevSectionIndex],
  );

  const hasNext = useMemo(
    () => !isUndefined(nextSectionIndex) && sections[nextSectionIndex],
    [nextSectionIndex],
  );

  const goToNext = useCallback(
    options => {
      if (hasNext) {
        goTo(
          {
            campaignId,
            sectionId: sections[nextSectionIndex],
          },
          options,
        );
      }
    },
    [sections, campaignId, hasNext, nextSectionIndex, currentSectionIndex],
  );

  const goToPrev = useCallback(
    options => {
      if (hasPrev) {
        goTo({ campaignId, sectionId: sections[prevSectionIndex] }, options);
      }
    },
    [sections, hasPrev, campaignId, currentSectionIndex, prevSectionIndex],
  );

  const handleUpdateEntityState = useCallback((entityId, status) => {
    entitiesStatesRef.current = new Map(
      entitiesStatesRef.current.set(entityId, status),
    );
  }, []);

  const handeToggleSection = providedSectionId => {
    if (providedSectionId !== activeSection) {
      goTo({
        campaignId,
        sectionId: providedSectionId,
      });

      return;
    }

    goToCampaign(campaignId, null);
  };

  const handleHidePane = () => {
    goTo({
      campaignId,
      sectionId: activeSection,
    });
  };

  return {
    goToNext,
    goToPrev,
    goTo,
    hasNext,
    hasPrev,
    activeSection,
    activePane,
    campaignId,
    hidePane: handleHidePane,
    toggleSection: handeToggleSection,
    updateEntityState: handleUpdateEntityState,
    ...other,
  };
};
