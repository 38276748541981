import { Box } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';
import { TableLeftHeader } from './TableLeft';
import { TableLeft } from './TableLeft/TableLeft';
import { TableRightHeader } from './TableRight';
import { TableRight } from './TableRight/TableRight';
import {
  AdvancedListBoxStyled,
  AdvancedSelectedBoxStyled,
  AdvancedWrapperStyled,
} from './styles';
import { dataType, optionType, tableSettings } from './types';
import { PlaceholderCell } from './PlaceholderCell';
import { useTable } from './hooks/useTable';
import { transformFromGroupedData, transformToIncludable } from './utils';
import { defaultTableSettings } from './constants';

export const Table = ({
  data,
  onChange,
  dataName,
  selected,
  selectedFilter,
  onFilter,
  minRowsAmount,
  renderCell,
  placeHolderCell,
  settings = defaultTableSettings,
  ...props
}) => {
  const {
    filteredSelected,
    data: actualData,
    isAddAllHovered,
    setIsAddAllHovered,
    isRemoveAllHovered,
    setIsRemoveAllHovered,
  } = useTable({ data, minRowsAmount, selected, selectedFilter });
  return (
    <AdvancedWrapperStyled direction="row" {...props}>
      <AdvancedListBoxStyled flex={1}>
        <TableLeftHeader
          data={data}
          onFilter={onFilter}
          onAddAllHovered={setIsAddAllHovered}
          onAddAll={() =>
            onChange([
              ...selected,
              ...transformFromGroupedData(data, selected, settings),
            ])
          }
        />
        <TableLeft
          data={actualData}
          dataName={dataName}
          renderCell={renderCell}
          placeholderCell={placeHolderCell ?? <PlaceholderCell />}
          isTargeted={isAddAllHovered}
          onChange={v =>
            onChange([
              transformToIncludable(v, selected, settings),
              ...selected,
            ])
          }
        />
      </AdvancedListBoxStyled>
      <AdvancedSelectedBoxStyled flex={1}>
        <TableRightHeader
          settings={settings}
          data={selected}
          onRemoveAll={() => onChange([])}
          onIncludeAll={() =>
            onChange(selected.map(s => ({ ...s, included: true })))
          }
          onExcludeAll={() =>
            onChange(selected.map(s => ({ ...s, included: false })))
          }
          onRemoveAllHovered={setIsRemoveAllHovered}
        />
        <TableRight
          settings={settings}
          data={filteredSelected}
          placeholderCell={placeHolderCell ?? <PlaceholderCell />}
          renderCell={renderCell}
          isTargeted={isRemoveAllHovered}
          onUpdate={v => onChange(selected.map(s => (s.id === v.id ? v : s)))}
          onChange={v => onChange(selected.filter(s => s.id !== v.id))}
        />
      </AdvancedSelectedBoxStyled>
    </AdvancedWrapperStyled>
  );
};

Table.propTypes = {
  ...Box.propTypes,
  selected: PropTypes.arrayOf(optionType).isRequired,
  onChange: PropTypes.func.isRequired,
  dataName: PropTypes.string.isRequired,
  data: dataType,
  onFilter: PropTypes.func,
  renderCell: PropTypes.func.isRequired,
  placeHolderCell: PropTypes.node,
  minRowsAmount: PropTypes.number,
  settings: tableSettings,
};
