import SelectedSegmentItem from '@v2/components/ui/SelectedSegmentItem';
import React from 'react';
import IncludeExcludeToggle from './IncludeExcludeToggle';
import PropTypes from 'prop-types';
import { getActualField } from './utils';

export const GeographyPreview = ({
  value,
  onChange,
  onEdit,
  placeholder = 'Geography selector',
}) => {
  return (
    <SelectedSegmentItem
      icon={
        <IncludeExcludeToggle
          value={value.blacklist ? 'exclude' : 'include'}
          onChange={type => {
            onChange({
              ...value,
              blacklist: type === 'exclude',
            });
          }}
        />
      }
      label={getActualField(value) ?? placeholder}
      onEdit={onEdit}
      placeholderText={placeholder}
    />
  );
};

GeographyPreview.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  placeholder: PropTypes.string,
};
