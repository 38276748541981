import React, { useCallback, useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Box, Grid, Link, DialogTitle, IconButton, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CallMadeIcon from '@mui/icons-material/CallMade';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { CreativeQuantityControl } from './index';
import { usePluralCopy } from '../hooks';
import { WrenchIcon } from '../icons';
import ModalWrapper from '../ModalWrapper';
import ModalContext from '../../providers/ModalContext';

const PREFIX = 'CreativeQCFormattingIssuesInfo';

const classes = {
  h3: `${PREFIX}-h3`,
  h6: `${PREFIX}-h6`,
  body1: `${PREFIX}-body1`,
  body2: `${PREFIX}-body2`,
  subtitle2: `${PREFIX}-subtitle2`,
  title: `${PREFIX}-title`,
  middleVerticalAlign: `${PREFIX}-middleVerticalAlign`,
  baselineVerticalAlign: `${PREFIX}-baselineVerticalAlign`,
  textBottomVerticalAlign: `${PREFIX}-textBottomVerticalAlign`
};

const StyledModalWrapper = styled(ModalWrapper)(() => ({
  [`& .${classes.title}`]: {
    borderBottom: '1px solid #e5e7eb',
    padding: '0',
  },

  [`& .${classes.middleVerticalAlign}`]: {
    verticalAlign: 'middle',
  },

  [`& .${classes.baselineVerticalAlign}`]: {
    verticalAlign: 'baseline',
  },

  [`& .${classes.textBottomVerticalAlign}`]: {
    verticalAlign: 'text-bottom',
  }
}));

const commonTypographyStyles = {
  color: '#13171A',
  lineHeight: '1.7',
};

const StyledTypography = styled(Typography)({
  h3: commonTypographyStyles,
  h6: commonTypographyStyles,
  body1: commonTypographyStyles,
  body2: commonTypographyStyles,
  subtitle2: commonTypographyStyles,
});

const subtitlePluralCopies = {
  1: () => "One video has formatting issues",
  plural: (count) => `${count} videos have formatting issues`,
};

const subbodyPluralCopies = {
  1: () => "This video has automatically been sent to our creative experts to be fixed, at no cost. This may take up to 24 hours.",
  plural: () => "These videos have automatically been sent to our creative experts to be fixed, at no cost. This may take up to 24 hours.",
};

const CreativeQCFormattingIssuesInfo = ({ data, onClose }) => {
  const { items, email } = data;

  const { setModal } = useContext(ModalContext);

  const { enqueueSnackbar } = useSnackbar();
  const getPluralCopy = usePluralCopy(items.length);

  const handleCopy = useCallback(() => {
    const messageCopies = items.map(item => {
      const messages = item.messages.map(message => `- ${message}`).join('\n');

      return `${item.name}:\n${messages}`;
    }).join('\n\n');

    const clipboardCopy = `Formatting issues being fixed:\n${messageCopies}`;
    const snackbarCopy = `The description of the formatting issues were copied to your clipboard`;

    window.navigator.clipboard.writeText(clipboardCopy);

    enqueueSnackbar(snackbarCopy, {
      variant: 'copySnackbar',
      autoHideDuration: 2000,
      preventDuplicate: true,
    })
  }, [items]);

  const handleAutomaticCreativeRepairClick = (event) => {
    event.preventDefault();

    setModal({
      isOpen: true,
      component: CreativeQuantityControl,
    });
  };

  return (
    <StyledModalWrapper
      isOpen
      hasSubmit
      submit="Got it"
      maxWidth="md"
      onClose={onClose}
      onSubmit={onClose}
      noCancel
      noFooterBg
    >
      <DialogTitle className={classes.title}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            item
            container
            alignItems="center"
            spacing={1}
            xs={10}
          >
            <Grid item>
              <WrenchIcon
                className={classes.middleVerticalAlign}
                width="28px"
                height="28px"
              />
            </Grid>

            <Grid item xs>
              <StyledTypography
                variant="h3"
                classes={{
                  h3: classes.h3,
                  h6: classes.h6,
                  body1: classes.body1,
                  body2: classes.body2,
                  subtitle2: classes.subtitle2
                }}>
                Formatting issues being fixed
              </StyledTypography>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <Box p={3}>
        <Grid container direction="column" alignItems="flex-end">
          <Grid item>
            <IconButton onClick={handleCopy} aria-label="copy" size="large">
              <FileCopyOutlinedIcon color="secondary" fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>

        <Box width="505px" margin="0 auto">
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            spacing={5}
          >
            <Grid item>
              <StyledTypography
                variant="h6"
                classes={{
                  h3: classes.h3,
                  h6: classes.h6,
                  body1: classes.body1,
                  body2: classes.body2,
                  subtitle2: classes.subtitle2
                }}>
                {getPluralCopy(subtitlePluralCopies)}
              </StyledTypography>
              <StyledTypography
                variant="body1"
                classes={{
                  h3: classes.h3,
                  h6: classes.h6,
                  body1: classes.body1,
                  body2: classes.body2,
                  subtitle2: classes.subtitle2
                }}>
                {getPluralCopy(subbodyPluralCopies)}
              </StyledTypography>
            </Grid>

            {items.map((item) => (
              <Grid item key={item.name}>
                <StyledTypography
                  variant="h6"
                  classes={{
                    h3: classes.h3,
                    h6: classes.h6,
                    body1: classes.body1,
                    body2: classes.body2,
                    subtitle2: classes.subtitle2
                  }}>
                  {item.name}
                </StyledTypography>

                <StyledTypography
                  component="ul"
                  classes={{
                    h3: classes.h3,
                    h6: classes.h6,
                    body1: classes.body1,
                    body2: classes.body2,
                    subtitle2: classes.subtitle2
                  }}>
                  {item.messages.length === 0 && (
                    <StyledTypography
                      component="li"
                      variant="body1"
                      classes={{
                        h3: classes.h3,
                        h6: classes.h6,
                        body1: classes.body1,
                        body2: classes.body2,
                        subtitle2: classes.subtitle2
                      }}>
                      The creative is still processing, check back here to see feedback
                    </StyledTypography>
                  )}

                  {item.messages.map((item, index) => (
                    <StyledTypography
                      component="li"
                      variant="body1"
                      key={index}
                      classes={{
                        h3: classes.h3,
                        h6: classes.h6,
                        body1: classes.body1,
                        body2: classes.body2,
                        subtitle2: classes.subtitle2
                      }}>
                      {item}
                    </StyledTypography>
                  ))}
                </StyledTypography>
              </Grid>
            ))}

            <Grid item>
              <StyledTypography
                variant="subtitle2"
                classes={{
                  h3: classes.h3,
                  h6: classes.h6,
                  body1: classes.body1,
                  body2: classes.body2,
                  subtitle2: classes.subtitle2
                }}>If you have any questions:</StyledTypography>

              <StyledTypography
                variant="body2"
                classes={{
                  h3: classes.h3,
                  h6: classes.h6,
                  body1: classes.body1,
                  body2: classes.body2,
                  subtitle2: classes.subtitle2
                }}>
                Contact your tvSci Expert at <Link href={`mailto:${email}`} underline="hover">{email}</Link>
              </StyledTypography>

              <StyledTypography
                variant="body2"
                classes={{
                  h3: classes.h3,
                  h6: classes.h6,
                  body1: classes.body1,
                  body2: classes.body2,
                  subtitle2: classes.subtitle2
                }}>
                Or read more about{' '}
                <Typography variant="body2" component="span">
                  <Link
                    component="button"
                    variant="body2"
                    onClick={handleAutomaticCreativeRepairClick}
                    className={classes.baselineVerticalAlign}
                    underline="hover">
                    Automatic Creative Repair{' '}
                    <CallMadeIcon
                      fontSize="small"
                      className={classes.textBottomVerticalAlign}
                    />
                  </Link>
                </Typography>
              </StyledTypography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </StyledModalWrapper>
  );
};

CreativeQCFormattingIssuesInfo.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreativeQCFormattingIssuesInfo;
