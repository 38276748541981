import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationMinus,
  faLocationPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { MenuItem, Select, styled } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    paddingBottom: 0,
    paddingTop: 0,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: '22px',

  '&.fa-location-minus': {
    color: theme.palette.red[1],
  },

  '&.fa-location-plus': {
    color: theme.palette.blue[12],
  },
}));

function IncludeExcludeToggle({ value, onChange }) {
  return (
    <StyledSelect
      value={value}
      onChange={e => onChange(e.target.value)}
      displayEmpty
    >
      <MenuItem value="include">
        <StyledIcon icon={faLocationPlus} />
      </MenuItem>
      <MenuItem value="exclude">
        <StyledIcon icon={faLocationMinus} />
      </MenuItem>
    </StyledSelect>
  );
}

IncludeExcludeToggle.propTypes = {
  value: PropTypes.oneOf(['include', 'exclude']).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default IncludeExcludeToggle;
