import React from 'react';
import PropTypes from 'prop-types';

import { useGetFlags } from '../components/hooks/apis/flags';

const FlagsContext = React.createContext(null);

FlagsContext.displayName = 'FlagsContext';

const getFlagsOverridesFromLocalStorage = () => {
  const flags = JSON.parse(localStorage.getItem('flags-override') || '{}');

  return flags;
};

export const FlagsContextProvider = ({ children }) => {
  const { flags, isFlagsLoading } = useGetFlags();

  const overriddenFlags = getFlagsOverridesFromLocalStorage(flags);

  return (
    <FlagsContext.Provider
      value={{
        flags: { ...flags, ...overriddenFlags },
        isFlagsLoading,
      }}
    >
      {children}
    </FlagsContext.Provider>
  );
};

FlagsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FlagsContext;
