import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, styled } from '@mui/material';
import Typography from '@mui/material/Typography';

const ENTER_TOOLTIP_DELAY = 600;

const TruncatedTextTooltip = ({ text, isExpanded }) => {
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const StyledTypography = styled(Typography)(({ theme }) => ({
    color: isExpanded ? theme.palette.common['black'] : theme.palette.grey[4],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }));

  // Determine whether the text is truncated
  // ref: https://stackoverflow.com/a/69968815
  const checkTruncation = () => {
    const element = textRef.current;
    if (element && element.scrollWidth > element.clientWidth) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  };

  useEffect(() => {
    checkTruncation();
  }, [text]);

  return (
    <Tooltip
      title={text}
      placement="bottom-end"
      disableHoverListener={!isTruncated}
      enterNextDelay={ENTER_TOOLTIP_DELAY}
    >
      <StyledTypography noWrap ref={textRef} variant="h4" fontWeight="bold">
        {text}
      </StyledTypography>
    </Tooltip>
  );
};

TruncatedTextTooltip.propTypes = {
  text: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
};

export default TruncatedTextTooltip;
