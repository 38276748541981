import React, { lazy, memo } from 'react';
import PropTypes from 'prop-types';

import { useCampaignAdGroupSettingsSection } from './hooks/useAdGroupSettingsSection';
import { FormProvider } from 'react-hook-form';
import { advancedModals } from '@v2/components/campaign/CampaignAdGroupSection';
import { fields } from './formConfig';
import { WizardSection } from '@v2/components/wizard/WizardSection';
import { handleErrors } from '../utils';

const AdGroupInfoPane = lazy(() => import('./AdGroupInfoPane'));
const AdGroupAdvancedDeliveryPane = lazy(
  () => import('./AdGroupAdvancedDeliveryPane'),
);
const AdGroupAdvancedAudiencePane = lazy(
  () => import('./AdGroupAdvancedAudiencePane'),
);
const AdGroupZipCodesPane = lazy(() => import('./AdGroupZipCodesPane'));
const AdGroupCustomInventoryPane = lazy(
  () => import('./AdGroupCustomInventoryPane'),
);
const AdGroupAdvancedInventoryPane = lazy(
  () => import('./AdGroupAdvancedInventoryPane'),
);

const AdGroupAdvancedCreativesPane = lazy(
  () => import('./AdGroupAdvancedCreativesPane'),
);

const AdGroupSettingsSection = ({
  sectionKey,
  adGroup,
  campaignId,
  ...sectionProps
}) => {
  const {
    form,
    campaign,
    sectionsInfo,
    mutating,
    submit,
    submitCustomInventory,
    submitCreatives,
    submitZipCodes,
    submitAudiences,
    submitAdvancedInventory,
    submitAdvancedDelivery,
    generalError,
    inventoryAvailability,
  } = useCampaignAdGroupSettingsSection({
    sectionKey,
    campaignId,
    adGroup,
    isDisplay: sectionProps.isDisplay,
  });

  return (
    <FormProvider {...form}>
      <WizardSection
        sectionKey={sectionKey}
        disabled={!campaign?.id}
        title="Ad group settings"
        icon="faUserGroup"
        panes={[
          <AdGroupInfoPane
            key="info"
            form={form}
            adGroup={adGroup}
            sectionsInfo={sectionsInfo}
            onSubmit={handleErrors(submit, {
              setError: form.setError,
              generalError,
              generalErrorFields: ['targeting'],
            })}
            mutating={mutating}
            inventoryAvailability={inventoryAvailability}
            renderInBackground
            {...sectionProps}
          />,
          <AdGroupAdvancedDeliveryPane
            key={advancedModals.bidStrategy}
            onSubmit={submitAdvancedDelivery}
            data={{
              frequencyCap: form.getValues(
                fields.configureAdGroupFrequencyCap.path,
              ),
              dayparting: form.getValues(
                fields.configureAdGroupDayparting.path,
              ),
            }}
            {...sectionProps}
          />,
          <AdGroupAdvancedAudiencePane
            key={advancedModals.audience}
            selected={form.getValues(fields.advancedAudience.path)}
            onSubmit={submitAudiences}
            {...sectionProps}
          />,
          <AdGroupZipCodesPane
            key={advancedModals.geography}
            adGroupId={adGroup}
            onSubmit={submitZipCodes}
            {...sectionProps}
          />,
          <AdGroupCustomInventoryPane
            key={advancedModals.customInventory}
            selected={form.getValues(fields.advancedCustomInventory.path)}
            onSubmit={submitCustomInventory}
            adGroup={adGroup}
            {...sectionProps}
          />,
          <AdGroupAdvancedInventoryPane
            key={advancedModals.inventory}
            adGroup={adGroup}
            selected={form.getValues(fields.advancedInventory.path)}
            onSubmit={submitAdvancedInventory}
            {...sectionProps}
          />,
          <AdGroupAdvancedCreativesPane
            key={advancedModals.creative}
            adGroup={adGroup}
            creatives={form.getValues(fields.creatives.path)}
            onSubmit={submitCreatives}
            {...sectionProps}
          />,
        ]}
      />
    </FormProvider>
  );
};

AdGroupSettingsSection.propTypes = {
  sectionKey: PropTypes.string,
  adGroup: PropTypes.object,
  campaignId: PropTypes.number,
};

export default memo(AdGroupSettingsSection);
