import React, { useContext, useEffect, useMemo } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useRouteMatch,
  matchPath
} from 'react-router-dom';
import { LoginCallback as OktaLoginCallback } from '@okta/okta-react';
import { setUserVars, identify } from '@fullstory/browser';
import 'fontsource-noto-sans-jp';

import Dashboard from './Dashboard';
// Will use this later
// import HomePage from './containers/HomePage';
import AdGroupsIndexPage from './containers/AdGroupsIndexPage/AdGroupsIndexPage';
import BusinessManager from './containers/BusinessManagerPage';
import CreateAccountPage from './containers/CreateAccountPage';
import VerticalCampaignFlowPage from './containers/VerticalCampaignFlowPage';
import CampaignsPage from './containers/CampaignsPage';
import CreativeDetailPage from './containers/CreativeDetailPage';
import CreativeIndexPage from './containers/CreativeIndexPage';
import CreativeIndexPerAdvertiserPage from './containers/CreativeIndexPerAdvertiserPage';
import DisplayDetailPage from './containers/DisplayDetailPage';
import DisplayIndexPage from './containers/DisplayIndexPage';
import ReportsPage from './containers/ReportsPage';
import RequestAccountPage from './containers/RequestAccountPage';
import SettingsPage from './containers/SettingsPage';
import SetupWizardPage from './containers/SetupWizardPage';
import SetupAdvertiserPage from './containers/SetupAdvertiserPage';
import TermsAndConditionsPage from './containers/TermsAndConditionsPage';
import SupportPage from './containers/SupportPage';
import TrackingPage from './containers/TrackingPage';
import MemberPage from './containers/MemberPage';
import NBCULoginCallbackPage from './containers/NBCULoginCallbackPage';
import QuietOktaLoginCallbackPage from './containers/QuietOktaLoginCallbackPage';
import LoginPage from './containers/LoginPage';
import NotFoundPage from './NotFoundPage';
import PrivateRoute from './PrivateRoute';
import SecureRoute from './SecureRoute';
import AcceptInvitePage from './containers/AcceptInvitePage/AcceptInvitePage';
import JoinPage from './containers/JoinPage/JoinPage';

import {
  useAdvertisers,
  useIntercom,
  usePermissions,
  useSSOUser,
  useOrg,
  useUser,
  useAuth,
  useFlags,
  useTenant,
  termsAndConditionsDialog,
} from './hooks';
import { useAppDeployment } from './hooks/appDeployment';
import { useGetLatestTenantTermAndCondition } from './hooks/apis/termsAndConditions';
import { usePostUserAgreement } from './hooks/apis/userAgreements';
import AdvertiserContext from './AdvertiserContext';
import DialogContext from '../providers/DialogContext';
import { OrganizationRoles, RoutePaths, Scopes, Themes } from '../constants';
import { useInterceptors } from '../interceptors';
import { WizardPage } from './containers/WizardPage';
import { WizardSelector } from './containers/WizardSelector';

const Logout = () => {
  const { auth, authState } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!authState.isPending) {
      if (authState.isAuthenticated) {
        auth.signOut();
        return;
      }

      history.push('/');
    }
  }, [auth, authState.isPending, authState.isAuthenticated]);

  return null;
};

const App = () => {
  const history = useHistory();
  const { authState } = useAuth();
  const adContext = useContext(AdvertiserContext);
  const { setDialog } = useContext(DialogContext);
  const { user, getUser } = useUser();
  const { hasPermission, arePermissionsLoaded } = usePermissions();
  const { currentAdvertiser, advertisers } = useAdvertisers();
  const { getSSOUser } = useSSOUser();
  const { org } = useOrg();
  const tenant = useTenant();
  const { theme } = useAppDeployment({
    currentAdvertiserId: adContext?.id,
    orgTenant: org?.primary_tenant,
  });
  const { flags, Flags, isFlagsLoading } = useFlags();
  const termsAndConditionsMatch = useRouteMatch(RoutePaths.TERMS_AND_CONDITIONS);

  const { data: latestTenantTermAndCondition } = useGetLatestTenantTermAndCondition(tenant.id);
  const { trigger: postUserAgreementTrigger } = usePostUserAgreement();

  const isTenantAdmin = useMemo(
    () => org?.organization_role === OrganizationRoles.TENANT_ADMIN,
    [org?.organization_role]
  );

  const canAccessApplication = hasPermission([Scopes.CAN_ACCESS_APPLICATION], true);
  const canViewCampaignSetup = hasPermission([Scopes.CAN_CREATE_CAMPAIGN]);
  const canUpdateCampaigns = hasPermission([Scopes.CAN_UPDATE_CAMPAIGN]);
  const canViewReports = true;
  const canViewBusinessManager = hasPermission([
    Scopes.CAN_VIEW_BUSINESS_MANAGER,
  ]);
  const shouldSetupAccount = hasPermission([
    Scopes.USER_SHOULD_SETUP_ACCOUNT,
  ]);

  const isPrivateRouteAllowed = useMemo(
    () =>
      user === null
      || adContext === null
      || user?.is_tvsci_employee === null
      || adContext.cost_model === null
      || user?.is_tvsci_employee
      || adContext?.cost_model !== 'CPA'
      || isTenantAdmin
    ,[user, adContext]
  );

  useInterceptors();

  useEffect(() => {
    const { theme: prevTheme } = currentAdvertiser || {};
    if (prevTheme === theme) return;

    adContext.updateAdvertiser({ theme });
  }, [theme]);

  // TODO: this function and the following useEffect
  // TODO: should go in the AdvertiserContext Provider
  const handleUpdateLooker = uses_looker => {
    if (!adContext.uses_looker || adContext.uses_looker !== uses_looker) {
      return adContext.updateAdvertiser({ uses_looker });
    }
  };

  useEffect(() => {
    if (user) {
      handleUpdateLooker(user.uses_looker);
    }
  }, [user]);

  useIntercom({
    advertiser: currentAdvertiser,
  });

  useEffect(() => {
    if (authState.isAuthenticated) {
      getSSOUser();
    }
  }, [authState.isAuthenticated]);

  useEffect(() => {
    if (user?.email) {
      identify(user.id);
      setUserVars({
        displayName: `${user.first_name} ${user.last_name}`,
        email: user.email,
      });
    }
  }, [user]);

  useEffect(() => {
    if (
      user
      && !user.has_agreed_to_latest_terms
      && user?.url
      && org?.url
      && latestTenantTermAndCondition.url
      && !termsAndConditionsMatch
    ) {
      setDialog(termsAndConditionsDialog({
        onSubmit: async () => {
          try {
            await postUserAgreementTrigger({
              terms: latestTenantTermAndCondition.url,
              user: user.url,
              organization: org.url,
            });

            await getUser();

            setDialog(null);
          } catch (error) {
            console.error('error in saving user agreement ', error);
          }
        }
      }));
    }
  }, [user, org, latestTenantTermAndCondition, termsAndConditionsMatch]);

  useEffect(() => {
    const setupRoutes = [
      RoutePaths.HOME,
      RoutePaths.CREATE_ACCOUNT,
      RoutePaths.ACCOUNT_SETUP,
      RoutePaths.JOIN,
      RoutePaths.ACCEPT_INVITE
    ];

    if (!arePermissionsLoaded) return;
    if (
      setupRoutes.some(path =>
        matchPath(history.location.pathname, {
          path,
          exact: true,
          strict: false,
        }),
      )
    ) {
      return;
    }

    const isAvailableAdAccountsLoaded = advertisers !== null;

    if (isAvailableAdAccountsLoaded && shouldSetupAccount)
      return history.push('/account-setup');

    if (!isAvailableAdAccountsLoaded) return;

    const hasAdAccountsToAccess = advertisers?.length >= 1;
    if (hasAdAccountsToAccess && canAccessApplication) return;

    history.push('/member');
  }, [
    advertisers,
    history.location.pathname,
    shouldSetupAccount,
    arePermissionsLoaded,
  ]);

  return (
    <Switch>
      <Route exact path={RoutePaths.LOGIN} component={LoginPage} />
      <Route exact path={RoutePaths.ACCEPT_INVITE} component={AcceptInvitePage} />
      <Route exact path={RoutePaths.JOIN} component={JoinPage} />
      <PrivateRoute
        path={RoutePaths.CREATE_ACCOUNT}
        component={CreateAccountPage}
        isAllowed={!authState.isAuthenticated}
        redirectTo={RoutePaths.HOME}
      />
      <Route
        path="/home"
        render={() => <Redirect to={RoutePaths.CAMPAIGNS} />}
      />
      <PrivateRoute
        exact
        path={RoutePaths.HOME}
        component={RequestAccountPage}
        isAllowed={!authState.isAuthenticated && theme === Themes.NBCU}
        redirectTo={RoutePaths.CAMPAIGNS}
      />
      <SecureRoute path={RoutePaths.MEMBER} component={MemberPage} />
      <SecureRoute
        path={RoutePaths.ACCOUNT_SETUP}
        component={SetupAdvertiserPage}
      />
      <PrivateRoute
        path={RoutePaths.OLD_CAMPAIGN_SETUP}
        route={SecureRoute}
        component={SetupWizardPage}
        isAllowed={canViewCampaignSetup}
        redirectTo={RoutePaths.REPORTS}
      />
      <PrivateRoute
        path={RoutePaths.OLD_CAMPAIGN_EDIT}
        route={SecureRoute}
        component={SetupWizardPage}
        isAllowed={canUpdateCampaigns}
        redirectTo={RoutePaths.REPORTS}
      />
      <PrivateRoute
        path={RoutePaths.REPORTS}
        route={SecureRoute}
        component={ReportsPage}
        isAllowed={canViewReports}
        redirectTo={RoutePaths.HOME}
      />
      <PrivateRoute
        path={RoutePaths.TRACKING}
        route={SecureRoute}
        component={TrackingPage}
        isAllowed={isPrivateRouteAllowed}
        redirectTo={RoutePaths.REPORTS}
      />
      <SecureRoute path={RoutePaths.DASHBOARD} component={Dashboard} />
      <PrivateRoute
        path={[
          RoutePaths.WIZARD_SELECTOR,
        ]}
        route={SecureRoute}
        component={WizardSelector}
        isAllowed={isPrivateRouteAllowed && flags[Flags.USER_GETS_VERTICAL_CAMPAIGN]}
        isLoading={isFlagsLoading}
        redirectTo={RoutePaths.REPORTS}
      />
      <PrivateRoute
        path={RoutePaths.CAMPAIGN_REWORK}
        route={SecureRoute}
        component={WizardPage}
        isAllowed={
          isPrivateRouteAllowed &&
          flags[Flags.USER_GETS_VERTICAL_CAMPAIGN]
        }
        isLoading={isFlagsLoading}
        redirectTo={RoutePaths.REPORTS}
      />
      <PrivateRoute
        path={[
          RoutePaths.CAMPAIGNS_NEW,
          RoutePaths.CAMPAIGN_EDIT,
          RoutePaths.CAMPAIGN_ADGROUPS_NEW,
          RoutePaths.CAMPAIGN_ADGROUP_EDIT,
        ]}
        route={SecureRoute}
        component={VerticalCampaignFlowPage}
        isAllowed={
          isPrivateRouteAllowed && flags[Flags.USER_GETS_VERTICAL_CAMPAIGN]
        }
        isLoading={isFlagsLoading}
        redirectTo={RoutePaths.REPORTS}
      />

      <PrivateRoute
        exact
        path={RoutePaths.CAMPAIGNS}
        route={SecureRoute}
        component={CampaignsPage}
        isAllowed={isPrivateRouteAllowed}
        redirectTo={RoutePaths.REPORTS}
      />
      <PrivateRoute
        path={RoutePaths.CREATIVE_DETAIL}
        route={SecureRoute}
        component={CreativeDetailPage}
        isAllowed={isPrivateRouteAllowed}
        redirectTo={RoutePaths.REPORTS}
      />
      <PrivateRoute
        path={RoutePaths.CREATIVE_INDEX}
        route={SecureRoute}
        component={CreativeIndexPage}
        isAllowed={isPrivateRouteAllowed}
        redirectTo={RoutePaths.REPORTS}
      />
      <PrivateRoute
        path={RoutePaths.DISPLAY_DETAIL}
        route={SecureRoute}
        component={DisplayDetailPage}
        isAllowed={isPrivateRouteAllowed}
        redirectTo={RoutePaths.REPORTS}
      />
      <PrivateRoute
        path={RoutePaths.DISPLAY_INDEX}
        route={SecureRoute}
        component={DisplayIndexPage}
        isAllowed={isPrivateRouteAllowed}
        redirectTo={RoutePaths.REPORTS}
      />
      <PrivateRoute
        path={RoutePaths.AD_GROUPS}
        route={SecureRoute}
        component={AdGroupsIndexPage}
        isAllowed={isPrivateRouteAllowed}
        redirectTo={RoutePaths.REPORTS}
      />
      <PrivateRoute
        path={RoutePaths.CREATIVES}
        route={SecureRoute}
        component={CreativeIndexPerAdvertiserPage}
        isAllowed={isPrivateRouteAllowed}
        redirectTo={RoutePaths.REPORTS}
      />
      <PrivateRoute
        path={RoutePaths.DISPLAYS}
        route={SecureRoute}
        component={CreativeIndexPerAdvertiserPage}
        isAllowed={isPrivateRouteAllowed}
        redirectTo={RoutePaths.REPORTS}
      />
      <PrivateRoute
        path={RoutePaths.MANAGER}
        route={SecureRoute}
        component={BusinessManager}
        isAllowed={canViewBusinessManager}
        redirectTo={RoutePaths.REPORTS}
      />
      <SecureRoute path={RoutePaths.SETTINGS} component={SettingsPage} />
      <SecureRoute path={RoutePaths.SUPPORT} component={SupportPage} />
      <Route path={RoutePaths.TERMS_AND_CONDITIONS} component={TermsAndConditionsPage} />
      <Route path={RoutePaths.NBCU_LOGIN_CALLBACK} component={NBCULoginCallbackPage} />
      <Route path={RoutePaths.QUIET_LOGIN_CALLBACK} component={QuietOktaLoginCallbackPage} />
      <Route path={RoutePaths.LOGIN_CALLBACK} component={OktaLoginCallback} />
      <Route path={RoutePaths.LOGOUT} component={Logout} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default App;
