import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Backdrop, Box, Button, Container, Fade, Grid, Modal, Paper } from '@mui/material';

import AdvertiserContext from './AdvertiserContext';
import AsyncButton from './AsyncButton';

const PREFIX = 'ModalWrapper';

const classes = {
  bar: `${PREFIX}-bar`,
  cancel: `${PREFIX}-cancel`,
  container: `${PREFIX}-container`,
  footer: `${PREFIX}-footer`,
  footerBg: `${PREFIX}-footerBg`,
  inner: `${PREFIX}-inner`,
  gradient: `${PREFIX}-gradient`,
  overlay: `${PREFIX}-overlay`,
  paper: `${PREFIX}-paper`,
  bottomBar: `${PREFIX}-bottomBar`,
  pad: `${PREFIX}-pad`
};

const StyledModal = styled(Modal)(({
  theme: { palette, spacing }
}) => ({
  maxHeight: '80%',

  [`& .${classes.bar}`]: {
    inset: 0,
    zIndex: 1,
    pointerEvents: 'none',
    position: 'absolute',
  },

  [`& .${classes.cancel}`]: {
    color: palette.grey.main,
  },

  [`& .${classes.container}`]: {
    marginTop: 64,
    paddingBottom: spacing(2),
    height: '100%',
    minHeight: '80%',
  },

  [`& .${classes.footer}`]: {
    backgroundColor: '#e5e7eb',
    position: 'absolute',
    bottom: 18,
    left: 0,
    right: 0,
    zIndex: 10,
  },

  [`& .${classes.footerBg}`]: {
    backgroundColor: 'white',
  },

  [`& .${classes.inner}`]: {
    overflow: 'auto',
    maxHeight: '100%',
    zIndex: 2,
    position: 'relative',
  },

  [`& .${classes.gradient}`]: {
    bottom: 90,
    pointerEvents: 'none',
  },

  [`& .${classes.overlay}`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 18,
    right: 0,
    zIndex: 12,
  },

  [`& .${classes.paper}`]: {
    overflow: 'auto',
    width: '100%',
    paddingTop: spacing(8),
    paddingLeft: spacing(6),
    paddingRight: spacing(6),
    paddingBottom: spacing(10),
    position: 'relative',
  },

  [`& .${classes.bottomBar}`]: {
    bottom: 16,
  },

  [`& .${classes.pad}`]: {
    paddingBottom: 117,
  }
}));

const ModalWrapper = props => {

  const adContext = useContext(AdvertiserContext);

  const {
    isDisabled,
    isLoading,
    isUploading = false,
    isOpen,
    maxWidth = 'lg',
    onClose,
    onSaveDraft,
    onSubmit = () => { },
    noCancel,
    noFooterBg,
    isOverlay,
    overlay,
    onCloseOverlay,
    hasSubmit,
    hasSaveDraft,
    submit,
    testId = "modal-submit-btn",
    children,
    className,
  } = props;

  const handleClose = () => {
    if (isOverlay) {
      return onCloseOverlay();
    }

    onClose();
  };

  const renderFooter = () => (
    <Box p={3} px={8} className={clsx(classes.footer, noFooterBg && classes.footerBg)}>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="flex-end"
      >
        {!noCancel && (
          <Grid item>
            <Button className={classes.cancel} onClick={onClose}>
              Cancel
            </Button>
          </Grid>
        )}

        {hasSaveDraft &&
          <Grid item>
            <AsyncButton
              data-testid={testId}
              disableElevation
              isDisabled={isDisabled}
              isLoading={isLoading || isUploading}
              onClick={(e) => onSaveDraft(e)}
              textButton="Save as Draft"
              loadingButton={
                isUploading
                  ? '...'
                  : 'Saving...'
              }
              style={{
                minWidth: 175,
                height: 54,
                borderWidth: 2,
              }}
              variant="outlined"
              type="regular"
            />
          </Grid>}

        <Grid item>
          <AsyncButton
            nativeType="submit"
            isDisabled={isDisabled}
            isLoading={isLoading || isUploading}
            textButton={submit ? submit : 'Save'}
            loadingButton={
              isUploading
                ? 'Uploading...'
                : isLoading
                  ? 'Saving...'
                  : 'Saving...'
            }
          />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <StyledModal
      className={clsx(`${adContext.theme}-theme`, className)}
      disableScrollLock
      open={isOpen}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          top: 64,
        },
        timeout: 200,
      }}
    >
      <Fade in={isOpen} timeout={350}>
        <Container
          component="form"
          className={classes.container}
          disableGutters
          maxWidth={maxWidth}
          onSubmit={!isOverlay ? onSubmit : undefined}
        >
          <Box
            display="flex"
            className={clsx(classes.inner, hasSubmit ? classes.pad : {})}
            width="100%"
          >
            <Paper
              className={clsx(
                classes.paper,
                !hasSubmit && classes.bottomBar,
              )}
              elevation={12}
              square
            >
              {children}
            </Paper>

            {hasSubmit && (
              <Box
                className={clsx(classes.gradient, 'Box-gradient--white')}
                borderRadius="9px"
              />
            )}
            {/* TODO: Move nested overlay from modal */}
            {isOverlay && <Box className={classes.overlay}>{overlay}</Box>}
            {hasSubmit && renderFooter()}
            <div
              className={clsx(
                classes.bar,
                '--background-colorbar'
              )}
            />
          </Box>
        </Container>
      </Fade>
    </StyledModal>
  );
};

ModalWrapper.propTypes = {
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUploading: PropTypes.bool,
  maxWidth: PropTypes.string,
  noCancel: PropTypes.bool,
  noFooterBg: PropTypes.bool,
  isOverlay: PropTypes.bool,
  onCloseOverlay: PropTypes.func,
  onClose: PropTypes.func,
  onSaveDraft: PropTypes.func,
  onSubmit: PropTypes.func,
  overlay: PropTypes.element,
  hasSubmit: PropTypes.bool,
  hasSaveDraft: PropTypes.bool,
  submit: PropTypes.string,
  testId: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ModalWrapper;
