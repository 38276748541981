import useSWR from 'swr';
import { useCurrentSession } from '../currentSession';
import { useOrg } from '../org';

export const useAdvertiserStripeSetupIntent = (options) => {
  const { getV1, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { org } = useOrg();
  const { id } = currentAdvertiser;

  return useSWR(
    apiIsReady && org.payment_provider === 'STRIPE' && id ? { url: `/advertisers/${id}/stripe_setup_intent/` } : null,
    ({ url, params }) => getV1(url, { params }).then(res => res.data),
    options,
  );
};
