import React, { Fragment, useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-scroll';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Tabs,
  Tab,
  Tooltip,
  Typography,
} from '@mui/material';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import AdvertiserContext from './AdvertiserContext';
import { useAPI } from './hooks/api';
import { useLoader } from './hooks/loader';
import { useSaveExit } from './hooks/saveExit';
import Title from './Title';
import CampaignFooter from './CampaignFooter';
import LoadingSpinner from './ui/LoadingSpinner';
import StyledCheckbox from './StyledCheckbox';
import plusIcon from '../images/icons/plus-icon.svg';
import { Themes } from '../constants';

const PREFIX = 'TargetingSegments';

const classes = {
  chip: `${PREFIX}-chip`,
  chipWrap: `${PREFIX}-chipWrap`,
  boxLabel: `${PREFIX}-boxLabel`,
  choicesWrap: `${PREFIX}-choicesWrap`,
  container: `${PREFIX}-container`,
  extendedIcon: `${PREFIX}-extendedIcon`,
  fieldset: `${PREFIX}-fieldset`,
  icon: `${PREFIX}-icon`,
  labelName: `${PREFIX}-labelName`,
  labelSub: `${PREFIX}-labelSub`,
  main: `${PREFIX}-main`,
  margin: `${PREFIX}-margin`,
  menuItems: `${PREFIX}-menuItems`,
  wrap: `${PREFIX}-wrap`,
  segmentsBox: `${PREFIX}-segmentsBox`,
  segmentsWrap: `${PREFIX}-segmentsWrap`,
  segmentTitle: `${PREFIX}-segmentTitle`,
  segmentsNav: `${PREFIX}-segmentsNav`,
  submenu: `${PREFIX}-submenu`,
  submenuSegments: `${PREFIX}-submenuSegments`,
  submenuWrap: `${PREFIX}-submenuWrap`,
  hide: `${PREFIX}-hide`,
  unselect: `${PREFIX}-unselect`,
  icons: `${PREFIX}-icons`,
  show: `${PREFIX}-show`,
  viewHeader: `${PREFIX}-viewHeader`,
  behaviorsTip: `${PREFIX}-behaviorsTip`
};

const StyledGrid = styled(Grid)(({
  theme: { palette, spacing, typography }
}) => ({
  [`& .${classes.chip}`]: {
    fontSize: '0.625rem',
    fontWeight: 500,
    minWidth: 60,

    ['&.active']: {
      backgroundColor: 'rgba(122, 220, 187, 0.55)',
      color: palette.primary.light,
    },

    ['&.defined']: {
      backgroundColor: '#c3e7fa',
      color: palette.secondary.main,
    },

    ['&.undefined']: {
      backgroundColor: '#fdb1b1',
      color: '#ff0000',
    },
  },

  [`& .${classes.chipWrap}`]: {
    textAlign: 'right',
  },

  [`& .${classes.boxLabel}`]: {
    display: 'flex',

    '& .MuiFormControlLabel-root': {
      margin: 0,
    },

    '& .MuiFormControlLabel-label': {
      padding: spacing(2),
    },
  },

  [`& .${classes.choicesWrap}`]: {
    marginBottom: spacing(3),
  },

  [`& .${classes.container}`]: {
    height: '100%',
    marginBottom: spacing(6),
  },

  [`& .${classes.extendedIcon}`]: {
    marginRight: spacing(1),
  },

  [`& .${classes.fieldset}`]: {
    width: '100%',
  },

  [`& .${classes.icon}`]: {
    width: 20,

    '& img': {
      width: '100%',
    },
  },

  [`& .${classes.labelName}`]: {
    ...typography.body2,
    margin: 0,
  },

  [`& .${classes.labelSub}`]: {
    ...typography.paragraph,
    fontSize: '0.7rem',
    margin: 0,
  },

  [`& .${classes.main}`]: {
    height: '100%',
  },

  [`& .${classes.margin}`]: {
    margin: spacing(1),
  },

  [`& .${classes.menuItems}`]: {
    cursor: 'pointer',
    color: '#727383',
    textAlign: 'center',

    '&:hover': {
      color: '#1dafff',
    }
  },

  [`& .${classes.wrap}`]: {
    height: 500,
    width: 590,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    marginLeft: spacing(10),
    marginRight: spacing(10),
  },

  [`& .${classes.segmentsBox}`]: {
    overflow: `hidden`,
    position: `relative`,
  },

  [`& .${classes.segmentsWrap}`]: {
    overflow: `auto`,
    height: 400,
    padding: spacing(4),
    paddingBottom: spacing(8),
  },

  [`& .${classes.segmentTitle}`]: {
    cursor: 'pointer',
    margin: 0,
    width: 'auto',
  },

  [`& .${classes.segmentsNav}`]: {
    backgroundColor: `#e5e7eb`,
  },

  [`& .${classes.submenu}`]: {
    position: `relative`,
    zIndex: 5,
    top: -1,
    color: `#727383`,
    fontSize: `0.75rem`,
    fontSeight: 500,
  },

  [`& .${classes.submenuSegments}`]: {
    position: `relative`,
    zIndex: 5,
    top: -1,
    color: `#727383`,
    fontSize: `0.75rem`,
    fontSeight: 500,
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
  },

  [`& .${classes.submenuWrap}`]: {
    marginBottom: 0,
  },

  [`& .${classes.hide}`]: {
    color: `#ff0000`,
    fontSize: '.75rem',
    fontWeight: 500,
    minWidth: 124,
  },

  [`& .${classes.unselect}`]: {
    color: `#1dafff`,
    fontSize: '.75rem',
    fontWeight: 500,
    marginRight: spacing(1),
  },

  [`& .${classes.icons}`]: {
    fontSize: '.625rem',
    width: 16,
    height: 16,
  },

  [`& .${classes.show}`]: {
    color: palette.primary.light,
    fontSize: '.75rem',
    fontWeight: 500,
    minWidth: 124,
  },

  [`& .${classes.viewHeader}`]: {
    ...typography.h5,
  },

  [`& .${classes.behaviorsTip}`]: {
    verticalAlign: 'middle',
  }
}));

const categoryTitles = [
  'Custom',
  'Advanced Demo',
  'Automotive',
  'Gaming',
  'Financial',
  'Political',
  'Restaurants',
  'Retail',
  'Travel',
];

const retargetingLabels = [
  {
    title: 'All Site Traffic',
    sub: 'Estimated Audience: 10,000 Visitors',
    status: 'Active',
  },
  {
    title: 'Cart Abandoners',
    sub: 'Deployed. Audience is Not Large Enough',
    status: 'Defined',
  },
  {
    title: 'Product Viewers',
    sub: 'Pixel Not Deployed',
    status: 'Undefined',
  },
];

const behaviorsTip = 'If you choose behavior and interest targeting, your data CPM will be $2.50 CPM (you won\'t pay extra even if you also choose multiple audiences or add demographic targeting). Targeting may limit scale. Keep in mind you are targeting at the household level.';

// TODO: integrate 1st Party Audiences
const customSegmentTitles = [
  // '1st Party Audiences',
  'Custom Behavior and Interest',
];

const formatChecked = (seg) => {
  return `${seg.trim().split(' ').join('_')}`;
};

const TargetingSegments = props => {

  const history = useHistory();

  const {
    advanced,
    combinedTargetingData,
    currentAdGroup,
    segments: propSegments,
    save,
    triggerSave,
    isEditing,
    setAdjustRecommendedBid,
    adjustRecommenedBid,
    setIsNoBidFees = () => {},
  } = props;

  const adContext = useContext(AdvertiserContext);
  const { isLoading, setIsLoading } = useLoader();
  const {
    isLoading: isFetching,
    setIsLoading: setIsFetching,
  } = useLoader();
  const { usePatch, useGetAll } = useAPI();
  const { saveProgress } = useSaveExit();

  // Internal State
  const initialSegments = () => propSegments.length > 0 ? propSegments : [];

  const [isHide, setIsHide] = useState(false);
  const [audiences, setAudiences] = useState(null);
  const [totalAudiences, setTotalAudiences] = useState([]);
  const [segments, setSegments] = useState(initialSegments());
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const [advancedDemo, setAdvancedDemo] = useState([]);
  const [automotive, setAutomotive] = useState([]);
  const [gaming, setGaming] = useState([]);
  const [financial, setFinancial] = useState([]);
  const [political, setPolitical] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [retail, setRetail] = useState([]);
  const [travel, setTravel] = useState([]);
  const [custom, setCustom] = useState([]);

  useEffect(() => {
    getAudiences();

    return () => {
      setIsLoading(false);
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    saveProgress(save, 'TargetingSegments', handleSave, triggerSave);
  }, [save]);

  useEffect(() => {
    if (audiences) {
      if (audiences.advanceddemo) {
        setAdvancedDemo(audiences.advanceddemo);
      }

      if (audiences.automotive) {
        setAutomotive(audiences.automotive);
      }

      if (audiences.gaming) {
        setGaming(audiences.gaming);
      }

      if (audiences.financial) {
        setFinancial(audiences.financial);
      }

      if (audiences.political) {
        setPolitical(audiences.political);
      }

      if (audiences.restaurants) {
        setRestaurants(audiences.restaurants);
      }

      if (audiences.retail) {
        setRetail(audiences.retail);
      }

      if (audiences.travel) {
        setTravel(audiences.travel);
      }

      if (audiences.custom) {
        setCustom(audiences.custom);
      }
    }
  }, [audiences]);

  // Used to set selected segments on load
  useEffect(() => {
    if (propSegments && propSegments.length > 0) {
      if (totalAudiences && totalAudiences.length > 0) {
        const selected = propSegments.reduce((acc, curr) => {
          const audience = totalAudiences.find(a => a.audience_name === curr);

          if (audience) {
            return [...acc, audience];
          }

          return acc;
        }, []);

        setSelectedSegments(selected);
      }
    }
  }, [propSegments, totalAudiences]);

  useEffect(() => {
    if (
      selectedSegments &&
      selectedSegments.length > 0 &&
      selectedSegments.some(s => s.no_fee)
    ) {
      setIsNoBidFees(true);
    } else {
      setIsNoBidFees(false);
    }
  }, [selectedSegments]);

  async function getAudiences() {
    setIsFetching(true);

    return await useGetAll('/audiences', [], total => {
      if (total && total.length > 0) {
        setTotalAudiences(total);

        const sorted = total.reduce((acc, curr) => {
          const type = curr.type && curr.type !== ''
            ? curr.type.toLowerCase()
            : 'custom';

          if (!acc[type] || !acc[type].length) {
            return {
              ...acc,
              [type]: [curr],
            };
          }

          return {
            ...acc,
            [type]: [...acc[type], curr],
          }
        }, {});

        setAudiences(sorted);
        setIsFetching(false);
      }
    });
  }

  const setAllSegments = (segments) => {
    setSegments(segments)

    if (props.setSegments) {
      props.setSegments(segments)
    }
  }

  useEffect(() => {
    if (propSegments && propSegments.length > 0) {
      setAllSegments(propSegments);
    }
  }, [propSegments]);

  const handleUnselectAll = () => {
    setAllSegments([]);
  };

  const handleShowSelected = () => {
    setIsHide(prev => !prev);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSetSegments = seg => name => {
    if (!selectedSegments.find(s => s.id === seg.id)) {
      setSelectedSegments(prev => [...prev, seg]);
    } else {
      setSelectedSegments(prev => prev.filter(p => p.id !== seg.id));
    }

    if (!segments.includes(name)) {
      setAllSegments(prev => [...prev, name]);
    } else {
      setAllSegments(prev => prev.filter(p => p !== name));
    }
  };

  const handleData = () => {
    const dataObj = {
      advanced,
      segments,
    };

    props.handleSegmentsData(dataObj);

    return dataObj;
  };

  const handleSave = () => {
    setIsLoading(true);

    const segmentsData = handleData();

    if (segmentsData.segments.length > 0) {
      setAdjustRecommendedBid(true)
    } else if (segmentsData.segments.length === 0 && !adjustRecommenedBid) {
      setAdjustRecommendedBid(false)
    }

    const data = {
      targeting: JSON.stringify({
        advanced,
        ...combinedTargetingData,
        ...segmentsData,
      }),
    };

    console.log('data from targeting segments', data);

    return usePatch(`/lineitems/${currentAdGroup.id}/`, data)
      .then(response => {
        if (response) {
          setIsLoading(false);

          // Handle Save & Exit button click
          if (save && save.step && save.step === 'TargetingSegments') {
            if (save.exit) {
              history.push('/');
            }
          }

          // Handle next button click
          if (!save.exit) {
            props.setStep('Inventory');
            props.updateBreadcrumbs('targeting', 75);
          }
        }

        return response;
      })
      .catch(error => {
        console.error('Error saving TargetingSegments', error);
        setAdjustRecommendedBid(false);
        setIsLoading(false);

        return error;
      });
  };

  const renderCustomAudience = () => (
    <Grid container alignItems="center" spacing={0}>
      <Grid item xs={2}>
        <div className={classes.icon}>
          <img src={plusIcon} />
        </div>
      </Grid>

      <Grid
        alignItems="center"
        container
        item
        justifyContent="space-between"
        xs={10}
      >
        <Grid item xs={12}>
          <p className={classes.labelName}>Custom Audience</p>
        </Grid>

        <Grid item xs={12}>
          <p className={classes.labelSub}>Activate Audience</p>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderChip = label => {
    if (label.status) {
      let cssClass = null;
      const status = label.status.toLowerCase();

      switch (status) {
        case 'active':
          cssClass = 'active';
          break;
        case 'defined':
          cssClass = 'defined';
          break;
        case 'undefined':
          cssClass = 'undefined';
          break;
        default:
          cssClass = '';
          break;
      }

      return (
        <Chip
          className={clsx(classes.chip, cssClass ? cssClass : '')}
          size="small"
          label={label.status}
        />
      );
    }
  };

  const renderLabel = label => (
    <Grid container spacing={1}>
      <Grid
        alignItems="center"
        container
        item
        justifyContent="space-between"
        xs={12}
      >
        <Grid item xs={9}>
          <p className={classes.labelName}>{label.title}</p>
        </Grid>

        <Grid className={classes.chipWrap} item xs={3}>
          {renderChip(label)}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <p className={classes.labelSub}>{label.sub}</p>
      </Grid>
    </Grid>
  );

  const renderCategoriesNav = () => (
    <Grid
      item
      container
      className={classes.submenuSegments}
      xs={12}
    >
      <Grid item container xs={12}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-evenly"
          xs={8}
        >
          {categoryTitles.map((c, i) => {
            if (i === 0 && !custom.length) {
              return null;
            }

            return (
              <Box key={c} display="flex" justifyContent="center">
                <Link
                  activeClass="activeMenuItem"
                  className={classes.menuItems}
                  containerId="categories-container"
                  offset={-15}
                  to={c.replace(/\s+/g, '-').toLowerCase()}
                  duration={270}
                  smooth
                  spy
                >
                  {c}
                </Link>
              </Box>
            );})}
        </Grid>

        <Grid item container xs={4} justifyContent="flex-end">
          <Button
            onClick={handleUnselectAll}
            size="small"
            className={classes.unselect}
            startIcon={<RemoveCircleOutlineIcon className={classes.icons} />}
          >
            Unselect All
          </Button>

          <Button
            disableRipple
            onClick={handleShowSelected}
            size="small"
            className={isHide ? classes.show : classes.hide}
            startIcon={
              isHide ? (
                <BlurCircularIcon
                  className={classes.icons}
                  style={{
                    width: 15,
                    height: 15,
                  }}
                />
              ) : (
                <NotInterestedIcon
                  className={classes.icons}
                  style={{
                    width: 15,
                    height: 15,
                  }}
                />
              )
            }
          >
            {isHide ? 'Show Unselected' : 'Hide Unselected'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  const filterSegments = (labels) => {
    const filtered = labels.filter(l => segments.includes(l.audience_name))
    return filtered.map(f => renderCheckboxLabel(f));
  };

  const renderCheckboxLabel = seg => {
    return (
      <Grid item xs={4} key={formatChecked(seg.audience_name)}>
        <StyledCheckbox
          checked={segments.includes(seg.audience_name)}
          name={seg.audience_name}
          onChange={handleSetSegments(seg)}
        />
      </Grid>
    );
  };

  const renderCategory = (title, labels) => {
    if (title.toLowerCase() === 'custom' && !custom.length) {
      return null;
    }

    return (
      <Grid
        key={title}
        container
        className={classes.choicesWrap}
        name={title.replace(/\s+/g, '-').toLowerCase()}
      >
        <FormControl
          variant="standard"
          component="fieldset"
          className={classes.formControl}
          fullWidth>
          <FormLabel component="legend">
            <Box pb={1}>
              <Typography variant="h5" component="h5">
                {title}
              </Typography>
            </Box>
          </FormLabel>

          <FormGroup row>
            <Grid container>
              {isHide
                ? filterSegments(labels) // labels.filter(l => segments.isArray && segments.include(l.label))
                : labels.map(l => renderCheckboxLabel(l))}
            </Grid>
          </FormGroup>
        </FormControl>
      </Grid>
    );
  };

  const renderTargetingView = () => {
    if (tabValue === 0) {
      return (
        <Fragment>
          <Box
            border={1}
            borderColor="grey.300"
            borderRadius="10px"
            className={classes.segmentsBox}
          >
            <Box borderRadius="9px" className="Box-gradient--white" />

            <Box className={classes.segmentsNav} px={2}>
              {renderCategoriesNav()}
            </Box>

            <Box id="categories-container" className={classes.segmentsWrap}>
              {isFetching && <LoadingSpinner />}

              {!isFetching && audiences && [
                custom,
                advancedDemo,
                automotive,
                gaming,
                financial,
                political,
                restaurants,
                retail,
                travel,
              ].map((c, i) => renderCategory(categoryTitles[i], c))}
            </Box>
          </Box>
        </Fragment>
      );
    }

    if (tabValue === 1) {
      return (
        <Box>
          <Box mt={2}>
            <FormControl
              variant="standard"
              component="fieldset"
              className={classes.formControl}
              fullWidth>
              <FormGroup row>
                <Grid container spacing={1}>
                  {retargetingLabels.map(label => (
                    <Grid item xs={4} key={label.title}>
                      <Box
                        m={1}
                        className={clsx('Button-choice', classes.boxLabel)}
                      >
                        <FormControlLabel
                          control={<Checkbox name={label.title} />}
                          label={renderLabel(label)}
                        />
                      </Box>
                    </Grid>
                  ))}

                  <Grid item xs={4}>
                    <Box
                      m={1}
                      className={clsx('Button-choice', classes.boxLabel)}
                    >
                      <FormControlLabel
                        control={<Checkbox name="Custom Audience" />}
                        label={renderCustomAudience()}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      );
    }

    if (tabValue === 2) {
      return (
        <Fragment>
          <Box
            border={1}
            borderColor="grey.300"
            borderRadius="10px"
            className={classes.segmentsBox}
          >
            <Box borderRadius="9px" className="Box-gradient--white" />

            <Box className={classes.segmentsNav} px={2}>
              <Grid className={classes.submenu} item container xs={12}>
                <Grid
                  alignItems="center"
                  container
                  item
                  justifyContent="space-between"
                  xs={12}
                >
                  <Grid container item xs={9} spacing={2}>
                    {customSegmentTitles.map((title, i) => (
                      <Grid item key={`${title}-${i}`}>
                        <p className={classes.segmentTitle}>
                          {title}
                        </p>
                      </Grid>
                    ))}
                  </Grid>

                  <Grid container item xs={3}>
                    <Grid item xs={6}>
                      <p className={classes.unselect}>Unselect All</p>
                    </Grid>
                    <Grid item xs={6}>
                      <p className={classes.hide}>Hide Unselected</p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box className={classes.segmentsWrap}>
              {[custom].map((section, i) => (
                <Grid
                  key={`${section}-${i}`}
                  container
                  className={classes.choicesWrap}
                >
                  <FormControl
                    variant="standard"
                    component="fieldset"
                    className={classes.formControl}
                    fullWidth>
                    <FormLabel component="legend">
                      <Box pb={1}>
                        <Typography variant="h5" component="h5">
                          {customSegmentTitles[i]}
                        </Typography>
                      </Box>
                    </FormLabel>

                    <FormGroup row>
                      <Grid container>
                        {section.map(seg => (
                          <Grid item xs={4} key={seg.audience_name}>
                            <StyledCheckbox
                              checked={segments.includes(seg.audience_name)}
                              name={seg.audience_name}
                              onChange={handleSetSegments(seg)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </FormControl>
                </Grid>
              ))}
            </Box>
          </Box>
        </Fragment>
      );
    }
  };

  return (
    <StyledGrid className={classes.container} container direction="column">
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box
            className={classes.main}
            flexGrow={1}
            width="100%"
            height="100%"
          >
            {adContext.theme === Themes.NBCU && (
              <>
                <Title>Select Behaviors & Interests</Title>
                <p>Select the additional behaviors and interests you wish to target.</p>
              </>
            )}

            <Grid container spacing={2}>
              <Box px={2}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                  <Tab
                    disableFocusRipple
                    disableRipple
                    label={(
                      <Grid
                        container
                        alignItems="center"
                        direction="row"
                        spacing={1}
                      >
                        <Grid item>
                          Behaviors &amp; Interests
                        </Grid>

                        <Grid item>
                          <Tooltip
                            title={behaviorsTip}
                            className={classes.behaviorsTip}
                          >
                            <InfoOutlinedIcon fontSize="small" color="secondary" />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    )}
                  />

                  <Tab
                    disableFocusRipple
                    disableRipple
                    label="Retargeting Audience"
                  />
                </Tabs>
              </Box>
            </Grid>

            <Grid container spacing={2}>
              <Box mr={1} mt={4} width="100%">
                {renderTargetingView()}
              </Box>
            </Grid>
          </Box>

          {!isEditing && (
            <Box className={classes.footer}>
              <CampaignFooter
                isLoading={isLoading}
                back={'Demographics'}
                next={'Inventory'}
                onBack={() => {
                  props.setStep('DemoTargeting');
                  props.updateBreadcrumbs('targeting', 25);
                }}
                onNext={() => {
                  setIsLoading(true);

                  triggerSave('TargetingSegments', false, 'Inventory');
                }}
                page={3}
              />
            </Box>
          )}
        </Box>
      </Grid>
    </StyledGrid>
  );
};

TargetingSegments.propTypes = {
  isEditing: PropTypes.bool,
  setStep: PropTypes.func,
  handleSegmentsData: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
  segments: PropTypes.array,
  setSegments: PropTypes.func,
  combinedTargetingData: PropTypes.object,
  currentAdGroup: PropTypes.object,
  save: PropTypes.object,
  triggerSave: PropTypes.func,
  advanced: PropTypes.bool,
  setAdjustRecommendedBid: PropTypes.func,
  setIsNoBidFees: PropTypes.func,
  adjustRecommenedBid: PropTypes.bool
};

export default TargetingSegments;
