import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import { InputAdornment, IconButton, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarRange } from '@fortawesome/pro-regular-svg-icons';

import moment from 'moment';

const IconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
}));

const SelectDate = forwardRef(({ label, uniqueKey, ...props }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const { field, fieldState, slotProps: passedSlotProps, ...rest } = props;
  const slotProps =
    passedSlotProps ?? fieldState
      ? {
          textField: {
            error: fieldState.invalid,
            helperText: fieldState.error?.message,
          },
        }
      : {};

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        minDate={moment()}
        {...rest}
        {...field}
        ref={ref}
        label={label}
        format="MMM D, YYYY"
        key={uniqueKey}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    <IconStyled icon={faCalendarRange} />
                  </IconButton>
                </InputAdornment>
              ),
            },
            ...slotProps.textField,
          },
        }}
      />
    </LocalizationProvider>
  );
});

SelectDate.displayName = 'SelectDate';

// Define the prop types for the SelectDate component
SelectDate.propTypes = {
  ...DatePicker.propTypes,
  uniqueKey: PropTypes.string,
};

export default SelectDate;
