import { useEffect, useMemo } from 'react';
import { Geo } from '../../constants';

const isEntireUS = item => {
  const name = item.place_name || item.name;
  return name === Geo.entireUS.place_name;
};

const isNotEntireUS = item => {
  const name = item.place_name || item.name;
  return name !== Geo.entireUS.place_name;
};

const isBlacklisted = item => !isEntireUS(item) && item.blacklist;

const isNotBlacklisted = item => !isEntireUS(item) && !item.blacklist;

export const useSelectEntireUS = ({ geo, setGeo }) => {
  useEffect(() => {
    if (geo.length) {
      const hasEntireUS = geo.some(isEntireUS);

      if (hasEntireUS && geo.some(isNotBlacklisted)) {
        setSelectEntireUS(false);
      }

      if (!hasEntireUS && !geo.some(isNotBlacklisted)) {
        setSelectEntireUS(true);
      }
    } else {
      setSelectEntireUS(true);
    }
  }, [geo]);

  const selectEntireUS = useMemo(() => (geo || []).some(isEntireUS), [geo]);

  const setSelectEntireUS = entireUS => {
    if (entireUS != selectEntireUS) {
      setGeo(prev => {
        let nextGeoResults = [...prev.filter(isNotEntireUS)];

        if (entireUS) {
          nextGeoResults = [...nextGeoResults.filter(isBlacklisted)];
          nextGeoResults.unshift(Geo.entireUS);
        }

        return nextGeoResults;
      });
    }
  };

  return {
    selectEntireUS,
    setSelectEntireUS,
  };
};
