import React, { useState, useEffect, Suspense } from 'react';
import { styled } from '@mui/material/styles';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Toolbar,
  Typography,
  CircularProgress,
  Box,
  Tabs,
  Tab
} from '@mui/material';
import AppHeader from '../AppHeader';
import DisplaysTable from '../Creatives/DisplaysTable';
import CreativesTable from '../Creatives/CreativesTable';
import { ErrorBoundary } from '@v2/components/ui/ErrorBoundary';

const PREFIX = 'CreativeIndexPage';

const classes = {
  head: `${PREFIX}-head`,
  body: `${PREFIX}-body`,
  link: `${PREFIX}-link`,
  tableLink: `${PREFIX}-tableLink`,
  spinner: `${PREFIX}-spinner`,
  spinnerWrap: `${PREFIX}-spinnerWrap`,
  tabs: `${PREFIX}-tabs`,
  tableCell: `${PREFIX}-tableCell`
};

const StyledAppHeader = styled(AppHeader)(({
                                             theme: { spacing }
                                           }) => ({
  [`& .${classes.link}`]: {
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: '700',
  },
  [`& .${classes.tableLink}`]: {
    fontWeight: '700',
    whiteSpace: 'normal',
    wordBreak: 'break-word'
  },
  [`& .${classes.spinner}`]: {
    height: 100,
    width: 100,
    margin: '0 auto',
    textAlign: 'center'
  },
  [`& .${classes.spinnerWrap}`]: {
    marginTop: spacing(12),
    width: '100%',
    textAlign: 'center'
  },
  [`& .${classes.tabs}`]: {
    borderBottom: `1px solid #e0e0e0`,
    width: `100%`,
    position: `relative`
  },
  [`& .${classes.tableCell}`]: {
    minWidth: 120
  },
  stat: {}
}));


const CreativeIndexPage = ({location, history}) => {

  const [tab, setTab] = useState(0);
  const { adGroupId, campaignId } = useParams();

  useEffect(() => {
    const nextTab = location.pathname === '/displays' ? 1 : 0;
    setTab(nextTab);
  }, [location]);

  const handleSetTab = (event, newTab) => {
    setTab(newTab);
  };

  const renderLoading = () => (<div className={classes.spinnerWrap}>
    <div className={classes.spinner}>
      <CircularProgress />
    </div>
  </div>);

  return (
    <StyledAppHeader history={history}>
      <Box m={5} mb={2}>
        <Typography>
          <strong><Link className={classes.link} to={`/campaigns/`}>Campaigns</Link></strong> &gt; <strong><Link
          className={classes.link} to={`/campaigns/${campaignId}/adgroups/${adGroupId}`}>Ad
          Groups</Link></strong> &gt; Creative
        </Typography>
      </Box>

      <Box border={1} borderColor="grey.300" p={6} pt={4} m={4} borderRadius="20px">
        <Toolbar disableGutters>
          <Typography variant="h6" component="div">Creative</Typography>
        </Toolbar>

        <Box mb={4} width="100%">
          <Tabs
            className={classes.tabs}
            onChange={handleSetTab}
            value={tab}
          >
            <Tab label="Creatives" />
            <Tab label="Displays" />
          </Tabs>
        </Box>

        <ErrorBoundary fallback={<div>Error loading items</div>}>
          <Suspense fallback={renderLoading()}>
            {tab === 0 && (<CreativesTable classes={classes} />)}
            {tab === 1 && (<DisplaysTable classes={classes} />)}
          </Suspense>
        </ErrorBoundary>
      </Box>
    </StyledAppHeader>
  );
};

CreativeIndexPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default CreativeIndexPage;
