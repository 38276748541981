import { omit } from 'lodash';
import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import ModalContext from './ModalContext';
import UnsavedChangesPrompt from '@v2/components/ui/UnsavedChangesPrompt';
import {
  actions,
  useCampaignWizardContext,
} from '../components/CampaignWizard/CampaignWizardContext';
import { sectionNames } from '../components/CampaignWizard/constants';
import { useQuery } from '../components/hooks';

const SCROLL_DELAY = 500;

const WizardNavigationContext = React.createContext(null);

WizardNavigationContext.displayName = 'WizardNavigationContext';

const getSectionKey = paneKey => paneKey?.split('::')[0] ?? '';

export const WizardNavigationContextProvider = ({ children }) => {
  const urlQuery = useQuery();
  const { sectionKeys, initialActivePaneKey } = useCampaignWizardContext();
  const { setModal } = useContext(ModalContext);
  const [blockedPaneKeys, setBlockedPaneKeys] = useState({});
  const [keyRefs, setKeyRefs] = useState({});
  const [paneKeys, setPaneKeys] = useState([]);
  const [activePaneKey, setActivePaneKey] = useState('');
  const [activePaneData, setActivePaneData] = useState(null);
  const [sectionData, setSectionData] = useState({});

  const activeSectionKey = useMemo(
    () => getSectionKey(activePaneKey),
    [activePaneKey],
  );

  const sectionKeyIndexes = useMemo(() => {
    return sectionKeys.reduce((acc, key, index) => {
      acc[key] = index;

      return acc;
    }, {});
  }, [sectionKeys]);

  const sectionKey = useMemo(() => urlQuery.get('sectionKey'), [urlQuery]);

  const registerSectionPane = useCallback(paneKey => {
    if (paneKeys.includes(paneKey)) return () => {};

    setPaneKeys(current => [...current, paneKey]);

    return () => {
      setPaneKeys(current => {
        return current.filter(key => key !== paneKey);
      });
    };
  }, [paneKeys]);

  const registerSection = useCallback(
    (sectionKey, ref, data) => {
      if (!sectionKeys.includes(sectionKey)) {
        setSectionData(keys => ({
          ...keys,
          [sectionKey]: { ...data },
        }));
      }

      setKeyRefs(current => ({ ...current, [sectionKey]: ref }));

      return () => {
        setSectionData(current => {
          return omit(current, sectionKey);
        });
        setKeyRefs(current => {
          return omit(current, sectionKey);
        });
      };
    },
    [sectionKeys],
  );

  const scrollTo = useCallback(key => {
    const ref = keyRefs[key];
    if (ref?.current) {
      window.setTimeout(() => {
        if (ref.current !== null) {
          ref.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, SCROLL_DELAY);
    }
  }, [keyRefs]);

  const isPaneBlocked = useCallback(paneKey => {
    return blockedPaneKeys?.[paneKey] ?? false;
  }, [blockedPaneKeys]);

  const updateActivePaneKey = useCallback((key, checkUnsaved = false) => {
    if (isPaneBlocked(activePaneKey) && checkUnsaved) {
      const currentSectionData = sectionData[activeSectionKey];

      setModal({
        isOpen: true,
        component: () => (
          <UnsavedChangesPrompt
            closeModal={() => setModal(null)}
            onConfirm={() => {
              setActivePaneKey(() => {
                scrollTo(getSectionKey(key));
                return key;
              });

              // This needs to happen last, otherwise the modal will unmount and the onConfirm won't finish
              setModal(null);
            }}
            workflowName={currentSectionData?.title ?? 'this section'}
          />
        ),
      });

        return;
      }

      if (key && !paneKeys.includes(key)) return;

      setActivePaneKey(() => {
        scrollTo(getSectionKey(key));
        return key;
      });
    },
    [isPaneBlocked, activePaneKey, sectionData, paneKeys, scrollTo],
  );

  const getFirstSectionPaneKey = useCallback(
    sectionKey => {
      return paneKeys.find(paneKey => paneKey.startsWith(sectionKey));
    },
    [paneKeys],
  );

  const getNextSectionIndex = useCallback(
    index => {
      const next = index + 1;

      if (next > sectionKeys.length - 1) {
        return sectionKeys.length - 1;
      }

      return next;
    },
    [sectionKeys],
  );

  const getPrevSectionIndex = useCallback(index => {
    const next = index - 1;

    if (next < 0) {
      return 0;
    }

    return next;
  }, []);

  const getNextSectionKey = useCallback(
    paneKey => {
      const sectionKey = getSectionKey(paneKey);

      const sectionIndex = sectionKeyIndexes[sectionKey];
      let nextIndex = getNextSectionIndex(sectionIndex);

      const addAdGroupSectionIndex = sectionKeyIndexes[sectionNames.addAdGroup];

      if (nextIndex === addAdGroupSectionIndex) nextIndex += 1;

      const nextSection = sectionKeys[nextIndex];
      return getFirstSectionPaneKey(nextSection);
    },
    [
      sectionKeyIndexes,
      getNextSectionIndex,
      sectionKeys,
      getFirstSectionPaneKey,
    ],
  );

  const getPrevSectionKey = useCallback(
    paneKey => {
      const sectionKey = getSectionKey(paneKey);

      const sectionIndex = sectionKeyIndexes[sectionKey];
      const prevIndex = getPrevSectionIndex(sectionIndex);

      const prevSection = sectionKeys[prevIndex];
      return getFirstSectionPaneKey(prevSection);
    },
    [
      sectionKeyIndexes,
      getPrevSectionIndex,
      sectionKeys,
      getFirstSectionPaneKey,
    ],
  );

  const goToNextSection = useCallback(() => {
    updateActivePaneKey(getNextSectionKey(activePaneKey));
  }, [updateActivePaneKey, getNextSectionKey, activePaneKey]);

  const goToPrevSection = useCallback(() => {
    updateActivePaneKey(getPrevSectionKey(activePaneKey));
  }, [activePaneKey, updateActivePaneKey, getPrevSectionKey]);

  const jumpTo = useCallback(key => {
    const paneKey = key.includes('::') ? key : getFirstSectionPaneKey(key);
    if (paneKey) {
      updateActivePaneKey(paneKey, true);
    }
  }, [getFirstSectionPaneKey, updateActivePaneKey]);

  const hasPrevSection = useCallback(
    sectionKey => {
      const index = sectionKeyIndexes[sectionKey];
      return index > 0;
    },
    [sectionKeyIndexes],
  );

  const hasNextSection = useCallback(
    sectionKey => {
      const index = sectionKeyIndexes[sectionKey];
      return index < sectionKeys.length - 1;
    },
    [sectionKeys, sectionKeyIndexes],
  );

  const isActive = useCallback(
    sectionKey => {
      return activeSectionKey.startsWith(sectionKey);
    },
    [activeSectionKey],
  );

  const isActivePane = useCallback(
    paneKey => {
      return activePaneKey === paneKey;
    },
    [activePaneKey],
  );

  const isNextAfterActive = useCallback(
    sectionKey => {
      const nextSectionKey = getSectionKey(getNextSectionKey(activePaneKey));
      return sectionKey === nextSectionKey;
    },
    [getNextSectionKey, activePaneKey],
  );

  const showPane = useCallback(
    (key, data = null) => {
      const paneKey = key.includes('::')
        ? key
        : `${activeSectionKey}::${key}`;

      if (paneKeys.includes(paneKey)) {
        setActivePaneData(data);
        setActivePaneKey(paneKey);
      }
    },
    [paneKeys, activeSectionKey],
  );

  const hidePane = useCallback(() => {
    setActivePaneKey(getFirstSectionPaneKey(activeSectionKey));
    setActivePaneData(null);
  }, [activeSectionKey, getFirstSectionPaneKey]);

  const hideSection = useCallback(() => {
    setActivePaneKey('');
    setActivePaneData(null);
  }, []);

  const blockPane = useCallback(paneKey => {
    setBlockedPaneKeys(keys => ({ ...keys, [paneKey]: true }));
  }, []);

  const unblockPane = useCallback(paneKey => {
    setBlockedPaneKeys(keys => ({ ...keys, [paneKey]: false }));
  }, []);

  const value = useMemo(() => ({
    activePaneKey,
    activePaneData,
    activeSectionKey,
    paneKeys,
    getPrevSectionKey,
    getNextSectionKey,
    goToNextSection,
    goToPrevSection,
    hasNextSection,
    hasPrevSection,
    isActive,
    isActivePane,
    isNextAfterActive,
    jumpTo,
    showPane,
    hidePane,
    hideSection,
    registerSection,
    registerSectionPane,
    blockPane,
    unblockPane,
    isPaneBlocked,
  }), [
    paneKeys,
    blockedPaneKeys,
    activePaneKey,
    activePaneData,
    activeSectionKey,
  ]);

  useEffect(() => {
    setActivePaneKey(() => {
      if (paneKeys?.includes(sectionKey)) {
        return sectionKey;
      }

      if (paneKeys?.includes(initialActivePaneKey)) {
        return initialActivePaneKey;
      }

      return paneKeys[0] ?? '';
    });
  }, [paneKeys, sectionKey, initialActivePaneKey]);

  useEffect(() => {
    if (activePaneKey) {
      scrollTo(getSectionKey(activePaneKey));
    }
  }, [activePaneKey]);

  return (
    <WizardNavigationContext.Provider value={value}>
      {children}
    </WizardNavigationContext.Provider>
  );
};

WizardNavigationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.oneOf(Object.values(actions)),
};

export default WizardNavigationContext;
