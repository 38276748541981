import { useContext } from 'react';

import FlagsContext from '../../providers/FlagsContext';
import { Flags } from '../../constants';

export const useFlags = () => {
  const flagsContext = useContext(FlagsContext);

  if (flagsContext === undefined) {
    throw new Error('useFlags was used outside a FlagsContextProvider');
  }

  const { flags, isFlagsLoading } = flagsContext;

  return { flags, Flags, isFlagsLoading };
};
