import { Switch as MuiSwitch, styled } from '@mui/material';
import React from 'react';

const SwitchStyled = styled(MuiSwitch)(({ theme }) => ({
  width: theme.spacing(4.5),
  height: theme.spacing(2.25),
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: theme.spacing(1.95),
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2.5,
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.green[6],
      },
      '& .MuiSwitch-thumb:before': {
        content: '""',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="${encodeURIComponent(
          theme.palette.green[6],
        )}" d="M3.18182 5.93284L0.795455 3.58209L0 4.36567L3.18182 7.5L10 0.783582L9.20455 0L3.18182 5.93284Z"/></svg>')`,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: theme.spacing(1.55),
    height: theme.spacing(1.55),
    borderRadius: theme.spacing(1.75),
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    margin: theme.spacing(0.125),

    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: theme.spacing(1),
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export const Switch = ({ ...props }) => {
  return <SwitchStyled {...props} />;
};

Switch.propTypes = {
  ...MuiSwitch.propTypes,
};
