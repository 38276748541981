import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { IconButton, InputAdornment, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockThree } from '@fortawesome/pro-light-svg-icons';

const IconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
}));

const SelectTime = forwardRef(
  (
    {
      value,
      uniqueKey,
      startOfDay,
      endOfDay,
      defaultValue,
      slotProps: passedSlotProps,
      fieldState,
      ...props
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    // Determine the initial state based on startOfDay and endOfDay props
    const getInitialState = () => {
      if (startOfDay) return moment().startOf('day'); // Sets the time to 00:00 AM
      if (endOfDay) return moment().endOf('day').subtract(4, 'minute'); // Sets the time to 11:55 PM
      return null;
    };

    const actualValue =
      !value && !defaultValue && (startOfDay || endOfDay)
        ? getInitialState()
        : value ?? defaultValue ?? null;

    const slotProps =
      passedSlotProps ?? fieldState
        ? {
            textField: {
              error: fieldState.invalid,
              helperText: fieldState.error?.message,
            },
          }
        : {};

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TimePicker
          {...props}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          ref={ref} // Forwarding the ref to the TimePicker component
          key={uniqueKey} // Unique key for React's reconciliation process
          value={actualValue} // The currently selected time
          slotProps={{
            ...slotProps,
            textField: {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      <IconStyled icon={faClockThree} />
                    </IconButton>
                  </InputAdornment>
                ),
              },
              ...slotProps.textField,
            },
          }}
        />
      </LocalizationProvider>
    );
  },
);

SelectTime.displayName = 'SelectTime'; // Display name for the SelectTime component

// PropType validation for the SelectTime component
SelectTime.propTypes = {
  ...TimePicker.propTypes, // Include TimePicker prop types
  uniqueKey: PropTypes.string, // Unique key for distinguishing multiple instances
  startOfDay: PropTypes.bool, // Indicate selection should default to the start of the day
  endOfDay: PropTypes.bool, // Indicate selection should default to the end of the day
};

export default SelectTime;
