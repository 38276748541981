import React from 'react';
import PropTypes from 'prop-types';

import { AccordionActions } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnDownLeft } from '@fortawesome/pro-regular-svg-icons';
import { useWizardContext } from '../../hooks/useWizardContext';
import SectionAction from './SectionAction';

export const SectionActions = ({
  nextCallback: nextCallbackProp,
  nextLabel: nextLabelProp,
  nextDisabled = false,
  prevDisabled = false,
  prevCallback: prevCallbackProp,
  prevLabel: prevLabelProp,
  sectionKey,
}) => {
  const { navigation } = useWizardContext();
  const { goToNext, goToPrev, hasNext, hasPrev } = navigation;

  const nextLabel = nextLabelProp || 'next';
  const prevLabel = prevLabelProp || 'prev';

  const nextCallback = nextCallbackProp || goToNext;
  const prevCallback = prevCallbackProp || goToPrev;

  const nextEnabled = !nextDisabled && (hasNext || nextCallbackProp);
  const prevEnabled = !prevDisabled && (hasPrev || prevCallbackProp);

  return (
    <AccordionActions
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <SectionAction
        label={prevLabel}
        handler={() => prevCallback({ sectionKey, navigation })}
        disabled={!prevEnabled}
      />
      <SectionAction
        label={nextLabel}
        variant="contained"
        handler={() => nextCallback({ sectionKey, navigation })}
        disabled={!nextEnabled}
        endIcon={<FontAwesomeIcon icon={faArrowTurnDownLeft} />}
      />
    </AccordionActions>
  );
};

SectionActions.propTypes = {
  nextCallback: PropTypes.func,
  nextLabel: PropTypes.string,
  prevCallback: PropTypes.func,
  prevLabel: PropTypes.string,
  sectionKey: PropTypes.string,
  nextDisabled: PropTypes.bool,
  prevDisabled: PropTypes.bool,
};
