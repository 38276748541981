import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import { Accordion } from '@mui/material';


const StyledAccordion = styled(Accordion)(({ theme }) => ({
  padding: '36px 62px',
  scrollMarginTop: theme.spacing(14), // consistent with vertical timeline
  backgroundColor: '#FFFFFF',
  '&.MuiPaper-rounded': {
    borderRadius: 20,
  },
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 'auto',
  },
  '&:not(:last-child)': {
    marginBottom: 25,
  },
  '.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 24,
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    marginTop: 12,
  },
  '&.Mui-disabled': {
    backgroundColor: '#FFFFFF',
  },
}));

const WizardSectionContainer = forwardRef(({ children, toggleSection, isActiveSection, ...rest }, ref) => {
  return (
    <StyledAccordion
      expanded={isActiveSection}
      elevation={3}
      onChange={toggleSection}
      ref={ref}
      {...rest}
    >
      {children}
    </StyledAccordion>
  );
});

WizardSectionContainer.displayName = 'WizardSectionContainer';

WizardSectionContainer.propTypes = {
  children: PropTypes.node,
  isActiveSection: PropTypes.bool,
  toggleSection: PropTypes.func,
};

export default WizardSectionContainer;
