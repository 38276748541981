import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocketLaunch } from '@fortawesome/pro-light-svg-icons';

import {
  IconButton,
  styled,
} from '@mui/material';

const StyledIcon = styled(FontAwesomeIcon)(({ theme, status }) => ({
  color: status === 'active' ? theme.palette.blue[6] : theme.palette.grey[4],
}));

const LaunchNode = ({ status, ...props }) => {
  return (
    <IconButton size='small' disableRipple {...props}>
      <StyledIcon icon={faRocketLaunch} status={status}/>
    </IconButton>
  );
}

LaunchNode.propTypes = {
  status: PropTypes.oneOf(['complete', 'add', 'pending', 'active']),
};

export default LaunchNode;
