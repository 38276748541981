import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  ButtonBase,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
  OutlinedInput,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/Warning';

import { useSaveExit } from './hooks/saveExit';
import { useLoader } from './hooks/loader';
import { useCopy } from './hooks';
import { forbiddenCharacters } from './util';
import AsyncButton from './AsyncButton';
import Title from './Title';
import freqCapIcon from '../images/icon-config-freqcaps.png'
import { Themes } from '../constants';

const PREFIX = 'CreateNewCampaign';

const classes = {
  breadcrumbs: `${PREFIX}-breadcrumbs`,
  divider: `${PREFIX}-divider`,
  adsEvery: `${PREFIX}-adsEvery`,
  formControl: `${PREFIX}-formControl`,
  margin: `${PREFIX}-margin`,
  nextBtn: `${PREFIX}-nextBtn`,
  cap: `${PREFIX}-cap`,
  icon: `${PREFIX}-icon`,
  iconWrap: `${PREFIX}-iconWrap`,
  freqCap: `${PREFIX}-freqCap`,
  freqCapWrap: `${PREFIX}-freqCapWrap`,
  freqCapsBtn: `${PREFIX}-freqCapsBtn`,
  incrementalSwitch: `${PREFIX}-incrementalSwitch`,
  incrementalWrap: `${PREFIX}-incrementalWrap`,
  info: `${PREFIX}-info`,
  goalsWrap: `${PREFIX}-goalsWrap`,
  pencil: `${PREFIX}-pencil`,
  tooltipWidth: `${PREFIX}-tooltipWidth`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.breadcrumbs}`]: {
    color: "red",
  },

  [`& .${classes.divider}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.adsEvery}`]: {
    textAlign: 'center',
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.nextBtn}`]: {
    marginTop: theme.spacing(4),
    textAlign: 'right',
  },

  [`& .${classes.cap}`]: {
    color: '#181c20',
    fontSize: '.925rem',
    fontWeight: 400,
    lineHeight: '1.4rem',
  },

  [`& .${classes.icon}`]: {
    fontSize: '1rem',
  },

  [`& .${classes.iconWrap}`]: {
    margin: `0 auto`,
    width: 45,
    height: 45,
    borderRadius: '50%',
    backgroundColor: theme.palette.grey.main,
    position: 'relative',
  },

  [`& .${classes.freqCap}`]: {
    color: '#47505d',
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.freqCapWrap}`]: {
    border: '1px solid rgb(224, 224, 224)',
    padding: theme.spacing(3),
    borderRadius: 10,
    width: '100%',
  },

  [`& .${classes.freqCapsBtn}`]: {
    width: '90%',
    textAlign: 'left',
  },

  [`& .${classes.incrementalSwitch}`]: {
    width: '100%',
  },

  [`& .${classes.incrementalWrap}`]: {
    width: '100%',
  },

  [`& .${classes.info}`]: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },

  [`& .${classes.goalsWrap}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.pencil}`]: {
    color: 'rgb(114 115 131 / 63%)',

    '&.MuiSvgIcon-fontSizeSmall': {
      fontSize: '1rem',
    },
  },

  [`& .${classes.tooltipWidth}`]: {
    backgroundColor: `#f2f3f5`,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    maxWidth: 180,
  },
}));

const Copies = {
  [Themes.DEFAULT]: {
    HEAD: 'Initiate a New Campaign',
    INTRO: 'Before we get started let’s name your television campaign. The more descriptive it is the easier it will be to find later!',
    START_TIME_LABEL: 'Start Time (EST)',
    END_TIME_LABEL: 'End Time (EST)',
    FREQUENCY_CAP_LABEL: 'Configure frequency cap',
  },
  [Themes.NBCU]: {
    HEAD: 'Create a New Campaign',
    INTRO: 'To get started, name your campaign, set your budget, and select your flight dates.',
    START_TIME_LABEL: 'Start Time (ET)',
    END_TIME_LABEL: 'End Time (ET)',
    FREQUENCY_CAP_LABEL: 'Configure Frequency Cap',
  }
}

const frequencyTip = `
Frequency caps can be set at various time frames and are used to control the over-delivery of your ad to the same household or to ensure maximum reach across your target audience.
`;

const incrementalityTip = `
This setting will randomly set aside a percentage of your campaign audience as a holdout group to determine the effectiveness of your campaign.  Instead of your assigned TV Ad, a randomly assigned PSA will be delivered to this audience to measure the affects of your ad compared to this control group.
`;

const retargetTip = `Display retargeting is an effective way to build frequency with your target audience after they are exposed to your CTV. CPMs range from $2-$3.`;

const abTestTip = `Split testing allows you to test different ad groups in a scientifically clean way that isolates the exposed households by test group so that there's no contamination.`;

const setGoalsTip = `Set a target goal for campaign optimization.`;

const CreateNewCampaign = (props) => {

  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { isLoading, setIsLoading } = useLoader();
  const { saveProgress } = useSaveExit();
  const Copy = useCopy(Copies);

  const [totalBudget, setTotalBudget] = useState('');
  const [budgetType, setBudgetType] = useState('daily');

  const {
    amount,
    campaignName,
    defaultValues = {},
    save,
    triggerSave,
    noEndDate,
    showDisplay = true,
    showTestIncrementality,
    goalsList = [],
    handleUpdateStartDate,
    handleUpdateStartTime,
    handleUpdateEndDate,
    handleUpdateEndTime,
  } = props;

  const {
    isDisplay,
    isExperiment,
    isGoals,
    isIncremental,
    handleDisplay,
    handleExperiment,
    handleGoals,
    handleIncremental,
    handleCapAds,
    adCap,
    display,
    experiment,
    incremental,
    goals,
  } = props.campaign;

  useEffect(() => {
    if (budgetType === 'daily') {
      handleTotalBudget();
    }
  }, [props.amount]);

  useEffect(() => {
    if (budgetType === 'total') {
      handleDailyBudget();
    }
  }, [totalBudget]);

  useEffect(() => {
    if (budgetType === 'total') {
      handleDailyBudget();
    }

    if (budgetType === 'daily') {
      handleTotalBudget();
    }
  }, [props.selectedStartDate, props.selectedEndDate]);

  useEffect(() => {
    saveProgress(
      save,
      'CreateNewCampaign',
      handleCreateCampaign,
      triggerSave
    );
  }, [save]);

  const duration = useMemo(() => {
    const budgetStartDate = Date.parse(props.selectedStartDate);
    const budgetEndDate = Date.parse(props.selectedEndDate);
    const length = Math.round(
      (budgetEndDate - budgetStartDate) / (1000 * 3600 * 24)
    );

    return length + 1;
  }, [props.selectedStartDate, props.selectedEndDate]);

  const isNextDisabled = useMemo(() => {
    return (
      (!campaignName || props.amount <= 0) ||
      (budgetType === 'total' && noEndDate) ||
      props.isDailyInputError ||
      props.isPercentageError
    )
  }, [amount, campaignName, budgetType, noEndDate])

  const advertiserNameSubComp = (() => {
    if (props.forCompany) {
      return props.companyName
    }

    return props.advertiserName;
  });

  const handleTotalBudget = () => {
    if (!props.noEndDate) {
      const campaignBudget = amount * duration;
      setTotalBudget(campaignBudget.toFixed(2));
    }

    if (props.noEndDate) {
      const monthly = amount * 30.4;
      setTotalBudget(monthly.toFixed(2));
    }
  };

  const handleDailyBudget = () => {
    if (!props.noEndDate) {
      const daily = totalBudget / duration;
      props.handleUpdateAmount(daily.toFixed(2));
    }

    if (props.noEndDate) {
      const daily = totalBudget / 30.4;
      props.handleUpdateAmount(daily.toFixed(2));
    }
  };

  const handleCreateCampaign = async () => {
    setIsLoading(true);

    try {
      const response = await props.handleSaveCampaign();
      if (response.isAxiosError) {
        throw response.response.data
      }

      setIsLoading(false);
      props.handleCampaignPanel(true);
      props.setIsAdGroup(true);
      props.updateBreadcrumbs('createCampaign', 100, 'targeting', 0);

      console.log('response from createnewcampaign', response);

      if (!save.exit) {
        history.push(`/campaign-edit/${response.data.id}`);
        props.setStep('GeoTargeting');
      }
      return response;
    } catch (error) {
      console.error('Error in saving CreateNewCampaign', error)

      if (Object.values(error).length > 0) {
        Object.values(error).map((message) => {
          enqueueSnackbar(message, {
            autoHideDuration: 3000,
            preventDuplicate: true,
            variant: 'error',
          });
        })
      }
      setIsLoading(false);
      return error;
    }
  };

  const handleDisplayToggle = event => {
    handleDisplay();

    if (!event.target.checked) {
      closeSnackbar();
    }

    if (event.target.checked) {
      const dayBudget = amount !== '' ? amount : 0;
      const budget =
        (parseFloat(display.percent, 10) / 100) * parseFloat(dayBudget, 10);

      if (!isNaN(budget)) {
        display.setDisplayBudget(Math.floor(budget * 100) / 100);
      }
    }
  };

  const handleDisplayPercentage = event => {
    const { value } = event.target;

    if (value != null) {
      display.setPercent(isNaN(value) ? 0 : value);

      const dayBudget = amount !== '' ? amount : 0;

      const budget =
        (parseFloat(isNaN(value) ? 0 : value, 10) / 100) *
        parseFloat(dayBudget, 10);

      if (!isNaN(budget)) {
        display.setDisplayBudget((Math.floor(budget * 100) / 100).toFixed(2));
      }
    }
  };

  // Formats the total/monthly budget amount with commas and desired decimal places
  function formatAmount(amount, decimals) {
    // Determines decimals to round to, and splits it into array of pre and post decimal
    const amountDec = amount.toFixed(decimals).split('.');
    // Turns the pre decimal number into an array, iterates from the end, and adds commas for formatting
    return amountDec[0].split('').reduceRight((acc, amount, i, orig) => {
      const pos = orig.length - i - 1;
      return amount + (pos && !(pos % 3) ? ',' : '') + acc;
    }, '') + (amountDec[1] ? `.${amountDec[1]}` : '');
  }

  // Sets total/daily budget message
  let overallBudgetMessage = '';
  if (props.amount && props.amount > 0 && !props.noEndDate) {
    const campaignBudget = props.amount * duration;
    overallBudgetMessage = `Your campaign budget is estimated to $${formatAmount(campaignBudget, 0)}, which will be distributed evenly across the campaign dates.`;
  } else if (props.amount && props.amount > 0 && props.noEndDate) {
    const monthlyBudget = props.amount * 30.4;
    overallBudgetMessage = `Your monthly budget is estimated to $${formatAmount(monthlyBudget, 0)}, which will be distributed evenly across the campaign dates.`;
  } else {
    overallBudgetMessage = 'Please enter a daily budget to see the estimated total budget.';
  }

  const renderGoals = () => (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormControlLabel
          className={classes.incrementalSwitch}
          control={
            <TextField
              id="set-goals"
              className={clsx(classes.incrementalSwitch, 'goals-optimized')}
              color="secondary"
              variant="outlined"
              select
              label="Goals"
              value={goals.optimize}
              onChange={(event) => goals.handleOptimize(event.target.value)}
            >
              {goalsList.map(({ key, value }) => (
                <MenuItem key={key} value={key}>{value}</MenuItem>
              ))}
            </TextField>
          }
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          color="secondary"
          id="goals-input"
          variant="outlined"
          type="number"
          value={goals.cpa}
          placeholder="0"
          label={`Target ${goals.optimize}`}
          InputProps={{
            startAdornment: goals.optimize === "ROAS" ? '' : <InputAdornment position="start">$</InputAdornment>,
          }}
          onKeyDown={(event) => {
            if (_.includes(forbiddenCharacters, event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(event) => goals.handleCPA(event.target.value)}
        />
      </Grid>
    </Grid>
  );

  const renderFreqCaps = () => (
    <Box my={1} className={classes.freqCapWrap}>
      {adCap.freqCaps.map(f => (
        <Typography key={f.fctype} className={classes.cap}>
          {f.impressions} ads every&nbsp;
          {f.duration} {f.fctype.toLowerCase()}(s)
        </Typography>
      ))}
    </Box>
  );

  const renderDisplay = () => (
    <Box my={1}>
      <Grid container>
        <Grid item xs={6}>
          <TextField
            autoFocus
            error={props.isPercentageError}
            className="display-input"
            color="secondary"
            variant="outlined"
            type="number"
            value={display.percent}
            label="Max % Display Budget"
            placeholder="Enter Amount"
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            InputLabelProps={{ shrink: true }}
            onChange={handleDisplayPercentage}
          />
        </Grid>
      </Grid>
    </Box>
  );

  const renderExperiment = () => (
    <Grid
      container
      item
      xs={12}
    >
      <Grid item xs={6}>
        <FormControl variant="standard" className={classes.incrementalSwitch}>
          <Select
            color="secondary"
            defaultValue={'2-Way Split'}
            variant="outlined"
            labelId="experiment-dropdown"
            id="experiment-dropdown"
            data-testid="experiment-dropdown"
            value={experiment.plan}
            onChange={(event) => experiment.setPlan(event.target.value)}
          >
            <MenuItem value={'2_WAY_SPLIT'} data-testid="2-way-split-option">2-way test</MenuItem>
            <MenuItem value={'3_WAY_SPLIT'} data-testid="3-way-split-option">3-way test</MenuItem>
            <MenuItem value={'4_WAY_SPLIT'} data-testid="4-way-split-option">4-way test</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  const renderIncremental = () => (
    <Grid
      container
      item
      xs={12}
    >
      <Grid item xs={6}>
        <FormControl variant="standard" className={classes.incrementalSwitch}>
          <Select
            color="secondary"
            defaultValue={'1PCT_HOLDOUT'}
            variant="outlined"
            labelId="incremental-dropdown"
            id="incremental-dropdown"
            value={incremental.holdOut}
            onChange={(event) => incremental.setHoldOut(event.target.value)}
          >
            <MenuItem value={'1PCT_HOLDOUT'}>1% hold out</MenuItem>
            <MenuItem value={'5PCT_HOLDOUT'}>5% hold out</MenuItem>
            <MenuItem value={'10PCT_HOLDOUT'}>10% hold out</MenuItem>
            <MenuItem value={'20PCT_HOLDOUT'}>20% hold out</MenuItem>
            <MenuItem value={'25PCT_HOLDOUT'}>25% hold out</MenuItem>
            <MenuItem value={'50PCT_HOLDOUT'}>50% hold out</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  return (
    (<Root>
      <Title>{Copy.HEAD}</Title>
      <p>{Copy.INTRO}</p>
      <Divider className={classes.divider} />

      <Grid container spacing={1}>
        <Grid item container justifyContent="flex-end">
          <Grid item>
            <Switch
              checked={props.noEndDate}
              onChange={props.handleNoEndDateChange}
              name="checkedA"
              color="secondary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              size="small"
            />
            <span>No end date</span>
          </Grid>
        </Grid>
        <Grid item container spacing={10} justifyContent="space-between">
          <Grid item xs={5}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Box mt={2}>
                  <TextField
                    id="campaign-name"
                    className="campaign-name"
                    autoFocus
                    color="secondary"
                    fullWidth
                    variant="outlined"
                    type="text"
                    label="Campaign Name"
                    value={props.campaignName}
                    onChange={event => props.setCampaignName(event.target.value)}
                  />

                  {advertiserNameSubComp()}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <TextField
                    className="advertiser-name"
                    disabled
                    fullWidth
                    variant="outlined"
                    type="text"
                    label="Advertiser Name"
                    value={defaultValues['advertiser-name'] || ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <LockOutlinedIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              </Grid>

              <Grid item container spacing={1}>
                <Grid container item spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      select
                      color="secondary"
                      label="Budget Type"
                      onChange={event => setBudgetType(event.target.value)}
                      value={budgetType}
                      variant="outlined"
                    >
                      <MenuItem value="daily">
                        Daily budget
                      </MenuItem>

                      <MenuItem value="total">
                        Total budget
                      </MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        color="secondary"
                        htmlFor="outlined-adornment-amount"
                      >
                        {`${budgetType === 'total' ? 'Total' : 'Daily'} Budget`}
                      </InputLabel>

                      <OutlinedInput
                        id="outlined-adornment-amount"
                        error={props.isDailyInputError}
                        className="campaign-budget"
                        color="secondary"
                        type="number"
                        placeholder="0"
                        value={budgetType === 'total'
                          ? totalBudget
                          : props.amount
                        }
                        onKeyDown={(event) => {
                          if (_.includes(forbiddenCharacters, event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(event) => event.preventDefault()}
                        onChange={(event) => {
                          if (budgetType === 'daily') {
                            props.handleUpdateAmount(event.target.value);
                          } else {
                            setTotalBudget(event.target.value);
                          }
                        }}
                        fullWidth
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={100}
                        inputProps={{ min: 0, step: .01 }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  {budgetType === 'daily' && (
                    <Grid item container direction="row">
                      <Grid item xs>
                        <InfoOutlinedIcon
                          className={classes.icon}
                          fontSize="small"
                          color="secondary"
                        />
                      </Grid>

                      <Grid item xs={11}>
                        <Typography sx={{ fontSize: '.75rem', textWrap: 'nowrap' }}>
                          {overallBudgetMessage}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {budgetType === 'total' && props.noEndDate && (
                    <Grid item container direction="row">
                      <Grid item xs>
                        <WarningIcon
                          className={classes.icon}
                          fontSize="small"
                          color="error"
                        />
                      </Grid>

                      <Grid item xs={11}>
                        <Typography sx={{ fontSize: '.75rem', textWrap: 'nowrap', color: '#f76a6a', }}>
                          Budget type Total Budget requires a campaign end date
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid container item xs={12} alignItems="center">
                <Grid container item xs={12} spacing={2}>
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Button
                        className="freq-cap-btn"
                        onClick={() => handleCapAds(true)}
                        endIcon={adCap.freqCaps.length > 0
                          ? (
                            <CreateOutlinedIcon
                              className={classes.pencil}
                              fontSize="small"
                            />
                          ) : null}
                      >
                        <img src={freqCapIcon} width={17} />

                        <Typography className={classes.freqCap}>
                          {Copy.FREQUENCY_CAP_LABEL}
                        </Typography>
                      </Button>

                      <Tooltip
                        classes={{ tooltip: classes.tooltipWidth }}
                        title={frequencyTip}
                      >
                        <InfoOutlinedIcon
                          className={classes.info}
                          fontSize="small"
                          color="secondary"
                        />
                      </Tooltip>
                    </Grid>

                    <ButtonBase
                      disableRipple
                      onClick={() => handleCapAds(true)}
                      className={classes.freqCapsBtn}
                    >
                      {adCap.freqCaps && adCap.freqCaps.length > 0 &&
                        renderFreqCaps()}
                    </ButtonBase>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={5} justifyContent="space-evenly" spacing={2}>
            <Grid item xs={12}>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    color="secondary"
                    disableToolbar
                    fullWidth
                    timezone="America/New_York"
                    inputFormat="MM/dd/yyyy"
                    inputVariant="outlined"
                    label="Start Date"
                    data-testid="start-date-picker"
                    id="date-picker-inline"
                    margin="normal"
                    minDate={props.selectedStartDate}
                    onChange={handleUpdateStartDate}
                    value={props.selectedStartDate}
                    variant="inline"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <DatePicker
                    color="secondary"
                    disabled={props.noEndDate}
                    disableToolbar
                    fullWidth
                    inputFormat="MM/dd/yyyy"
                    inputVariant="outlined"
                    label="End Date"
                    data-testid="end-date-picker"
                    id="date-picker-inline-end"
                    margin="normal"
                    minDate={props.selectedStartDate}
                    onChange={handleUpdateEndDate}
                    onClick={props.enableDateTimeInput}
                    value={props.noEndDate ? null : props.selectedEndDate}
                    variant="inline"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    timezone="America/New_York"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TimePicker
                    color="secondary"
                    margin="normal"
                    inputVariant="outlined"
                    label={Copy.START_TIME_LABEL}
                    id="time-picker"
                    value={props.selectedStartTime}
                    onChange={handleUpdateStartTime}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                    fullWidth
                    timezone="America/New_York"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TimePicker
                    color="secondary"
                    disabled={props.noEndDate}
                    margin="normal"
                    inputVariant="outlined"
                    label={Copy.END_TIME_LABEL}
                    id="time-picker-end"
                    value={props.noEndDate ? null : props.selectedEndTime}
                    onChange={handleUpdateEndTime}
                    onClick={props.enableDateTimeInput}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                    fullWidth
                    timezone="America/New_York"
                  />
                </Grid>
              </Grid>

            </Grid>

            <Grid container item xs={12}>
              {showTestIncrementality && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isIncremental}
                        onChange={handleIncremental}
                        name="testIncrementality"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        size="small"
                      />
                    }
                    label="Test Incrementality"
                  />

                  <Tooltip
                    classes={{ tooltip: classes.tooltipWidth }}
                    title={incrementalityTip}
                  >
                    <InfoOutlinedIcon
                      className={classes.info}
                      fontSize="small"
                      color="secondary"
                    />
                  </Tooltip>
                </Grid>
              )}

              {isIncremental &&
                <Collapse
                  className={classes.incrementalWrap}
                  in={isIncremental}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box my={1} height="100%" width="100%">
                    {renderIncremental()}
                  </Box>
                </Collapse>}

              {showDisplay && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={isIncremental}
                        checked={isDisplay}
                        onChange={handleDisplayToggle}
                        name="displaySwitch"
                        size="small"
                        inputProps={{
                          'aria-label': 'Retarget exposed audience with display'
                        }}
                      />
                    }
                    label="Retarget exposed audience with display"
                  />

                  <Tooltip
                    classes={{ tooltip: classes.tooltipWidth }}
                    title={retargetTip}
                  >
                    <InfoOutlinedIcon
                      className={classes.info}
                      fontSize="small"
                      color="secondary"
                    />
                  </Tooltip>
                </Grid>
              )}

              {isDisplay &&
                <Collapse
                  className={classes.incrementalWrap}
                  in={isDisplay}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box my={2} height="100%" width="100%">
                    {renderDisplay()}
                  </Box>
                </Collapse>}

              <Grid container item justifyContent="flex-start">
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={isDisplay || isIncremental}
                        checked={isExperiment}
                        onChange={event => handleExperiment(event.target.checked)}
                        name="setExperiment"
                        size="small"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label="Run an A/B test"
                  />

                  <Tooltip
                    classes={{ tooltip: classes.tooltipWidth }}
                    title={abTestTip}
                  >
                    <InfoOutlinedIcon
                      className={classes.info}
                      fontSize="small"
                      color="secondary"
                    />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Collapse
                  className={classes.goalsWrap}
                  in={isExperiment}
                  timeout={100}
                  unmountOnExit
                >
                  <Box my={1} height="100%" width="100%">
                    {renderExperiment()}
                  </Box>
                </Collapse>
              </Grid>

              <Grid container item justifyContent="flex-start">
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={isIncremental}
                        checked={isGoals}
                        onChange={handleGoals}
                        name="setGoals"
                        size="small"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label="Set Goals"
                  />

                  <Tooltip
                    classes={{ tooltip: classes.tooltipWidth }}
                    title={setGoalsTip}
                  >
                    <InfoOutlinedIcon
                      className={classes.info}
                      fontSize="small"
                      color="secondary"
                    />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Collapse
                  className={classes.goalsWrap}
                  in={isGoals}
                  timeout={100}
                  unmountOnExit
                >
                  <Box my={1} height="100%" width="100%">
                    {renderGoals()}
                  </Box>
                </Collapse>
              </Grid>
            </Grid>

            <Grid
              className={classes.nextBtn}
              container
              item
              justifyContent="flex-end"
              alignItems="flex-end"
              xs={12}
            >
              <Grid item>
                <AsyncButton
                  isDisabled={isNextDisabled}
                  id="create-new-campaign"
                  isLoading={isLoading}
                  variant="contained"
                  color="secondary"
                  size="medium"
                  className={clsx('Button--medium', 'initiate-campaign-btn')}
                  onClick={() => {
                    setIsLoading(true);
                    triggerSave('CreateNewCampaign', false, 'GeoTargeting');
                  }}
                  loadingButton="Saving..."
                >
                  Next
                </AsyncButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Root>)
  );
}

CreateNewCampaign.propTypes = {
  campaign: PropTypes.object,
  forCompany: PropTypes.bool,
  companyName: PropTypes.string,
  advertiserName: PropTypes.string,
  defaultValues: PropTypes.object,
  updateBreadcrumbs: PropTypes.func,
  campaignName: PropTypes.string,
  setCampaignName: PropTypes.func,
  selectedStartDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  selectedStartTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  handleUpdateStartDate: PropTypes.func,
  handleUpdateStartTime: PropTypes.func,
  selectedEndDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  selectedEndTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  handleUpdateEndDate: PropTypes.func,
  enableDateTimeInput: PropTypes.func,
  handleUpdateEndTime: PropTypes.func,
  noEndDate: PropTypes.bool,
  handleNoEndDateChange: PropTypes.func,
  testIncrementally: PropTypes.bool,
  showDisplay: PropTypes.bool,
  isDailyInputError: PropTypes.bool,
  isPercentageError: PropTypes.bool,
  showTestIncrementality: PropTypes.bool,
  handleTestIncrementally: PropTypes.func,
  amount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  handleUpdateAmount: PropTypes.func,
  goalsList: PropTypes.array,
  setIsAdGroup: PropTypes.func,
  setStep: PropTypes.func,
  handleCampaignData: PropTypes.func,
  handleCampaignPanel: PropTypes.func,
  handleSaveCampaign: PropTypes.func,
  save: PropTypes.object,
  triggerSave: PropTypes.func,
};

export default CreateNewCampaign;
