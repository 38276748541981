import { useContext } from 'react';

import WizardNavigationContext from '../../providers/WizardNavigationContext';

export const useWizardNavigation = () => {
  const wizardNavigationContext = useContext(WizardNavigationContext);

  if (wizardNavigationContext === undefined) {
    throw new Error(
      'useWizardNavigation WizardNavigationContext was used outside of its Provider',
    );
  }

  return wizardNavigationContext;
};
