import React, { useMemo } from 'react';
import { fields } from '../formConfig';
import { sections } from '@v2/components/campaign/CampaignAdGroupSection';
import { Stack, Typography } from '@mui/material';
import { subSections } from '@v2/components/campaign/CampaignAdGroupSection/constants';
import { entityStatus } from '../../constants';
import moment from 'moment';

export const useSectionsInfo = ({ form }) => {
  const [status, startDate, startTime = moment()] = form.watch([
    fields.status.path,
    fields.startDate.path,
    fields.startTime.path,
  ]);
  const { errors } = form.formState;

  const [bundlesError, bidStrategyError] = [
    errors[fields.advancedCustomInventory.path],
    errors[fields.adGroupBidStrategy.path],
  ];

  const info = useMemo(() => {
    const inventory = [];
    const bidStrategy = [];
    const dates = [];

    if (bundlesError) {
      inventory.push({
        section: sections.inventory,
        type: 'error',
        content: (
          <Typography variant="body2">{bundlesError.message}</Typography>
        ),
      });
    }

    if (bidStrategyError) {
      bidStrategy.push({
        section: sections.bidStrategy,
        type: 'error',
        content: (
          <Typography variant="body2">{bidStrategyError.message}</Typography>
        ),
      });
    }

    if (status === entityStatus.active) {
      dates.push({
        section: sections.general,
        type: 'info',
        sub: subSections.general.dates,
        content: (
          <Stack>
            <Typography variant="body2">
              Starting date is disabled for active ad groups.
            </Typography>
          </Stack>
        ),
      });
    }

    return [...inventory, ...dates, ...bidStrategy];
  }, [bundlesError, bidStrategyError, status, startDate, startTime]);

  return {
    info,
  };
};
