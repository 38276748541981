import PropTypes from 'prop-types';

export const optionType = PropTypes.shape({
  id: PropTypes.number,
  included: PropTypes.bool,
  featured: PropTypes.bool,
});

export const optionalOptionType = PropTypes.oneOfType([
  optionType,
  PropTypes.oneOf([null]),
]);

export const tabType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  hint: PropTypes.string,
});

export const contextType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  stressed: PropTypes.bool.isRequired,
});

export const groupedOptionType = PropTypes.shape({
  groupName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(optionType),
});

export const dataType = PropTypes.oneOfType([
  PropTypes.arrayOf(optionType),
  PropTypes.arrayOf(groupedOptionType),
]);

export const tableSettings = PropTypes.shape({
  includable: PropTypes.bool,
  separateInclExcl: PropTypes.bool,
});
