import moment from 'moment';

import CampaignSettingsSection from './CampaignSettingsSection';
import AdGroupSettingsSection from './AdGroupSettingsSection';
import ReviewAndLaunchSection from './ReviewAndLaunchSection';

import { NodeLabels, nodeKeys, sectionNames } from './constants';
import { buildSectionKey } from '../../helpers';

const getStatus = (data, keys) => {
  return keys.every(key => Boolean(data?.[key])) ? 'complete' : 'pending';
};

const buildCampaignSettings = (campaign = null) => {
  const campaignId = campaign?.id;

  return {
    key: buildSectionKey(['CampaignSettings']),
    props: { campaignId, campaign },
    name: campaign?.name,
    component: CampaignSettingsSection,
    section: sectionNames.campaign,
    data: campaign,
    nodes_info: [
      {
        key: nodeKeys.campaignNameDates,
        label: NodeLabels.CAMPAIGN_NAME_DATES,
        status: getStatus(campaign, ['name', 'startDate']),
      },
      {
        key: nodeKeys.campaignBudget,
        label: NodeLabels.CAMPAIGN_BUDGET,
        status: getStatus(campaign, ['dailyBudget']),
      },
      {
        key: nodeKeys.campaignObjective,
        label: NodeLabels.CAMPAIGN_OBJECTIVE,
        // campaign objective not yet implemented in BE?
        status: getStatus(campaign, ['objective']),
      },
    ],
  };
};

export const buildAdGroupSettingsSection = ({
  campaignId,
  adGroupId = 1,
  campaign,
  adGroup,
  isDisplay = false,
}) => {
  return {
    key: buildSectionKey([
      isDisplay ? 'displayAdGroupSettings' : 'adGroupSettings',
      adGroupId,
    ]),
    props: { campaignId, campaign, adGroup, adGroupId, isDisplay },
    name: adGroup?.name,
    component: AdGroupSettingsSection,
    temporary: !adGroup?.id,
    section: isDisplay ? sectionNames.displayAdGroup : sectionNames.adGroup,
    data: adGroup,
    nodes_info: [
      {
        key: nodeKeys.adGroupNameDates,
        label: NodeLabels.AD_GROUP_NAME_DATES,
        status: getStatus(adGroup, ['name', 'start_date']),
      },
      {
        key: nodeKeys.adGroupBudget,
        label: NodeLabels.AD_GROUP_BUDGET,
        status: getStatus(adGroup, ['daily_budget']),
      },
      {
        key: nodeKeys.adGroupBidStrategy,
        label: NodeLabels.AD_GROUP_BID_STRATEGY,
        status: getStatus(adGroup, ['bid_strategy']),
      },
      {
        key: nodeKeys.adGroupGeography,
        label: NodeLabels.AD_GROUP_GEOGRAPHY,
        status: getStatus(adGroup, ['geo_targets']),
      },
      {
        key: nodeKeys.adGroupAudience,
        label: NodeLabels.AD_GROUP_AUDIENCE,
        status: getStatus(adGroup, ['audience_targets']),
      },
      {
        key: nodeKeys.adGroupInventory,
        label: NodeLabels.AD_GROUP_INVENTORY,
        status: getStatus(adGroup, ['inventory_targets']),
      },
      {
        key: nodeKeys.adGroupCreative,
        label: NodeLabels.AD_GROUP_CREATIVE,
        status: getStatus(adGroup, ['creative']),
      },
    ],
  };
};

export const buildDisplayAdGroupSettingsSection = ({
  campaignId,
  campaign,
  adGroupId = 1,
  adGroup,
}) => {
  const timestamp = moment().format('MMMM Do, h:mm a');

  const newAdGroupSection = buildAdGroupSettingsSection({
    campaignId,
    adGroupId,
    campaign,
    adGroup: adGroup ?? {
      name: `${timestamp} Ad Group - Display Retargeting`,
    },
    isDisplay: true,
  });

  return newAdGroupSection;
};

const buildReviewAndLaunchSection = campaign => {
  const campaignId = campaign?.id;

  return {
    key: buildSectionKey(['ReviewAndLaunch']),
    props: { campaignId },
    component: ReviewAndLaunchSection,
    section: sectionNames.reviewAndLaunch,
    nodes_info: [
      {
        key: 'review',
        label: NodeLabels.REVIEW_CAMPAIGN,
        status: 'pending',
      },
    ],
  };
};

export const buildNewCampaignSections = (data = {}) => {
  return [
    buildCampaignSettings(),
    buildAdGroupSettingsSection(data),
    buildReviewAndLaunchSection(),
  ];
};

export const buildExistingCampaignSections = ({
  campaignId,
  campaign,
  adGroups,
  staticDisplayAdGroups,
}) => {
  const adGroupsSections =
    adGroups.length > 0
      ? adGroups.map(adGroup =>
          buildAdGroupSettingsSection({
            campaignId,
            adGroupId: adGroup.id,
            campaign,
            adGroup,
          }),
        )
      : [buildAdGroupSettingsSection({ campaignId, campaign })];
  const reviewAndLaunchSection = buildReviewAndLaunchSection(campaign);

  const staticDisplayAdGroupsSections = staticDisplayAdGroups.length
    ? staticDisplayAdGroups.map(adGroup =>
        buildDisplayAdGroupSettingsSection({
          campaignId,
          campaign,
          adGroupId: adGroup.id,
          adGroup,
        }),
      )
    : [];

  // The campaign.display_pct will be set to `"0.0"` for no display or `"0.1"`
  // for display. Since the `"0.0"` string will be evaluated as truthy, we
  // cast it to a number before evaluating its truthiness.
  const campaignHasDisplay = Number(campaign?.display_pct) ? true : false;

  if (campaignHasDisplay && !staticDisplayAdGroupsSections.length) {
    staticDisplayAdGroupsSections.push(
      buildDisplayAdGroupSettingsSection({ campaignId, campaign }),
    );
  }

  return [
    buildCampaignSettings(campaign),
    ...adGroupsSections,
    ...staticDisplayAdGroupsSections,
    reviewAndLaunchSection,
  ];
};

export const buildAddAdGroupTimelineNode = () => {
  return {
    key: 'addAdGroup',
    section: sectionNames.addAdGroup,
    nodes_info: [
      { key: nodeKeys.addAdGroup, label: 'Add Ad Group', status: 'add' },
    ],
  };
};
