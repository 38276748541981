import React, { Fragment, useState, useEffect, useContext, useMemo, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Typography,
  Checkbox,
  Chip,
  Box,
  Button,
  Menu,
  MenuItem,
  InputLabel,
  Select,
  ListItemText,
  FormControl,
} from '@mui/material';
// Commented out imports to be added in again
// import SearchIcon from '@mui/icons-material/Search';
// import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
// import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import moment from 'moment-timezone';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import AppHeader from '../../AppHeader';
import AdvertiserContext from '../../AdvertiserContext';
import AddAdGroup from '../../AddAdGroup';
import ManageAdGroup from '../../ManageAdGroup';
import ManageBid from '../../ManageBid';
import EditWeighting from '../../EditWeighting';
import ManageDisplay from '../../ManageDisplay';
import SortableTableHead from '../../ui/SortableTableHead';
import { useAPI } from '../../hooks/api';
import { useSort } from '../../hooks/sort';
import { useCampaigns } from '../../hooks/campaigns';
import { useBWSync, useFlags, useUser } from '../../hooks';
import { formatToUSD } from '../../util';
import { getStatus } from './getStatus';
import { StatusList, StatusKeys, StatusNames, Statuses } from './statuses';
import { Themes } from '../../../constants';
import { BidStrategyBadge } from '../../BidStrategy';
import LazyLoadingObserver from '../../LazyLoadingObserver';
import DeliverySignal from '../../DeliverySignal';
import { useWizardRedirect } from '@components/hooks/wizardRedirect';

const LINEITEMS_URL = '/lineitems';
const STATIC_DISPLAY_LINEITMES_URL = '/static_display_lineitems';

const PREFIX = 'AdGroupsIndexPage';

const classes = {
  link: `${PREFIX}-link`,
  active: `${PREFIX}-active`,
  actionButton: `${PREFIX}-actionButton`,
  draft: `${PREFIX}-draft`,
  pending: `${PREFIX}-pending`,
  paused: `${PREFIX}-paused`,
  display: `${PREFIX}-display`,
  font: `${PREFIX}-font`,
  scheduled: `${PREFIX}-scheduled`,
  formControl: `${PREFIX}-formControl`,
  menuIcon: `${PREFIX}-menuIcon`,
  stats: `${PREFIX}-stats`,
  tableCell: `${PREFIX}-tableCell`,
  height: `${PREFIX}-height`,
  tableHeader: `${PREFIX}-tableHeader`
};

const StyledAppHeader = styled(AppHeader)(({
  theme: { typography, spacing }
}) => ({
  [`& .${classes.link}`]: {
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: '700',
  },

  [`& .${classes.active}`]: {
    color: '#0fbf84',
    background: 'rgba(122, 220, 187, 0.55)',
    // fontSize: '0.625rem',
  },

  [`& .${classes.actionButton}`]: {
    marginLeft: -8, // aligning based on the visible left edge of the button
  },

  [`& .${classes.draft}`]: {
    color: '#EB9D00',
    background: 'rgba(255, 171, 5, 0.25)',
  },

  [`& .${classes.pending}`]: {
    color: '#EB9D00',
    background: 'rgba(255, 171, 5, 0.25)',
    // fontSize: '0.625rem',
  },

  [`& .${classes.paused}`]: {
    color: '#ff0000',
    background: 'rgba(255, 0, 0, 0.31)',
    // fontSize: '0.625rem',
  },

  [`& .${classes.display}`]: {
    color: '#47505d',
  },

  [`& .${classes.font}`]: {
    // fontSize: '0.75rem',
  },

  [`& .${classes.scheduled}`]: {
    color: '#1dafff',
    background: 'rgba(29, 175, 255, 0.24)',
  },

  [`& .${classes.formControl}`]: {
    margin: spacing(1),
    minWidth: 120,
  },

  [`& .${classes.menuIcon}`]: {
    marginRight: 12,
    fontSize: 18,
  },

  [`& .${classes.stats}`]: {
    fontWeight: '700',
    // fontSize: '0.75rem',
    minHeight: 81,
    height: 81,
  },

  [`& .${classes.tableCell}`]: {
    // fontSize: '0.75rem',
    minWidth: 120,
    minHeight: 81,
    height: 81,
  },

  [`& .${classes.height}`]: {
    // fontSize: '0.75rem',
    minHeight: 81,
    height: 81,
  },

  [`& .${classes.tableHeader}`]: {
    ...typography.h5,
    color: 'rgba(114, 115, 131, 0.8)',
    fontSize: '0.75rem',
    minWidth: 120,
  }
}));


const AdGroupsIndexPage = (props) => {
  const { user } = useUser();
  const { showBWSync, renderBWSyncStatus } = useBWSync();
  const adContext = useContext(AdvertiserContext);
  const { campaignId } = useParams();
  const { useGet, usePost, usePatch } = useAPI();
  const {
    order,
    orderBy,
    handleRequestSort,
    getComparator,
    stableSort
  } = useSort();
  const { getCampaignData } = useCampaigns();
  const { flags, Flags } = useFlags();

  const [campaign, setCampaign] = useState({});
  const [adGroups, setAdGroups] = useState([]);
  const [displays, setDisplays] = useState([]);
  const [sharedAdGroup, setSharedAdGroup] = useState({});
  const [selected, setSelected] = useState([]);
  const [statuses, setStatuses] = useState(StatusKeys);
  const [currentAnchorEl, setCurrentAnchorEl] = useState(null);
  const [targetMenu, setTargetMenu] = useState(null);
  const [hasControl, setHasControl] = useState(false);
  const [isControlGroup, setIsControlGroup] = useState(false);
  const [isEditBid, setIsEditBid] = useState(false);
  const [isEditWeighting, setIsEditWeighting] = useState(false);
  const [isAddAdGroup, setIsAddAdGroup] = useState(false);
  const [isManageAdGroup, setIsManageAdGroup] = useState(false);
  const [isManageDisplay, setIsManageDisplay] = useState(false);
  const [isNewAdGroup, setIsNewAdGroup] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [currentAdGroup, setCurrentAdGroup] = useState(null);
  const [currentAdGroupCreatives, setCurrentAdGroupCreatives] = useState(null);
  const [isLazyLoadingFinished, setIsLazyLoadingFinished] = useState(true);
  const lineItemsNextPageRef = useRef(LINEITEMS_URL);
  const staticLineItemsNextPageRef = useRef(STATIC_DISPLAY_LINEITMES_URL);

  const { newGroup, editGroup } = useWizardRedirect();

  const isVerticalCampaignFlowEnabled = flags[Flags.USER_GETS_VERTICAL_CAMPAIGN];

  const isBidEnabled = useMemo(
    () => adContext.tvsciq && adContext.tvsciq !== 'DISABLED',
    [adContext.tvsciq],
  );

  const headCells = useMemo(() => {
    const cells = [
      { id: 'id', sortable: true, label: 'ID', disablePadding: false },
      { id: 'name', sortable: true, label: 'Ad Group', disablePadding: false },
      { id: 'campaignName', sortable: false, label: 'Campaign', disablePadding: false },
      { id: 'start_date', sortable: true, label: 'Start date', disablePadding: false },
      { id: 'end_date', sortable: true, label: 'End date', disablePadding: false },
      { id: 'maxBid', sortable: true, label: 'Max Bid', disablePadding: false },
      { id: 'spend', sortable: true, label: 'Spend', disablePadding: false },
    ];

    if (adContext.theme === Themes.DEFAULT) {
      cells.push({ id: 'budgetRemaining', sortable: true, label: 'Budget remaining', disablePadding: false });
    }

    cells.push(
      { id: 'visits', sortable: true, label: 'Visits', disablePadding: false },
      { id: 'costPerVisit', sortable: true, label: 'Cost per visit', disablePadding: false },
    )

    if (isBidEnabled) {
      cells.push(
        {
          id: 'biddingStrategy',
          sortable: false,
          label: 'Bidding Strategy',
          disablePadding: false,
        },
      );
    }

    if (showBWSync) {
      cells.push(
        {
          id: 'adServerCheck',
          sortable: false,
          label: 'Ad Server Check',
          disablePadding: false,
        },
      );
    }


    cells.push({ id: 'deliverySignal', sortable: false, label: 'Delivering', disablePadding: false });

    cells.push(
      { id: 'status', sortable: true, label: 'Status', disablePadding: false },
      { id: 'action', sortable: false, label: 'Action', disablePadding: false },
    );

    return cells;
  }, [
    adContext.theme,
    isBidEnabled,
    showBWSync,
  ]);

  const showVastTag = useMemo(
    () => adContext.theme === Themes.DEFAULT || user?.is_tvsci_employee,
    [user, adContext]
  );

  useEffect(() => {
    if (adContext && adContext.id !== null && campaignId) {
      initCampaign(campaignId);
    }
  }, [campaignId, adContext.id]);

  useEffect(() => {
    if (adContext.id !== null) {
      resetLineItems();
      resetStaticLineItems();
    }
  }, [adContext.id]);

  useEffect(() => {
    // TODO: consume updated data
    // instead of making another api call
    if (hasSaved) {
      setAdGroups([]);
      setDisplays([]);
      initCampaign(campaignId);
      resetLineItems();
      resetStaticLineItems();
    }
  }, [hasSaved]);

  const resetLineItems = () => {
    lineItemsNextPageRef.current = LINEITEMS_URL;
    setIsLazyLoadingFinished(true);
    setAdGroups([]);
  }

  const resetStaticLineItems = () => {
    staticLineItemsNextPageRef.current = STATIC_DISPLAY_LINEITMES_URL;
    setIsLazyLoadingFinished(true);
    setDisplays([]);
  }

  const handleScrollToBottom = async () => {
    const lineItemsNextPage = lineItemsNextPageRef.current;
    const staticLineItemsNextPage = staticLineItemsNextPageRef.current;
    const promises = [];

    if (adContext?.id && isLazyLoadingFinished && statuses.length !== 0) {
      if (lineItemsNextPage) {
        promises.push(getLineItemsNextPage(lineItemsNextPage));
      }
      if (staticLineItemsNextPage) {
        promises.push(getStaticLineItemsNextPage(staticLineItemsNextPage));
      }
    }

    if (promises.length > 0) {
      setIsLazyLoadingFinished(false);
      try {
        await Promise.all(promises);
      } catch (error) {
        console.error('Error loading additional line items:', error);
      } finally {
        setIsLazyLoadingFinished(true);
      }
    }
  };

  const handleStatusesChange = event => {
    const { value } = event.target;

    if (value[value.length - 1] === 'all') {
      setStatuses(statuses.length === StatusKeys.length ? [] : StatusKeys);

      return;
    }

    setStatuses(value);
  };

  async function initCampaign(id) {
    try {
      const campaignData = await getCampaignData(id);

      const {
        budget,
        end_date,
        start_date,
        url: campaign,
      } = campaignData;

      setCampaign(campaignData);

      setSharedAdGroup({
        budget,
        campaign,
        end_date,
        start_date
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function getLineItemsNextPage(nextPage) {
    try {
      const response = await useGet(nextPage, {
        params: {
          campaign: campaignId,
        },
      });
      const formattedResults = response.results
        .map(li => ({
          ...li,
          status: getStatus(li.active, li.pending_active, li.draft)
        }));

      if (formattedResults.some(f => f.is_control_group)) {
        setHasControl(true);
      }

      lineItemsNextPageRef.current = response.next;

      return setAdGroups([...adGroups, ...formattedResults]);

    } catch (error) {
      console.log(error);
    }
  }

  function getStaticLineItemsNextPage(nextPage) {
    return useGet(nextPage, {
      params: {
        campaign: campaignId,
      }
    })
    .then(res => {
      setDisplays(() => {
        const newDisplays = res.results
          .map(item => ({
            ...item,
            status: getStatus(item.active, item.pending_active, item.draft),
          }));
        return [
          ...displays,
          ...newDisplays,
        ]
      });
      staticLineItemsNextPageRef.current = res.next;
    })
    .catch(error => {
      console.error('Failed to fetch static line items:', error);
    });
  }

  function getStartDateForDupe(start) {
    // if start date is expired, set as today
    if (moment(start).isBefore()) {
      return moment(new Date()).utc().format()
    }

    // if start date is in the future, use same start date
    return start;
  }

  function getEndDateForDupe(end, campaign) {
    // if end date is expired, use campaign end date
    if (end && moment(end).isBefore()) {
      return campaign.end_date;
    }

    if (!end) {
      return null;
    }

    // if end date is in the future, use same end date
    return end;
  }

  const getBidStrategy = (bidStrategyUrl) => {
    if (!bidStrategyUrl) return null;

    const { bidstrategy_set: strategies } = adContext;
    const strategy = strategies.find(item => item.url == bidStrategyUrl);

    return strategy;
  }

  const handleAddAdGroup = () => {
    if(isVerticalCampaignFlowEnabled) {
     return newGroup({ campaignId })
    }

    const blankAdGroup = {
      active: true,
      budget: null,
      campaign: null,
      cpm: '',
      creatives: [],
      daily_budget: '00',
      draft: false,
      end_date: null,
      freq_caps: [],
      name: '',
      start_date: null,
      targeting: JSON.stringify({
        advanced: false,
        age: [2, 65],
        bundles: [],
        dayparting: [],
        gender: 'All',
        geo: {
          cities: [],
          countries: [{
            place_name: 'Entire US',
            id: 'entire-us',
            blacklist: false,
          }],
          dmas: [],
          states: [],
          zipcodes: []
        },
        income: [30, 250],
        inventory: 'reach',
      }),
      ...sharedAdGroup,
    };

    setIsNewAdGroup(true);
    setHasSaved(false);
    setCurrentAdGroup(blankAdGroup);
    setIsAddAdGroup(true);
  };

  const handleManageAdGroup = group => {
    if (isVerticalCampaignFlowEnabled) {
      handleCloseMenu();

      return editGroup({
        campaignId,
        groupId: group.id,
      });
    }

    if (group && group.is_control_group) {
      return setIsControlGroup(true);
    }

    setCurrentAdGroup(group);
    setHasSaved(false);
    setIsManageAdGroup(true);
  };

  const handleDuplicateAdGroup = async group => {
    const { name, start_date, end_date, daily_budget } = group;
    const startDate = getStartDateForDupe(start_date);
    const endDate = getEndDateForDupe(end_date, campaign);

    try {
      const response = await usePost(`lineitems/${group.id}/duplicate`, {
        name: `Copy of ${name}`,
        start_date: startDate,
        end_date: endDate,
        daily_budget,
      });

      if (response) {
        console.log('response from duplicate', response);
        resetLineItems();
        resetStaticLineItems();
      }

      return response;
    } catch (error) {
      console.error('Error duplicating campaign', error);
    }
  };

  const getCreativeAdGroups = adGroupId => {
    return useGet('/creative_lineitems', {
      params: {
        line_item_id: adGroupId,
        expand: 'creative',
      },
    })
  };

  const patchCreativeAdGroup = (id, data) => usePatch(`/creative_lineitems/${id}`, data);

  const handleIsDuplicatable = adGroup => {
    const { end_date } = adGroup;

    return (
      end_date &&
      moment(end_date).isBefore() &&
      moment(campaign.end_date).isBefore()
    )
  }

  const handleManageDisplay = display => {
    setCurrentAdGroup(display);
    setHasSaved(false);
    setIsManageDisplay(true);
  };

  const handleCloseAll = () => {
    setIsAddAdGroup(false);
    setIsControlGroup(false);
    setIsManageAdGroup(false);
    setIsManageDisplay(false);
    setIsNewAdGroup(false);
    setCurrentAdGroup(null);
  };

  const handleOpenWeighting = async group => {
    const { results: creativeAdGroups } = await getCreativeAdGroups(group.id);

    const adGroupCreatives = creativeAdGroups.map(creativeAdGroup => ({
      ...creativeAdGroup.creative_object,
      weighting: creativeAdGroup.weighting,
      creativeAdGroupId: creativeAdGroup.id,
    }));

    setCurrentAdGroupCreatives(adGroupCreatives);
    setCurrentAdGroup(group);
    setIsEditWeighting(true);
  };

  const handleWeightingSubmit = async ({ weightedDelivery, creatives }) => {
    const { data: adGroup } = await usePatch(`/lineitems/${currentAdGroup.id}/`, {
      creative_weighting_method: weightedDelivery ? 'WEIGHTED' : 'RANDOM',
    });

    setAdGroups(adGroups.map(group => {
      if (group.id === adGroup.id) {
        return {
          ...adGroup,
          status: getStatus(adGroup.active, adGroup.pending_active, adGroup.draft),
        };
      }

      return group;
    }));

    if (adGroup.creative_weighting_method === 'WEIGHTED') {
      const requests = creatives.map(({ weighting, creativeAdGroupId }) => (
        patchCreativeAdGroup(creativeAdGroupId, { weighting }))
      );

      await Promise.all(requests);
    }

    handleCloseWeighting();
  };

  const handleCloseWeighting = () => {
    setIsEditWeighting(false);
    setCurrentAdGroup(null);
    setCurrentAdGroupCreatives(null);
  };

  const handleOpenBid = group => {
    setCurrentAdGroup(group);
    setHasSaved(false);
    setIsEditBid(true);
  };

  const handleCloseBid = () => {
    setIsEditBid(false);
    setCurrentAdGroup(null);
  };

  const handleDisplayStatus = (display) => {
    return usePatch(`/static_display_lineitems/${display.id}`, {
      active: !display.active,
    })
      .then((response) => {
        setDisplays((prev) =>
          prev.map((p) => p.id !== display.id
            ? p
            : ({ ...p, active: response.data.active }))
        );
      })
      .catch((error) => {
        console.log('Error in Display Status: ', error);
      });
  };

  const handlePauseActive = adGroup => {
    return usePatch(`/lineitems/${adGroup.id}/`, { active: !adGroup.active })
      .then((res) => {
        setAdGroups(adGroups.map(c => {
          if (hasControl) {
            return {
              ...c,
              active: res.data.active,
              status: getStatus(res.data.active, res.data.pending_active, res.data.draft)
            };
          }

          if (c.id !== adGroup.id) {
            return {
              ...c,
              status: getStatus(c.active, c.pending_active, c.draft)
            };
          }

          return {
            ...c,
            active: res.data.active,
            status: getStatus(res.data.active, res.data.pending_active, res.data.draft)
          };
        }));
      }).catch((error) => {
        console.log(error);
      });
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setIsSelectAll(true);
      const newSelecteds = adGroups.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setIsSelectAll(false);
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleOpenMenu = (event, rowId) => {
    setCurrentAnchorEl(event.currentTarget);
    setTargetMenu(null);
    setTargetMenu(rowId);
  };

  const handleCloseMenu = () => {
    setCurrentAnchorEl(null)
    setTargetMenu(null);
  };

  const campaignStatusIconRender = (status) => {
    if (status === Statuses.ACTIVE || status === true) {
      return <Chip label="Active" className={classes.active} />;
    }

    if (status === Statuses.PENDING) {
      return <Chip label="Pending" className={classes.pending} />;
    }

    return <Chip label="Paused" className={classes.paused} />;
  };

  const renderStatus = (status, type) => {
    if (status) {
      return (
        <Fragment>
          <PauseCircleOutlineOutlinedIcon
            fontSize="small"
            className={classes.menuIcon}
          />

          Pause {type}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <PlayCircleOutlineIcon
          fontSize="small"
          className={classes.menuIcon}
        />

        Activate {type}
      </Fragment>
    );
  }

  const formatEndDate = (endDate) => {
    if (Date.parse(endDate)) {
      return moment(endDate).format('l')
    }
    return 'No End Date'
  };

  const renderDisplayRow = (display, index) => {
    const isItemSelected = isSelected(display.id);
    const labelId = `display-table-checkbox-${index}`;
    const { stats_cache: stats } = display;

    return (
      <TableRow
        hover
        onClick={(event) => handleClick(event, display.id)}
        role="checkbox"
        tabIndex={-1}
        key={display.id}
        aria-checked={isItemSelected}
        selected={isItemSelected}
        data-testid="adgroup-row"
      >
        <TableCell padding="checkbox">
          <Checkbox
            size="small"
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          {display.id}
        </TableCell>

        <TableCell
          className={classes.tableCell}
          component="th" id={labelId}
          scope="row"
        >
          <Typography
            className={classes.display}
            variant="h5"
          >
            <Link
              className={classes.link}
              to={`/campaigns/${campaignId}/adgroups/${display.id}/displays`}
            >
              {display.name}
            </Link>
          </Typography>
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          {campaign.name}
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          {moment(display.start_date).format('l')}
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          {formatEndDate(display.end_date)}
        </TableCell>

        <TableCell
          className={classes.tableCell}
          align="left"
        >
          {display.cpm}
        </TableCell>

        <TableCell
          className={clsx(classes.stats)}
          align="left"
        >
          {stats != null ? formatToUSD(stats.total_spend) : '-'}
        </TableCell>

        {adContext.theme === Themes.DEFAULT && (
          <TableCell
            className={clsx(classes.stats)}
            align="left"
          >
            {stats != null ? formatToUSD(stats.budget_remaining) : '-'}
          </TableCell>
        )}

        <TableCell
          className={clsx(classes.stats)}
          align="left"
        >
          {stats != null ? stats.visit_count : '-'}
        </TableCell>

        <TableCell
          className={clsx(classes.tableCell, classes.stats)}
          align="left"
        >
          {stats != null ? formatToUSD(stats.cost_per_visit) : '-'}
        </TableCell>

        {isBidEnabled && (
          <TableCell>
            -
          </TableCell>
        )}

        {showBWSync && (
          <TableCell align="left">
            {renderBWSyncStatus('static_display_lineitems', display.id, display.active !== Statuses.ACTIVE)}
          </TableCell>
        )}


        <TableCell align="left">
          <DeliverySignal signal={display.active ? display.delivery_signal : null} />
        </TableCell>


        <TableCell className={classes.height} align="left">
          {campaignStatusIconRender(display.active)}
        </TableCell>

        <TableCell className={classes.height} align="left">
          <Button
            className={classes.actionButton}
            onClick={(event) => handleOpenMenu(event, display.id)}
            endIcon={<ExpandMoreIcon />}
          >
            Manage
          </Button>

          <Menu
            anchorEl={currentAnchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: 40, horizontal: "right" }}
            open={targetMenu === display.id}
            onClick={handleCloseMenu}
          >
            <MenuItem onClick={() => handleManageDisplay(display)}>
              <AspectRatioOutlinedIcon fontSize="small" className={classes.menuIcon} />
              Manage Display
            </MenuItem>

            <MenuItem onClick={() => handleDisplayStatus(display)}>
              {renderStatus(display.active, 'Display')}
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <StyledAppHeader history={props.history}>
      <Box m={5} mb={2}>
        <Typography>
          <strong><Link className={classes.link} to={`/campaigns/`}>Campaigns</Link></strong> &gt; Ad Groups
        </Typography>
      </Box>

      <Box
        border={1}
        borderColor="grey.300"
        p={6}
        pt={4}
        m={4}
        borderRadius="20px"
      >
        <Toolbar disableGutters>
          <Grid container>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              xs={12}
            >
              <Grid item xs={3}>
                <Typography variant="h6" component="div">Ad Groups</Typography>
              </Grid>

              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-end"
                xs={9}
              >
                <Grid item>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="status-multiple-checkbox-label">Status</InputLabel>
                    <Select
                      variant="standard"
                      multiple
                      color="secondary"
                      id="status-multiple-checkbox"
                      labelId="status-multiple-checkbox-label"
                      label="Status"
                      value={statuses}
                      onChange={handleStatusesChange}
                      renderValue={(selected) => {
                        const values = selected.map(item => StatusNames[item]).join(', ');
                        const text = selected.length === StatusList.length ? 'All' : values;

                        return `Showing [${text}] status`;
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250
                          }
                        }
                      }}>
                      <MenuItem value="all">
                        <Checkbox
                          checked={statuses.length === StatusList.length}
                          indeterminate={statuses.length > 0 && statuses.length < StatusList.length}
                        />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {StatusList.map(({ name, value }) => (
                        <MenuItem key={value} value={value}>
                          <Checkbox checked={statuses.includes(value)} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item>
                  {campaign && !campaign.experiment_type &&
                    <Button
                      color="secondary"
                      onClick={handleAddAdGroup}
                      startIcon={<AddIcon />}
                    >
                      Add Ad Group
                    </Button>}
                </Grid>
              </Grid>
            </Grid>

            {/* TODO: add this in later and make functional */}
            {/* <Grid item xs={7}>
              <TextField
                defaultValue={campaign.name}
                variant="outlined"
                InputProps={{
                  startAdornment:
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon/>
                    </IconButton>
                  </InputAdornment>
                }}
              />
              <Button variant="outlined"
                startIcon={<PlayCircleOutlineOutlinedIcon/>}
              >
                Activate
              </Button>
              <Button variant="outlined"
                startIcon={<PauseCircleOutlineOutlinedIcon/>}
              >
                Pause
              </Button>
              <Button variant="outlined"
                startIcon={<ArchiveOutlinedIcon/>}
              >
                Archive
              </Button>
            </Grid> */}
          </Grid>
        </Toolbar>

        <TableContainer>
          <Table>
            <SortableTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onSelectAll={handleSelectAllClick}
              isSelectAll={isSelectAll}
            />

            <TableBody>
              {stableSort(
                [...adGroups, ...displays],
                getComparator(order, orderBy)
              )
                .filter((campaign) => statuses.includes(campaign.status))
                .reduce((unique, campaign) => {
                  if (!unique.some(c => c.id === campaign.id)) {
                    unique.push(campaign);
                  }
                  return unique;
                }, [])
                .map((adGroup, index) => {
                  if (adGroup.url && adGroup.url.includes('static')) {
                    return renderDisplayRow(adGroup, index);
                  }

                  const isItemSelected = isSelected(adGroup.id);
                  const labelId = `ad-groups-table-checkbox-${index}`;
                  const { stats_cache: stats } = adGroup;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, adGroup.id)}
                      role="checkbox"
                      tabIndex={-1}
                      key={adGroup.id}
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                      data-testid="adgroup-row"
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          size="small"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">{adGroup.id}</TableCell>
                      <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" align="left">
                        <Link data-testid={`ad-group-${campaign.name}`} className={classes.link} to={`/campaigns/${campaignId}/adgroups/${adGroup.id}/creative`}>{adGroup.name}</Link>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">{campaign.name}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{moment(adGroup.start_date).format('l')}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{formatEndDate(adGroup.end_date)}</TableCell>

                      <TableCell
                        className={classes.tableCell}
                        align="left"
                      >
                        ${adGroup.cpm}
                      </TableCell>

                      <TableCell
                        className={clsx(classes.stats)}
                        align="left"
                      >
                        {stats != null ? formatToUSD(stats.total_spend) : '-'}
                      </TableCell>

                      {adContext.theme === Themes.DEFAULT && (
                        <TableCell
                          className={clsx(classes.stats)}
                          align="left"
                        >
                          {stats != null ? formatToUSD(stats.budget_remaining) : '-'}
                        </TableCell>
                      )}

                      <TableCell
                        className={clsx(classes.stats)}
                        align="left"
                      >
                        {stats && stats.visit_count != null ? stats.visit_count : '-'}
                      </TableCell>

                      <TableCell
                        className={clsx(classes.stats)}
                        align="left"
                      >
                        {stats && stats.cost_per_visit != null ? formatToUSD(stats.cost_per_visit) : '-'}
                      </TableCell>

                      {isBidEnabled && (
                        <TableCell
                          className={clsx(classes.tableCell, classes.stats)}
                          align="left"
                        >
                          <BidStrategyBadge bidStrategy={getBidStrategy(adGroup.bid_strategy)} />
                        </TableCell>
                      )}

                      {showBWSync && (
                        <TableCell align="left">
                          {renderBWSyncStatus('lineitems', adGroup.id, adGroup.status !== Statuses.ACTIVE)}
                        </TableCell>
                      )}


                      <TableCell className={classes.tableCell} align="left">
                        <DeliverySignal signal={adGroup.active ? adGroup.delivery_signal : null} />
                      </TableCell>


                      <TableCell className={classes.height} align="left">
                        {adGroup.status === Statuses.ACTIVE && <Chip label="Active" className={classes.active} />}
                        {adGroup.status === Statuses.PENDING && <Chip label="Pending" className={classes.pending} />}
                        {adGroup.status === Statuses.PAUSED && <Chip label="Paused" className={classes.paused} />}
                        {adGroup.status === Statuses.DRAFT && (<Chip label="Draft" className={classes.draft} />)}
                      </TableCell>

                      <TableCell className={classes.height} align="left">
                        <Button
                          className={classes.actionButton}
                          onClick={(event) => handleOpenMenu(event, adGroup.id)}
                          endIcon={<ExpandMoreIcon />}
                        >
                          Manage
                        </Button>

                        <Menu
                          anchorEl={currentAnchorEl}
                          getContentAnchorEl={null}
                          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                          open={targetMenu === adGroup.id}
                          onClick={handleCloseMenu}
                        >
                          <MenuItem
                            onClick={() => handleManageAdGroup(adGroup)}
                          >
                            <PeopleOutlineOutlinedIcon
                              fontSize="small"
                              className={classes.menuIcon}
                            />

                            Manage Ad Group
                          </MenuItem>

                          {!adGroup.draft &&
                            <MenuItem
                              disabled={handleIsDuplicatable(adGroup)}
                              onClick={() => handleDuplicateAdGroup(adGroup)}
                            >
                              <FileCopyOutlinedIcon
                                fontSize="small"
                                className={classes.menuIcon}
                              />

                              Duplicate Ad Group
                            </MenuItem>}

                          <MenuItem onClick={() => handleOpenWeighting(adGroup)}>
                            <OndemandVideoIcon
                              fontSize="small"
                              className={classes.menuIcon}
                            />

                            Edit Weighting
                          </MenuItem>

                          {!adGroup.is_control_group && !adGroup.draft && (
                            <MenuItem onClick={() => handleOpenBid(adGroup)}>
                              <EditOutlinedIcon
                                fontSize="small"
                                className={classes.menuIcon}
                              />

                              Edit Bid
                            </MenuItem>
                          )}

                          {!adGroup.is_control_group && !adGroup.draft && (
                            <MenuItem onClick={() => handlePauseActive(adGroup)}>
                              {renderStatus(adGroup.active, 'Ad Group')}
                            </MenuItem>
                          )}

                          {/*{!adGroup.is_control_group && ( <MenuItem onClick={handleCloseMenu}>
                            <ArchiveOutlinedIcon className={classes.menuIcon} /> Archive Campaign
                          </MenuItem>
                          )}*/}
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              <LazyLoadingObserver
                handleScrollToBottom={handleScrollToBottom}
                isLazyLoadingFinished={isLazyLoadingFinished}
              />
            </TableBody>
          </Table>
        </TableContainer>

        {isAddAdGroup &&
          <AddAdGroup
            adGroup={currentAdGroup}
            campaign={campaign}
            isOpen={isAddAdGroup}
            isNew={isNewAdGroup}
            showVastTag={showVastTag}
            onClose={handleCloseAll}
            setCampaign={setCampaign}
            setHasSaved={setHasSaved}
            showGenre={adContext.theme === Themes.NBCU}
            showType={adContext.theme === Themes.DEFAULT}
          />}

        {isManageAdGroup &&
          <ManageAdGroup
            adGroup={currentAdGroup}
            campaign={campaign}
            hasControlAdGroup={hasControl}
            isModal
            showVastTag={showVastTag}
            showGenre={adContext.theme === Themes.NBCU}
            isNew={isNewAdGroup}
            isOpen={isManageAdGroup}
            onClose={handleCloseAll}
            setCampaign={setCampaign}
            setHasSaved={setHasSaved}
          />}

        {isEditWeighting && (
          <EditWeighting
            data={{
              weightedDelivery: currentAdGroup.creative_weighting_method === 'WEIGHTED',
              creatives: currentAdGroupCreatives,
            }}
            onSubmit={handleWeightingSubmit}
            onClose={handleCloseWeighting}
            isOpen={isEditWeighting}
          />
        )}

        {isEditBid &&
          <ManageBid
            adGroup={currentAdGroup}
            isOpen={isEditBid}
            onClose={handleCloseBid}
            setHasSaved={setHasSaved}
          />}

        {isManageDisplay &&
          <ManageDisplay
            isModal
            isOpen={isManageDisplay}
            campaign={campaign}
            display={currentAdGroup}
            onClose={handleCloseAll}
            setCampaign={setCampaign}
            setHasSaved={setHasSaved}
          />}

        {isControlGroup &&
          <Dialog
            className={classes.dialog}
            maxWidth="xs"
            onClose={handleCloseAll}
            open={isControlGroup}
          >
            <DialogTitle>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <Box mr={1}>
                    <InfoOutlinedIcon fontSize="small" color="secondary" />
                  </Box>

                  <Typography variant="h5">
                    Manage Control Group
                  </Typography>
                </Box>

                <IconButton size="small" onClick={handleCloseAll}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </DialogTitle>

            <DialogContent>
              <Typography variant="body2">
                This Ad Group is a Control Group and can not be edited here.
                Changes made to the Test Group will be applied <br />
                to the Control Group.
              </Typography>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseAll} color="secondary">
                OK
              </Button>
            </DialogActions>
          </Dialog>}
      </Box>
    </StyledAppHeader>
  );
}

AdGroupsIndexPage.propTypes = {
  history: PropTypes.object,
};

export default AdGroupsIndexPage;
