import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Tabs,
  Tab,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { AdAccountUsersList } from './AdAccountUsersList';
import { GeneralInfoForm } from '../GeneralInfoForm';
import { OrgChips } from '../../OrgChips';
import { ReportingForm } from '../ReportingForm';
import { SupportTab } from './SupportTab';
import { useAPI } from '../../hooks/api';
import { useLoader } from '../../hooks/loader';

const PREFIX = 'ManageAdAccount';

const classes = {
  active: `${PREFIX}-active`,
  admin: `${PREFIX}-admin`,
  card: `${PREFIX}-card`,
  selectedCard: `${PREFIX}-selectedCard`,
  cardContent: `${PREFIX}-cardContent`,
  cardLabel: `${PREFIX}-cardLabel`,
  cart: `${PREFIX}-cart`,
  chip: `${PREFIX}-chip`,
  download: `${PREFIX}-download`,
  dropdown: `${PREFIX}-dropdown`,
  emptyChannels: `${PREFIX}-emptyChannels`,
  general: `${PREFIX}-general`,
  submit: `${PREFIX}-submit`,
  successMsg: `${PREFIX}-successMsg`,
  supportAccess: `${PREFIX}-supportAccess`,
  tab: `${PREFIX}-tab`,
  textfield: `${PREFIX}-textfield`
};

const StyledBox = styled(Box)(({
  theme: { palette, spacing, typography }
}) => ({
  [`& .${classes.active}`]: {
    backgroundColor: '#c2f1e3',
    color: '#05734e',
  },

  [`& .${classes.admin}`]: {
    backgroundColor: '#bfe7ff',
    color: '#2592cf',
  },

  [`& .${classes.card}`]: {
    width: 150,
    height: 150,
    cursor: 'pointer',

    '&:hover': {
      boxShadow:
        '0px 5px 5px -3px rgb(29 175 255 / 20%), 0px 8px 10px 1px rgb(29 175 255 / 14%), 0px 3px 14px 2px rgb(29 175 255 / 12%)',
    },
  },

  [`& .${classes.selectedCard}`]: {
    backgroundColor: 'rgba(29, 175, 255, 0.2)',

    '&:hover': {
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
  },

  [`& .${classes.cardContent}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingBottom: '16px !important',
  },

  [`& .${classes.cardLabel}`]: {
    marginTop: spacing(1),
    textAlign: 'center',
  },

  [`& .${classes.cart}`]: {
    width: 68,
    height: 58,
    margin: '0 auto',
  },

  [`& .${classes.chip}`]: {
    marginLeft: spacing(2),
    padding: spacing(1),
  },

  [`& .${classes.download}`]: {
    width: 35,
    height: 58,
    margin: '0 auto',
  },

  [`& .${classes.dropdown}`]: {
    width: '48%',
  },

  [`& .${classes.emptyChannels}`]: {
    height: 120,

    ['& td']: {
      borderBottom: 'none',
    },

    ['& td p']: {
      color: '#67747c',
    },
  },

  [`& .${classes.general}`]: {
    width: 58,
    height: 58,
    margin: '0 auto',
  },

  [`& .${classes.submit}`]: {
    ...typography.h4,
    color: '#fff',
    marginLeft: spacing(2),
    padding: spacing(2),
    width: 175,
  },

  [`& .${classes.successMsg}`]: {
    color: palette.primary.light,
  },

  [`& .${classes.supportAccess}`]: {
    color: '#67747c',
  },

  [`& .${classes.tab}`]: {
    ['& h4']: {
      fontWeight: 500,
    },

    ['&.Mui-selected h4']: {
      fontWeight: 700,
    },
  },

  [`& .${classes.textfield}`]: {
    marginBottom: spacing(3),
  }
}));

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      style={{ width: '100%', height: '100%' }}
      {...other}
    >
      {value === index && (
        <Box p={2} height="100%">
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ManageAdAccount = ({
  org,
  currentAccount,
  onCancel,
  categories,
}) => {

  const { useGetAll, useGet } = useAPI();
  const { isLoading, setIsLoading } = useLoader(true);
  const [tabIndex, setTabIndex] = useState(0);

  const [advertiser, setAdvertiser] = useState(null);
  const [billingAccounts, setBillingAccounts] = useState([]);

  const handleTabChange = (event, index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (!currentAccount.advertiser) return;

    useGet(currentAccount.advertiser).then(response => {
      setAdvertiser(response);
    });
  }, [currentAccount.advertiser]);

  useEffect(() => {
    useGetAll('/billing_accounts', [], results => {
      setBillingAccounts(results)
      setIsLoading(false);
    });
  }, []);

  return (
    <StyledBox
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box mb={6}>
        <Box mb={4}>
          <Box
            mb={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box width="50%">
              <Button
                startIcon={<ArrowBackIcon color="secondary" />}
                onClick={onCancel}
              >
                <Typography>{advertiser?.name}</Typography>
              </Button>
            </Box>

            <OrgChips org={org} />
          </Box>

          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="Business Manager Tabs"
            className={classes.tabs}
          >
            <Tab
              className={classes.tab}
              label={<Typography variant="h4">General Info</Typography>}
            />
            <Tab
              className={classes.tab}
              label={<Typography variant="h4">Ad Account Users</Typography>}
            />
            <Tab
              className={classes.tab}
              label={<Typography variant="h4">Reporting</Typography>}
            />
            {/* <Tab
              className={classes.tab}
              label={<Typography variant="h4">Support</Typography>}
            /> */}
          </Tabs>
        </Box>

        <Box height="100%">
          <TabPanel value={tabIndex} index={0}>
            <GeneralInfoForm
              isLoading={isLoading}
              advertiser={advertiser}
              setAdvertiser={setAdvertiser}
              categories={categories}
              billingAccounts={billingAccounts}
              onClose={onCancel}
            />
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            <AdAccountUsersList currentAccount={currentAccount} />
          </TabPanel>

          <TabPanel value={tabIndex} index={2}>
            <ReportingForm
              advertiser={advertiser}
              setAdvertiser={setAdvertiser}
            />
          </TabPanel>

          <TabPanel value={tabIndex} index={3}>
            <SupportTab />
          </TabPanel>
        </Box>
      </Box>
    </StyledBox>
  );
};

ManageAdAccount.propTypes = {
  org: PropTypes.object,
  currentAccount: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  categories: PropTypes.array,
};

export default ManageAdAccount;
