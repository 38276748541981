import InfoIcon from '@mui/icons-material/Info';
import {
  Avatar,
  FormControlLabel,
  Link,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { BudgetTextField } from '@v2/components/ui/BudgetTextField';
import { set } from 'lodash';
import moment from 'moment';
import React, { createContext } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';
import { entityStatus } from '../../../../components/CampaignWizard/constants';
import {
  endDateValidation,
  endTimeValidation,
  startDateSimpleValidation,
  startTimeSimpleValidation,
} from '../../../../components/CampaignWizard/validations';
import { allValue } from '../Audience/constants';
import { distinctNames } from '../GeoTargeter/constants';
import { weightingRotationOptions } from './AdvancedSubflows/AdvancedCreative';
import { TABS } from './AdvancedSubflows/AdvancedCreative/constants';
import { AvatarImageStyled } from './styles';
import { getLockedInfo, getLockedStatus, getUnlockedContent } from './utils';

export const AdvancedContext = createContext({
  audience: [],
  inventory: [],
  library: [],
  onCreateFile: () => {},
  customInventory: [],
});

const trackingLockedInfo =
  'This bid strategy will become available once tracking is set up.';

export const groupOptions = ({
  control,
  isMaxCPMBidEnabled,
  bidStrategySet,
  bidStrategyEventSet,
}) => {
  const eventPicker = () => (
    <Stack>
      <Controller
        control={control}
        name={fields.adGroupBidStrategyEvent.path}
        render={({ field, fieldState }) => {
          return (
            <FormControlLabel
              sx={{ marginLeft: 0, marginBottom: 2.5 }}
              control={
                <TextField
                  {...field}
                  {...fieldState}
                  select
                  fullWidth
                  data-testid="bid-strategy-event"
                  id="bid-strategy-event"
                  label="Conversion Event"
                  helperText={fieldState.error?.message}
                >
                  {bidStrategyEventSet?.map(({ id, name }) => (
                    <MenuItem
                      key={id}
                      value={id}
                      data-testid={`bid-strategy-event-${id}`}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              }
            />
          );
        }}
      />
    </Stack>
  );

  const maxCpm = (required = false) => (
    <Stack>
      {!required && (
        <FormControlLabel
          control={
            <Controller
              control={control}
              name={fields.maxCPMBidEnabled.path}
              render={({ field }) => (
                <Switch {...field} color="primary" checked={!!field.value} />
              )}
            />
          }
          label="Set Max CPM Bid (optional)"
          labelPlacement="end"
          sx={{ marginRight: 'auto', marginBottom: 2.5 }}
        />
      )}
      {(isMaxCPMBidEnabled || required) && (
        <Stack
          direction="row"
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: 1.25,
          }}
        >
          <Controller
            name={fields.maxCPMBid.path}
            control={control}
            render={({ field }) => (
              <BudgetTextField
                {...field}
                label="Max CPM Bid"
                variant="outlined"
                sx={{ marginRight: 2.5 }}
              />
            )}
          />
          <InfoIcon sx={{ color: 'action.active' }} />
          <Typography sx={{ marginLeft: 1.25 }}>
            The recommended bid range for your inventory selection is $24-32.
          </Typography>
        </Stack>
      )}
    </Stack>
  );

  return bidStrategySet.map(option => ({
    value: option.id,
    label: option.display_name,
    description: option.description,
    unlockedContent: getUnlockedContent(option, { maxCpm, eventPicker }),
    locked: getLockedStatus(option, bidStrategyEventSet),
    lockedInfo: getLockedInfo(option, { trackingLockedInfo }),
    infoText: option.tooltip,
  }));
};

export const getInventoryOptions = ({
  customInventoryItems = [],
  onAdvancedCustomInventory,
  isCustomInventoryExpanded,
  setIsCustomInventoryExpanded,
  isDisplay = false,
}) => {
  if (isDisplay) {
    return [
      {
        value: 'premiumDisplay',
        label: 'Premium Display',
        description:
          'Brand-safe and cost-effective website and app display inventory, \
        to extend your reach',
      },
    ];
  }

  return [
    {
      value: 'performanceOptimized',
      label: 'Performance Optimized',
      description:
        'Inventory is organized by bundle, content, network, and app type for narrow \
       content or publisher targeting. Best for performance advertisers looking to \
       maximize exposure but drive down CPA/CAC, while maintaining brand safety \
       and prioritizing target audience above content.',
      infoText: (
        <Typography component="span" variant="body2">
          <a
            href="#"
            target="_blank"
            title=""
            style={{ textDecoration: 'none' }}
          >
            Learn more about Performance Optimized inventory
          </a>
        </Typography>
      ),
      unlockedContent: <Stack></Stack>,
      recommended: false,
      recommendedInfo:
        'Recommended based on your campaign objective and bid strategy',
    },
    {
      value: 'maximumReach',
      label: 'Maximum Reach',
      description:
        'Brand safe, high quality publishers, with lower costs due to a broad range \
      of living room quality content. Best for broad branding advertisers that are targeting a wider audience.',
      infoText: 'Learn more about Performance Optimized inventory',
    },
    {
      value: 'bundles',
      label: 'Custom Inventory',
      description: (
        <Stack
          onClick={onAdvancedCustomInventory}
          style={{ marginBottom: 2.5 }}
        >
          <Typography component="span" variant="body2">
            You select which channels and genres to include or exclude. Best
            for experienced and high-volume CTV advertisers with direct supply
            relationships, looking to leverage tvScientific to take their
            buying to the next level.
          </Typography>
        </Stack>
      ),
      unlockedContent: (
        <Stack
          onClick={onAdvancedCustomInventory}
          spacing={1}
          sx={{ marginBottom: 2.5 }}
        >
          <Stack direction="row" spacing={1}>
            {customInventoryItems
              .slice(
                0,
                isCustomInventoryExpanded ? customInventoryItems.length : 2,
              )
              .map(i => (
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                  key={i.id}
                >
                  <Avatar
                    sx={{
                      bgcolor: i.icon?.url ? 'transparent' : 'grey.8',
                    }}
                    variant="square"
                  >
                    {i.icon ? (
                      <AvatarImageStyled src={i.icon?.url} />
                    ) : (
                      <span />
                    )}
                  </Avatar>
                  <Typography variant="h3">{i.display_name}</Typography>
                </Stack>
              ))}
          </Stack>
          {!isCustomInventoryExpanded && customInventoryItems.length > 2 ? (
            <Link
              sx={{
                cursor: 'pointer',
              }}
              onClick={e => {
                e.preventDefault();
                setIsCustomInventoryExpanded();
              }}
            >
              + {customInventoryItems.length - 2} more...
            </Link>
          ) : null}
        </Stack>
      ),
    },
  ];
};

const requiredMessage = 'Required field';

export const fields = {
  timezone: {
    path: 'timezone',
    defaultValue: 'est',
  },
  status: {
    path: 'status',
    defaultValue: entityStatus.draft,
  },
  draft: {
    path: 'draft',
    defaultValue: true,
  },
  description: {
    path: 'description',
    defaultValue: '',
  },
  campaign: {
    path: 'campaign',
    defaultValue: null,
  },
  advancedAudience: {
    path: 'advancedAudience',
    defaultValue: [],
  },
  advancedInventory: {
    path: 'advancedInventory',
    defaultValue: {
      os: {
        blacklist: false,
        ids: [],
      },
      device: {
        blacklist: false,
        ids: [],
      },
    },
  },
  adGroupName: {
    path: 'name',
    defaultValue: '',
    rule: yup.string().nullable().required(requiredMessage),
  },
  adGroupBudget: {
    path: 'daily_budget',
    defaultValue: 0,
    rule: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      })
      .nullable()
      .test('is-not-zero', requiredMessage, value => {
        if (!value) return false;

        return parseFloat(value) !== 0;
      }),
  },
  startDate: {
    path: 'start_date',
    defaultValue: moment().startOf('day'),
    rule: startDateSimpleValidation('end_date'),
  },
  endDate: {
    path: 'end_date',
    defaultValue: null,
    rule: endDateValidation('start_date'),
  },
  startTime: {
    path: 'start_time',
    defaultValue: moment().startOf('day'),
    rule: startTimeSimpleValidation('start_date', 'end_date', 'end_time'),
  },
  endTime: {
    path: 'end_time',
    defaultValue: null,
    rule: endTimeValidation('start_date', 'start_time', 'end_date'),
  },
  geo: {
    path: 'geo',
    defaultValue: [],
    rule: yup
      .array()
      .of(
        yup.object().shape({
          blacklist: yup.boolean(),
        }),
      )
      .required(),
  },
  maxCPMBidEnabled: {
    path: 'bidStrategyMaxCPMBidEnabled',
    defaultValue: false,
  },
  maxCPMBid: {
    path: 'cpm',
    defaultValue: null,
  },
  targetCPA: {
    path: 'bidStrategyTargetCPA',
    defaultValue: 0,
  },
  social: {
    path: 'social',
    defaultValue: {
      age: [],
      gender: allValue,
      income: [],
    },
  },
  configureAdGroupFrequencyCapEnabled: {
    path: 'bidStrategyConfigureAdGroupFrequencyCapEnabled',
    defaultValue: false,
  },
  configureAdGroupFrequencyCap: {
    path: 'bidStrategyConfigureAdGroupFrequencyCap',
    defaultValue: [],
  },
  configureAdGroupDaypartingEnabled: {
    path: 'bidStrategyConfigureAdGroupDaypartingEnabled',
    defaultValue: false,
  },
  configureAdGroupDayparting: {
    path: 'bidStrategyConfigureAdGroupDayparting',
    defaultValue: [],
  },
  inventoryOption: {
    path: 'inventory_option',
    defaultValue: 'performanceOptimized',
  },
  advancedCustomInventory: {
    path: 'bundles',
    defaultValue: [],
    rule: yup.array().when('inventory_option', {
      is: 'bundles',
      then: yup
        .array()
        .required('At least one custom inventory option should be selected')
        .min(1, 'At least one custom inventory option should be selected'),
    }),
  },
  adGroupBidStrategy: {
    path: 'bid_strategy',
    defaultValue: null,
    rule: yup.number().nullable().required('Please select a bid strategy'),
  },
  adGroupBidStrategyEvent: {
    path: 'bid_strategy_event',
    defaultValue: null,
  },
  creatives: {
    path: 'creatives',
    defaultValue: [],
  },
  weightingRotation: {
    path: 'creative_weighting_method',
    defaultValue: weightingRotationOptions.random,
  },
  geography: {
    path: 'geo',
    defaultValues: [],
  },
  inventory: {
    path: 'inventory',
    defaultValue: null,
  },
  targeting: {
    path: 'targeting',
    defaultValue: null,
  },
};

export const audienceTabValues = {
  firstPartyData: 'first_party_data',
  private: 'private',
  retargeting: 'retargeting',
  thirdPartyData: 'third_party_data',
};

export const audienceTabs = [
  {
    label: 'Behaviors & Interests',
    value: audienceTabValues.thirdPartyData,
  },
  {
    label: 'Retargeting Audiences',
    value: audienceTabValues.retargeting,
    hint: '',
  },
  {
    label: '1st Party Audiences',
    value: audienceTabValues.firstPartyData,
    hint: '',
  },
  {
    label: 'Private',
    value: audienceTabValues.private,
    hint: '',
  },
];

export const inventoryTabs = [
  {
    label: 'Device Type',
    value: 'device',
    settings: {
      includable: true,
      separateInclExcl: false,
    },
  },
  {
    label: 'Operating System',
    value: 'os',
    hint: 'Some hint',
    settings: {
      includable: true,
      separateInclExcl: false,
    },
  },
];

export const customInventoryTabValues = {
  channels: 'channels',
  inventory: 'inventory',
  appName: 'appName',
  appListId: 'appListId',
  dealId: 'dealId',
};

export const customInventoryTabs = [
  {
    label: 'Inventory',
    value: customInventoryTabValues.inventory,
    includable: false,
  },
  {
    label: 'App Name',
    value: customInventoryTabValues.appName,
    hint: 'Some hint',
    internalOnly: true,
  },
  {
    label: 'App List ID',
    value: customInventoryTabValues.appListId,
    hint: 'Some hint',
    internalOnly: true,
  },
  {
    label: 'Deal ID',
    value: customInventoryTabValues.dealId,
    hint: 'Some hint',
    internalOnly: true,
  },
];

export const formSchema = yup.object().shape(
  Object.keys(fields).reduce((acc, key) => {
    const obj = { ...acc };
    const nextField = fields[key];
    if (nextField.rule) {
      set(obj, nextField.path, nextField.rule);
    }
    return obj;
  }, {}),
);

const defaultAdGroupName = () => {
  const timestamp = moment().format('MMMM Do h:mma');
  return `${timestamp} Ad Group`;
};

export const defaultValues = () => {
  const defaultVals = Object.entries(fields).reduce(
    (acc, [, value]) => ({
      ...acc,
      [value.path]: value.defaultValue,
    }),
    {},
  );
  defaultVals[fields.adGroupName.path] = defaultAdGroupName();
  return defaultVals;
};

export const entireUSId = 'country.14135384517372290';

export const sections = {
  general: 'general',
  bidStrategy: 'bidStrategy',
  geography: 'geography',
  audience: 'audience',
  inventory: 'inventory',
  creative: 'creative',
};

export const subSections = {
  [sections.general]: {
    nameAndBudget: 'nameAndBudget',
    dates: 'dates',
  },
};

export const advancedModals = {
  general: 'general',
  creative: 'creative',
  audience: 'audience',
  inventory: 'inventory',
  bidStrategy: 'bid-strategy',
  customInventory: 'custom-inventory',
  geography: 'geography',
};

export const entireUSValue = {
  type: distinctNames.country,
  text: 'All of United States',
  name: 'Entire US',
};

export const defaultGeoValue = {
  [distinctNames.countries]: [
    {
      name: entireUSValue.name,
      type: entireUSValue.type,
      text: entireUSValue.text,
      blacklist: false,
    },
  ],
};

export const creativesInfo = {
  [TABS.VAST_TAG]: (
    <Typography color="blue.1">
      We recommend using one creative per VAST tag. Linking multiple creatives
      will make it hard to understand which creative drives what performance.
    </Typography>
  ),
  [TABS.UPLOAD_ASSET]: (
    <Typography color="blue.1">
      We will check your video specs to ensure it meets minimum quality
      requirements. Issues will be automatically fixed in most cases.
    </Typography>
  ),
};
