import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash'
import numeral from 'numeral'
import { Link } from 'react-scroll';
import { useHistory } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Button,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import { useAPI } from './hooks/api';
import { useLoader } from './hooks/loader';
import { useSaveExit } from './hooks/saveExit';
import CampaignFooter from './CampaignFooter';
import Title from './Title';
import tvsIcon from '../images/network-logos/tvs-logo-white.png';
import { getMinorityData } from '../helpers';

const PREFIX = 'SelectCategories';

const classes = {
  hide: `${PREFIX}-hide`,
  show: `${PREFIX}-show`,
  unselect: `${PREFIX}-unselect`,
  submenu: `${PREFIX}-submenu`,
  menuItems: `${PREFIX}-menuItems`,
  arrowBack: `${PREFIX}-arrowBack`,
  box: `${PREFIX}-box`,
  boxHeader: `${PREFIX}-boxHeader`,
  boxLabel: `${PREFIX}-boxLabel`,
  bundleGroup: `${PREFIX}-bundleGroup`,
  bundleHeader: `${PREFIX}-bundleHeader`,
  bundleHeaderWrap: `${PREFIX}-bundleHeaderWrap`,
  bundlesNav: `${PREFIX}-bundlesNav`,
  bundlesWrap: `${PREFIX}-bundlesWrap`,
  divider: `${PREFIX}-divider`,
  fieldset: `${PREFIX}-fieldset`,
  info: `${PREFIX}-info`,
  labelName: `${PREFIX}-labelName`,
  labelCpm: `${PREFIX}-labelCpm`,
  paper: `${PREFIX}-paper`,
  tooltip: `${PREFIX}-tooltip`,
  wrap: `${PREFIX}-wrap`,
  title: `${PREFIX}-title`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.hide}`]: {
    color: `#ff0000`,
    fontSize: '.75rem',
    fontWeight: 500,
    minWidth: 124,
  },

  [`& .${classes.show}`]: {
    color: theme.palette.primary.light,
    fontSize: '.75rem',
    fontWeight: 500,
    minWidth: 124,
  },

  [`& .${classes.unselect}`]: {
    color: `#1dafff`,
    fontSize: '.75rem',
    fontWeight: 500,
    marginRight: theme.spacing(1),
  },

  [`& .${classes.submenu}`]: {
    zIndex: 5,
    fontSize: `0.75rem`,
    fontSeight: 500,
    background: '#181c20',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },

  [`& .${classes.menuItems}`]: {
    cursor: `pointer`,
    color: 'white'
  },

  [`& .${classes.arrowBack}`]: {
    color: theme.palette.grey.main,
    textAlign: 'center',
  },

  [`& .${classes.box}`]: {
    backgroundColor: `rgba(38, 47, 60, 0.95)`,
    position: `absolute`,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1000,
    padding: `80px 80px 80px`,
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.boxHeader}`]: {
    color: `#fff`,
    fontSize: 24,
    margin: 0,
  },

  [`& .${classes.boxLabel}`]: {
    display: 'flex',

    ['& .MuiFormControlLabel-root']: {
      margin: '0 auto',
    },
  },

  [`& .${classes.bundleGroup}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.bundleHeader}`]: {
    fontSize: 14,
    textAlign: `left`,
    color: '#ffffff',
  },

  [`& .${classes.bundleHeaderWrap}`]: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },

  [`& .${classes.bundlesNav}`]: {
    alignSelf: 'center',
  },

  [`& .${classes.bundlesWrap}`]: {
    maxHeight: 490,
    overflowY: 'auto',
    paddingBottom: theme.spacing(8),
  },

  [`& .${classes.divider}`]: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(2),
    backgroundColor: 'white'
  },

  [`& .${classes.fieldset}`]: {
    width: '100%',
  },

  [`& .${classes.info}`]: {
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: '1rem',
  },

  [`& .${classes.labelName}`]: {
    ...theme.typography.paragraph,
    fontWeight: 'bold',
    margin: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '95%',
    color: 'white'
  },

  [`& .${classes.labelCpm}`]: {
    ...theme.typography.paragraph,
    margin: 0,
    color: 'white'
  },

  [`& .${classes.paper}`]: {
    background: 'rgba(38, 47, 60, 0.1)',
    border: '1px solid #727383',
    marginBottom: 10,
    height: '100%',
    maxHeight: 545,
    overflow: 'hidden',
  },

  [`& .${classes.tooltip}`]: {
    backgroundColor: `#f2f3f5`,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    maxWidth: 180,
  },

  [`& .${classes.wrap}`]: {
    background: `rgba(38, 47, 60, 0.95)`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    padding: `34px 37px 72px`,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 64,
    width: '100%'
  },

  [`& .${classes.title}`]: {
    color: 'white'
  }
}));

const bundleNames = ['Network', 'Genre', 'Category', 'Speciality'];

/////////////////////////////
// SELECT BUNDLES COMPONENT
/////////////////////////////
const SelectCategories = props => {

  const history = useHistory();

  const { isLoading, setIsLoading } = useLoader();
  const { useGetAll, usePatch } = useAPI();
  const { saveProgress } = useSaveExit();
  const { state } = useStateMachine();

  const {
    screenSize,
    combinedTargetingData,
    currentAdGroup,
    save,
    canNext,
    triggerSave,
    setStep,
    isDefaultDemo,
    isNoBidFees,
  } = props;

  const initialBundles = () => props.bundles && props.bundles.length > 0
    ? props.bundles
    : [];

  const [bundles, setBundles] = useState(initialBundles());
  const [bundleUrl, setBundleUrl] = useState(null);
  const [network, setNetwork] = useState([]);
  const [genre, setGenre] = useState([]);
  const [category, setCategory] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    if (bundleUrl != null) {
      getBundles();
    }
  }, [bundleUrl]);

  useEffect(() => {
    if (props.bundles && props.bundles.length > 0) {
      setBundles(props.bundles);
    }
  }, [props.bundles]);

  useEffect(() => {
    saveProgress(save, 'Bundles', handleSave, triggerSave);
  }, [save]);

  useEffect(() => {
    if (isDefaultDemo != null && isNoBidFees != null) {
      handleBundleUrl();
    }
  }, [isDefaultDemo, isNoBidFees]);

  // Collects and sorts bundles by type
  function getBundles() {
    return useGetAll(bundleUrl, [], total => {
      if (total && total.length > 0) {
        const sorted = total.reduce((acc, curr) => {
          const type = curr.type && curr.type !== ''
            ? curr.type.toLowerCase()
            : 'reach';

          if (
            !acc[type] ||
            !acc[type].length ||
            !acc[type].length === 0
          ) {
            return {
              ...acc,
              [type]: [curr],
            };
          }

          return {
            ...acc,
            [type]: [...acc[type], curr],
          }
        }, {});

        setNetwork(sorted.network);
        setGenre(sorted.genre);
        setCategory(sorted.category);
        setSpeciality(sorted.speciality);
      }
    });
  }

  const handleBundleUrl = () => {
    const interest = '?fees=interest';
    const demo = '?fees=demo';
    let url = '/bundles';

    if (isNoBidFees) {
      url = `${url}${interest}`;
      setBundleUrl(url);
      return;
    }

    if (isDefaultDemo === false) {
      url = `${url}${demo}`;
    }

    setBundleUrl(url);
  };

  const handleShowSelected = () => {
    setIsHide(prev => !prev);
  };

  const handleUnselectAll = () => {
    setBundles([]);

    if (props.setBundles) {
      props.setBundles([]);
    }
  };

  // Event handlers
  const handleCheck = event => {
    const { checked, name } = event.target;

    if (checked) {
      setBundles(prev => [...prev, name]);

      if (props.setBundles) {
        props.setBundles(prev => [...prev, name]);
      }
    } else {
      const filtered = bundles.filter(bundle => bundle !== name);
      setBundles(filtered);

      if (props.setBundles) {
        props.setBundles(filtered);
      }
    }
  };

  // TODO: use submit handler from parent component instead of this function to prevent duplication
  const handleSave = async () => {
    setIsLoading(true);

    const { deviceTypes, operatingSystems } = state.data.inventoryStep.deviceOverlayModal;

    const deviceTypeMinorityData = getMinorityData(deviceTypes);
    const operatingSystemMinorityData = getMinorityData(operatingSystems);

    const cpmArr = [];

    const bundlesCPM = _.flatten(_.map(bundles, (bundle) => (
      [...network, ...genre, ...category, ...speciality]
        .filter(i => i.bundle_name === bundle)
    )))

    _.map(bundlesCPM, (bundle) => (
      cpmArr.push(Number(bundle.estimated_cpm))
    ))

    cpmArr.sort((a, b) => a - b)

    props.setCpmRange([cpmArr[0], cpmArr.slice(-1)[0]]);

    const dataObj = {
      inventory: 'bundles',
      screen_size: screenSize,
      bundles,
      device: {
        blacklist: !deviceTypeMinorityData.isSelectedMinority,
        ids: deviceTypeMinorityData.data.map(item => item.id)
      },
      os: {
        blacklist: !operatingSystemMinorityData.isSelectedMinority,
        ids: operatingSystemMinorityData.data.map(item => item.id)
      },
    };

    props.handleInventoryData(dataObj);

    const data = {
      targeting: JSON.stringify({
        ...combinedTargetingData,
        ...dataObj,
      }),
    };

    console.log('data from select categories', {
      inventory: 'bundles',
      bundles,
      screen_size: screenSize,
    });

    try {
      const response = await usePatch(`/lineitems/${currentAdGroup.id}/`, data);

      setIsLoading(false);

      // Handle Save & Exit button click
      if (save && save.step && save.step === 'Bundles') {
        if (save.exit) {
          history.push('/');
        }
      }

      // Handle next button click
      if (!save.exit) {
        setStep('NameAdGroup');
      }

      return response;
    } catch (error) {
      console.error('Error in saving Bundles', error);

      setIsLoading(false);

      return error;
    }
  };

  const categoriesMenuItemsSubComp = bundleNames.map(
    (category) => (
      <Grid className={classes.bundlesNav} item key={category}>
        <Link
          activeClass="activeMenuItem"
          className={classes.menuItems}
          containerId="bundles-container"
          offset={0}
          to={category.replace(/\s+/g, '-').toLowerCase()}
          duration={270}
          smooth
          spy
        >
          {category} Bundles
        </Link>
      </Grid>
    )
  );

  const renderLabel = label => {

    return (
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <img
            style={{ width: 25 }}
            src={label.icon && label.icon.url ? label.icon.url : tvsIcon}
            alt={label.icon && label.icon.name ? label.icon.name : "TVS"}
          />
        </Grid>

        <Grid item xs={10}>
          <p className={classes.labelName}>{label.bundle_name}</p>
          <p className={classes.labelCpm}>
            Estimated CPM: {numeral(Math.ceil(label.estimated_cpm)).format('$0,0')} +

            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={label.info || ''}
            >
              <InfoOutlinedIcon
                className={classes.info}
                fontSize="small"
              />
            </Tooltip>
          </p>
        </Grid>
      </Grid>
    );
  };

  const renderBundleGroup = (bundle, index) => (
    <Grid
      key={`${bundleNames[0]}-${index}`}
      item
      xs={4}
    >
      <FormControl variant="standard" className={classes.fieldset} component="fieldset">
        <FormGroup>
          <Grid container spacing={2}>
            <Grid
              key={`${bundle.bundle_name.toLowerCase()} - ${index}`}
              item
              xs={12}
            >
              <Box
                className={clsx(
                  'Button-choice-gray',
                  classes.boxLabel
                )}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bundles.includes(bundle.bundle_name)}
                      onClick={handleCheck}
                      name={bundle.bundle_name}
                    />
                  }
                  label={renderLabel(bundle)}
                />
              </Box>
            </Grid>
          </Grid>
        </FormGroup>
      </FormControl>
    </Grid>
  );

  return (
    <Root>
      <Grid
        className={classes.wrap}
        container
        style={{ position: props.inventoryOverlay ? 'absolute' : 'relative' }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid container item alignItems="center" justifyContent="flex-start">
              {!props.isEditing && (
                <Grid item>
                  <IconButton
                    className={classes.arrowBack}
                    size="small"
                    onClick={() => {
                      props.setInventoryOverlay(false);
                    }}
                  >
                    <ArrowBackIosIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}

              <Grid item xs={11}>
                <Title className={classes.title}>
                  Step 2: Select Bundles
                </Title>
              </Grid>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />

          <Paper className={classes.paper}>
            <Grid className={classes.submenu} item container xs={12}>
              <Grid item container xs={12} justifyContent="space-between">
                <Grid item container xs={8} spacing={4}>
                  {categoriesMenuItemsSubComp}
                </Grid>

                <Grid item container xs={4} justifyContent="center">
                  <Button
                    onClick={handleUnselectAll}
                    size="small"
                    className={classes.unselect}
                    startIcon={
                      <RemoveCircleOutlineIcon
                        className={classes.icons}
                      />
                    }
                  >
                    Unselect All
                  </Button>

                  <Button
                    disableRipple
                    onClick={handleShowSelected}
                    size="small"
                    className={isHide ? classes.show : classes.hide}
                    startIcon={
                      isHide ? (
                        <BlurCircularIcon
                          className={classes.icons}
                          style={{
                            width: 15,
                            height: 15,
                          }}
                        />
                      ) : (
                        <NotInterestedIcon
                          className={classes.icons}
                          style={{
                            width: 15,
                            height: 15,
                          }}
                        />
                      )
                    }
                  >
                    {isHide ? 'Show Unselected' : 'Hide Unselected'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Divider />

            <Box p={2} pt={3} pb={4}>
              <Grid
                className={classes.bundlesWrap}
                container
                id="bundles-container"
                spacing={2}
              >
                <Grid
                  className={classes.bundleGroup}
                  container
                  item
                  spacing={2}
                  xs={12}
                  name="network"
                >
                  <Grid
                    className={classes.bundleHeaderWrap}
                    item
                    xs={12}
                  >
                    <Typography className={classes.bundleHeader} variant="h5">
                      Network Bundles
                    </Typography>
                  </Grid>

                  {network.length > 0 &&
                    network
                      .filter(bundle => !bundle.ott)
                      .filter(bundle => isHide ? bundles.includes(bundle.bundle_name) : true)
                      .map((b, i) => renderBundleGroup(b, i)
                    )}
                </Grid>

                <Grid
                  className={classes.bundleGroup}
                  container
                  item
                  spacing={2}
                  xs={12}
                  name="genre"
                >
                  <Grid
                    className={classes.bundleHeaderWrap}
                    item
                    xs={12}
                  >
                    <Typography className={classes.bundleHeader} variant="h5">
                      Genre Bundles
                    </Typography>
                  </Grid>

                  {genre.length > 0 &&
                    genre
                      .filter(bundle => !bundle.ott)
                      .filter(bundle => isHide ? bundles.includes(bundle.bundle_name) : true)
                      .map((b, i) => renderBundleGroup(b, i)
                    )}
                </Grid>

                <Grid
                  className={classes.bundleGroup}
                  container
                  item
                  spacing={2}
                  xs={12}
                  name="category"
                >
                  <Grid
                    className={classes.bundleHeaderWrap}
                    item
                    xs={12}
                  >
                    <Typography className={classes.bundleHeader} variant="h5">
                      Category Bundles
                    </Typography>
                  </Grid>

                  {category.length > 0 &&
                    category
                      .filter(bundle => !bundle.ott)
                      .filter(bundle => isHide ? bundles.includes(bundle.bundle_name) : true)
                      .map((b, i) => renderBundleGroup(b, i)
                    )}
                </Grid>

                <Grid
                  className={classes.bundleGroup}
                  container
                  item
                  spacing={2}
                  xs={12}
                  name="speciality"
                >
                  <Grid
                    className={classes.bundleHeaderWrap}
                    item
                    xs={12}
                  >
                    <Typography className={classes.bundleHeader} variant="h5">
                      Speciality Bundles
                    </Typography>
                  </Grid>

                  {speciality.length > 0 &&
                    speciality
                      .filter(bundle => !bundle.ott)
                      .filter(bundle => isHide ? bundles.includes(bundle.bundle_name) : true)
                      .map((b, i) => renderBundleGroup(b, i)
                    )}
                </Grid>
              </Grid>
            </Box>
          </Paper>

          {!props.isEditing && (
            <CampaignFooter
              isLoading={isLoading}
              isDisabled={!canNext}
              back={
                props.hasAdvancedTargeting
                  ? 'TargetingSegments'
                  : 'Demographics'
              }
              next={'Save Ad Group...'}
              onBack={() => {
                props.setInventoryOverlay(false);
                if (props.hasAdvancedTargeting) {
                  props.setStep('TargetingSegments');
                } else {
                  props.setStep('DemoTargeting');
                }

                props.updateBreadcrumbs('targeting', 25);
              }}
              onNext={() => {
                setIsLoading(true);
                triggerSave('Bundles', false, 'NameAdGroup');
              }}
              page={4}
            />
          )}
        </Grid>
      </Grid>
    </Root>
  );
};

SelectCategories.propTypes = {
  isEditing: PropTypes.bool,
  bundles: PropTypes.array,
  hasAdvancedTargeting: PropTypes.bool,
  setBundles: PropTypes.func,
  setStep: PropTypes.func,
  isDefaultDemo: PropTypes.bool,
  isNoBidFees: PropTypes.bool,
  targetEntireUS: PropTypes.bool,
  setTargetEntireUS: PropTypes.func,
  handleInventoryData: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
  currentAdGroup: PropTypes.object,
  combinedTargetingData: PropTypes.object,
  save: PropTypes.object,
  canNext: PropTypes.bool,
  triggerSave: PropTypes.func,
  setCpmRange: PropTypes.func,
  setInventoryOverlay: PropTypes.func,
  inventoryOverlay: PropTypes.bool,
  screenSize: PropTypes.string,
};

export default SelectCategories;
