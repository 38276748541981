import {
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@v2/components/ui/Button';

import ModalWrapper from '../../../../components/ModalWrapper';

const StyledModalWrapper = styled(ModalWrapper)({
  maxWidth: '800px',
  margin: 'auto',
});

const UnsavedChangesPrompt = ({ closeModal, onConfirm, workflowName }) => (
  <StyledModalWrapper isOpen onClose={closeModal}>
    <DialogTitle>Unsaved Changes</DialogTitle>
    <DialogContent>
      <Typography>
        Are you sure that you want to leave {workflowName}?
      </Typography>
      <Typography>Your unsaved changes will be lost.</Typography>
    </DialogContent>
    <DialogActions>
      <Button label="Cancel" onClick={closeModal} />
      <Button label="I'm sure" onClick={onConfirm} />
    </DialogActions>
  </StyledModalWrapper>
);

export default UnsavedChangesPrompt;

UnsavedChangesPrompt.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  workflowName: PropTypes.string.isRequired,
};
