import { yupResolver } from '@hookform/resolvers/yup';
import { defaults } from 'lodash';
import PropTypes from 'prop-types';
import React, { lazy, memo, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  initialValues,
  validationSchema,
} from '@v2/components/forms/CampaignForm/formConfig';
import { WizardSection } from '@v2/components/wizard/WizardSection';
import { SWRConfig } from 'swr';
import { withCamelCase } from '../../../swr';
import {
  useCurrentSession,
  useQuery,
  useWizardNavigation,
} from '../../hooks';
import {
  useGetCampaign,
  usePatchCampaign,
  usePostCampaign,
} from '../../hooks/apis/campaigns';
import {
  parseApiDataToFormValues,
  parseFormValuesToApiData,
} from './CampaignInfoPane/utils';
import { useCampaignWizardContext } from '../CampaignWizardContext';
import { generatePath, useHistory } from 'react-router';
import { handleErrors, removeNullValues } from '../utils';
import { RoutePaths } from '../../../constants';
import { fields } from '@v2/components/forms/CampaignForm/constants';

const CampaignInfoPane = lazy(() => import('./CampaignInfoPane'));
const AdvancedCampaignDeliveryOptions = lazy(
  () => import('./AdvancedCampaignDeliveryOptions'),
);

const CampaignSettingsSection = ({
  sectionKey,
  campaignId,
  ...sectionProps
}) => {
  const urlQuery = useQuery();
  const history = useHistory();
  const { currentAdvertiser } = useCurrentSession();
  const { campaign } = useGetCampaign(campaignId);
  const { trigger: updateCampaign } = usePatchCampaign(campaignId);
  const { trigger: postCampaign } = usePostCampaign();
  const resolver = yupResolver(validationSchema());

  const {
    addDisplayAdGroupSection,
    deleteDisplayAdGroupSection,
    campaignStaticDisplayAdGroups,
  } = useCampaignWizardContext();

  const { getNextSectionKey, activePaneKey } = useWizardNavigation();

  const handleSubmit = useCallback(
    async values => {
      const data = removeNullValues(
        parseFormValuesToApiData({
          ...values,
          advertiser: currentAdvertiser.id,
        }),
      );

      const { id } = campaignId
        ? await updateCampaign(data)
        : await postCampaign(data);

      if (id) {
        /**
         * TODO: use the `Map` object of url and query params as navigation key instead of the string navigation key
         * e.g. /vertical-campaign/18889/?section=adGroupSettings => navigation key: { params: { campaignId: 18889 }, searchParams: { section: 'adGroupSettings' } }
         * instead of /vertical-campaign/18889/?sectionKey=18889-blpj0doygva-adGroupSettings%3A%3Ainfo => navigation key: "18889-blpj0doygva-adGroupSettings::info"
         * */
        urlQuery.set('sectionKey', getNextSectionKey(activePaneKey));

        history.replace({
          pathname: generatePath(RoutePaths.CAMPAIGN_EDIT, {
            campaignId: id,
          }),
          search: urlQuery.toString(),
        });
      }
    },
    [campaignId, currentAdvertiser.id, getNextSectionKey, activePaneKey],
  );

  const data = useMemo(
    () =>
      parseApiDataToFormValues({
        ...campaign,
        advertiserName: currentAdvertiser.name,
      }),
    [campaign, currentAdvertiser.name],
  );

  const methods = useForm({
    mode: 'onChange',
    values: defaults(data, initialValues()),
    resetOptions: {
      keepDirtyValues: true,
    },
    resolver,
  });

  const handleRetargetDisplayToggleChange = (event, displayPctField) => {
    const { checked } = event.target;

    if (checked) {
      addDisplayAdGroupSection();
    } else {
      deleteDisplayAdGroupSection();
    }

    methods.setValue(fields.displayPct.path, checked);
    displayPctField.onChange(checked);
  };

  return (
    <FormProvider {...methods}>
      <WizardSection
        sectionKey={sectionKey}
        title="Campaign settings"
        icon="faRocket"
        panes={[
          <CampaignInfoPane
            key="info"
            campaignStaticDisplayAdGroups={campaignStaticDisplayAdGroups}
            onSubmit={handleErrors(handleSubmit, {
              setError: methods.setError,
            })}
            onRetargetDisplayToggleChange={handleRetargetDisplayToggleChange}
            campaignId={campaignId}
            renderInBackground
            sectionKey={sectionKey}
            {...sectionProps}
          />,
          <AdvancedCampaignDeliveryOptions
            key="advancedCampaignDeliveryOptions"
            renderInBackground
            {...sectionProps}
          />,
        ]}
      />
    </FormProvider>
  );
};

CampaignSettingsSection.propTypes = {
  sectionKey: PropTypes.string,
  campaignId: PropTypes.number,
};

const CampaignSettingsSectionWrapper = props => {
  return (
    <SWRConfig
      value={parent => ({
        use: [...parent.use, withCamelCase],
      })}
    >
      <CampaignSettingsSection {...props} />
    </SWRConfig>
  );
};

CampaignSettingsSectionWrapper.propTypes = {
  ...CampaignSettingsSection.propTypes,
};

export default memo(CampaignSettingsSectionWrapper);
