import useSWR from 'swr';

import { useCurrentSession } from '../currentSession';

export const useGetAdvertiserCategories = () => {
  const { getV1, apiIsReady } = useCurrentSession();

  const fetcher = ({ url, params }) =>
    getV1(url, { params }).then(res => res.data.results);

  const url = '/advertiser_categories/';

  const swr = useSWR(apiIsReady ? { url } : null, fetcher);

  const { data, error, isLoading } = swr;

  return {
    data,
    error,
    isLoading,
  };
};
