import { split } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { CampaignTypes } from '@v2/components/forms';

import { sectionNames } from './constants';
import {
  buildAddAdGroupTimelineNode,
  buildDisplayAdGroupSettingsSection,
  buildExistingCampaignSections,
  buildNewCampaignSections,
} from './sections';

import {
  useCampaignAdGroupsPage,
  useDeleteAdGroup,
  usePatchAdGroup,
} from '../hooks/apis/adGroups';
import {
  useCampaignStaticDisplayAdGroupsPage,
  useDeleteStaticDisplayAdGroup,
  usePatchStaticDisplayAdGroup,
} from '../hooks/apis/staticDisplayAdGroups';
import { useGetCampaign } from '../hooks/apis/campaigns';

const CampaignWizardContext = React.createContext(null);

CampaignWizardContext.displayName = 'CampaignWizardContext';

export const actions = {
  ADD: 'add',
};

export const getEmptyGroupSection = (sections = []) =>
  sections.find(section => {
    return (
      section.section === sectionNames.adGroup && !section.props.adGroupId
    );
  });

export const getEmptyGroupKey = sectionKeys =>
  sectionKeys.find(sectionKey => {
    const keyParts = sectionKey.split('-');

    return keyParts.length === 2 && keyParts[1] === 'adGroupSettings';
  });

export const getEmptyGroup = sections => {
  return sections.find(section => section.data.temporary);
};

// insert new ad group section after the last ad group section
const insertNewAdGroup = (sections, newAdGroupSection) => {
  const lastIndex = sections
    .map(Object)
    .reverse()
    .findIndex(element => element.section == sectionNames.adGroup);
  if (lastIndex !== -1) {
    const idx = sections.length - 1 - lastIndex;
    return [
      ...sections.slice(0, idx + 1),
      newAdGroupSection,
      ...sections.slice(idx + 1),
    ];
  }
  return [...sections, newAdGroupSection];
};

// add-ad-group is treated as a section
const insertAddAdGroupSectionKey = sectionKeys => {
  const lastIndex = sectionKeys
    .map(String)
    .reverse()
    .findIndex(element => element.startsWith('adGroupSettings'));

  if (lastIndex !== -1) {
    const idx = sectionKeys.length - 1 - lastIndex;
    return [
      ...sectionKeys.slice(0, idx + 1),
      sectionNames.addAdGroup,
      ...sectionKeys.slice(idx + 1),
    ];
  }
  return [...sectionKeys, sectionNames.addAdGroup];
};

// delete ad group section with the given sectionKey
const deleteAdGroup = async (sections, sectionKey, triggers) => {
  const idx = sections.findIndex(item => item.key === sectionKey);
  const adGroupId = sections[idx]?.data?.id;
  if (idx !== -1) {
    // only make DELETE request if it is saved in BE
    if (adGroupId) {
      await triggers.patchAdGroupTrigger({ id: adGroupId, creatives: [] });
      await triggers.deleteAdGroupTrigger(adGroupId);
    }
    return [...sections.slice(0, idx), ...sections.slice(idx + 1)];
  }
  return sections;
};

export const CampaignWizardContextProvider = ({
  campaignId,
  children,
  adGroupId,
  action,
}) => {
  const [sections, setSections] = useState([]);
  const [campaignType, setCampaignType] = useState(null);

  // NOTE: fetching data here and again from the section that uses it
  // NOTE: if it was fetched already, it will be cached
  // NOTE: so no extra requests are made
  const { campaign } = useGetCampaign(campaignId);

  const {
    items: campaignAdGroups,
    mutate: mutateCampaignAdGroups,
    // remove: deleteAdGroupTrigger,
    add: addAdGroupTrigger,
    duplicate: duplicateAdGroupTrigger,
  } = useCampaignAdGroupsPage(campaignId);
  const mutateAdGroupsOption = { onSuccess: () => mutateCampaignAdGroups() };
  const { trigger: deleteAdGroupTrigger } = useDeleteAdGroup(
    mutateAdGroupsOption,
  );
  const {
    items: campaignStaticDisplayAdGroups,
    mutate: mutateCampaignStaticDisplayAdGroups,
  } = useCampaignStaticDisplayAdGroupsPage(campaignId);
  const mutateStaticDisplayAdGroupsOption = {
    onSuccess: () => mutateCampaignStaticDisplayAdGroups(),
  };
  const { trigger: patchStaticDisplayAdGroupTrigger } =
  usePatchStaticDisplayAdGroup(mutateStaticDisplayAdGroupsOption);
const { trigger: deleteStaticDisplayAdGroupTrigger } =
  useDeleteStaticDisplayAdGroup(mutateStaticDisplayAdGroupsOption);

  const { trigger: patchAdGroupTrigger } = usePatchAdGroup(
    mutateAdGroupsOption,
  );


  const triggers = {
    patchAdGroupTrigger,
    deleteAdGroupTrigger,
    patchStaticDisplayAdGroupTrigger,
    deleteStaticDisplayAdGroupTrigger,
  };

  const addAdGroupSection = useCallback(() => {
    addAdGroupTrigger();
  }, [addAdGroupTrigger]);

  const addDisplayAdGroupSection = useCallback(() => {
    const newAdGroupSection = buildDisplayAdGroupSettingsSection({
      campaignId,
      campaign,
    });

    setSections(prevSections =>
      insertNewAdGroup(prevSections, newAdGroupSection),
    );
  }, [campaign, campaignId]);

  const duplicateAdGroupSection = useCallback(
    sectionKey => {
      duplicateAdGroupTrigger(Number(split(sectionKey, '-')[1]));
    },
    [duplicateAdGroupTrigger],
  );

  const deleteAdGroupSection = useCallback(
    async sectionKey => {
      const numOfAdGroups = sections.filter(item =>
        item.key.startsWith('adGroupSettings'),
      ).length;
      if (numOfAdGroups > 1) {
        const newSections = await deleteAdGroup(
          sections,
          sectionKey,
          triggers,
        );
        setSections(newSections);
      } else {
        // TODO: snackbar warning or other warning design
        console.warn('Cannot delete the only ad group');
      }
    },
    [sections],
  );

  const deleteDisplayAdGroupSection = useCallback(async () => {
    const section = sections.find(item => item.props?.isDisplay);
    if (section) {
      const newSections = await deleteAdGroup(
        sections,
        section.key,
        triggers,
      );
      setSections(newSections);
    }
  }, [sections, setSections, deleteAdGroup]);

  useEffect(() => {
    const data = {
      campaignId,
      campaign,
      adGroups: campaignAdGroups,
      staticDisplayAdGroups: campaignStaticDisplayAdGroups,
    };
    if (!campaignId) {
      setSections(buildNewCampaignSections(data));
    } else {
      setSections(buildExistingCampaignSections(data));
    }
  }, [campaign, campaignAdGroups, campaignStaticDisplayAdGroups, campaignId]);

  const timelineNodes = useMemo(
    () =>
      sections.reduce(
        (acc, { key, name, section, nodes_info }) => ({
          ...acc,
          [key]: { key, name, section, nodes_info },
        }),
        {},
      ),
    [sections],
  );

  const extraTimelineNodes = useMemo(() => {
    const extraNodes = {};
    extraNodes[sectionNames.addAdGroup] = buildAddAdGroupTimelineNode();
    return extraNodes;
  }, []);

  const sectionKeys = useMemo(() => {
    let sectionKeys = sections.map(section => section.key);
    sectionKeys = insertAddAdGroupSectionKey(sectionKeys);
    return sectionKeys;
  }, [sections]);

  const nodes = useMemo(() => {
    let nodes = sections.reduce((acc, { key }) => {
      return { ...acc, [key]: timelineNodes[key] };
    }, {});
    nodes = { ...nodes, ...extraTimelineNodes };
    return nodes;
  }, [sections, timelineNodes, extraTimelineNodes]);

  useEffect(() => {
    switch (action) {
      case actions.ADD:
        if (!getEmptyGroup(sections)) {
          addAdGroupSection();
        }
        break;
      default:
        return;
    }
  }, [action, addAdGroupSection, sections]);

  // If the user is trying to view a specific ad group, open the wizard to that section
  const initialActivePaneKey = useMemo(
    () =>
      adGroupId
        ? `${
            sections?.find(section => {
              return (
                section.section === sectionNames.adGroup &&
                section.props.adGroupId === adGroupId
              );
            })?.key
          }::info`
        : action === actions.ADD
          ? `${getEmptyGroupSection(sections)?.key}::info`
          : null,
    [adGroupId, action, sections],
  );

  const value = useMemo(
    () => ({
      nodes,
      sections,
      sectionKeys,
      campaign,
      campaignAdGroups,
      campaignStaticDisplayAdGroups,
      addAdGroupSection,
      addDisplayAdGroupSection,
      duplicateAdGroupSection,
      deleteAdGroupSection,
      deleteDisplayAdGroupSection,
      initialActivePaneKey,
      campaignType,
      setCampaignType,
    }),
    [
      sections,
      campaign,
      campaignAdGroups,
      campaignStaticDisplayAdGroups,
      campaignType,
    ],
  );

  useEffect(() => {
    if (campaignId) {
      setCampaignType(CampaignTypes.FROM_SCRATCH);
    }
  }, [campaignId]);

  useEffect(() => {
    if (!campaignAdGroups.length) {
      addAdGroupTrigger();
    }
  }, [campaignAdGroups, addAdGroupTrigger]);

  return (
    <CampaignWizardContext.Provider value={value}>
      {children}
    </CampaignWizardContext.Provider>
  );
};

CampaignWizardContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  campaignId: PropTypes.number,
  adGroupId: PropTypes.number,
  action: PropTypes.oneOf(Object.values(actions)),
};

export const useCampaignWizardContext = () => {
  const campaignWizardContext = useContext(CampaignWizardContext);

  if (!campaignWizardContext) {
    throw new Error(
      'useCampaignWizardContext CampaignWizardContext was used outside of its Provider',
    );
  }

  return campaignWizardContext;
};

export default CampaignWizardContext;
