import {
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { PropTypes } from 'prop-types';
import React, { Fragment } from 'react';
import {
  AdvancedTableStyled,
  FirstCellStyled,
  LastCellStyled,
  TableGroupCellStyled,
} from '../styles';
import { optionalOptionType } from '../types';
import { TableLeftRow } from './TableLeftRow';

export const TableLeft = ({
  data = [],
  dataName,
  onChange,
  renderCell,
  isTargeted,
  placeholderCell,
}) => {
  return (
    <TableContainer>
      <AdvancedTableStyled>
        <TableHead>
          <TableRow>
            <FirstCellStyled>{dataName}</FirstCellStyled>
            <LastCellStyled />
          </TableRow>
        </TableHead>
        <TableBody data-testid="advanced-left-table-body">
          {data?.[0]?.groupName
            ? data.map((v, i) => (
                <Fragment key={`${v.groupName}-${i}`}>
                  <TableRow>
                    <TableGroupCellStyled colSpan={2}>
                      <Typography variant="caption" color="grey.1">
                        {v.groupName}
                      </Typography>
                    </TableGroupCellStyled>
                  </TableRow>
                  {v.data.map((d, j) => (
                    <TableLeftRow
                      key={data.id ?? `placeholder-${v.groupName}-${i}-${j}`}
                      data={d}
                      index={j}
                      isTargeted={v && isTargeted}
                      onChange={onChange}
                      placeholderCell={placeholderCell}
                      renderCell={renderCell}
                    />
                  ))}
                </Fragment>
              ))
            : data.map((v, i) => (
                <TableLeftRow
                  key={data.id ?? `placeholder-${i}`}
                  data={v}
                  index={i}
                  isTargeted={v && isTargeted}
                  onChange={onChange}
                  placeholderCell={placeholderCell}
                  renderCell={renderCell}
                />
              ))}
        </TableBody>
      </AdvancedTableStyled>
    </TableContainer>
  );
};

TableLeft.propTypes = {
  data: PropTypes.arrayOf(optionalOptionType),
  dataName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isTargeted: PropTypes.bool,
  renderCell: PropTypes.func.isRequired,
  placeholderCell: PropTypes.node,
};
