import { useContext } from 'react';
import OrgContext from '../../providers/OrgContext';

export const useOrg = () => {
  const orgContext = useContext(OrgContext);

  if (orgContext === undefined) {
    throw new Error('useOrg OrgContext was used outside of its Provider');
  }

  return orgContext;
};
