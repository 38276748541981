import React from 'react';
import { Accordion, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { useWizardSection } from '../hooks/useWizardSection';

const SectionStyled = styled(Accordion)(({ theme }) => ({
  padding: '36px 62px',
  scrollMarginTop: theme.spacing(14), // consistent with vertical timeline
  backgroundColor: '#FFFFFF',
  '&.MuiPaper-rounded': {
    borderRadius: 20,
  },
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 'auto',
  },
  '&:not(:last-child)': {
    marginBottom: 25,
  },
  '.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 24,
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    marginTop: 12,
  },
  '&.Mui-disabled': {
    backgroundColor: '#FFFFFF',
  },
}));

export const Section = ({ sectionProps, sectionKey, children, ...props }) => {
  const { active, toggleSection } = useWizardSection({
    key: sectionKey,
  });

  return (
    <SectionStyled
      {...props}
      slotProps={{
        transition: {
          unmountOnExit: true,
          mountOnEnter: false,
        },
      }}
      expanded={active}
      onChange={() => toggleSection(sectionKey)}
    >
      {children}
    </SectionStyled>
  );
};

Section.propTypes = {
  ...Accordion.propTypes,
  sectionKey: PropTypes.string,
};
