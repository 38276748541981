import { fromHighlightedContent } from '@v2/components/campaign/Advanced/utils';
import { chain, escapeRegExp, partition } from 'lodash';
import { groupDataByFields, toHighlightedContent } from '../../../Advanced';

export const transformToContext = (data, filter, by) => {
  const result = [...data];
  if (filter) {
    const reg = new RegExp(escapeRegExp(filter), 'i');

    return result
      .filter(v => reg.test(v[by]) || reg.test(v.id))
      .map(v => ({
        ...v,
        [by]: toHighlightedContent(v[by], filter),
      }));
  }

  return result;
};

export const transformFromContext = (data, by) => {
  if (Array.isArray(data)) {
    return data.map(v => ({
      ...v,
      [by]: fromHighlightedContent(v[by]),
    }));
  }

  return data;
};

export const createGroupList = (list, groupName) =>
  list.length
    ? [
        {
          groupName,
          data: list,
        },
      ]
    : [];

export const transformToGroups = ({
  data,
  generalGroupField,
  featuredFieldName,
  privateFieldName,
  nonTypedFieldName,
}) => {
  const [pBundles, fBundles, nonBundles, list] = chain(data)
    .thru(list => partition(list, ({ private: pBundle }) => pBundle))
    .thru(([pBundles, list]) => [
      pBundles,
      ...partition(list, ({ featured }) => featured),
    ])
    .thru(([pBundles, fBundles, list]) => [
      pBundles,
      fBundles,
      ...partition(list, ({ type }) => !type || type === 'None'),
    ])
    .value();

  return [
    ...createGroupList(fBundles, featuredFieldName),
    ...createGroupList(pBundles, privateFieldName),
    ...createGroupList(nonBundles, nonTypedFieldName),
    ...groupDataByFields(list, generalGroupField),
  ];
};
