import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

const errors = {
  cpm: 'A valid number is required for Max CPM.',
  detail: 'Refreshing token... Please try again.',
  error: 'There was an error with your request. Please contact support. Your error ID is {CID}.',
  name: 'You must give your ad group a name.',
  targeting: 'There is a conflict in your targeting selection. Please contact support.',
  400: 'There was an error with your request. Please contact support.',
  401: 'The token has expired. Please try again.',
  403: 'Authentication credentials were not provided.',
  500: 'There was an error with your request. Please contact support.',
};

////////////////////
// useErrors HOOK
////////////////////
export const useErrors = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    return () => {
      setIsError(false);
    };
  }, []);

  const handleErrorMsg = async (err, variant) => {
    const errorMsgs = err instanceof Array ? err : [err];

    errorMsgs.forEach(errorMsg =>  enqueueSnackbar(errorMsg, {
        autoHideDuration: 10000,
        preventDuplicate: true,
        variant,
      })
    )
  };

  const parseError = (error, variant, refreshToken) => {
    if (error && error.response && error.response.status) {
      const { data, status } = error.response;
      const dataErrors = Object.keys(data);

      if (typeof data === 'string') {
        const message = data.length > 120 ? `${data.substring(0, 120)}...` : data;
        handleErrorMsg(message, variant);
        return;
      }

      if(status === 403 && data.detail === "You do not have permission to perform this action."){
        enqueueSnackbar("You don't have permissions to perform this change, please check with your Org Admin", {
          autoHideDuration: 5000,
          preventDuplicate: true,
          variant: 'warning',
        });
        return
      }

      if (dataErrors && dataErrors.length > 0) {
        dataErrors.forEach(d => {
          if (errors[d]) {
            if (d === 'detail' && status === 403) {
              handleErrorMsg(errors[d], 'warning');

              // If it errors out with a 403,
              // it is likely that the token has expired
              if (refreshToken) {
                refreshToken();
              }
            } else if (d === 'error') {
              // If error contains a CID display it to the user
              const errorMessage = errors[d].replace(/{CID}/g, data[d]);
              handleErrorMsg(errorMessage, variant);
            } else if (d !== 'targeting' && data[d] !== errors[d]) {
              // Display server message for non-targeting errors that dont match
              // hardcoded values, e.g. name errors
              handleErrorMsg(data[d], variant);
            } else {
              handleErrorMsg(errors[d], variant);
            }
          } else {
            handleErrorMsg(data[d], 'error')
          }
        });
      } else {
        if (errors[status]) {
          handleErrorMsg(
            errors[status],
            status === 403 ? 'warning' : variant
          );
        }
      }

      if (Object.values(data).length > 0) {
        Object.values(data).map(e => {
          handleErrorMsg(e, 'error');
        });
      }
    }
  };

  const handleError = (error, variant, refreshToken) => {
    setIsError(true);
    parseError(error, variant, refreshToken);
  };

  return {
    isError,
    handleError,
  };
};
