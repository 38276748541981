import { Button, Typography, styled } from '@mui/material';

export const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'active',
})(({ active, theme }) => ({
  backgroundColor: active ? theme.palette.blue[11] : 'inherit',
  outline: 'none',
  border: 'none',
  '&:hover': {
    border: 'none',
  },
}));

export const StyledSubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.blue[6],
  '&:hover': {
    backgroundColor: theme.palette.blue[6],
  },
}));

export const StyledButtonText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
}));
