import * as yup from 'yup';

export const fields = {
  name: {
    path: 'name',
    rule: yup.string(),
  },
  language: {
    path: 'language',
    rule: yup.string(),
  },
  weighting: {
    path: 'weighting',
  },
  click_url: {
    path: 'click_url',
    rule: yup.string().url('Please provide a valid URL'),
  },
  impression_tracking_pixel: {
    path: 'pixels',
  },
  showClickUrl: {
    path: 'showClickUrl',
  },
  showImpressionTrackingPixel: {
    path: 'showImpressionTrackingPixel',
  },
};

export const validationSchema = yup.object().shape(
  Object.values(fields).reduce((acc, { path, rule }) => {
    if (!rule) return acc;

    acc[path] = rule;
    return acc;
  }, {}),
);
