import { Stack, Typography, styled } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';
import AudienceBlock from './AudienceBlock';
import { ages, genders, incomes } from './constants';
import {
  fromArray,
  getAgesLabel,
  getIncomeLabel,
  getOptionsRange,
  transformFromAll,
} from './utils';

const StyledLabel = styled(Typography)({
  fontWeight: 500,
});

const Audience = ({ value, onChange, ...props }) => {
  const handleChange = key => changedValue => {
    onChange({
      ...value,
      [key]: changedValue,
    });
  };

  return (
    <Stack spacing={2} {...props}>
      <AudienceBlock
        label={v => (
          <StyledLabel>
            {genders.find(g => g.value === v)?.label ?? 'All genders'}
          </StyledLabel>
        )}
        type="gender"
        onChange={handleChange('gender')}
        options={genders}
        value={fromArray(value.gender)}
        multiple={false}
      />
      <AudienceBlock
        type="age"
        label={v => (
          <StyledLabel>{getAgesLabel(transformFromAll(v))}</StyledLabel>
        )}
        onChange={v => {
          handleChange('age')(getOptionsRange(v, ages.slice(1)));
        }}
        options={ages}
        value={transformFromAll(value.age)}
        multiple
      />
      <AudienceBlock
        type="income"
        label={v => (
          <StyledLabel>{getIncomeLabel(transformFromAll(v))}</StyledLabel>
        )}
        onChange={v => {
          handleChange('income')(getOptionsRange(v, incomes.slice(1)));
        }}
        options={incomes}
        value={transformFromAll(value.income)}
        multiple
      />
    </Stack>
  );
};

Audience.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    gender: PropTypes.string,
    age: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    hhi: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  }),
  ...Stack.propTypes,
};

export default Audience;
