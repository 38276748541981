import { deburr } from 'lodash';
import useSWR from 'swr';
import { useAPI } from '../../../../../components/hooks/api';
import { dmaData } from '../../../../../components/util';

const mbToken = process.env.REACT_APP_MAPBOX_TOKEN;
const geoBaseUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
// TODO: Use v6 of the API if we can solve the CORS error
// const geoBaseUrlV2 = 'https://api.mapbox.com/search/geocode/v6/forward';

const searchDMAs = (search = '', limit = 5) => {
  const lowerSearch = search.toLowerCase();
  return dmaData.reduce((result, dma) => {
    if (
      result.length < limit &&
      dma.text.toLowerCase().startsWith(lowerSearch)
    ) {
      const code = dma.code.toString();
      result.push({
        ...dma,
        place_name: dma.text,
        id: code,
        code,
        type: 'dma',
      });
    }

    return result;
  }, []);
};

export const useGeoSearchV2 = (search = '') => {
  const { unhandledGet } = useAPI();

  const fetcher = ({ url, params }) =>
    unhandledGet(url, params).then(res => {
      const { features } = res.data;
      const dmaResults = searchDMAs(search, 2);
      return [
        ...dmaResults,
        ...features.map(v => ({ ...v, type: v.place_type[0] })),
      ];
    });

  const params = {
    access_token: mbToken,
    autocomplete: true,
    country: 'us',
    types: 'country,region,postcode,place',
    // TODO: if we use v6 of the mapbox api, we need to include the q param here
    // q: search,
  };

  const inputValue = deburr(search.trim()).toLowerCase();

  const url = `${geoBaseUrl}/${encodeURIComponent(inputValue)}.json`;
  // TODO: If we use geoBaseUrlV2, the base URL is all we need, plus the params (with q param)
  // const url_v2 = geoBaseUrlV2;

  const { data, error, loading } = useSWR(
    inputValue.length >= 3 ? { url, params } : null,
    fetcher,
  );

  return { data, error, loading };
};
