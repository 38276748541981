import { generatePath, useHistory, useLocation } from 'react-router';
import { RoutePaths } from '@constants';
import { useMemo } from 'react';
import { defaultTheme_v2, padmanTheme_v2 } from '@themes';

export const useWizardSelector = () => {
  const { state } = useLocation();

  const key = useMemo(() => state?.key ?? new Date().getTime(), [state?.key]);

  const isPeacockDomain = window.location.hostname.startsWith('peacock');
  const theme = isPeacockDomain ? padmanTheme_v2 : defaultTheme_v2;
  const history = useHistory();

  return {
    theme,
    key,
    select: () => {
      history.push(
        `${generatePath(RoutePaths.CAMPAIGN_REWORK, { campaignId: 'new' })}${window.location.search}`,
      );
    },
  };
};
