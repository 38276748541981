import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { faBolt } from '@fortawesome/pro-regular-svg-icons';
import { CampaignTypes } from '@v2/components/forms';
import { ButtonCard } from '@v2/components/campaign/ButtonCard';
import { TvSciQSvgIcon } from '@v2/components/icons/TvSciQSvgIcon';
import { CustomListItem } from './CustomListItem';
import { Icon } from '@v2/components/campaign/Icon';
import { useTheme } from '@mui/material/styles';
import QuickStartImage from '@v2/images/padman/quick-start-campaign.svg';
import FromScratchImage from '@v2/images/padman/campaign-from-scratch.svg';

const TvSciQLogo = () => (
  <span>
    <TvSciQSvgIcon sx={{ marginRight: '4px', marginBottom: '-4px' }} />
    <Box component="span" sx={{ color: 'blue.1', marginRight: '4px' }}>
      tvSc
      <span style={{ fontWeight: '700' }}>iQ</span>
    </Box>
  </span>
);

export const CampaignTypeForm = ({ onSubmit, ...props }) => {
  const theme = useTheme();
  const isPeacockDomain = window.location.hostname.startsWith('peacock');

  return (
    <Box
      sx={{ background: 'common.white' }}
      {...props}
    >
      <Stack
        direction="row"
        justifyContent="center"
        spacing={4}
      >
        <ButtonCard
          disabled
          id="Streaming TV"
          cardHeaderEnabled={false}
          cardHeaderTitle="RECOMMENDED"
          customIcon={isPeacockDomain ? <img src={QuickStartImage} alt="Quick Start Campaign" height='150px' /> : <TvSciQSvgIcon sx={{ fontSize: 110 }}/>}
          iconColor="green.8"
          iconMargin="55px 0 35px"
          cardFooterTitle={<><strong>Pro tip:</strong> Advertisers that are new to CTV, typically see a 10-15% lift in ROI with quick-start campaigns.</>}
          cardFooterIcon={faBolt}
          cardFooterBGColor={theme.palette.tip['light']}
          onSelect={() => onSubmit(CampaignTypes.QUICK_START)}
        >
          <Box sx={{
            position: 'absolute',
            top: -24,
            right: -24,
          }}>
            <Icon name="comingSoon"/>
          </Box>
          <Box px={4}>
            <Typography variant="h4" mb={1}>
              Build a quick-start campaign
            </Typography>
            <List sx={{ width: '100%' }}>
              <CustomListItem>
                Let {!isPeacockDomain && <TvSciQLogo />} provide intelligent
                targeting, budget allocation, and bid strategy recommendations.
              </CustomListItem>
            </List>
          </Box>
        </ButtonCard>

        <ButtonCard
          id='Linear TV'
          cardHeaderEnabled={false}
          customIcon={isPeacockDomain ? <img src={FromScratchImage} alt="Campaign from Scratch" height='150px' /> : null}
          iconName={isPeacockDomain ? null : 'faTools'}
          iconColor="grey.5"
          iconMargin="55px 0 35px"
          onSelect={() => onSubmit(CampaignTypes.FROM_SCRATCH)}
        >
          <Box padding='0 30px'>
            <Typography variant="h4" mb={1}>
              Build a campaign from scratch
            </Typography>
            <List sx={{ width: '100%' }}>
              <CustomListItem>
                Advanced targeting and audience profiles.
              </CustomListItem>
              <CustomListItem>
                Add multiple Ad Groups
              </CustomListItem>
            </List>
          </Box>
        </ButtonCard>
      </Stack>
    </Box>
  );
};

CampaignTypeForm.defaultProps = {
  data: {
    type: '',
  },
};

CampaignTypeForm.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};
