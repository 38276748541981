import moment from 'moment';
import * as yup from 'yup';
import {
  endDateValidation,
  endTimeValidation,
  startDateSimpleValidation,
  startTimeSimpleValidation,
} from '../../../../components/CampaignWizard/validations';

export const sections = {
  dates: 'dates',
};

const requiredMessage = 'Required field';

const defaultCampaignName = () => {
  const timestamp = moment().format('MMMM Do h:mma');
  return `${timestamp} Campaign`;
};

export const fields = {
  name: {
    path: 'name',
    rule: yup.string().required(requiredMessage),
    defaultValue: defaultCampaignName(),
  },
  advertiserName: {
    path: 'advertiserName',
    defaultValue: '',
  },
  budgetType: {
    path: 'budgetType',
    defaultValue: 'lifetime_budget',
  },
  lifetimeBudget: {
    path: 'lifetimeBudget',
    defaultValue: '',
    rule: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      )
      .when('budgetType', {
        is: 'lifetime_budget',
        then: s =>
          s
            .required('Required')
            .min(0.01, 'Please enter a budget over one cent.'),
        otherwise: s => s.notRequired(),
      }),
  },
  dailyBudget: {
    path: 'dailyBudget',
    defaultValue: '',
    rule: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      )
      .min(0.01, 'Please enter a daily budget over one cent.'),
  },
  dailyBudgetCap: {
    path: 'dailyBudgetCap',
    defaultValue: '',
    rule: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      )
      .min(0.01, 'Please enter a daily bugdet cap over one cent.'),
  },

  startDate: {
    path: 'startDate',
    rule: startDateSimpleValidation('endDate'),
    defaultValue: moment(),
  },
  endDate: {
    path: 'endDate',
    rule: endDateValidation('startDate'),
  },
  startTime: {
    path: 'startTime',
    rule: startTimeSimpleValidation('startDate', 'endDate', 'endTime'),
  },
  endTime: {
    path: 'endTime',
    defaultValue: null,
    rule: endTimeValidation('startDate', 'startTime', 'endDate'),
  },
  isDisplay: {
    path: 'isDisplay',
    defaultValue: false,
  },
  displayPct: {
    path: 'displayPct',
  },
  objective: {
    path: 'objective',
    defaultValue: 'audienceReach',
  },
  advancedOptions: {
    path: 'advancedOptions',
    defaultValue: {
      isIncremental: false,
      isExperiment: false,
      isCapAds: false,
      freqCaps: [],
      experimentPlan: null,
      experimentType: null,
    },
  },
  active: {
    path: 'active',
    defaultValue: false,
  },
  draft: {
    path: 'draft',
    defaultValue: true,
  },
  budget: {
    path: 'budget',
  },
  status: {
    path: 'status',
  },
  advertiser: {
    path: 'advertiser',
  },
};
