import { useContext } from 'react';

import AuthContext from '../../providers/AuthContext';

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth AuthContext was used outside of its Provider');
  }

  return context;
};
