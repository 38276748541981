import { faCirclePlus, faRemove } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, IconButton, Stack, styled } from '@mui/material';
import React from 'react';
import { GeoTargeter } from '../GeoTargeter';
import {
  DividerStyled,
  DividerTypographyStyled,
  SectionBoxStyled,
  SectionInnerBoxStyled,
} from './styles';
import { useGeography, useGeographyItem } from './hooks/useGeography';
import { PropTypes } from 'prop-types';
import { distinctNames } from '../GeoTargeter/constants';
import { GeographyPreview } from '../GeoTargeter/GeographyPreview';
import { getZipcodesLabel } from './utils';
import { getActualField } from '../GeoTargeter/utils';
import { entireUSId } from './constants';
import {
  SectionInfo,
  sectionInfoType,
} from '../../../../components/SectionInfo';

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 700,
  ml: 0,
  pl: theme.spacing(0.5),
  pt: 0,
  color: theme.palette.grey[1],
  '& .MuiButton-startIcon': {
    color: theme.palette.primary.main,
    fontSize: '1rem',
  },
}));

// Ensure the IconButton that uses FontAwesomeIcon is styled correctly
// Adjust size if using smaller/larger icons
const StyledFAIconButton = styled(IconButton)(({ theme }) => ({
  minWidth: theme.spacing(5),
  minHeight: theme.spacing(5),
}));

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const GeographyItem = ({
  index,
  onAdvanced,
  value,
  onBlur,
  onRemove,
  onUpdate,
  focused,
  ...props
}) => {
  const { change, chnageSelectionType } = useGeographyItem({
    onAdvanced,
    value,
    onBlur,
    onUpdate,
  });

  return (
    <Stack direction="row" gap={2} justifyContent="center" alignItems="center">
      {value.type === distinctNames.zipList ? (
        <GeographyPreview
          onChange={change}
          value={{
            ...value,
            text: getZipcodesLabel(value.codes),
          }}
          onEdit={() => {
            onAdvanced({
              data: value,
              list: [],
              index,
            });
          }}
        />
      ) : (
        <GeoTargeter
          {...props}
          value={value}
          focused={focused}
          onBlur={onBlur}
          label={getActualField(value)}
          onChange={change}
          onChangeSelectionType={chnageSelectionType}
          selectionType={value.blacklist ? 'exclude' : 'include'}
        />
      )}
      <Box>
        {value.id !== entireUSId && (
          <StyledFAIconButton onClick={onRemove}>
            <FontAwesomeIcon icon={faRemove} size="md" />
          </StyledFAIconButton>
        )}
      </Box>
    </Stack>
  );
};

GeographyItem.propTypes = {
  ...GeoTargeter.propTypes,
  index: PropTypes.number.isRequired,
  onAdvanced: PropTypes.func,
};

export const Geography = ({ info, onAdvanced, ...props }) => {
  const { list, add, focused, blur, focus, remove, update } = useGeography();

  return (
    <SectionBoxStyled {...props}>
      <DividerStyled textAlign="left">
        <DividerTypographyStyled>Geography</DividerTypographyStyled>
      </DividerStyled>
      <SectionInnerBoxStyled>
        <SectionInfo infoBlocks={info} />
        <Stack spacing={2}>
          {list.map((item, index) => (
            <GeographyItem
              focused={focused.has(index)}
              onBlur={blur(index)}
              onFocus={focus(index)}
              onRemove={remove(index)}
              onUpdate={update(index)}
              onAdvanced={onAdvanced}
              key={item.id}
              value={item}
              index={index}
            />
          ))}

          <ButtonContainer>
            <StyledButton
              onClick={() => add()}
              size="small"
              variant="text"
              startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
            >
              Add geography
            </StyledButton>
            <StyledButton
              onClick={() =>
                onAdvanced({
                  list: list.filter(v => v.type === distinctNames.zipList),
                })
              }
              size="small"
              variant="text"
              startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
            >
              Enter zip codes
            </StyledButton>
          </ButtonContainer>
        </Stack>
      </SectionInnerBoxStyled>
    </SectionBoxStyled>
  );
};

Geography.propTypes = {
  ...SectionBoxStyled.propTypes,
  onAdvanced: PropTypes.func,
  info: PropTypes.arrayOf(sectionInfoType),
};
