import React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowTurnDownLeft, faArrowTurnDownRight, faDisplay,
  faDisplayChartUpCircleCurrency, faCheck, faPlus,
  faMegaphone, faMessageCaptions, faUserGroup, faRocket, faArrowRight,
  faArrowLeft,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  faArrowTurnDownLeft, faArrowTurnDownRight, faDisplay,
  faDisplayChartUpCircleCurrency, faCheck, faPlus,
  faMegaphone, faMessageCaptions, faUserGroup, faRocket, faArrowRight,
  faArrowLeft
);

/**
 * Enhanced UI button component for user interaction using MUI,
 * with extended features such as icon support and styling customization.
 */
const Button = ({
  size,
  label,
  color,
  variant,
  onClick,
  bgColor,
  textColor,
  borderColor,
  icon,
  iconAlign,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  lineHeight,
  ...props
}) => {
  const muiVariant =  variant || 'contained';
  const muiSize = size || 'medium'
  const muiColor = color || 'primary';

  // Style customization based on new props
  const style = {
    backgroundColor: bgColor,
    color: textColor,
    border: borderColor ? `1px solid ${borderColor}` : undefined,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    lineHeight,
    // hover & active values base on the selected bgColor color
    '&:hover': {
      backgroundColor: `color-mix(in srgb, ${bgColor}, black 10%)`,
    },
    '&:active': {
      backgroundColor: `color-mix(in srgb, ${bgColor}, black 20%)`,
    },
  };

  const renderIcon = () => icon && <FontAwesomeIcon icon={icon} />;

  return (
    <MuiButton
      variant={muiVariant}
      size={muiSize}
      color={muiColor}
      sx={style}
      startIcon={icon && iconAlign === 'left' ? renderIcon() : undefined}
      endIcon={icon && iconAlign === 'right' ? renderIcon() : undefined}
      onClick={onClick}
      {...props}
    >
      {iconAlign === 'iconOnly' ? renderIcon() : label}
    </MuiButton>
  );
};

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  label: PropTypes.string,
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'success', 'error', 'info', 'warning']),
  onClick: PropTypes.func,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  icon: PropTypes.object, // Adjust according to the correct PropTypes for FontAwesome icons
  iconAlign: PropTypes.oneOf(['left', 'right', 'iconOnly']),
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  fontStyle: PropTypes.string,
  fontWeight: PropTypes.string,
  lineHeight: PropTypes.string,
};

Button.defaultProps = {
  variant: 'contained',
  size: 'medium',
  onClick: undefined,
  iconAlign: 'left', // Default alignment of icon if provided
};

export default Button;
