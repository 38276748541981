import { useFormContext } from 'react-hook-form';
import { fields } from '../constants';

export const useBidStrategy = ({ onAdvanced }) => {
  const { setValue, watch, trigger, control } = useFormContext();

  const [frequencyCap = [], dayparting = [], isMaxCPMBidEnabled] = watch([
    fields.configureAdGroupFrequencyCap.path,
    fields.configureAdGroupDayparting.path,
    fields.maxCPMBidEnabled.path,
  ]);

  const handleAdvanced = (index, type) => {
    setValue(
      fields.configureAdGroupFrequencyCap.path,
      frequencyCap?.map((v, i) => ({
        ...v,
        isEditing: i === index && type === 'frequencyCap',
      })) ?? [],
    );

    setValue(
      fields.configureAdGroupDayparting.path,
      dayparting?.map((v, i) => ({
        ...v,
        isEditing: i === index && type === 'dayparting',
      })) ?? [],
    );

    onAdvanced();
  };

  return {
    setValue,
    control,
    frequencyCap,
    trigger,
    dayparting,
    isMaxCPMBidEnabled,
    advanced: handleAdvanced,
  };
};
