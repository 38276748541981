import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, styled } from '@mui/material';
import React from 'react';
import { PropTypes } from 'prop-types';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { get } from 'lodash';

const StyledInfoIcon = styled(FontAwesomeIcon)(({ theme, color }) => ({
  color: color ? get(theme.palette, color) : theme.palette.blue[6],
  fontSize: '1rem',
  marginLeft: 2,
}));

const Hint = ({ children, color, ...props }) => {
  return (
    <Tooltip {...props} title={children} arrow placement="top">
      <StyledInfoIcon color={color} icon={faCircleInfo} />
    </Tooltip>
  );
};

Hint.propTypes = {
  ...Tooltip.propTypes,
  children: PropTypes.node,
  color: PropTypes.string,
};

export default Hint;
