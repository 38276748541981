import { useContext } from 'react';

import CurrentSessionContext from '../../providers/CurrentSessionContext';

export const useCurrentSession = () => {
  const currentSessionContext = useContext(CurrentSessionContext);

  if (!currentSessionContext) {
    throw new Error(
      'useCurrentSession CurrentSessionContext was used outside of its Provider',
    );
  }

  return currentSessionContext;
};
