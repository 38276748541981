import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
// TODO: TBD - Add Custom Tracking Event
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// TODO: TBD - Add Custom Tracking Event
// import IconButton from '@mui/material/IconButton';
// import InputBase from '@mui/material/InputBase';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AdvertiserContext from './AdvertiserContext';
import { useTracking } from './hooks/tracking';
import Title from './Title';
import { SetupWizardSteps, Themes } from '../constants';

const PREFIX = 'TrackingEvents';

const classes = {
  backBtn: `${PREFIX}-backBtn`,
  divider: `${PREFIX}-divider`,
  margin: `${PREFIX}-margin`,
  extendedIcon: `${PREFIX}-extendedIcon`,
  addEvent: `${PREFIX}-addEvent`,
  addEventBtn: `${PREFIX}-addEventBtn`,
  bottomNav: `${PREFIX}-bottomNav`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.backBtn}`]: {
    color: `rgba(0, 0, 0, 0.1)`,
  },

  [`& .${classes.divider}`]: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.extendedIcon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.addEvent}`]: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    borderRadius: '10px',
    backgroundColor: 'rgb(255, 255, 255)',
    width: '89%',
    padding: theme.spacing(1),
    boxShadow:
      '0 5px 5px -3px rgba(24, 28, 32, 0.07), 0 3px 14px 2px rgba(24, 28, 32, 0.12), 0 8px 10px 1px rgba(24, 28, 32, 0.1)',
  },

  [`& .${classes.addEventBtn}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.bottomNav}`]: {
    marginTop: theme.spacing(4),
    position: 'absolute',
    bottom: '10%',
  }
}));

/////////////////////////////
// TRACKING EVENTS COMPONENT
/////////////////////////////
const TrackingEvents = props => {


  const { trackingEvents } = useTracking();
  const adContext = useContext(AdvertiserContext);

  // TODO: TBD - Add Custom Tracking Event
  // const [newTrackingEvent, setNewTrackingEvent] = useState('');
  // TODO: TBD - Add Custom Tracking Event
  // const updateNewTrackingEvent = (event) => {
  //   setNewTrackingEvent(event);
  // };

  // TODO: TBD - Add Custom Tracking Event
  // const handleClickAddTrackingEvent = () => {
  //   if (newTrackingEvent) {
  //     props.handleSelectEvent(newTrackingEvent);
  //     updateNewTrackingEvent('');
  //   }
  // };

  // For displaying custom tracking events
  // Compare trackingEvents and props.selectedEvents
  const customTrackingEvents = props.selectedEvents
    .filter(selectedEvent => {
      // Filter out any duplicates
      return !trackingEvents.some(standardEvent => {
        return selectedEvent.name === standardEvent.name;
      });
    })
    .map(filteredEvent => {
      // Render the remaining, or in other words, the custom tracking events
      return (
        <Grid item xs={4} key={filteredEvent.name}>
          <Box m={1} className="Button-choice">
            <FormControlLabel
              control={
                <Checkbox
                  name={filteredEvent.name}
                  checked={props.selectedEvents.some(
                    event => event.name === filteredEvent.name
                  )}
                  onClick={() => props.handleSelectEvent(filteredEvent)}
                />
              }
              label={filteredEvent.name}
            />
          </Box>
        </Grid>
      );
    });

  return (
    <Root>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Title>Select Tracking Events</Title>

          {adContext.theme === Themes.NBCU && (
            <p>
              Choose the events you wish to track and have displayed in your
              campaign reporting.
            </p>
          )}
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Grid container className={classes.margin}>
        {props.totalEvents.map(trackingEvent => {
          return (
            <Grid item xs={4} key={trackingEvent.name}>
              <Box m={1} className="Button-choice">
                <FormControlLabel
                  control={
                    <Checkbox
                      name={trackingEvent.name}
                      checked={props.selectedEvents.some(
                        event => event.name === trackingEvent.name
                      )}
                      onClick={() => props.handleSelectEvent(trackingEvent)}
                    />
                  }
                  label={trackingEvent.name}
                />
              </Box>
            </Grid>
          );
        })}

        {customTrackingEvents}

        {/* TODO: TBD - Add Custom Tracking Event */}
        {/* <Grid id="new-event-container" item xs={4}>
          <Box className={classes.addEvent}>
            <IconButton
              className={classes.addEventBtn}
              onClick={() => handleClickAddTrackingEvent()}
              size="small"
            >
              <AddCircleIcon color="secondary" />
            </IconButton>

            <InputBase
              placeholder="Add tracking event"
              inputProps={{ 'aria-label': 'naked' }}
              value={newTrackingEvent}
              onChange={(event) => updateNewTrackingEvent(event.target.value)} />
          </Box>
        </Grid> */}
      </Grid>

      <Grid
        className={classes.bottomNav}
        container
        alignItems="center"
        justifyContent="flex-end"
      >
        <Grid container item xs={6} justifyContent="flex-start">
          <Box>
            <Button
              color="secondary"
              className="Button-campaign-nav"
              onClick={() => {
                props.setStep(SetupWizardSteps.TRACKING_SETUP);
                props.updateBreadcrumbs('tracking', 25);
              }}
              startIcon={<ArrowBackIcon />}
              variant="outlined"
              disableElevation
            >
              Set Up Performance Tracking
            </Button>
          </Box>
        </Grid>

        <Grid container item xs={6} justifyContent="flex-end">
          <Button
            disableElevation
            className="Button--medium"
            color="secondary"
            variant="contained"
            size="medium"
            onClick={() => {
              props.setStep(SetupWizardSteps.INSTALL_TRACKING_CODE);
              props.updateBreadcrumbs('tracking', 75);
            }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Root>
  );
};

TrackingEvents.propTypes = {
  selectedEvents: PropTypes.array,
  handleSelectEvent: PropTypes.func,
  setStep: PropTypes.func,
  totalEvents: PropTypes.array,
  updateBreadcrumbs: PropTypes.func,
};

export default TrackingEvents;
