// TODO:
// 1. Retrieve saved data and submit new data
// 2. Dynamic Chips (i.e. Agency, Admin, Active, etc)
// 3. "Cancel button" resets to original/cached data
// 4. Enable save button with useMemo when changes are made to data

import React, { useContext, useState, useMemo, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, Grid, InputAdornment, MenuItem, Typography, Tabs, Tab } from '@mui/material';

import { useAPI } from '../hooks/api';
import { useUtil } from '../hooks/util';
import AdvertiserContext from '../AdvertiserContext';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useForm } from 'react-hook-form';
import {
  getInitialValues,
  parseFormValuesToApi,
  validationSchema,
} from './formConfig';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFTextField } from '../RHFTextField';
import { OrgChips } from '../OrgChips';
import stateOptions from '../utilStates';
import { RHFSelect } from '../RHFSelect';

const PREFIX = 'ManageOrganization';

const classes = {
  root: `${PREFIX}-root`,
  active: `${PREFIX}-active`,
  admin: `${PREFIX}-admin`,
  chip: `${PREFIX}-chip`,
  company: `${PREFIX}-company`,
  dropdown: `${PREFIX}-dropdown`,
  headerWrap: `${PREFIX}-headerWrap`,
  header: `${PREFIX}-header`,
  state: `${PREFIX}-state`,
  submit: `${PREFIX}-submit`,
  subsection: `${PREFIX}-subsection`,
  successMsg: `${PREFIX}-successMsg`,
  textfield: `${PREFIX}-textfield`,
  tabs: `${PREFIX}-tabs`,
  zip: `${PREFIX}-zip`
};

const StyledBox = styled(Box)(({
  theme: { palette, spacing, typography }
}) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
    marginTop: 60,
    marginBottom: 60,
  },

  [`& .${classes.active}`]: {
    backgroundColor: '#c2f1e3',
    color: '#05734e',
  },

  [`& .${classes.admin}`]: {
    backgroundColor: '#bfe7ff',
    color: '#2592cf',
  },

  [`& .${classes.chip}`]: {
    marginLeft: spacing(2),
    padding: spacing(1),
  },

  [`& .${classes.company}`]: {
    color: '#1dafff',
    fontSize: '0.75rem',
  },

  [`& .${classes.dropdown}`]: {
    width: '48%',
  },

  [`& .${classes.headerWrap}`]: {
    position: 'absolute',
    top: spacing(7),
    width: 125,
  },

  [`& .${classes.header}`]: {
    fontSize: '0.75rem',
  },

  [`& .${classes.state}`]: {
    width: '65%',
  },

  [`& .${classes.submit}`]: {
    ...typography.h4,
    color: '#fff',
    marginLeft: spacing(2),
    padding: spacing(2),
    width: 175,
  },

  [`& .${classes.subsection}`]: {
    margin: 0,
    marginTop: spacing(3),
  },

  [`& .${classes.successMsg}`]: {
    color: palette.primary.light,
  },

  [`& .${classes.textfield}`]: {
    marginTop: spacing(3),
  },

  [`& .${classes.tabs}`]: {
    borderRight: `1px solid ${palette.divider}`,
    paddingTop: spacing(18),
  },

  [`& .${classes.zip}`]: {
    maxWidth: '30%',
  }
}));

const ManageOrganization = ({ org, setOrg }) => {

  const adContext = useContext(AdvertiserContext);
  const { usePatch } = useAPI();
  const { parseAddress } = useUtil();

  const [status, setStatus] = useState('');
  const isLoading = status === 'loading';
  const isSuccess = status === 'success';

  const [tabIndex, setTabIndex] = useState(0);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: getInitialValues(org),
    resolver: yupResolver(validationSchema),
  });

  const isSaveDisabled = useMemo(() => {
    return isLoading;
  }, [isLoading]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleVerifyAddress = () => {
    if (org?.business_physical_address && (
      !org?.street_address_1 &&
      !org?.city &&
      !org?.state &&
      !org?.postal_code
    )) {
      return parseAddress(org.business_physical_address);
    }

    return {};
  };

  const handleCancel = () => {
    reset(getInitialValues(org));
  };

  useEffect(() => {
    const addressFields = handleVerifyAddress();

    reset(getInitialValues({ ...org, ...addressFields }));
  }, [org]);

  const onSubmit = handleSubmit(async values => {
    setStatus('loading');

    const { primary_org } = adContext;
    const [, orgId] = primary_org.split('/').reverse();

    try {
      const { data } = await usePatch(
        `/organizations/${orgId}/`,
        parseFormValuesToApi(values)
      );

      setOrg(data);
      setStatus('success');
    } catch (err) {
      console.error(err);
      setStatus('error');
    }
  });

  const renderGeneralInfo = () => (
    <Grid container spacing={10}>
      <Grid item xs={6}>
        <Box>
          <Typography variant="h4">Company Address</Typography>

          <RHFTextField
            className={classes.textfield}
            disabled
            label="Legal Company Name"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LockOutlinedIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            control={control}
            name="name"
          />

          <RHFTextField
            className={classes.textfield}
            label="Street Address"
            control={control}
            name="address1"
          />

          <RHFTextField
            className={classes.textfield}
            label="Street Address 2"
            control={control}
            name="address2"
          />

          <RHFTextField
            className={classes.textfield}
            label="City"
            control={control}
            name="city"
          />

          <Box
            pt={3}
            mb={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <RHFSelect
              className={classes.state}
              label="State"
              control={control}
              name="state"
            >
              {stateOptions.map(m => (
                <MenuItem key={m.abbreviation} value={m.abbreviation}>
                  {m.name}
                </MenuItem>
              ))}
            </RHFSelect>

            <RHFTextField
              className={classes.zip}
              label="Zip Code"
              control={control}
              name="zip"
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Box>
          <Typography variant="h4">Other Company Information</Typography>

          <RHFTextField
            className={classes.textfield}
            label="Company Phone Number"
            control={control}
            name="phone"
          />

          <RHFTextField
            className={classes.textfield}
            label="DBA / Trade Name (optional)"
            control={control}
            name="dba"
          />
        </Box>
      </Grid>
    </Grid>
  );

  // TODO: storing for finance implentation
  // const renderFinance = () => (
  //   <Grid container spacing={10}>
  //     <Grid item xs={12}>
  //       <Alert severity="info" style={{ marginBottom: 12 }}>
  //         Need more credit?
  //       </Alert>

  //       <Alert severity="info">Want to pay by invoice?</Alert>
  //     </Grid>

  //     <Grid item xs={6}>
  //       <Box>
  //         <TextField
  //           color="secondary"
  //           disabled
  //           fullWidth
  //           label="Legal Company Name"
  //           margin="none"
  //           onChange={event => setCredit(event.target.value)}
  //           value={credit}
  //           InputLabelProps={{ shrink: true }}
  //           InputProps={{
  //             endAdornment: (
  //               <InputAdornment position="end">
  //                 <LockOutlinedIcon fontSize="small" />
  //               </InputAdornment>
  //             ),
  //           }}
  //           variant="outlined"
  //         />

  //         <TextField
  //           className={classes.textfield}
  //           color="secondary"
  //           disabled
  //           fullWidth
  //           label="Legal Company Name"
  //           margin="none"
  //           onChange={event => setLiability(event.target.value)}
  //           value={liability}
  //           InputLabelProps={{ shrink: true }}
  //           InputProps={{
  //             endAdornment: (
  //               <InputAdornment position="end">
  //                 <LockOutlinedIcon fontSize="small" />
  //               </InputAdornment>
  //             ),
  //           }}
  //           variant="outlined"
  //         />
  //       </Box>
  //     </Grid>

  //     <Grid item xs={6}>
  //       <Box>
  //         <Typography variant="h4">Billing Terms</Typography>
  //         <Typography>Net 0</Typography>
  //       </Box>
  //     </Grid>
  //   </Grid>
  // );

  return (
    <StyledBox
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box>
        <Box
          mb={7}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="Organization Tabs"
            >
              <Tab
                label={<Typography variant="h4">General Info</Typography>}
              />
              {/* TODO: storing for finance implentation */}
              {/* <Tab label="Finance" /> */}
            </Tabs>
          </Box>

          <Box>
            <OrgChips org={org} />
          </Box>
        </Box>

        {tabIndex === 0 && renderGeneralInfo()}
        {/* TODO: storing for finance implentation
        {tabIndex === 1 && renderFinance()} */}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        style={{ textAlign: 'right' }}
        width="100%"
      >
        {isSuccess && (
          <Typography component={'span'} className={classes.successMsg}>
            Your Settings have been saved!
          </Typography>
        )}

        <Button
          color="secondary"
          disabled={isLoading}
          disableElevation
          disableRipple
          onClick={handleCancel}
          type="reset"
        >
          Cancel
        </Button>

        <Button
          className={clsx(classes.submit)}
          color="secondary"
          disabled={isSaveDisabled}
          disableElevation={isSuccess}
          disableRipple
          variant="contained"
          type="submit"
          onClick={onSubmit}
        >
          {isLoading ? 'Saving...' : 'Save Settings'}
        </Button>
      </Box>
    </StyledBox>
  );
};

ManageOrganization.propTypes = {
  org: PropTypes.object,
  setOrg: PropTypes.func,
};

export default ManageOrganization;
