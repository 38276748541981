import { Stack } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Table } from './Table';
import { Tabs } from './Tabs';
import { LegendTypographyStyled } from './styles';
import { dataType, optionType } from './types';

const Advanced = ({
  data = [],
  tabs,
  selected,
  selectedFilter,
  selectedTab,
  onChangeTab,
  onChange,
  onFilter,
  renderCell,
  legend = [],
  minRowsAmount = 0,
  ...props
}) => {
  const tab = tabs.find(tab => tab.value === selectedTab);

  return (
    <Stack {...props}>
      <Stack direction="row" alignItems="flex-end">
        <Tabs
          flex={1}
          data={tabs}
          selected={selectedTab}
          onChange={tab => onChangeTab(tab)}
        />
      </Stack>
      <Stack spacing={1.5}>
        <Stack>
          <Table
            renderCell={renderCell}
            onFilter={onFilter}
            dataName={tab.label}
            data={data}
            settings={tab.settings}
            onChange={onChange}
            minRowsAmount={minRowsAmount}
            selected={selected}
            selectedFilter={selectedFilter}
          />
        </Stack>
        {!!legend.length && (
          <Stack
            direction="row"
            spacing={3}
            data-testid="advanced-table-legend"
          >
            {legend.map(l => (
              <Stack
                key={l.label}
                direction="row"
                alignItems="center"
                spacing={1.25}
              >
                {l.type === 'node' ? (
                  l.value
                ) : (
                  <Stack
                    width={4}
                    height={12}
                    borderRadius={2}
                    bgcolor={l.value}
                  />
                )}
                <LegendTypographyStyled>{l.label}</LegendTypographyStyled>
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

Advanced.propTypes = {
  ...Stack.propTypes,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      hint: PropTypes.string,
    }),
  ).isRequired,
  legend: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['color', 'node']),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      label: PropTypes.string.isRequired,
    }),
  ),
  data: dataType,
  byTab: PropTypes.bool,
  renderCell: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(optionType).isRequired,
  selectedTab: PropTypes.string.isRequired,
  minRowsAmount: PropTypes.number,
  onChangeTab: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFilter: PropTypes.func,
};

export default Advanced;
