import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import {
  Box, Card, CardActionArea, CardContent, CardHeader,
  Stack,
  Tooltip, Typography,
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faCheck, } from '@fortawesome/pro-regular-svg-icons';
import { faTools } from '@fortawesome/pro-light-svg-icons';
import {
  faDisplay,
  faDisplayChartUpCircleCurrency,
} from '@fortawesome/pro-thin-svg-icons';

// Mapping of icons to FontAwesomeIcon components
const iconMapping = {
  faDisplay,
  faDisplayChartUpCircleCurrency,
  faTools
};

// Styles for the card container
const StyledCard = styled(Card, {
    shouldForwardProp: (prop) => prop !== 'selected',
  })(({ theme, selected }) => ({
  background: selected ? theme.palette.blue[11] : theme.palette.grey[12],
  borderRadius: theme.shape.borderRadius,
  boxShadow: selected ? 'none' : theme.shadows[4],
  '&:hover': {
    background: selected ? theme.palette.blue[11] : theme.palette.grey[12],
    boxShadow: selected ? 'none' : theme.blueHoverShadow,
  },
  width: '100%',
}));

const StyledCardActionArea = styled(CardActionArea)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  height: '100%',
});

// Styles for the description typography
const StyledDescriptionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '1.625',
  textAlign: 'left',
}));

// Styles for the check icon indicating active state
const StyledCheckedIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.blue[6],
  fontSize: '1.25rem',
  position: 'absolute',
  right: '14px',
  top: '14px',
}));

// Customizable styles for FontAwesomeIcon including props
const StyledFontAwesomeIcon = styled(FontAwesomeIcon, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'id',
})(({ theme, isActive, iconHeight, iconWidth, iconMargin, iconColor }) => ({
  // Directly use the `color` CSS property, controlled by the `iconColor` prop value
  color: iconColor ? iconColor : (isActive ? theme.palette.grey[1] : theme.palette.grey[6]),
  height: iconHeight,
  width: iconWidth,
  margin: iconMargin,
}));

// Styles for the information icon
const StyledInfoIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.blue[6],
  fontSize: '1rem',
  marginLeft: 8,
}));

// Customize CardHeader with specific background and height
const StyledCardHeader = styled(CardHeader)(({ theme, cardHeaderBGColor, cardHeaderTextColor }) => ({
  background: cardHeaderBGColor || theme.palette.green[10], // Set the specified background color
  padding: '0',
  minHeight: 30, // Set the specified height
  width: '100%',
  '.MuiCardHeader-title': { // Target the title with specific styles
    color: cardHeaderTextColor || theme.palette.green[5],
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
    padding: '0 0 0 12px',
  },
}));

const StyledCardFooter = styled(Box)(({ theme, cardFooterBGColor, cardFooterIconColor }) => ({
  backgroundColor: cardFooterBGColor || theme.palette.chart[8],
  width: '100%',
  padding: '16px',
  alignSelf: 'flex-end', // Add this line to align the footer at the end of the parent
  marginTop: 'auto', // Add this line to push the footer to the bottom
  '.svg-inline--fa': {
    color: cardFooterIconColor || theme.palette.chart[5],
  },
}));

// Styles for the tooltip
const StyledTooltip = styled(Tooltip)(({ theme, cardHeaderEnabled }) => ({
  color: theme.palette.grey[6],
  position: 'absolute',
  top: cardHeaderEnabled ? 60 : 30, // Adjust the top position based on cardHeaderEnabled
  right: 30,
  zIndex: 1,
}));

const ButtonCard = ({
    title,
    description,
    iconName,
    iconColor,
    iconHeight,
    iconWidth,
    iconMargin,
    tooltipText,
    showCheckedIcon = false,
    cardHeaderEnabled = false, // Determines if the CardHeader should be shown
    cardHeaderBGColor, // Set the card header BG Color
    cardHeaderTextColor, // Set the card header text color
    cardHeaderTitle = '', // Title for the CardHeader, if enabled
    customIcon, // Custom SVG icon code
    children, // Accept children as a prop
    cardFooterTitle, // Add a prop for the card footer title
    cardFooterBGColor, // Add a prop for the card footer background color
    cardFooterIcon, // Add a prop for the card footer icon
    cardFooterIconColor, // Add a prop for the card footer icon color
    selected,
    onSelect,
    disabled,
  }) => {
  return (
    <StyledCard selected={selected} title={title}>
      <StyledCardActionArea onClick={onSelect} disabled={disabled}>
        {/* Conditionally render the CardHeader based on cardHeaderEnabled prop */}
        {cardHeaderEnabled && (
          <StyledCardHeader title={cardHeaderTitle} cardHeaderBGColor={cardHeaderBGColor} cardHeaderTextColor={cardHeaderTextColor} />
        )}

        {/* Info icon with tooltip */}
        {tooltipText && (
          <StyledTooltip title={tooltipText} cardHeaderEnabled={cardHeaderEnabled} placement="top" arrow>
            <StyledInfoIcon icon={faCircleInfo} />
          </StyledTooltip>
        )}

        <Box position="relative">
          <CardContent>
            {/* Active state check icon */}
            {showCheckedIcon && selected && (
              <StyledCheckedIcon icon={faCheck} />
            )}
            {/* Title with optional info icon */}
            {title && (
              <Typography variant="h6" component="div">
                {title}
              </Typography>
            )}
            {/* Icon name with customizable props */}
            {iconName && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <StyledFontAwesomeIcon
                  icon={iconMapping[iconName]}
                  isActive={selected}
                  iconColor={iconColor}
                  iconHeight={iconHeight}
                  iconWidth={iconWidth}
                  iconMargin={iconMargin}
                />
              </Box>
            )}
            {/* Custom SVG Icon */}
            {customIcon && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {React.cloneElement(customIcon, { iconHeight, iconWidth, fillColor: iconColor, style: { margin: iconMargin, } })}
              </Box>
            )}

            {children}

            {/* Description text */}
            <StyledDescriptionTypography variant="body2" color="text.secondary">
              {description}
            </StyledDescriptionTypography>

          </CardContent>
        </Box>

        {/* Card Footer */}
        {cardFooterTitle && (
          <StyledCardFooter cardFooterBGColor={cardFooterBGColor} cardFooterIcon={cardFooterIcon} cardFooterIconColor={cardFooterIconColor}>
            <Stack direction="row" alignItems="flex-start" spacing={2}>
              {cardFooterIcon && (
                <Box sx={{ fontSize: 24 }}>
                  <FontAwesomeIcon icon={cardFooterIcon} />
                </Box>
              )}
              <Typography variant="body2" color="text.primary">
                {cardFooterTitle}
              </Typography>
            </Stack>
          </StyledCardFooter>
        )}
      </StyledCardActionArea>
    </StyledCard>
  );
};

ButtonCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  iconName: PropTypes.oneOf(Object.keys(iconMapping)), // Specify iconName is one of the predefined icon names
  iconColor: PropTypes.string, // Specify svg iconName color
  tooltipText: PropTypes.string, // Additional info displayed in tooltip
  iconHeight: PropTypes.string,
  iconWidth: PropTypes.string,
  iconMargin: PropTypes.string,
  showCheckedIcon: PropTypes.bool,
  cardHeaderEnabled: PropTypes.bool, // New propType for enabling CardHeader
  cardHeaderBGColor: PropTypes.string, // New propType for CardHeader BG Color
  cardHeaderTextColor: PropTypes.string, // New propType for CardHeader text color
  cardHeaderTitle: PropTypes.string, // New propType for the CardHeader's title
  customIcon: PropTypes.element, // Accepts a React element (e.g., SVG component)
  children: PropTypes.node, // Add propTypes validation for children
  cardFooterTitle: PropTypes.string, // Add propTypes validation for cardFooterTitle
  cardFooterBGColor: PropTypes.string, // Add propTypes validation for cardFooterBGColor
  cardFooterIcon: PropTypes.string, // Add propTypes validation for cardFooterIcon
  cardFooterIconColor: PropTypes.string, // Add propTypes validation for cardFooterIconColor
  selected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ButtonCard.defaultProps = {
  description: '',
  iconName: '',
  iconColor: '',
  tooltipText: '',
  iconHeight: '100px',
  iconWidth: '100px',
  iconMargin: '25px 0 35px',
  showCheckedIcon: false,
  cardHeaderEnabled: false, // By default, CardHeader is not shown
  cardHeaderBGColor: null, // Default CardHeader BG Color transparent
  cardHeaderTextColor: null, // Default CardHeader text color is transparent
  cardHeaderTitle: '', // Default title is empty
  customIcon: null, // Default is null, indicating no custom icon is provided
  children: null, // Default to null or undefined
  cardFooterTitle: '', // Default to empty string
  cardFooterBGColor: null, // Default to empty string
  cardFooterIcon: null, // Default to empty string
  cardFooterIconColor: null, // Default to empty string
  selected: false,
  disabled: false,
};

export default ButtonCard;
