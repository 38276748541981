import React from 'react';
import {
  faClose,
  faPlayCircle,
  faPlusCircle,
  faTimer,
  faTrashXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faPercent,
  faImageLandscape,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import {
  formatBytes,
  formatDuration,
} from '@v2/components/campaign/FileUploads';
import { Controller } from 'react-hook-form';
import { LinkStyled } from '../../../styles';
import { VideoPreview } from '../VideoPreview';
import { ImagePreview } from '../ImagePreview';
import { fields } from '../constants';
import { fields as manageFields } from './constants';
import { useManageListElement } from './hooks/useManageListElement';
import PropTypes from 'prop-types';

const VideoPreviewStyled = styled(VideoPreview)(({ theme }) => ({
  width: theme.spacing(34.25),
  height: theme.spacing(19.25),
  borderRadius: 0,
}));

const ImagePreviewStyled = styled(ImagePreview)(({ theme }) => ({
  width: theme.spacing(34.25),
  height: theme.spacing(19.25),
  borderRadius: 0,
}));

const FileInfoStack = styled(Stack)({
  width: '100%',
  marginLeft: 16,
  marginRight: 8,
  marginBottom: 8,
});

const TimerIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.spacing(2.25),
}));

const TitleStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
}));

const PlayCircleIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.blue[12],
}));

const RemoveIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(1.75),
  height: theme.spacing(2),
}));

const AdditionalInfoTypographyStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[4],
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

export const ManageListElement = ({
  onUpdate,
  onRemove,
  index,
  weightingRotation,
  field,
}) => {
  const { control, isVideo, isImage, errors } = useManageListElement({
    field,
    onUpdate,
  });

  return (
    <Stack direction="row">
      {isVideo && <VideoPreviewStyled src={field.preview_url} />}
      {isImage && <ImagePreviewStyled src={field.preview_url} />}
      <FileInfoStack direction="column" spacing={2.5}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <TitleStyled variant="body2">
            <PlayCircleIconStyled
              icon={isImage ? faImageLandscape : faPlayCircle}
              size="1x"
            />{' '}
            {field.name}
          </TitleStyled>
          <Stack direction="row" alignItems="center">
            {isVideo && (
              <Typography variant="caption" color={'grey.7'}>
                {`${formatBytes(field.size)} ${isVideo ? `• ${formatDuration(field.duration)} • 1080P` : ''}`}
              </Typography>
            )}
            <IconButton onClick={onRemove} size="small">
              <RemoveIconStyled icon={faTrashXmark} />
            </IconButton>
          </Stack>
        </Stack>

        <Stack spacing={3.75}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Controller
              control={control}
              name={`${fields.draftCreatives.path}.${index}.${manageFields.name.path}`}
              defaultValue={field.path}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Creative Title"
                  variant="outlined"
                  size="small"
                  sx={{ flex: 3 }}
                />
              )}
            />
            <FormControl size="small" sx={{ flex: 2 }}>
              <InputLabel htmlFor={`language-select-${index}`}>
                Language
              </InputLabel>
              <Controller
                control={control}
                defaultValue="English"
                name={`${fields.draftCreatives.path}.${index}.${manageFields.language.path}`}
                render={({ field }) => (
                  <Select
                    {...field}
                    id={`language-select-${index}`}
                    input={<OutlinedInput label="Language" />}
                  >
                    <MenuItem value="English">English</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <Controller
              control={control}
              name={`${fields.draftCreatives.path}.${index}.${manageFields.weighting.path}`}
              render={({ field: fieldData }) => {
                return (
                  <TextField
                    {...fieldData}
                    label="Rotation"
                    variant="outlined"
                    type="number"
                    disabled={!weightingRotation}
                    value={!weightingRotation ? '--' : field.weighting ?? ''}
                    size="small"
                    sx={{ flex: 1 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <FontAwesomeIcon icon={faPercent} />
                        </InputAdornment>
                      ),
                    }}
                  />
                );
              }}
            />
          </Stack>
          {(field[manageFields.showClickUrl.path] ||
            field[manageFields.showImpressionTrackingPixel.path]) && (
            <Stack spacing={3.75}>
              {field[manageFields.showClickUrl.path] && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Controller
                    control={control}
                    name={`${fields.draftCreatives.path}.${index}.${manageFields.click_url.path}`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Click URL"
                        variant="outlined"
                        error={
                          errors[fields.draftCreatives.path]?.[index]?.[
                            manageFields.click_url.path
                          ]
                        }
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                  <IconButton
                    onClick={() =>
                      onUpdate({
                        [manageFields.showClickUrl.path]: false,
                        [manageFields.click_url.path]: '',
                      })
                    }
                    size="small"
                  >
                    <FontAwesomeIcon icon={faClose} />{' '}
                  </IconButton>
                </Stack>
              )}
              {field[manageFields.showImpressionTrackingPixel.path] && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Controller
                    control={control}
                    name={`${fields.draftCreatives.path}.${index}.${manageFields.impression_tracking_pixel.path}`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Impression Tracking Pixel"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                  <IconButton
                    onClick={() =>
                      onUpdate({
                        [manageFields.showImpressionTrackingPixel.path]:
                          false,
                        [manageFields.impression_tracking_pixel.path]: '[]',
                      })
                    }
                    size="small"
                  >
                    <FontAwesomeIcon icon={faClose} />{' '}
                  </IconButton>
                </Stack>
              )}
            </Stack>
          )}
          <Stack direction="row" spacing={3}>
            <Stack direction="row" flex={1} spacing={1}>
              {!field[manageFields.showClickUrl.path] && (
                <LinkStyled
                  onClick={() =>
                    onUpdate({
                      [manageFields.showClickUrl.path]: true,
                    })
                  }
                  underline="hover"
                  color="primary"
                >
                  <FontAwesomeIcon icon={faPlusCircle} /> Add Click URL
                </LinkStyled>
              )}
              {isVideo &&
                !field[manageFields.showImpressionTrackingPixel.path] && (
                  <LinkStyled
                    onClick={() =>
                      onUpdate({
                        [manageFields.showImpressionTrackingPixel.path]: true,
                      })
                    }
                    underline="hover"
                    color="primary"
                  >
                    <FontAwesomeIcon icon={faPlusCircle} />
                    Add Impression Tracking Pixel
                  </LinkStyled>
                )}
            </Stack>
            {isVideo && field.pending_active && (
              <AdditionalInfoTypographyStyled variant="caption">
                <TimerIconStyled icon={faTimer} /> Queued for review
              </AdditionalInfoTypographyStyled>
            )}
          </Stack>
        </Stack>
      </FileInfoStack>
    </Stack>
  );
};

ManageListElement.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  field: PropTypes.object,
  weightingRotation: PropTypes.bool,
  isDisplay: PropTypes.bool,
};
