import React from 'react';
import { Stack } from '@mui/material';
import { subwayProps } from '../types';
import { SubwayNode } from './Subway';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { subwayIconList } from '../constants';
import { SubwayVerticalDivider } from '../styles';

export const AddAdGroup = ({ onClick, disabled, ...props }) => {
  return (
    <Stack spacing={1.5} alignItems="flex-start" useFlexGap {...props}>
      <SubwayVerticalDivider length="long" />
      <SubwayNode
        disabled={disabled}
        startIcon={<FontAwesomeIcon icon={subwayIconList.add} />}
        name="add_ad_group"
        label="Ad Group"
        status="add"
        onClick={onClick}
      />
      <SubwayVerticalDivider length="long" />
    </Stack>
  );
};

AddAdGroup.propTypes = {
  ...Stack.propTypes,
  ...subwayProps,
};
