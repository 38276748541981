import { useFormContext } from 'react-hook-form';
import { fields } from '../constants';
import { useEffect } from 'react';
import { getBoundingDates } from '../../../../../components/CampaignWizard/validations';

const dateFields = [
  fields.startDate.path,
  fields.startTime.path,
  fields.endDate.path,
  fields.endTime.path,
];

export const useGeneral = ({ campaign }) => {
  const {
    control,
    trigger,
    watch,
    formState: { errors, dirtyFields },
  } = useFormContext();
  const [startDate, startTime, endDate, endTime] = watch(dateFields);
  const status = watch(fields.status.path);

  useEffect(() => {
    if (dateFields.some(field => dirtyFields[field])) {
      trigger(dateFields);
    }
  }, [startDate, startTime, endDate, endTime]);

  return {
    status,
    control,
    errors,
    endDate,
    ...getBoundingDates(campaign?.startDate, campaign?.endDate),
  };
};
