import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  ButtonBase,
} from '@mui/material';

import MenuIcon from './MenuIcon';
import TruncatedTextTooltip from './TruncableTextToolTip';
import { sectionNames } from '../../../../components/CampaignWizard/constants'

const categoryToTitleMap = {
  [sectionNames.campaign]: 'Campaign',
  [sectionNames.adGroup]: 'Ad Group',
  [sectionNames.displayAdGroup]: 'Ad Group',
  [sectionNames.reviewAndLaunch]: 'Review & Launch',
};

const SubwaySectionTitle = ({
  title,
  category,
  adGroupNo,
  isExpanded,
  handleTitleClick,
  handleDuplicate,
  handleDelete,
  ...props
}) => {
  const sectionTitle = useMemo(() => {
    if ([sectionNames.adGroup, sectionNames.displayAdGroup].includes(category)) {
      return `${categoryToTitleMap[category]} ${adGroupNo}${title && `: ${title}`}`;
    }
    return title !== '' ? title : categoryToTitleMap[category];
  }, [category, adGroupNo, title]);



  return (
    <Grid container justifyContent='flex-start' spacing={2} flexWrap='nowrap' {...props}>
      <Grid item justifyContent='flex-start' alignItems='center' sx={{ minWidth: 0 }}>
        <ButtonBase
          disableRipple
          sx={{ justifyContent: 'flex-start', maxWidth: '100%' }}
          onClick={handleTitleClick}
        >
          <TruncatedTextTooltip text={sectionTitle} isExpanded={isExpanded} />
        </ButtonBase>
      </Grid>
      {category === sectionNames.adGroup &&
        <MenuIcon handleDuplicate={handleDuplicate} handleDelete={handleDelete} sx={{ flexShrink: 0 }} />}
    </Grid>
  )
};

SubwaySectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.oneOf([
    sectionNames.campaign,
    sectionNames.adGroup,
    sectionNames.displayAdGroup,
    sectionNames.reviewAndLaunch,
  ]).isRequired,
  adGroupNo: PropTypes.number,
  isExpanded: PropTypes.bool.isRequired,
  handleTitleClick: PropTypes.func.isRequired,
  handleDuplicate: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default SubwaySectionTitle;
