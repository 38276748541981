import PropTypes from 'prop-types';
import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary } from '@mui/material';

export const SectionSummary = ({ children, ...props }) => {
  return (
    <AccordionSummary
      {...props}
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
    >
      {children}
    </AccordionSummary>
  );
};

SectionSummary.propTypes = {
  ...AccordionSummary.propTypes,
  children: PropTypes.node,
};
