import * as yup from 'yup';

export const joinSchema = yup.object().shape({
  organization_name: yup.string().required('Company Name is required'),
  street_address_1: yup.string().required('Street Address is required'),
  street_address_2: yup.string().optional(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required').nullable(),
  zip_code: yup.string().required('Zip Code is required'),
  phone: yup.string().required('Phone is required'),

  advertiser_name: yup.string(),
  advertiser_website_address: yup.string(),
  category: yup.string().nullable(),
  primary_outcome: yup.string().nullable(),
  sensitive: yup.string().nullable(),
  accepted_terms: yup.boolean().isTrue('In order to user the tvScientific Platform you must agree to the Terms and Conditions')
});
