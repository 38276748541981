import { useFieldArray, useFormContext } from 'react-hook-form';
import { fields } from '../../constants';
import { useCreativeContext } from '../../hooks/useCreativeContext';

export const useManage = ({ onSubmit }) => {
  const { updateCreative } = useCreativeContext();
  const { control, watch, setValue, getFieldState } = useFormContext();
  const {
    fields: listFields,
    remove,
    update,
  } = useFieldArray({
    control,
    name: fields.draftCreatives.path,
  });

  const [draftCreatives, weightingRotation] = watch([
    fields.draftCreatives.path,
    fields.weightingRotation.path,
  ]);

  const controlledFields = listFields.map((field, index) => ({
    ...field,
    ...draftCreatives[index],
  }));

  const handleSubmit = async () => {
    const data = await Promise.all(controlledFields.map(updateCreative));

    setValue(fields.creatives.path, data);

    onSubmit();
  };

  return {
    controlledFields,
    weightingRotation,
    control,
    submit: handleSubmit,
    disabled: getFieldState(fields.draftCreatives.path).invalid,
    remove: index => () => remove(index),
    update: index => data =>
      update(index, { ...controlledFields[index], ...data }),
  };
};
