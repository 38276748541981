export const Scopes = {
  CAN_ACCESS_APPLICATION: 'CAN_ACCESS_APPLICATION',
  CAN_LIST_CAMPAIGN: 'CAN_LIST_CAMPAIGN',
  CAN_CREATE_CAMPAIGN: 'CAN_CREATE_CAMPAIGN',
  CAN_UPDATE_CAMPAIGN: 'CAN_UPDATE_CAMPAIGN',
  CAN_READ_REPORTING: 'CAN_READ_REPORTING',
  CAN_VIEW_BUSINESS_MANAGER: 'CAN_VIEW_BUSINESS_MANAGER',
  CAN_UPDATE_ADVERTISER: 'CAN_UPDATE_ADVERTISER',
  CAN_CREATE_USER: 'CAN_CREATE_USER',
  USER_SHOULD_SETUP_ACCOUNT: 'USER_SHOULD_SETUP_ACCOUNT',
  CAN_SET_SMALL_FLAT_CPM: 'CAN_CREATE_TENANT',
  CAN_VIEW_QC: 'CAN_VIEW_QC',
};
