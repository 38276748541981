import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import AdvertiserContext from "../components/AdvertiserContext";
import { useAPI } from "../components/hooks/api";
import { useAuth } from "../components/hooks";

const context = createContext();

export const useRBACContext = () => useContext(context);

export const RBACContextProvider = ({ children }) => {
  const { authState } = useAuth();
  const { useGet } = useAPI();
  const adContext = useContext(AdvertiserContext);

  const [roles, setRules] = useState({
    advertiser_roles: [],
    organization_roles: []
  });

  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    if (authState.isPending || !authState.isAuthenticated) {
      return;
    }

    useGet('/roles').then((data) => setRules(data))
    useGet('/permissions').then((data) => setPermissions(data))

    // adContext.id is required in order to get the advertiser roles when change ad account context
  }, [authState.isPending, authState.isAuthenticated, adContext.id, adContext?.primary_org]);

  return (
    <context.Provider value={{
      roles,
      permissions,
    }}>
      {children}
    </context.Provider>
  );
}

RBACContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
