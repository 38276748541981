import useSWR, { useSWRConfig } from 'swr';
import { useCurrentSession } from '../currentSession';
import useSWRMutation from 'swr/mutation';
import { useMemo } from 'react';

const buildStaticCreativeCacheKey = (
  creativeId,
  currentAdvertiser,
  params = {},
) => {
  const url = `/staticdisplaycreatives/${creativeId}`;

  const cacheKey = {
    url,
    advertiser: currentAdvertiser.id ?? '-',
    ...params,
  };

  return cacheKey;
};

export const useDisplays = ({ page, params = {} }) => {
  const { getV1, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { disabled = false, ...otherParams } = params;

  const fetcher = ({ url, params }) =>
    getV1(url, params).then(res => res.data);

  if (page >= 1) {
    otherParams['page'] = page;
  }

  const swr = useSWR(
    apiIsReady && !disabled
      ? {
          url: '/static_display_creatives/',
          advertiser: currentAdvertiser?.id ?? '-',
          otherParams,
        }
      : null,
    fetcher,
  );

  const { data, error, isLoading } = swr;

  return {
    displays: useMemo(
      () =>
        data?.results.map(result => ({ ...result, type: 'display' })) ?? [],
      [data],
    ),
    nextPage: data?.next,
    previousPage: data?.previous,
    count: data?.count,
    error,
    isLoading,
  };
};

export const usePatchStaticDisplayCreative = () => {
  const { patchV1, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { mutate } = useSWRConfig();

  const updateDisplayCreative = (url, { arg: { id, ...data } }) => {
    return patchV1(`${url}${id}/`, data).then(res => res.data);
  };

  const options = {
    onSuccess: updatedDisplayCreative => {
      const key = buildStaticCreativeCacheKey(
        updateDisplayCreative.id,
        currentAdvertiser,
      );
      mutate(key, updatedDisplayCreative, { populateCache: true });
    },
    revalidate: false,
  };

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? '/static_display_creatives/' : null,
    updateDisplayCreative,
    options,
  );

  return { trigger, isMutating };
};

export const useStaticDisplayAdGroupCreatives = adGroup => {
  const { getV1, apiIsReady } = useCurrentSession();
  const fetcher = urls => {
    return Promise.all(urls.map(url => getV1(url).then(res => res.data)));
  };
  const urls = adGroup.creatives.map(creativeId => ({
    url: `/static_display_creatives/${creativeId}/`,
    adGroupId: adGroup.id,
  }));
  const swr = useSWR(
    apiIsReady && urls.length > 0 ? urls.map(item => item.url) : null,
    fetcher,
  );
  const { data, error, isLoading } = swr;

  return {
    adGroupsCreatives: data,
    error,
    isLoading,
  };
};
