import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Slider,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

import AdvertiserContext from './AdvertiserContext';
import { convertToTime, valuetext } from './util';
import { Themes } from '../constants';

const PREFIX = 'DayParting';

const classes = {
  root: `${PREFIX}-root`,
  root2: `${PREFIX}-root2`,
  box: `${PREFIX}-box`,
  addItems: `${PREFIX}-addItems`,
  item: `${PREFIX}-item`,
  delete: `${PREFIX}-delete`,
  divider: `${PREFIX}-divider`,
  text: `${PREFIX}-text`,
  title: `${PREFIX}-title`,
  manage: `${PREFIX}-manage`,
  numbered: `${PREFIX}-numbered`,
  actions: `${PREFIX}-actions`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div', {
  shouldForwardProp: prop => prop !== 'appTheme',
})(({ theme, appTheme }) => ({
  [`& .${classes.box}`]: {
    backgroundColor: theme.palette.background.overlay,
    position: 'absolute',
    top: `8.825rem`,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 200,
    padding: `40px 80px 80px`,
    display: 'flex',
    flexDirection: 'column',

    '.MuiSlider-markLabel': {
      color: theme.palette.text.overlay,
    },

    '.Button--small': {
      marginRight: 'rem(20)',
    }
  },

  [`& .${classes.addItems}`]: {
    flex: 1,
    marginTop: theme.spacing(0),
  },

  [`& .${classes.item}`]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: '1px solid rgba(224, 224, 224, 0.3)',
  },

  [`& .${classes.delete}`]: {
    color: appTheme === Themes.DEFAULT ? '#fff' : theme.palette.secondary.main,
  },

  [`& .${classes.divider}`]: {
    backgroundColor: 'rgb(224, 224, 224)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },

  [`& .${classes.text}`]: {
    color: theme.palette.text.overlay,
    textAlign: 'left',
  },

  [`& .${classes.title}`]: {
    color: theme.palette.text.overlay,
  },

  [`& .${classes.manage}`]: {
    paddingTop: theme.spacing(8),
    top: 0,
  },

  [`& .${classes.numbered}`]: {
    backgroundColor: `#1dafff`,
    borderRadius: `50%`,
    color: theme.palette.text.overlay,
    display: `inline-block`,
    padding: `2px`,
    height: `1.5rem`,
    width: `1.5rem`,
    textAlign: 'center',
    marginRight: theme.spacing(2),
  },

  [`& .${classes.actions}`]: {
    textAlign: 'end',
  }
}));

/////////////////////////////
// CONSTANTS
/////////////////////////////
const [dayMin, dayMax] = [-12, 12];
const Days = [
  'su',
  'mo',
  'tu',
  'we',
  'th',
  'fr',
  'sa'
];
const DayCopies = {
  su: 'Su',
  mo: 'Mo',
  tu: 'Tu',
  we: 'We',
  th: 'Th',
  fr: 'Fr',
  sa: 'Sa',
};

const dayMarks = [{
    value: dayMin,
    label: '12AM',
  }, {
    value: dayMax,
    label: '12AM',
  }
];



/////////////////////////////
// DAY PARTING COMPONENT
/////////////////////////////
const DayParting = (props) => {
  const hasDayParts = () => props.dayParts.length > 0 ? props.dayParts : [];

  // Init state
  const [time, setTime] = useState([dayMin, dayMax]);
  const [parts, setParts] = useState(hasDayParts);
  const [activeDays, setActiveDays] = useState([]);
  const [hasScheduled, setHasScheduled] = useState(false);
  const [hasParts, setHasParts] = useState(false);
  const [hasPartsOnInit, setHasPartsOnInit] = useState(false);

  const adContext = useContext(AdvertiserContext);

  const StyledToggleButtonGroup = useMemo(() => props.theme === Themes.DEFAULT ? styled(ToggleButtonGroup)({
    background: 'transparent',
  }) : ToggleButtonGroup, [props.theme]);

  const StyledToggleButton = useMemo(() => props.theme === Themes.DEFAULT ? styled(ToggleButton)(({ theme }) => ({
    root: {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.secondary.contrastText,
      background: 'transparent',
      padding: '5px 15px',

      '&.MuiToggleButtonGroup-groupedHorizontal': {
        border: '1px solid rgba(29, 175, 255, 0.5)',
      },
      '&:not(:first-child)::before': {
        content: 'none',
      },
      '&:hover, &.Mui-selected, &.Mui-selected:hover': {
        color: theme.palette.secondary.contrastText,
        background: 'transparent',
        border: `1px solid ${theme.palette.secondary.main}`,
      },
      '&.Mui-selected, &.Mui-selected:hover': {
        background: theme.palette.secondary.dark,
      },
    }
  })) : ToggleButton, [props.theme]);

  // useEffect for listening to day selections
  useEffect(() => {
    if (parts.length === 0) {
      setHasParts(false)
    }

    if (parts && parts.length > 0) {
      setHasParts(true);
    }

    if (activeDays.length > 0) {
      setHasScheduled(true);
    }

    if (activeDays.length === 0) {
      setHasScheduled(false);
    }
  });

  // Checks for parts when view first loads
  useEffect(() => {
    if (parts && parts.length > 0) {
      setHasPartsOnInit(true);
    }
  }, []);

  // Local state handlers
  const handleTimeRange = (event, value) => {
    setTime(value);
  };

  // Resets the UI for scheduling a day part
  const handleResetScheduler = () => {
    setTime([dayMin, dayMax]);
    setActiveDays([]);
  };

  const handleSelectDays = (event, value) => {
    setActiveDays(value);
  };

  // Toggle action for selected days
  // Adds a scheduled day part to state
  const handleAddPart = () => {
    const days = Days.reduce((prev, curr) => {
      prev[curr] = {
        day: DayCopies[curr],
        selected: activeDays.includes(curr),
      };

      return prev;
    }, {});

    const part = {
      days,
      time,
    };

    setParts([
      ...parts,
      part,
    ]);

    return handleResetScheduler();
  };

  // Deletes a scheduled day part from state
  const handleDeletePart = (part, index) => {
    if (part && part === parts[index]) {
      const newParts = parts.filter((p, i) => (p !== part && i !== index));
      setParts(newParts);
    }
  };

  const handleSetDayParting = isCanceled => {
    props.setDayParting(false);

    if (!isCanceled) {
      props.setDayParts(parts);
    }
  };

  const renderDayPart = (part, index) => {
    const date = `${Days
      .filter(d => part.days[d].selected)
      .map(d => DayCopies[d])
      .join(', ')}, ${convertToTime(part.time[0])}-${convertToTime(
      part.time[1]
    )}`;

    return (
      <Grid container key={index} spacing={10}>
        <Grid item xs={10}>
          <Grid container justifyContent="flex-start">
            <Grid item xs={4}>
              <p className={classes.text}>
                <span className={classes.numbered}>{index + 1}</span>

                <span>{date}</span>
              </p>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs className={classes.actions}>
          <IconButton
            aria-label="delete"
            onClick={() => {
              handleDeletePart(part, index);
            }}
            size="large">
            <DeleteIcon className={classes.delete} />
          </IconButton>
        </Grid>

        <Divider className={classes.divider} />
      </Grid>
    );
  };

  return (
    <Root appTheme={adContext.theme}>
      <Box className={clsx(classes.box, props.isManage ? classes.manage : {})}>
        <Typography variant="h3" component="h3" gutterBottom className={classes.title}>
          {props.title || 'Configure day-parting'}
        </Typography>

        {adContext.theme === Themes.NBCU && (
          <p className={classes.title}>Select the dayparts for your plan.</p>
        )}

        <Divider className={classes.divider} />

        {(parts && parts.length > 0) &&
          <Grid container className={classes.item}>
            {parts.map((part, index) => renderDayPart(part, index))}
          </Grid>
        }

        <Grid container spacing={10} className={classes.addItems}>
          <Grid item xs={5}>
            <StyledToggleButtonGroup
              onChange={handleSelectDays}
              value={activeDays}
            >
              {Days.map((day, index) => (
                <StyledToggleButton
                  key={index}
                  value={day}
                >
                  {DayCopies[day]}
                </StyledToggleButton>
              ))}
            </StyledToggleButtonGroup>
          </Grid>

          <Grid item xs={5}>
            <Slider
              value={time}
              min={dayMin}
              max={dayMax}
              marks={dayMarks}
              onChange={handleTimeRange}
              valueLabelDisplay="auto"
              valueLabelFormat={convertToTime}
              aria-labelledby="range-slider"
              getAriaValueText={valuetext}
              color="secondary"
              name="age"
            />
          </Grid>

          <Grid item xs className={classes.actions}>
            <Button
              color="secondary"
              disabled={!hasScheduled}
              onClick={() => { handleAddPart() }}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              variant="contained"
            >
              ADD
            </Button>
          </Grid>
        </Grid>

        <Grid
          alignItems="center"
          container
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              onClick={() => { handleSetDayParting(true) }}
              color="secondary"
              size="small"
              className="Button--small"
            >
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <Button
              onClick={() => { handleSetDayParting(false) }}
              color="secondary"
              variant="contained"
              size="medium"
              className="Button--medium"
              disabled={!hasPartsOnInit ? !hasParts : false}
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Root>
  );
};

DayParting.propTypes = {
  dayParts: PropTypes.array,
  setDayParting: PropTypes.func,
  setDayParts: PropTypes.func,
  title: PropTypes.string,
  isManage: PropTypes.bool,
  theme: PropTypes.string,
};

export default DayParting;
