import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';

const WizardContainer = ({ children }) => {
  return (
    <Box sx={{ px: 12, py: 6 }}>
      <Grid container spacing={8} justifyContent='space-between'>
        {children}
      </Grid>
    </Box>
  );
};

WizardContainer.propTypes = {
  children: PropTypes.node,
};

export default WizardContainer;
