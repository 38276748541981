import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Box, Container, Grid, Stack, Paper, Tabs, Tab, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';

import { EmbedLookerChart } from '../EmbedLookerChart';
import AppHeader from '../AppHeader';
import AdvertiserContext from '../AdvertiserContext';
import { useAPI } from '../hooks/api';
import SankeyChart from '../visualizations/SankeyChart';
import { useLoader, useOrg, useUser } from "../hooks";
import AsyncButton from './../AsyncButton';
import { formatDateData } from '../util';
import { OrganizationRoles, Themes } from '../../constants';

const PREFIX = 'ReportsPage';

const classes = {
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
  dashboard: `${PREFIX}-dashboard`,
  select: `${PREFIX}-select`,
  tabs: `${PREFIX}-tabs`,
  endDate: `${PREFIX}-endDate`,
  dateFilterBtn: `${PREFIX}-dateFilterBtn`
};

const StyledAppHeader = styled(AppHeader)(({ theme }) => ({
  [`& .${classes.container}`]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(4),
    maxWidth: 'none',
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(5),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    minHeight: 1200,
  },

  [`& .${classes.dashboard}`]: {
    '& > iframe': {
      width: '100%',
      height: 1100,
    }
  },

  [`& .${classes.select}`]: {
    width: 200,
  },

  [`& .${classes.tabs}`]: {
    minWidth: 275,
    margin: '0 20px',

    ['& .MuiTypography-root']: {
      fontSize: '1.25rem',
    },

    ['&.Mui-selected .MuiTypography-root']: {
      fontWeight: 700,
    }
  },

  [`& .${classes.endDate}`]: {
    marginLeft: '10px',
  },

  [`& .${classes.dateFilterBtn}`]: {
    marginTop: '15px'
  }
}));

const EmbedLookerChartStyled = styled(EmbedLookerChart)(() => ({
  // TODO: Dynamically stretch iframe height to fit content
  height: 1100,
}));


const CampaignPerformanceDashboard = {

  LOOKER_V1: '',
  LOOKER_V2: 'CAMPAIGN_PERFORMANCE',
};

const CampaignPerformanceLookerDashboards = [
  CampaignPerformanceDashboard.LOOKER_V1,
  CampaignPerformanceDashboard.LOOKER_V2,
];

const IncrementalityDashboards = {
  [Themes.DEFAULT]: 'INCREMENTALITY',
  [Themes.NBCU]: 'NBCU_INCREMENTALITY',
};

const PathToPurchaseDashboards = {
  [Themes.DEFAULT]: 'P2P',
  [Themes.NBCU]: 'NBCU_P2P',
}

const ReportsPage = props => {
  const location = useLocation();
  const { user } = useUser();
  const { org } = useOrg();
  const adContext = useContext(AdvertiserContext);

  const { useGet } = useAPI();
  const enableView = (!!user && !!user.is_tvsci_employee) || (!!adContext && adContext.cost_model !== 'CPA');

  const [tab, setTab] = useState('campaignPerformance');
  const { isLoading, setIsLoading } = useLoader(false);
  const [dashboardData, setDashboardData] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(
   moment().subtract(30, 'days')
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    moment()
  );
  const [minStartDate] = useState(
    moment().subtract(90, 'days')
  );

  const showLookerDashboard = useMemo(
    () => adContext?.uses_looker && adContext?.looker_validated,
    [adContext?.uses_looker, adContext?.looker_validated],
  );

  const campaignPerformanceDashboard = useMemo(() => {
    if (adContext.theme === Themes.NBCU) {
      return CampaignPerformanceDashboard.LOOKER_V1;
    }

    if (adContext?.cost_model === 'CPA') {
      return CampaignPerformanceDashboard.LOOKER_V2;
    }

    if (adContext?.cost_model === 'CPM' && showLookerDashboard) {
      return CampaignPerformanceDashboard.LOOKER_V2;
    }
  }, [adContext.theme, adContext?.cost_model, showLookerDashboard]);

  const incrementalityDashboard = useMemo(
    () => IncrementalityDashboards[adContext.theme],
    [adContext.theme]
  );

  const pathToPurchaseDashboard = useMemo(
    () => PathToPurchaseDashboards[adContext.theme],
    [adContext.theme]
  );

  const tabs = useMemo(() => {
    const tabsList = [];

    if (
      adContext.theme === Themes.NBCU
      && adContext?.cost_model === 'CPM') {
      tabsList.push({
        id: 'campaignDeliveryNBCU',
        title: 'Campaign Delivery',
      });
      tabsList.push({
        id: 'campaignPerformanceNBCU',
        title: 'Campaign Performance',
      });
      if (adContext.path_to_purchase_validated) {
        tabsList.push({
          id: 'pathToPurchase',
          title: 'Path to Purchase',
        });
      }
    }

    if(
      adContext.theme === Themes.NBCU &&
      adContext?.cost_model === 'CPA'){
      if (
        org?.organization_role === OrganizationRoles.TENANT_ADMIN) {
        tabsList.push({
          id: 'cpaInternal',
          title: 'Campaign Performance',
        });
      } else{
        tabsList.push({
          id: 'cpaExternal',
          title: 'Campaign Performance',
        });
      }
    }

    if (adContext.theme === Themes.DEFAULT) {
      // TODO: Create scopes of permission instead of using theme and cost model
      if (
        adContext?.cost_model === 'CPM'
        && showLookerDashboard
      ) {
        tabsList.push({
          id: 'campaignDelivery',
          title: 'Campaign Delivery',
        });
      }

      tabsList.push({
        id: 'campaignPerformance',
        title: 'Campaign Performance',
      });

      // TODO: Show Daily Performance tab when ready
      // if (enableView) {
      //   tabsList.push({
      //     id: 'dailyPerformance',
      //     title: 'Daily Performance',
      //   });
      // }

      if (adContext.path_to_purchase_validated) {
        tabsList.push({
          id: 'pathToPurchase',
          title: 'Path to Purchase',
        });
      }

      // TODO: Create scopes of permission instead of using theme and cost model
      if (
        adContext?.cost_model === 'CPM'
        && showLookerDashboard
      ) {
        tabsList.push({
          id: 'inventoryAndGeo',
          title: 'Inventory & Geo',
        });
      }

      if (adContext.has_incrementality) {
        tabsList.push({
          id: 'campaignIncrementality',
          title: 'Campaign Incrementality',
        });
      }

      // TODO: Show Source Data tab when ready
      // if (enableView) {
      //   tabsList.push({
      //     id: 'sourceData',
      //     title: 'Source Data',
      //   });
      // }

      if (user?.is_tvsci_employee) {
        tabsList.push({
          id: 'beta',
          title: 'Beta',
        });
      }
    }

    return tabsList;
  }, [enableView, adContext, showLookerDashboard, user]);

  useEffect(() => {
    const id = tabs.length > 0 ? tabs[0].id : null;

    setTab(id);
  }, [adContext.id, tabs])

  useEffect(() => {
    if (
      adContext?.id
      && tab === 'pathToPurchase'
      && adContext.path_to_purchase_validated
    ) {
      fetchP2PData();
    }
  }, [adContext.id, tab, location]);

  const fetchP2PData = async () => {
    setIsLoading(true)
    const p2pUrl = `${process.env.REPORTING_API}/sankeylast30days`

    try {
      const response = await useGet(p2pUrl);

      if (response) {
        const parsed = await parseP2PData(response)
        setDashboardData(parsed)
        setIsLoading(false)
      }
      return response;
    } catch (err) {
      console.log('error loading path to purchase data', err);
      setIsLoading(false)
      return err;
    }
  };

  const parseP2PData = (data) => {
    const dataMap = {
      "links": [
        {
          "source": 0,
          "target": 1,
          "value": null
        },
        {
          "source": 0,
          "target": 2,
          "value": null
        },
        {
          "source": 0,
          "target": 3,
          "value": null
        },
        {
          "source": 0,
          "target": 4,
          "value": null
        },
        {
          "source": 1,
          "target": 5,
          "value": null
        },
        {
          "source": 2,
          "target": 6,
          "value": null
        },
        {
          "source": 3,
          "target": 7,
          "value": null
        },
        {
          "source": 4,
          "target": 8,
          "value": null
        },
        {
          "source": 5,
          "target": 9,
          "value": null
        },
        {
          "source": 6,
          "target": 9,
          "value": null
        },
        {
          "source": 7,
          "target": 9,
          "value": null
        },
        {
          "source": 8,
          "target": 9,
          "value": null
        }
      ],
      "nodes": [
        {
          "color": "#0fbf84",
          "name": "Impressions"
        },
        {
          "color": "#f27799",
          "name": "Direct/Organic"
        },
        {
          "color": "#5744b2",
          "name": "Social"
        },
        {
          "color": "#ffb837",
          "name": "Search"
        },
        {
          "color": "#727383",
          "name": "Other"
        },
        {
          "color": "#f27799",
          "name": "Direct/Organic Outcome"
        },
        {
          "color": "#5744b2",
          "name": "Social Outcome"
        },
        {
          "color": "#ffb837",
          "name": "Search Outcome"
        },
        {
          "color": "#727383",
          "name": "Other Outcome"
        },
        {
          "color": "#009061",
          "name": "Conversion"
        }
      ]
    }

    const values = []
    let results = {};

    data.forEach(arr => {
      arr.forEach(item => {
        if (typeof item === 'number') values.push(item)
      })

    })
    results = dataMap.links.map((link, i) => ({...link, value: values[i]}))

    dataMap.links = results;

    return dataMap;
  }

  const filterP2PData = async (startDate, endDate) => {
    const start = formatDateData(startDate).split('T')[0];
    const end = formatDateData(endDate).split('T')[0];
    const p2pUrl = `${process.env.REPORTING_API}/sankey?startDate=${start}&endDate=${end}`
    try {
      const response = await useGet(p2pUrl);

      if (response) {
        const parsed = await parseP2PData(response)
        setDashboardData(parsed)
        setIsLoading(false)
      }
      return response;
    } catch (err) {
      console.log('error loading path to purchase data', err);
      setIsLoading(false)
      return err;
    }
  }

  const handleUpdateStartDate = date => {
    if (!date) {
      date = '';
    }
    setSelectedStartDate(date);
  };

  const handleUpdateEndDate = date => {
    if (!date) {
      date = '';
    }
    setSelectedEndDate(date);
  };

  const renderPathToPurchase = () => (
    <>
      <Box mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mt={2} mb={1}>
              <EmbedLookerChartStyled
                dashboard={pathToPurchaseDashboard}
                withAuth={false}
              />
            </Box>

            <Box width="100%">
              <Grid container item xs={12} justifyContent="space-evenly" spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4}>
                      <DatePicker
                        color="secondary"
                        disableFuture
                        disableToolbar
                        fullWidth
                        inputFormat='MM/dd/yyyy'
                        inputVariant='outlined'
                        label="Start Date"
                        data-testid="start-date-picker"
                        id='date-picker-inline'
                        margin='normal'
                        minDate={minStartDate}
                        maxDate={selectedEndDate}
                        onChange={handleUpdateStartDate}
                        value={selectedStartDate}
                        variant='inline'
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <DatePicker
                        className={classes.endDate}
                        color="secondary"
                        disableFuture
                        disableToolbar
                        fullWidth
                        inputFormat='MM/dd/yyyy'
                        inputVariant='outlined'
                        label="End Date"
                        data-testid="end-date-picker"
                        id='date-picker-inline'
                        margin='normal'
                        minDate={selectedStartDate}
                        onChange={handleUpdateEndDate}
                        value={selectedEndDate}
                        variant='inline'
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <AsyncButton
                        id="date-filter"
                        isLoading={isLoading}
                        variant="contained"
                        color="secondary"
                        size="medium"
                        className={clsx('Button--medium', classes.dateFilterBtn)}
                        onClick={() => {
                          setIsLoading(true);
                          filterP2PData(selectedStartDate, selectedEndDate);
                        }}
                        loadingButton="Loading..."
                      >
                        Filter
                      </AsyncButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {!isLoading && dashboardData ? (
                <SankeyChart data={dashboardData} />
                ) : (
                  <p>loading...</p>
                )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  return (
    <StyledAppHeader history={props.history}>
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              className={clsx(classes.paper, 'background-img-inline-example')}
            >
              {adContext && (
                <Box my={4}>
                  <Tabs
                    value={tab}
                    onChange={(e, v) => setTab(v)}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {tabs.map(tab => (
                      <Tab
                        disableRipple
                        className={classes.tabs}
                        key={tab.id}
                        value={tab.id}
                        label={
                          <Typography>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              {tab.title}
                            </Box>
                          </Typography>
                        }
                      />
                    ))}
                  </Tabs>
                </Box>
              )}

              {adContext.theme === Themes.NBCU && (
                <Box>
                  {tab === 'campaignDeliveryNBCU' && (
                    <EmbedLookerChartStyled dashboard="NBCU_CAMPAIGN_DELIVERY" />
                  )}
                  {tab === 'campaignPerformanceNBCU' && (
                    <EmbedLookerChartStyled dashboard="NBCU_CAMPAIGN_PERFORMANCE" />
                  )}

                  {tab === 'pathToPurchase' && (
                    <>
                      {showLookerDashboard &&
                      adContext.path_to_purchase_validated ?
                        renderPathToPurchase() :
                        <EmbedLookerChartStyled
                          dashboard={
                            adContext.has_incrementality
                              ? incrementalityDashboard
                              : 'DATA_TABLE'
                          }
                        />
                      }
                    </>
                  )}
                  {tab === 'cpaInternal' && (
                    <EmbedLookerChartStyled dashboard="CPA_INTERNAL_NBCU" />
                  )}

                  {tab === 'cpaExternal' && (
                    <EmbedLookerChartStyled dashboard="CPA_EXTERNAL_NBCU" />
                  )}
                </Box>
              )}


              {adContext.theme === Themes.DEFAULT && (
                <Box>
                {tab === 'campaignDelivery' && (
                    <EmbedLookerChartStyled dashboard="CAMPAIGN_DELIVERY" />
                )}

                {tab === 'campaignPerformance'
                  && CampaignPerformanceLookerDashboards.includes(campaignPerformanceDashboard)
                  && (
                    <EmbedLookerChartStyled dashboard={campaignPerformanceDashboard} />
                  )
                }

                {tab === 'dailyPerformance' && showLookerDashboard && (
                  <EmbedLookerChartStyled dashboard="DAILY_PERFORMANCE" />
                )}

                {tab === 'pathToPurchase' && (
                  <>
                    {showLookerDashboard &&
                    adContext.path_to_purchase_validated ?
                      renderPathToPurchase() :
                      <EmbedLookerChartStyled
                        dashboard={
                          adContext.has_incrementality
                            ? incrementalityDashboard
                            : 'DATA_TABLE'
                        }
                      />
                    }
                  </>
                )}

                {tab === 'inventoryAndGeo' && (
                    <EmbedLookerChartStyled dashboard="INVENTORY_GEO" />
                  )
                }

                {tab === 'campaignIncrementality' && (
                  <EmbedLookerChartStyled
                    dashboard={
                      showLookerDashboard &&
                      adContext.path_to_purchase_validated &&
                      adContext.has_incrementality
                        ? incrementalityDashboard
                        : 'DATA_TABLE'
                    }
                  />
                )}

                {
                  tab === 'sourceData'
                    && showLookerDashboard
                    && adContext.path_to_purchase_validated
                    && adContext.has_incrementality
                    && (
                    <EmbedLookerChartStyled dashboard="DATA_TABLE" />
                  )
                }

                {tab === 'beta' && (
                  <Stack direction="column" spacing={2}>
                    <Typography variant="body2">
                      This screen is displaying reports that are tested internally while being considered for wider release. Please share with customers, and collect their feedback.
                    </Typography>

                    <EmbedLookerChartStyled dashboard="INTERNAL_EXPERIMENTAL" />
                  </Stack>
                )}
              </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </StyledAppHeader>
  );
};

ReportsPage.propTypes = {
  history: PropTypes.object,
};

export default ReportsPage;
