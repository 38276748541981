import React from 'react';
import {
  DividerStyled,
  DividerTypographyStyled,
  LinkStyled,
  SectionBoxStyled,
  SectionInnerBoxStyled,
} from './styles';
import { Avatar, Button, Stack, styled } from '@mui/material';
import { faArrowUpRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import { useCreative } from './hooks/useCreative';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { VideoPreview } from './AdvancedSubflows/AdvancedCreative';
import {
  SectionInfo,
  sectionInfoType,
} from '../../../../components/SectionInfo';

const CreativeButtonStyled = styled(Button, {
  shouldForwardProp: prop => prop !== 'isDisplay',
})(({ isDisplay }) => ({
  width: isDisplay ? '280px' : '213px',
}));

const VideoPreviewStyled = styled(VideoPreview)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(8.375),
  borderRadius: 0,
}));

const ImagePreviewStyled = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(8.375),
  borderRadius: 0,
}));

export const Creative = ({ onAdvanced, info, isDisplay, ...props }) => {
  const { creatives, showMore, setShowMore } = useCreative();

  return (
    <SectionBoxStyled {...props}>
      <DividerStyled textAlign="left">
        <DividerTypographyStyled>Creative</DividerTypographyStyled>
      </DividerStyled>
      <SectionInnerBoxStyled>
        <SectionInfo infoBlocks={info} />
        {creatives.length ? (
          <Stack spacing={3}>
            <Stack spacing={2} direction="row">
              {creatives
                .slice(0, showMore ? creatives.length : 3)
                .map(c =>
                  c.video_asset ? (
                    <VideoPreviewStyled key={c.id} src={c.preview_url} />
                  ) : (
                    <ImagePreviewStyled
                      key={c.id}
                      variant="square"
                      src={c.preview_url}
                    />
                  ),
                )}
              {creatives.length > 3 && !showMore && (
                <LinkStyled onClick={() => setShowMore(true)}>
                  +{creatives.length - 3} more
                </LinkStyled>
              )}
            </Stack>
            <LinkStyled onClick={onAdvanced}>
              <FontAwesomeIcon icon={faPencil} /> Edit Creative
            </LinkStyled>
          </Stack>
        ) : (
          <CreativeButtonStyled
            onClick={onAdvanced}
            endIcon={<FontAwesomeIcon icon={faArrowUpRight} />}
            variant="outlined"
            color="primary"
            isDisplay={isDisplay}
          >
            {isDisplay ? 'Add Display Creative' : 'Add Creative'}
          </CreativeButtonStyled>
        )}
      </SectionInnerBoxStyled>
    </SectionBoxStyled>
  );
};

Creative.propTypes = {
  ...SectionBoxStyled.propTypes,
  onAdvanced: PropTypes.func,
  info: PropTypes.arrayOf(sectionInfoType),
  isDisplay: PropTypes.bool,
};
