import { creativeTabs } from '../../constants';
import { useCreativeContext } from '../../hooks/useCreativeContext';

export const useList = ({ files, onRemove, onCancel }) => {
  const { setTab } = useCreativeContext();
  const handleCancel = idx => () => {
    onCancel(idx);
  };

  const handleRemove = idx => () => {
    onRemove(idx);
  };

  const handleToLibrary = () => {
    setTab({
      key: creativeTabs[0].value,
    });
  };

  return {
    controlledFields: files,
    uploaded: files.every(field => field.progress === 100),
    toLibrary: handleToLibrary,
    remove: handleRemove,
    cancel: handleCancel,
  };
};
