import { useContext, useState } from 'react';

import { useAuth } from './auth';
import { useAPI } from './api';
import { useDimensions } from './dimensions';
import AdvertiserContext from '../AdvertiserContext';
import apiInstance from '../../connection/apiClient';

const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
// Max file size limit is 500 mb
const fileMax =  500 * 1024 * 1024

export const formatFileName = (file) =>
  file && file.trim().replace(/[^a-z0-9.]/gi, '_');

const formatUploadSize = (num) => {
  const exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1024)),
    units.length - 1
  );
  const size = (num / Math.pow(1024, exponent)).toFixed(2) * 1;
  const unit = units[exponent];

  return `${size} ${unit}`;
};

export const useUpload = (props) => {
  const {
    uploadMetadataState = useState([]),
  } = props || {};
  const [uploadMetadata, setUploadMetadata] = uploadMetadataState;

  const adContext = useContext(AdvertiserContext);
  const { authState } = useAuth();
  const { useDelete } = useAPI();
  const { dimensions } = useDimensions();

  const [isUploading, setIsUploading] = useState(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [progressBars, setProgressBars] = useState({});
  const [uploadError, setUploadError] = useState(null);

  const handleCheckDimensions = (width, height) =>
    dimensions.some(
      d => d.creative_width === width && d.creative_height === height
    );

  const fetchDimensions = image => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = reject;

      const reader = new FileReader();

      reader.onloadend = function (ended) {
        img.src = ended.target.result;
      }

      reader.readAsDataURL(image);
    });
  };

  // Decorate progress function and set progress values
  const handleProgressUpload = (fileName) => (progressEvent) => {
    const total = progressEvent.lengthComputable
      ? progressEvent.total
      : progressEvent.target
        ? progressEvent.target.getResponseHeader('content-length') ||
        progressEvent.target.getResponseHeader('x-decompressed-content-length')
        : null;

    if (total !== null) {
      setProgressBars((prev) => ({
        ...prev,
        [fileName]: Math.round((progressEvent.loaded * 100) / total),
      }));
    }
  };

  const deleteFile = (url) =>
    useDelete(url)
      .then((response) => {
        console.log('response from delete file', response);
        return response;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });

  // TODO: Abstract upload logic into single function
  // that dynamically reads file type of upload endpoint
  const uploadDisplay = async files => {
    const nextUploadMetadata = [...uploadMetadata];

    const invalidFilesErrors = [];
    const filesToUpload = [];

    const dimensions = await Promise.all(files.map(file => fetchDimensions(file)));

    for (const [index, file] of files.entries()) {
      const name = file.name || file.fileName || file.path || '';

      if (file.size && file.size > fileMax) {
        invalidFilesErrors.push(`File ${name} size cannot exceed 100 MB`);
        continue;
      }

      const { width, height } = dimensions[index];
      const isDimensionValid = handleCheckDimensions(width, height);

      if (!isDimensionValid) {
        invalidFilesErrors.push(`${name} has invalid width x height combination: ${width}x${height}. If you are uploading other files, do not leave this page.`);
        continue;
      }

      filesToUpload.push(file);
    }

    if (invalidFilesErrors.length > 0)
      setUploadError(invalidFilesErrors);

    setIsUploadSuccess(false);

    const uploadPromises = filesToUpload.map((file) => {
      const uploadOptions = {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${authState.accessToken}`,
          'Content-Type': 'multipart/form-data',
          'X-TVS-AdvertiserContext': adContext.id,
        },
        onUploadProgress: handleProgressUpload(formatFileName(file.name)),
      };

      const currentTime = Date.now();
      const fileName = formatFileName(file.name);
      const newKey = `metadata-${uploadMetadata.length}${currentTime}`;
      const newDisplay = {
        key: newKey,
        title: file.name,
        fileName: file.name,
        fileSize: formatUploadSize(file.size),
        resolution: 'Calculating...',
        uploadable: true,
      };

      setIsUploading(true);

      setProgressBars(prev => ({
        ...prev,
        [fileName]: 100,
      }));

      nextUploadMetadata.push(newDisplay);

      const formData = new FormData();

      formData.append('file', file);
      formData.append('name', fileName);
      formData.append('advertiser', adContext.url);
      formData.append('media_type', file.type);
      formData.append('active', true);

      return apiInstance.post('/image_assets/', formData, uploadOptions);
    });

    setUploadMetadata(nextUploadMetadata);

    return Promise.all(uploadPromises)
      .then(responses => {
        const nextResponses = responses.filter(r => r !== null);
        console.log('responses', nextResponses);
        setIsUploadSuccess(true);

        return nextResponses;
      })
      .catch(error => {
        console.log('Error in uploadDisplay', error);

        return error;
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const uploadCreative = (files) => {
    setIsUploadSuccess(false);

    const nextUploadMetadata = [...uploadMetadata];

    const uploadPromises = files.map((file) => {
      // Stop upload if file size exceed 500mb
      if (file.size && file.size > fileMax) {
        setUploadError(`File size cannot exceed 500 MB`);
        return;
      }

      const uploadOptions = {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${authState.accessToken}`,
          'Content-Type': 'multipart/form-data',
          'X-TVS-AdvertiserContext': adContext.id,
        },
        onUploadProgress: handleProgressUpload(formatFileName(file.name)),
      };

      // TODO use FileReader API to calculate duration

      const currentTime = Date.now();
      const fileName = formatFileName(file.name);
      const newKey = `metadata-${uploadMetadata.length}${currentTime}`;
      const newCreative = {
        key: newKey,
        title: file.name,
        fileName: file.name,
        fileSize: formatUploadSize(file.size),
        weighting: 100,
        duration: null,
        resolution: 'Calculating...',
        uploadable: true,
      };

      setIsUploading(true);

      setProgressBars((prev) => ({
        ...prev,
        [fileName]: 100,
      }));

      nextUploadMetadata.push(newCreative);

      const formData = new FormData();

      formData.append('file', file);
      formData.append('name', fileName);
      formData.append('advertiser', adContext.url);
      formData.append('media_type', file.type);

      return apiInstance.post('/video_assets/', formData, uploadOptions);
    });

    setUploadMetadata(nextUploadMetadata);

    return Promise.all(uploadPromises)
      .then((responses) => {
        setIsUploadSuccess(true);

        return responses;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return {
    formatFileName,
    isUploading,
    setIsUploading,
    isUploadSuccess,
    setIsUploadSuccess,
    progressBars,
    setProgressBars,
    deleteFile,
    uploadMetadata,
    uploadError,
    setUploadError,
    setUploadMetadata,
    uploadCreative,
    uploadDisplay,
  };
};
