import { AddCircleOutline, SearchOutlined } from '@mui/icons-material';
import { InputAdornment, Stack, styled } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';
import {
  ActionButtonStyled,
  AdvancedHeaderBoxStyled,
  TextFieldStyled,
} from '../styles';
import { optionalOptionType } from '../types';

const SearchFieldStyled = styled(TextFieldStyled)(({ theme }) => ({
  height: '34px',
  background: theme.palette.common.white,
  width: '330px',

  '& .MuiInputBase-root': {
    height: '34px',

    '& .MuiInputBase-input': {
      fontSize: '0.75rem',
    },
  },
}));

export const TableLeftHeader = ({
  data = [],
  onFilter,
  onAddAll,
  onAddAllHovered,
}) => {
  return (
    <AdvancedHeaderBoxStyled>
      <Stack flex={1}>
        {onFilter && (
          <SearchFieldStyled
            size="small"
            placeholder="Search by name or ID..."
            onInput={e => {
              onFilter(e.target.value);
            }}
            InputProps={{
              'data-testid': 'advanced-filter-input',
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        )}
      </Stack>
      <ActionButtonStyled
        disabled={!data.length}
        actionType="add"
        onClick={onAddAll}
        variant="text"
        color="primary"
        data-testid="advanced-add-all"
        onMouseEnter={() => onAddAllHovered?.(true)}
        onMouseLeave={() => onAddAllHovered?.(false)}
      >
        <AddCircleOutline />
        <span>Add all</span>
      </ActionButtonStyled>
    </AdvancedHeaderBoxStyled>
  );
};

TableLeftHeader.propTypes = {
  data: PropTypes.arrayOf(optionalOptionType),
  onFilter: PropTypes.func,
  onAddAll: PropTypes.func,
  onAddAllHovered: PropTypes.func,
};
