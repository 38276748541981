import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useUser } from '../components/hooks/';
import BWSyncStatus from '../components/BWSync';

const BWSyncContext = React.createContext(null);

BWSyncContext.displayName = 'BWSyncContext';

export const BWSyncContextProvider = ({ children }) => {
  const { user } = useUser();
  const [bwSync, setBWSync] = useState({});

  const showBWSync = !!user?.is_tvsci_employee;

  const renderBWSyncStatus = (type, key, isDisabled) => {
    const value = bwSync[type]?.[key]?.['value'];

    return value ? (
      <BWSyncStatus bwStatus={value.inSync} diff={value.outOfSync} isDisabled={isDisabled}/>
    ) : '-';
  };

  return (
    <BWSyncContext.Provider
      value={{ setBWSync, bwSync, showBWSync, renderBWSyncStatus }}
    >
      {children}
    </BWSyncContext.Provider>
  );
};

BWSyncContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BWSyncContext;
