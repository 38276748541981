import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  styled,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faCircleCheck,
  faCircleDashed,
} from '@fortawesome/pro-light-svg-icons';
import { faCircleArrowRight } from '@fortawesome/pro-solid-svg-icons';

import LaunchNode from './LaunchNode';

const iconList = {
  complete: faCircleCheck,
  add: faPlusCircle,
  pending: faCircleDashed,
  active: faCircleArrowRight,
};

const SubwayNode = ({
  label,
  status,
  handleClick,
  ...props
}) => {
  const StyledButton = styled(Button)(({ theme }) => ({
    ...theme.typography.body1,
    padding: 0,
    color: status === 'active' ? theme.palette.common.black : theme.palette.grey[4],
    justifyContent: 'flex-start',
    '&.Mui-disabled': {
      color: status === 'active' ? theme.palette.common.black : theme.palette.grey[4],
    },
    '&:hover': {
      color: status === 'add' ? theme.palette.blue[6] : '',
      '& .MuiButton-startIcon': {
        color: status === 'add' ? theme.palette.blue[6] : '',
      },
    },
    '& .MuiButton-startIcon': {
      marginLeft: 0,
      color: status === 'active' ? theme.palette.blue[6] : theme.palette.grey[4],
    },
  }));

  if (status === 'add') {
    return (
      <StyledButton
        startIcon={<FontAwesomeIcon icon={iconList[status]} />}
        disableRipple
        onClick={handleClick}
        {...props}
      >
        {label}
      </StyledButton>
    );
  } return (
    label !== 'Launch Campaign' ? (
      <StyledButton
        startIcon={<FontAwesomeIcon icon={iconList[status]} />}
        disabled
        disableRipple
        {...props}
      >
        {label}
      </StyledButton>
    ) : (
      <LaunchNode status={status} disabled />
    )
  );
}

SubwayNode.propTypes = {
  label: PropTypes.string,
  status: PropTypes.oneOf(['complete', 'add', 'pending', 'active']).isRequired,
  handleClick: PropTypes.func,
};

export default SubwayNode;
