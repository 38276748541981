import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useCreativeUploadContext } from '../../hooks/useCreativeUploadContext';

export const useManageListElement = ({ field, onUpdate }) => {
  const prevFieldsData = useRef();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { getAdditionalCreativeInfo } = useCreativeUploadContext();

  const isImage = field?.type === 'display';
  const isVideo = !isImage;

  const { id } = field;

  useEffect(() => {
    prevFieldsData.current = field;
  }, [field]);

  useEffect(() => {
    const { weighting: savedWeighting } = prevFieldsData.current;

    getAdditionalCreativeInfo?.({ id }).then(
      ({ id: creative_id, lineitem, weighting } = {}) => {
        onUpdate({
          creative_id,
          weighting: weighting ?? savedWeighting,
          lineitem,
        });
      },
    );
  }, [id]);

  return {
    isImage,
    isVideo,
    field,
    control,
    errors,
  };
};
