import { useEffect, useMemo } from 'react';
import { useCurrentSession } from '../currentSession';
import useSWRInfinite from 'swr/infinite';
import { flatMap } from 'lodash';

export const useLoadAll = (url, fetcher) => {
  const { apiIsReady } = useCurrentSession();

  const {
    data = [],
    error,
    isLoading,
    setSize,
  } = useSWRInfinite(
    (index, previousPageData) => {
      if (!apiIsReady || (previousPageData && !previousPageData.next))
        return null;

      if (!index)
        return {
          url,
        };

      return {
        url,
        params: {
          page: index + 1,
        },
      };
    },
    ({ url, params }) => fetcher(url, params).then(res => res.data),
  );

  useEffect(() => {
    const lastPage = data[data.length - 1];
    if (lastPage && lastPage.next) {
      setSize(prev => prev + 1);
    }
  }, [data]);

  const results = useMemo(
    () => flatMap(data.map(page => page?.results)),
    [data],
  );

  return {
    items: results,
    data,
    error,
    isLoading,
  };
};
