import { useState } from 'react';

// Ecommerce Platforms
const ecommerceSites = [
  'None',
  'Big Commerce',
  'Magento',
  'Salesforce',
  'SAP',
  'Shopify',
  'Squarespace',
  'Woo Commerce',
  'Wordpress',
  'Custom',
];

// Base tracking events
export const trackingEvents = [
  { name: 'Lead generated', slug: 'lead_generated', value: '', window: 30 },
  { name: 'Keyword search', slug: 'keyword_search', value: '', window: 30 },
  { name: 'Viewed product', slug: 'viewed_product', value: '', window: 30 },
  { name: 'Add to cart', slug: 'add_to_cart', value: '', window: 30 },
  { name: 'Start checkout', slug: 'start_checkout', value: '', window: 30 },
  { name: 'Complete purchase', slug: 'complete_purchase', value: '', window: 30 },
  { name: 'Complete registration', slug: 'complete_registration', value: '', window: 30 },
  { name: 'Trial Started', slug: 'trial_started', value: '', window: 30 },
];

export const useTracking = (method = '') => {
  const [ecommerce, setEcommerce] = useState('');
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [trackingMethod, setTrackingMethod] = useState(method);
  const [totalEvents, setTotalEvents] = useState(trackingEvents);

  const handleEventSlug = (slug, name) => {
    setSelectedEvents(prev => prev.map(s => {
      if (s.name !== name) {
        return s;
      }

      return {
        ...s,
        slug,
      }
    }));
  };

  const handleEventValue = (value, name) => {
    setSelectedEvents(prev => prev.map(s => {
      if (s.name !== name) {
        return s;
      }

      return {
        ...s,
        value,
      }
    }));
  };

  const handleSelectEvent = tracking => {
    if (selectedEvents.find(e => e.name === tracking.name)) {
      setSelectedEvents(prev => prev.filter(p => p.name !== tracking.name));

      if (!trackingEvents.find(t => t.name === tracking.name)) {
        setTotalEvents(prev => prev.filter(p => p.name !== tracking.name));
      }
    } else {
      setSelectedEvents(prev => [...prev, tracking]);
    }
  };

  return {
    ecommerce,
    ecommerceSites,
    handleEventSlug,
    handleEventValue,
    handleSelectEvent,
    selectedEvents,
    setEcommerce,
    setSelectedEvents,
    setTrackingMethod,
    setTotalEvents,
    totalEvents,
    trackingEvents,
    trackingMethod,
  };
};
