import React from 'react';
import { useList } from './hooks/useList';
import {
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { useListElement } from './hooks/useListElement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/pro-solid-svg-icons';
import {
  formatBytes,
  formatDuration,
} from '@v2/components/campaign/FileUploads';
import {
  faPlayCircle,
  faPlusCircle,
  faTrashXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { LinkStyled } from '../../../styles';

const FileInfoStack = styled(Stack)({
  width: '100%',
});

const TitleStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
}));

const PlayCircleIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[5],
}));

const RemoveIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(1.75),
  height: theme.spacing(2),
}));

const ListElement = ({ field, progress, onRemove, onCancel }) => {
  const { isComplete, cancelUploading } = useListElement({
    field,
    progress,
  });

  return (
    <FileInfoStack direction="column" spacing={2.5}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <TitleStyled variant="body2">
          <PlayCircleIconStyled icon={faPlayCircle} size="1x" /> {field.name}
        </TitleStyled>
        <Stack
          direction="row"
          justifyContent="center"
          spacing={3}
          alignItems="center"
        >
          <Typography variant="caption" color={'grey.7'}>
            {isComplete
              ? `${formatBytes(field.size)} • ${formatDuration(field.duration)} • 1080P`
              : 'Uploading...'}
          </Typography>
          {isComplete && (
            <IconButton onClick={onRemove} size="small">
              <RemoveIconStyled icon={faTrashXmark} />
            </IconButton>
          )}

          {!isComplete && (
            <IconButton onClick={onCancel} size="small">
              <RemoveIconStyled onClick={cancelUploading} icon={faRemove} />
            </IconButton>
          )}
        </Stack>
      </Stack>
      {!isComplete && (
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ backgroundColor: 'grey.11', width: '100%' }}
        />
      )}
    </FileInfoStack>
  );
};

ListElement.propTypes = {
  field: PropTypes.object.isRequired,
  progress: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export const List = ({ files, onRemove, onCancel }) => {
  const { controlledFields, uploaded, remove, cancel, toLibrary } = useList({
    files,
    onRemove,
    onCancel,
  });

  return (
    <Stack spacing={5.5} flex={1}>
      {controlledFields.map((field, idx) => (
        <ListElement
          onRemove={remove(field.id)}
          onCancel={cancel(field.id)}
          key={field.id}
          field={field}
          progress={field.progress}
          index={idx}
        />
      ))}
      {uploaded && (
        <LinkStyled onClick={toLibrary} underline="hover">
          <FontAwesomeIcon icon={faPlusCircle} /> Add Creative
        </LinkStyled>
      )}
    </Stack>
  );
};

List.propTypes = {
  files: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
