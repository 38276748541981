import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Switch } from '../Switch';
import {
  AdvancedElementRowStyled,
  AdvancedTableStyled,
  FirstCellStyled,
  LastCellStyled,
} from '../styles';
import { optionalOptionType, tableSettings } from '../types';
import { RemoveCircleOutline } from '@mui/icons-material';

export const TableRight = ({
  data = [],
  onChange,
  onUpdate,
  renderCell,
  isTargeted,
  placeholderCell,
  settings,
}) => {
  return (
    <TableContainer>
      <AdvancedTableStyled>
        <TableHead>
          <TableRow>
            <FirstCellStyled>Selected</FirstCellStyled>
            {settings.includable && <TableCell>Incl/Excl</TableCell>}
            <LastCellStyled></LastCellStyled>
          </TableRow>
        </TableHead>
        <TableBody data-testid="advanced-right-table-body">
          {data.map((v, i) => (
            <AdvancedElementRowStyled
              rowType="remove"
              className={v ? 'hover-row' : ''}
              targeted={v && isTargeted}
              onClick={() => {
                if (v) {
                  onChange(v);
                }
              }}
              highlighted={i % 2}
              key={v?.id ?? `placeholder-${i}`}
            >
              <FirstCellStyled>
                {v ? renderCell(v) : placeholderCell}
              </FirstCellStyled>

              {settings.includable && (
                <TableCell
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  {!!v && (
                    <Switch
                      disabled={!settings.separateInclExcl}
                      onChange={e => {
                        onUpdate({ ...v, included: e.target.checked });
                      }}
                      checked={v.included}
                    />
                  )}
                </TableCell>
              )}
              <TableCell
                sx={{
                  overflow: 'hidden',
                }}
              >
                <RemoveCircleOutline
                  className="hover-icon"
                  color="primary"
                  sx={{
                    maxWidth: '100%',
                    display: !v || !isTargeted ? 'none' : 'block',
                    opacity: '33%',
                  }}
                />
              </TableCell>
            </AdvancedElementRowStyled>
          ))}
        </TableBody>
      </AdvancedTableStyled>
    </TableContainer>
  );
};

TableRight.propTypes = {
  data: PropTypes.arrayOf(optionalOptionType),
  onChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isTargeted: PropTypes.bool,
  renderCell: PropTypes.func.isRequired,
  placeholderCell: PropTypes.node,
  settings: tableSettings,
};
