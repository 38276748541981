import moment from 'moment-timezone';

export const validateAcceptPlatformInvite = platformInvite => {
  // Don't allow an accepted invite
  if (platformInvite?.status !== 'INVITED') {
    throw new Error('Invite in incorrect status');
  }

  // If it is expired, throw an error
  if(platformInvite?.expiry && moment(platformInvite.expiry).isBefore(new Date())) {
    throw new Error('Invite is expired');
  }
};
