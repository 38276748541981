import React from 'react';
import PropTypes from 'prop-types';

import { useLibrary } from './hooks/useLibrary';
import { AdvancedBox } from '../../../AdvancedBox';
import {
  Button,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { CancelButtonStyled, LinkStyled } from '../../../styles';
import { Controller } from 'react-hook-form';
import { fields, TABS } from '../constants';
import { Edit } from '../Upload/Edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { TableRowGeneral } from './TableRowGeneral';
import clsx from 'clsx';
import { tableOptions } from './constants';

const SearchFieldStyled = styled(TextField)(({ theme }) => ({
  width: theme.spacing(43.75),
  height: theme.spacing(4.5),

  '.MuiInputBase-root': {
    height: theme.spacing(4.5),

    'legend span': {
      display: 'none',
    },

    '&.Mui-focused legend span': {
      display: 'inline-block',
    },
  },
  '.MuiInputBase-input': {
    paddingLeft: theme.spacing(1),
  },
  '.MuiInputAdornment-root': {
    marginLeft: 0,
  },
  '.MuiInputLabel-root:not(.Mui-focused):not(.MuiFormLabel-filled)': {
    transform: `translate(45px,${parseInt(theme.spacing(4.5), 10) / 4}px)`,
  },
  '.MuiInputBase-input, .MuiInputLabel-root:not(.Mui-focused):not(.MuiFormLabel-filled)':
    {
      fontSize: theme.typography.caption.fontSize,
    },
}));

const TableStyled = styled(Table)(({ theme }) => ({
  // tableLayout: 'fixed',
  // width: '100%',
  '.sticky-left': {
    left: 0,
  },
  '.sticky-right': {
    right: 0,
  },
  thead: {
    zIndex: 4,
    tr: {
      backgroundColor: theme.palette.common.white,
      position: 'sticky',
      '.sticky': {
        backgroundColor: theme.palette.common.white,
        zIndex: 3,
      },
      top: 0,
      zIndex: 4,
    },
  },
  'tbody tr': {
    backgroundColor: theme.palette.common.white,
    '.sticky': {
      zIndex: 3,
      backgroundColor: theme.palette.common.white,
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[12],
      '.sticky': {
        backgroundColor: theme.palette.grey[12],
      },
    },
    td: {
      wordBreak: 'break-word',
      p: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
      },
    },
  },
  'tbody, thead': {
    '.sticky': {
      position: 'sticky',
    },
  },
}));

export const Library = ({ onClose, onSubmit, isDisplay, info, ...props }) => {
  const {
    library,
    columns,
    setTab,
    sort,
    drop,
    order,
    orderBy,
    creatives,
    cancel,
    showDropzone,
    control,
    search,
  } = useLibrary({ onClose });

  return (
    <AdvancedBox
      {...props}
      info={info.info}
      onClose={onClose}
      header={
        <Stack direction="row" alignItems="center">
          <Stack flex={1}>
            <Typography variant="h4">
              Step 1:{' '}
              {info.select}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={4}>
            {!showDropzone && (
              <Controller
                control={control}
                name={fields.search.path}
                render={({ field }) => (
                  <SearchFieldStyled
                    {...field}
                    label={isDisplay ? 'Search displays' : 'Search videos'}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          <SearchOutlined
                            sx={{
                              width: '2',
                              height: '2',
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            )}
            <LinkStyled
              underline="hover"
              onClick={() => setTab(TABS.UPLOAD_ASSET)}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              {info.upload}
            </LinkStyled>
            {!isDisplay && (
              <LinkStyled
                underline="hover"
                onClick={() => setTab(TABS.VAST_TAG)}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Add by VAST Tag
              </LinkStyled>
            )}
          </Stack>
        </Stack>
      }
    >
      <Stack flex={1} spacing={5}>
        <Stack
          flex={1}
          sx={{
            overflowX: 'scroll',
            overflowY: 'scroll',
            maxHeight: 'calc(100vh - 300px)',
            position: 'relative',
          }}
          spacing={4.25}
        >
          <TableStyled>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: 0,
                  }}
                />
                {columns.map(column => (
                  <TableCell
                    className={clsx({
                      sticky:
                        tableOptions.sticky.left.includes(column.id) ||
                        tableOptions.sticky.right.includes(column.id),
                      'sticky-left': tableOptions.sticky.left.includes(
                        column.id,
                      ),
                      'sticky-right': tableOptions.sticky.right.includes(
                        column.id,
                      ),
                    })}
                    sx={column.headerSx}
                    key={column.id}
                  >
                    {tableOptions.sortable.includes(column.id) ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={e => {
                          sort(e, column.id);
                        }}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {library.map(row => (
                <TableRowGeneral
                  key={row.id}
                  item={row}
                  columns={columns}
                  search={search}
                />
              ))}
            </TableBody>
          </TableStyled>
          {!showDropzone && search && !library?.length && (
            <Stack flex={1} alignItems="center" justifyContent="center">
              <Typography variant="body2">
                Your search - <b>{search}</b> - did not match any results
              </Typography>
            </Stack>
          )}
          {showDropzone && (
            <Edit flex={1} isDisplay={isDisplay} onDrop={drop}>
              <Typography variant="body2">No creatives in library</Typography>
            </Edit>
          )}
        </Stack>
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <CancelButtonStyled variant="text" onClick={cancel}>
                Cancel
              </CancelButtonStyled>
            </Stack>
            <Button
              disabled={!creatives?.length}
              onClick={onSubmit}
              variant="contained"
            >
              Next: Manage Creative
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </AdvancedBox>
  );
};

Library.propTypes = {
  ...AdvancedBox.propTypes,
  isDisplay: PropTypes.bool,
  info: PropTypes.object,
};
