import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  styled,
} from '@mui/material';

const StickyBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(14),
}));

const VerticalTimeline = ({ children }) => {
  return (
    <Grid item xs={3}>
      <StickyBox>
        {children}
      </StickyBox>
    </Grid>
  );
};

VerticalTimeline.propTypes = {
  children: PropTypes.node,
};

export default VerticalTimeline;
