import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useWizardNavigation } from '../../../../components/hooks';

import WizardSectionContents from './WizardSectionContents';
import WizardSectionSummary from './WizardSectionSummary';
import WizardSectionContainer from './WizardSectionContainer';
import WizardSectionDetails from './WizardSectionDetails';

function WizardSection({ panes, sectionKey, title, icon, ...rest }) {
  const { isActive, registerSection, hideSection, jumpTo } =
    useWizardNavigation();
  const sectionRef = useRef(null);

  useEffect(() => {
    return registerSection(sectionKey, sectionRef, { title });
  }, [sectionKey, registerSection, sectionRef]);

  const sectionToggle = sectionKey => {
    if (isActive(sectionKey)) {
      hideSection();
    } else {
      jumpTo(sectionKey);
    }
  };

  return (
    <WizardSectionContainer
      isActiveSection={isActive(sectionKey)}
      ref={sectionRef}
      toggleSection={() => sectionToggle(sectionKey)}
      {...rest}
    >
      <WizardSectionSummary
        title={title}
        isActiveSection={isActive(sectionKey)}
        icon={icon}
      />
      <WizardSectionDetails>
        <WizardSectionContents sectionKey={sectionKey} panes={panes} />
      </WizardSectionDetails>
    </WizardSectionContainer>
  );
}

WizardSection.propTypes = {
  panes: PropTypes.arrayOf(PropTypes.node).isRequired,
  sectionKey: PropTypes.string.isRequired,
  props: PropTypes.object,
  title: PropTypes.string,
  icon: PropTypes.string,
};

export default WizardSection;
