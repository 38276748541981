import { Box, Stack, Typography, styled } from '@mui/material';
import { PropTypes } from 'prop-types';
import { Audience as AudienceElement } from '../Audience';

import React from 'react';
import {
  AdditionalOptionsTypographyStyled,
  AdvancedSectionIconStyled,
  DividerStyled,
  DividerTypographyStyled,
  SectionBoxStyled,
  SectionInnerBoxStyled,
} from './styles';
import { fields } from './constants';
import { useAudience } from './hooks/useAudience';
import {
  faPen,
  faSquareArrowUpRight,
} from '@fortawesome/pro-regular-svg-icons';
import { DataList } from './DataList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map, slice } from 'lodash';
import {
  SectionInfo,
  sectionInfoType,
} from '../../../../components/SectionInfo';
import { Controller } from 'react-hook-form';

const PenStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  cursor: 'pointer',
  color: theme.palette.grey[5],
}));

export const Audience = ({
  campaignName,
  onAdvanced,
  info,
  isDisplay,
  ...props
}) => {
  const { audience, control } = useAudience({
    campaignName,
    isDisplay
  });

  return (
    <SectionBoxStyled {...props}>
      <DividerStyled textAlign="left">
        <DividerTypographyStyled>Audience</DividerTypographyStyled>
      </DividerStyled>
      <SectionInnerBoxStyled>
        <SectionInfo infoBlocks={info} />
        <Controller
          control={control}
          name={fields.social.path}
          render={({ field }) => <AudienceElement {...field} />}
        />
        <Stack spacing={1}>
          <AdditionalOptionsTypographyStyled onClick={onAdvanced}>
            <AdvancedSectionIconStyled icon={faSquareArrowUpRight} />
            Advanced audience targeting options
          </AdditionalOptionsTypographyStyled>
          {!!audience.length && (
            <DataList
              singleSubField
              title="Include:"
              data={audience}
              render={d => (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body2">
                    {map(slice(d, 0, 2), 'audience_name').join(', ')}{' '}
                    {d.length > 2 && `+${d.length - 2} more...`}
                  </Typography>
                  <PenStyled
                    role="button"
                    onClick={onAdvanced}
                    icon={faPen}
                  />
                </Stack>
              )}
            />
          )}
        </Stack>
      </SectionInnerBoxStyled>
    </SectionBoxStyled>
  );
};

Audience.propTypes = {
  ...Box.propTypes,
  campaignName: PropTypes.string,
  onAdvanced: PropTypes.func,
  info: PropTypes.arrayOf(sectionInfoType),
  isDisplay: PropTypes.bool,
};
