import { array, bool, object, string } from 'yup';
import {
  validationSchema as freqCapValidationSchema
} from '@v2/components/campaign/FrequencyCapFieldsGroup/formConfig';

export const validationSchema = object().shape({
  freqCaps: array()
    .when('isCapAds', {
      is: true,
      then: array()
        .of(freqCapValidationSchema())
        .required('Frequency caps are required')
        .min(1, 'At least one frequency cap is required').required('Frequency caps are required when isCapAds is true')
    }),
  experimentPlan: string()
    .when('isExperiment', {
      is: true,
      then: s => s.required()
    }),
  experimentType: string()
    .when('isIncremental', {
      is: true,
      then: s => s.required()
    }),
  isCapAds: bool(),
  isExperiment: bool(),
  isIncremental: bool()
}).test(
  'no-editing',
  'All frequency caps must be saved before submitting',
  function (value) {
    if (value.isCapAds) {
      return value.freqCaps.every(s => s.isEditing === false);
    }

    return true;
  }
);
