import { useState } from 'react';
import { buildRange } from '../utils';

export const useButtonSlider = ({ onChange, value = [], multiple }) => {
  const [edited, setEdited] = useState(value);
  const [isSelecting, setIsSelecting] = useState(false);

  const handleChange = newValue => {
    if (multiple) {
      if (isSelecting) {
        setEdited(
          Array.isArray(newValue) ? buildRange(edited, newValue) : newValue,
        );
      } else {
        setEdited(newValue);
      }
      setIsSelecting(!isSelecting);
    } else {
      setEdited(newValue);
    }
  };

  const handleClickAll = (allValue) => {
    setEdited(allValue);
    setIsSelecting(false);
  }

  const handleSubmit = () => {
    onChange(edited);
  };

  const handleCancel = () => {
    onChange(value);
  };

  return {
    all: handleClickAll,
    change: handleChange,
    submit: handleSubmit,
    cancel: handleCancel,
    edited,
    isSelecting,
  };
};
