import React, { useContext }from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import AdvertiserContext from '../AdvertiserContext';
import AppHeader from '../AppHeader';
import { useCopy } from '../hooks';
import { Themes } from '../../constants';

const PREFIX = 'MemberPage';

const classes = {
  container: `${PREFIX}-container`,
  formContainer: `${PREFIX}-formContainer`,
  pageCopy: `${PREFIX}-pageCopy`,
  pageTitle: `${PREFIX}-pageTitle`,
  pageSubtitle: `${PREFIX}-pageSubtitle`,
  illustration: `${PREFIX}-illustration`
};

const StyledAppHeader = styled(AppHeader)(({
  theme: { spacing }
}) => ({
  [`& .${classes.container}`]: {
    paddingTop: spacing(10),
    paddingBottom: spacing(15),
  },

  [`& .${classes.formContainer}`]: {},

  [`& .${classes.pageCopy}`]: {
    fontStyle: "normal",
    fontSize: 20,
    lineHeight: "normal",
    letterSpacing: 0,
    color: "#181c20",
    whiteSpace: 'pre-line',
  },

  [`& .${classes.pageTitle}`]: {
    maxWidth: 600,
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0,
    color: "#181c20",
    marginBottom: spacing(2),
    whiteSpace: 'pre-line',
  },

  [`& .${classes.pageSubtitle}`]: {
    maxWidth: 600,
    fontSize: 28,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0,
    color: "#9aa0a6",
    marginBottom: spacing(1),
    whiteSpace: 'pre-line',
  },

  [`& .${classes.illustration}`]: {
    maxWidth: "100%",
    height: "auto",
  }
}));

const Copies = {
  [Themes.DEFAULT]: {
    HEAD: 'Welcome to ROI-Based TV',
    SUB: `Build, optimize, and measure your CTV advertising campaigns in minutes. \n Get started today by activating your account here`,
  },
  [Themes.NBCU]: {
    HEAD: 'Welcome to Peacock Ad Manager',
    SUB: '',
  },
};

export default function MemberPage() {

  const adContext = useContext(AdvertiserContext);
  const Copy = useCopy(Copies);

  return (
    <StyledAppHeader
      hidenav="true"
      navSkipSaveStatus={''}
    >
      <Box className={clsx(classes.container, 'CreateAccount')}>
        <Container maxWidth="lg">
          <Grid container spacing={6} direction="row" justifyContent="center" alignItems="flex-start">
            <Grid item xs={7} className={"CreateAccount-header"} >
            <Typography
                variant="h1"
                className={classes.pageTitle}
              >
                {Copy.HEAD}
              </Typography>

              <Typography
                component="h3"
                className={classes.pageSubtitle}
                variant="subtitle1"
              >
                {Copy.SUB}
              </Typography>

              <Box>
                <img
                  src={adContext.theme === Themes.NBCU
                    ? require('../../images/nbcu/padman-hero.png')
                    : require('../../images/img-create-account.png')}
                  alt="signup request illustration"
                  className={classes.illustration}
                />
              </Box>
            </Grid>

            <Grid container item xs={5} direction="column" justifyContent="flex-start" style={{marginTop: '50px'}}>
              <Typography
                  variant="h3"
                  // className={classes.pageCopy}
                >
                  You Need Access
              </Typography>
              <Typography
                  variant="p"
                  className={classes.pageCopy}
                >
                It looks like you do not have access to any Ad Accounts. Please ask an Organization Admin for Help or reach out for support              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </StyledAppHeader>
  );
}
