import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { entireUSValue, fields } from '../constants';
import { getActualGeoNameField } from '../utils';
import { uniqBy, pick } from 'lodash';
import { getActualField, getActualFieldPlain } from '../../GeoTargeter/utils';
import { distinctNames } from '../../GeoTargeter/constants';

const useGeographyFields = () => {
  const { control, watch } = useFormContext();
  const { fields: arrayFields, ...data } = useFieldArray({
    control,
    name: fields.geography.path,
  });

  const watchFieldArray = watch(fields.geography.path);

  const list = arrayFields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray?.[index],
    };
  });

  return {
    ...data,
    list,
  };
};

export const useGeographyItem = ({ value, onBlur, onUpdate }) => {
  const handleChange = (newValue, selectionType) => {
    const actualValue = newValue ?? {
      id: '',
      place_name: '',
    };

    onUpdate({
      ...pick(actualValue, ['id', 'code', 'place_name', 'type']),
      text: getActualField(actualValue),
      name: getActualFieldPlain(actualValue),
      blacklist: selectionType === 'exclude',
    });

    if (newValue) {
      onBlur();
    }
  };

  const handleChangeSelectionType = selectionType => {
    onUpdate({
      ...value,
      blacklist: selectionType === 'exclude',
    });
  };

  return {
    chnageSelectionType: handleChangeSelectionType,
    change: handleChange,
  };
};

export const useGeography = () => {
  const [focused, setFocused] = useState(new Set());
  const { list, remove, append, update } = useGeographyFields();

  const handleRemoveEntireIfOverlaps = useCallback(() => {
    const entireUSIndex = list.findIndex(
      field => field.id === entireUSValue.id || field.name === 'Entire US',
    );

    if (entireUSIndex === -1) return;

    const { blacklist: entireUSBlacklist } = list[entireUSIndex];

    if (list.length > 1) {
      const isOverlapping = list.some(
        (value, index) =>
          index !== entireUSIndex &&
          (getActualGeoNameField(value) !== '' ||
            value.type === distinctNames.zipList) &&
          value.blacklist === entireUSBlacklist,
      );
      if (isOverlapping) {
        remove(entireUSIndex);
      }
    }
  }, [list]);

  const handleRemoveDuplicates = useCallback(() => {
    const uniqFields = uniqBy(list, field => {
      if (field.type === distinctNames.zipList) {
        return field.codes.join('');
      }

      return getActualGeoNameField(field);
    });

    list.forEach((field, idx) => {
      if (!uniqFields.includes(field)) {
        remove(idx);
      }
    });
  }, [list]);

  const handleAddGeography = useCallback(
    value => {
      append({
        ...value,
        blacklist: false,
      });
    },
    [append],
  );

  const handleRemove = index => () => {
    remove(index);
  };

  const handleUpdate = index => value => {
    update(index, value);
  };

  const handleFocus =
    index =>
    (isFocused = true) => {
      setFocused(prev => {
        const newSet = new Set(prev);

        if (isFocused) {
          newSet.add(index);
          return newSet;
        }

        newSet.delete(index);
        return newSet;
      });
    };

  const handleBlur = index => () => {
    handleFocus(index)(false);
  };

  useEffect(() => {
    if (!list.length) {
      handleAddGeography(entireUSValue);
    }

    handleRemoveEntireIfOverlaps();
    handleRemoveDuplicates();
  }, [
    handleAddGeography,
    handleRemoveEntireIfOverlaps,
    handleRemoveDuplicates,
    list,
  ]);

  return {
    list,
    focused,
    update: handleUpdate,
    blur: handleBlur,
    focus: handleFocus,
    remove: handleRemove,
    add: handleAddGeography,
  };
};
