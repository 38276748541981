import {
  IconButton,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import {
  AssignButtonStyled,
  StatusChipExistingStyled,
  StatusChipFailedStyled,
  StatusChipUploadingStyled,
  UploadingAssignButtonStyled,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImageLandscape } from '@fortawesome/pro-solid-svg-icons';
import { faClose, faPlayCircle } from '@fortawesome/pro-regular-svg-icons';
import {
  HighlightedContent,
  toHighlightedContent,
} from '@v2/components/campaign/Advanced';
import { AssignButton } from './AssignButton';
import { isFile, isProcessing, isUploading } from './utils';

const ImageStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[5],
}));

const ImageDisplayStyled = styled(ImageStyled)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(1.75),
}));

const ImageVideoStyled = styled(ImageStyled)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
}));

export const getColumns = ({
  onToggleAssign,
  assignedCreatives,
  // onPreviewCreative,
  onUploadingCancel,
}) => [
  { id: 'id', label: 'ID', sortable: true },
  {
    id: 'name',
    label: 'Creative Title',
    align: 'left',
    class: '',
    headerSx: {
      maxWidth: 200,
    },
    cellSx: {
      maxWidth: 200,
    },
    render: (row, { search }) => (
      <Stack title={row.name} alignItems="center" spacing={1} direction="row">
        {/* <IconButton onClick={() => onPreviewCreative(row)}> */}
        {row.type === 'display' ? (
          <ImageDisplayStyled icon={faImageLandscape} />
        ) : (
          <ImageVideoStyled icon={faPlayCircle} />
        )}
        {/* </IconButton> */}
        <HighlightedContent
          variant="body2"
          data={toHighlightedContent(row.name, search)}
        />
      </Stack>
    ),
  },
  {
    id: 'type',
    label: 'Type',
    sortable: false,
    defaultValue: row => row.mime_type ?? 'n/a',
  },
  {
    id: 'lineitem_set',
    label: 'Ad groups',
    render: row => {
      const lineItemsSet =
        row.type === 'display'
          ? row.staticdisplaylineitem_set
          : row.lineitem_set;

      if (isFile(row)) {
        return <Typography variant="body2">1 Ad group</Typography>;
      }
      return (
        <Typography variant="body2">
          {lineItemsSet.length > 0
            ? `${lineItemsSet.length} Ad group${
                lineItemsSet.length > 1 ? 's' : ''
              }`
            : 'Not Assigned'}
        </Typography>
      );
    },
  },
  {
    id: 'spend',
    label: 'Spend',
  },
  {
    id: 'visits',
    label: 'Visits',
  },
  {
    id: 'cost_per_visit',
    label: 'Cost per Visit',
  },
  {
    id: 'media_upload_date',
    label: 'Uploaded',
    render: row => {
      const d = moment(row.media_upload_date);
      return (
        <Typography variant="body2">
          {d.isValid() ? d.format('MM/DD/YYYY') : 'n/a'}
        </Typography>
      );
    },
  },
  {
    id: 'media_update_date',
    label: 'Updated',
    render: row => {
      const d = moment(row.media_update_date);

      return (
        <Typography variant="body2">
          {d.isValid() ? d.format('MM/DD/YYYY') : 'n/a'}
        </Typography>
      );
    },
  },
  {
    id: 'status',
    label: 'Status',
    render: row => {
      if (isUploading(row)) {
        return <StatusChipUploadingStyled label="Uploading" />;
      }

      if (row.qc?.status === 'FAILED') {
        return (
          <Tooltip
            title={
              <List>
                {row.qc.messages.map((message, index) => (
                  <ListItem key={index}>
                    <Typography variant="body2">{message}</Typography>
                  </ListItem>
                ))}
              </List>
            }
          >
            <StatusChipFailedStyled label="Failed" />
          </Tooltip>
        );
      }

      if (row.pendingActive) {
        return <StatusChipUploadingStyled label="Processing" />;
      }

      return (
        <StatusChipExistingStyled
          active={row.active}
          label={row.active ? 'Active' : 'Inactive'}
        />
      );
    },
  },
  {
    id: 'actions',
    label: 'Action',
    render: row => {
      if (isUploading(row) || isProcessing(row)) {
        return (
          <Stack
            sx={{
              width: 170,
            }}
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <UploadingAssignButtonStyled disabled>
              {isUploading(row) ? 'Uploading...' : 'Processing...'}
            </UploadingAssignButtonStyled>
            {isUploading(row) && (
              <IconButton
                onClick={() => onUploadingCancel(row.id)}
                size="small"
              >
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
            )}
          </Stack>
        );
      }
      const foundAssignedElement = assignedCreatives.find(
        c => c.id === row.id,
      );
      return foundAssignedElement ? (
        <AssignButton onClick={() => onToggleAssign(row)} />
      ) : (
        <AssignButtonStyled onClick={() => onToggleAssign(row)}>
          Assign
        </AssignButtonStyled>
      );
    },
  },
];

export const tableOptions = {
  sticky: {
    left: ['name'],
    right: ['actions'],
  },
  sortable: [
    'id',
    'name',
    'status',
    'media_upload_date',
    'media_updated_date',
  ],
};
