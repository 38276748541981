import useSWR, { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

import { useCurrentSession } from '../currentSession';
import { useCallback, useEffect, useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import { last, set } from 'lodash';

const buildAdGroupCacheKey = (adGroupId, currentAdvertiser, params = {}) => {
  const url = `/lineitems/${adGroupId}/`;

  return { url, advertiser: currentAdvertiser.id ?? '-', ...params };
};

export const useGetAdGroup = (adGroupId, options) => {
  const { get, apiIsReady, currentAdvertiser } = useCurrentSession();

  const fetcher = ({ url, params }) =>
    get(url, { params }).then(res => res.data);

  const swr = useSWR(
    apiIsReady && adGroupId
      ? buildAdGroupCacheKey(adGroupId, currentAdvertiser)
      : null,
    fetcher,
    options,
  );

  const { data, error, isLoading } = swr;

  return {
    adGroup: data,
    error,
    isLoading,
  };
};

export const usePatchAdGroup = initOptions => {
  const { patch, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { mutate } = useSWRConfig();

  const updateAdGroup = ({ url }, { arg: { id, ...data } }) =>
    patch(`${url}${id}/`, data).then(res => res.data);

  const options = {
    onSuccess: updatedAdGroup => {
      const key = buildAdGroupCacheKey(updatedAdGroup.id, currentAdvertiser);
      mutate(key, updatedAdGroup, { populateCache: true });
    },
    revalidate: false,
    ...initOptions,
  };

  return useSWRMutation(
    apiIsReady ? { url: '/lineitems/' } : null,
    updateAdGroup,
    options,
  );
};

export const useDeleteAdGroup = options => {
  const { delV1, apiIsReady } = useCurrentSession();

  const deleteAdGroup = (url, { arg: id }) =>
    delV1(`${url}${id}/`).then(res => res.data);

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? `/lineitems/` : null,
    deleteAdGroup,
    {
      revalidate: true,
      ...options,
    },
  );

  return { trigger, isMutating };
};

export const usePatchAdGroups = options => {
  const { patch, apiIsReady } = useCurrentSession();

  const updateAdGroups = async ({ url }, { arg: updates }) =>
    await Promise.all(
      updates.map(({ id, ...data }) =>
        patch(`${url}${id}/`, data).then(res => res.data),
      ),
    );

  return useSWRMutation(
    apiIsReady ? { url: '/lineitems/' } : null,
    updateAdGroups,
    options,
  );
};

export const useCreateAdGroup = initOptions => {
  const { post, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { mutate } = useSWRConfig();

  const fetcher = ({ url }, { arg }) => post(url, arg).then(res => res.data);

  const options = {
    onSuccess: newAdGroup => {
      const key = buildAdGroupCacheKey(newAdGroup.id, currentAdvertiser);
      mutate(key, newAdGroup, { populateCache: true });
    },
    revalidate: false,
    ...initOptions,
  };

  return useSWRMutation(
    apiIsReady ? { url: '/lineitems/' } : null,
    fetcher,
    options,
  );
};

export const useCampaignAdGroupsPage = (campaignId, options) => {
  const { get, patchV1, delV1, apiIsReady, currentAdvertiser } =
    useCurrentSession();
  const { mutate: swrMutate } = useSWRConfig();

  const url = '/lineitems/';

  const { data, error, isLoading, setSize, mutate } = useSWRInfinite(
    (index, previousPageData) => {
      if (
        !apiIsReady ||
        !campaignId ||
        (previousPageData && !previousPageData.next)
      )
        return null;

      return {
        url,
        advertiser: currentAdvertiser.id ?? '-',
        params: {
          campaign: campaignId,
          ...(index && { page: index + 1 }),
        },
      };
    },
    ({ url, params }) => get(url, params).then(res => res.data),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      ...options,
    },
  );

  useEffect(() => {
    if (data?.next) {
      setSize(prev => prev + 1);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      data.forEach(page => {
        page.results.forEach(adGroup => {
          swrMutate(
            buildAdGroupCacheKey(adGroup.id, currentAdvertiser),
            adGroup,
            { populateCache: true },
          );
        });
      });
    }
  }, [data, currentAdvertiser]);

  const items = useMemo(
    () => (data ? [].concat(...data.map(page => page?.results ?? [])) : []),
    [data],
  );

  const add = useCallback(
    predefinedData =>
      mutate(prevData => {
        const lastPage = { ...last(prevData) };

        const newResult = {
          id: (lastPage?.results?.length ?? 0) + 1,
          temporary: true,
          ...predefinedData,
        };

        return [
          ...(prevData?.slice(0, -1) ?? []),
          {
            ...lastPage,
            results: [...(lastPage?.results ?? []), newResult],
          },
        ];
      }, false),
    [mutate],
  );

  const remove = useCallback(
    id =>
      mutate(async prevData => {
        const pageWithItem = prevData.find(page =>
          page.results.find(result => result.id === id),
        );
        const { temporary = false } =
          pageWithItem?.results.find(result => result.id === id) || {};

        if (!temporary) {
          await patchV1(`${url}${id}/`, { creatives: [] });
          await delV1(`${url}${id}/`);
        }

        return prevData.map(page => {
          const updatedResults = page.results.filter(
            result => result.id !== id,
          );
          return set({ ...page }, 'results', updatedResults);
        });
      }, false),
    [mutate],
  );

  const duplicate = useCallback(
    id =>
      mutate(prevData => {
        return prevData.map(page => {
          const itemToDuplicate = page.results.find(
            result => result.id === id,
          );
          if (!itemToDuplicate) return page;

          const duplicatedItem = {
            ...itemToDuplicate,
            id: page.results.length + 1,
            temporary: true,
          };

          return {
            ...page,
            results: [...page.results, duplicatedItem],
          };
        });
      }, false),
    [mutate],
  );

  return {
    items,
    data,
    error,
    isLoading,
    add,
    remove,
    duplicate,
    mutate,
  };
};
