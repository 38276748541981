import { Box, Stack, styled } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { blockTypeData } from './constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '../Icon';
import { ButtonSlider } from '@v2/components/ui/ButtonSlider';

const WrapperBox = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.blue[11],
  borderRadius: '5px',
  padding: '14px 24px',
}));

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.blue[6],
  cursor: 'pointer',
}));

const StyledPlainIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.blue[6],
  cursor: 'pointer',
}));

const StyledIconBox = styled(Stack)({
  width: '24px',
});

const AudienceBlock = ({
  value = [],
  onChange,
  type,
  label,
  options,
  multiple = false,
  ...props
}) => {
  const [isEditing, setIsEditing] = React.useState(false);

  return isEditing ? (
    <ButtonSlider
      options={options}
      value={value}
      onChange={v => {
        onChange(v);
        setIsEditing(false);
      }}
      multiple={multiple}
    />
  ) : (
    <WrapperBox
      alignItems="center"
      direction="row"
      spacing={2}
      data-testid={`audience-block-${type}`}
      {...props}
    >
      <StyledIconBox display="flex" alignItems="center">
        <StyledPlainIcon name={blockTypeData[type].icon} />
      </StyledIconBox>
      <Box flex={1}>{label(value)}</Box>
      <Stack
        data-testid={`audience-block-${type}-edit`}
        alignItems="center"
        onClick={() => setIsEditing(true)}
      >
        <StyledIcon icon={faPen} />
      </Stack>
    </WrapperBox>
  );
};

AudienceBlock.propTypes = {
  ...Stack.propTypes,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.keys(blockTypeData)).isRequired,
  label: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      label: PropTypes.string,
      activable: PropTypes.bool,
    }),
  ).isRequired,
};

export default AudienceBlock;
