import React from 'react';
import PropTypes from 'prop-types';

import { AccordionDetails } from '@mui/material';

const WizardSectionDetails = ({ children }) => {
  return <AccordionDetails>{children}</AccordionDetails>;
};

WizardSectionDetails.propTypes = {
  children: PropTypes.node,
};

export default WizardSectionDetails;
