import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

const WizardSectionsContainer = ({ children }) => {
  return (
    <Grid item xs={9}>
      {children}
    </Grid>
  );
};

WizardSectionsContainer.propTypes = {
  children: PropTypes.node,
};

export default WizardSectionsContainer;
