import useSWRMutation from 'swr/mutation';
import { useCurrentSession } from '../currentSession';

export const usePostUserAgreement = (data, options) => {
  const { postV1 } = useCurrentSession();

  return useSWRMutation(
    { url: '/user_agreements/' },
    ({ url }, { arg }) => postV1(url, arg).then(res => res.data),
    options,
  );
};
