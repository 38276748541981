import { useState } from 'react';
import _ from 'lodash'

import states from '../utilStates';

/////////////////////////////
// MAPBOX CONSTANTS
/////////////////////////////
const mbToken = process.env.REACT_APP_MAPBOX_TOKEN;
const geoBaseUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places`;
const queries = `fuzzyMatch=false&country=us&types=country,region,postcode,place`;

export const geoUrl = search =>
  `${geoBaseUrl}/${JSON.stringify(
    search
  )}.json?access_token=${mbToken}&${queries}`;

// Prepare data for BE
const formatGeo = (results) => {
  const formatResults = results.map(r => {
    // Handle Entire US data
    if (r.place_name && r.place_name === 'Entire US') {
      return {
        name: r.place_name,
        type: 'country',
        blacklist: r.blacklist,
      };
    }

    // Handle DMA data
    if (r.code) {
      let name = r.text ? r.text : r.name
      if (r.place_name) {
        name = r.place_name
      }
      return {
        code: r.code,
        name,
        type: 'dma',
        blacklist: r.blacklist
      };
    }

    const { blacklist, id, name, place_name, place_type, text, type } = r;

    // Handle Mapbox data
    return {
      id,
      text,
      name: place_name ? place_name : name ? name : text,
      type: place_type && place_type[0]
        ? place_type[0]
        : type,
      blacklist,
    };
  });

  const resultsObj = {
    cities: [],
    countries: [],
    dmas: [],
    states: [],
    zipcodes: [],
  };

  formatResults.forEach(res => {
    switch (res.type) {
      case 'dma':
        resultsObj.dmas.push(res);
        break;
      case 'place':
      case 'neighborhood':
        resultsObj.cities.push(res);
        break;
      case 'region':
        resultsObj.states.push(res);
        break;
      case 'postcode':
        resultsObj.zipcodes.push(res);
        break;
      default:
        resultsObj.countries.push(res);
        break;
    }
  });

  return resultsObj;
};

const formatGeoForClient = (geo) => {
  // get geo reuslts and format for display
  let formattedGeoResults = [];
  const geoResults = geo;

  _.map(geoResults, (i) => {
    if (i.length !== 0) {
      formattedGeoResults.push(i)
    }
  })
  formattedGeoResults = _.flatten(formattedGeoResults)
  formattedGeoResults = formattedGeoResults.map(({ name: place_name, ...rest }) => ({ place_name, ...rest }));
  return formattedGeoResults
}

const formatGeoName = result => {
  const { context, place_name, place_type, text } = result;

  if (place_name && place_type && context) {
    if (['place', 'neighborhood', 'postcode'].indexOf(place_type[0]) > -1) {
      const contextState = context.filter(c => c.id.indexOf('region') > -1);
      const state = states.find(s => s.name === contextState[0].text);

      return `${text}, ${state.abbreviation} (City)`;
    }
  }

  if (place_type && place_type[0] && place_type[0] === 'region') {
    return `${text}, US (State)`;
  }

  return place_name ? place_name : text;
};

//////////////////////////
// useGeo HOOK
//////////////////////////
export const useGeo = () => {
  const [geo, setGeo] = useState([]);

  return {
    formatGeo,
    formatGeoForClient,
    formatGeoName,
    geo,
    geoUrl,
    setGeo,
  };
};
