import { snakeCase } from 'lodash';

const isObject = obj => obj && typeof obj === 'object' && !Array.isArray(obj);

export const keysToSnakeCase = obj => {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj).forEach(k => {
      n[snakeCase(k)] = keysToSnakeCase(obj[k]);
    });

    return n;
  } else if (Array.isArray(obj)) {
    return obj.map(i => keysToSnakeCase(i));
  }

  return obj;
};
