import { TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function CreativeTableHead({ classes, headers }) {
  return (
    <TableHead>
      <TableRow>
        {headers.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            classes={{
              head: classes.head,
              body: classes.body
            }}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CreativeTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired
};

export default CreativeTableHead;
