import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import apiInstance from '../connection/apiClient';
import {
  PING_TOKEN_STORAGE_KEY,
  BASE_PING_HEADERS,
  PING_CONFIG,
  PING_AUTH_BASE_URL,
} from './../ping';

export const PingContext = createContext({});

export const PingContextProvider = ({ children }) => {
  const [tokenRefreshInterval, setTokenRefreshInterval] = useState(null);
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    isPending: true,
  });

  const getStateFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem(PING_TOKEN_STORAGE_KEY));
  };

  const updateLocalStorage = state => {
    localStorage.setItem(PING_TOKEN_STORAGE_KEY, JSON.stringify(state));
  };

  const updateTokens = async tokens => {
    const {
      access_token: accessToken,
      refresh_token: refreshToken,
      expires_in: expiresIn,
    } = tokens;

    const newState = {
      accessToken,
      refreshToken,
      expiresIn,
      expiryDate: expiresIn ? Date.now() + expiresIn * 1000 : undefined,
      isAuthenticated: true,
      isPending: false,
    };

    await setAuthState(newState);
    updateLocalStorage(newState);
  };

  const getAuthTokens = async code => {
    const oauthTokenUrl = `${PING_AUTH_BASE_URL}access_token/`;

    const data = {
      code,
      referer: window.location.origin,
      redirectUri: PING_CONFIG.redirectURL,
    };

    const response = await apiInstance.post(oauthTokenUrl, data);

    if (response.status_code >= 400) {
      // TODO: handle exceptions
      throw new Error(response);
    }

    await updateTokens(response.data);
  };

  const refreshToken = async () => {
    if (!tokenRefreshInterval) {
      return;
    }

    const refreshTokenUrl = `${PING_AUTH_BASE_URL}refresh_token/`;

    const data = {
      refreshToken: authState.refreshToken,
      referer: window.location.origin,
      redirectUri: PING_CONFIG.redirectURL,
    };

    const response = await apiInstance.post(refreshTokenUrl, data, {
      headers: {
        ...BASE_PING_HEADERS,
        Authorization: `Bearer ${authState.accessToken}`,
      },
    });

    if (response.status_code >= 400) {
      // TODO: handle exceptions
      clearInterval(tokenRefreshInterval);
      setTokenRefreshInterval(null);
      throw new Error(response);
    }

    await updateTokens(response.data);
  };

  const introspectToken = async (token, tokenTypeHint = 'access_token') => {
    const introspectTokenUrl = `${PING_AUTH_BASE_URL}introspect_token/`;

    const data = {
      token,
      tokenTypeHint,
    };

    const response = await apiInstance.post(introspectTokenUrl, data, {
      headers: {
        ...BASE_PING_HEADERS,
        Authorization: `Bearer ${authState.accessToken}`,
      },
    });

    if (response.status_code >= 400) {
      // TODO: handle exceptions
      throw new Error(response);
    }

    return response.data;
  };

  const introspectAccessToken = async token => {
    const result = await introspectToken(token);
    if (!result['active']) {
      signOut();
    }
    return result;
  };

  const getUser = async () => {
    const token = authState.accessToken;

    const user = await introspectAccessToken(token);
    return {
      email: user['Email'],
      name: `${user['FirstName']} ${user['LastName']}`,
    };
  };

  const revokeAccessToken = async () => {
    const revokeTokenUrl = `${PING_AUTH_BASE_URL}revoke_token/`;

    if (!authState.accessToken) {
      return;
    }

    const data = {
      token: authState.accessToken,
    };

    const response = await apiInstance.post(revokeTokenUrl, data, {
      headers: {
        ...BASE_PING_HEADERS,
        Authorization: `Bearer ${authState.accessToken}`,
      },
    });

    if (response.status_code >= 400) {
      // TODO: handle exceptions
      throw new Error(response);
    }

    return response.data;
  };

  const clearAuthState = () => {
    setAuthState({
      isAuthenticate: false,
      isPending: false,
    });
  };

  const clear = () => {
    clearInterval(tokenRefreshInterval);
    setTokenRefreshInterval(null);
    clearAuthState();
    updateLocalStorage({});
  };

  const signOut = async () => {
    await revokeAccessToken();
    clear();
  };

  useEffect(() => {
    const currentState = getStateFromLocalStorage();

    if (currentState?.accessToken) {
      return setAuthState({
        ...currentState,
        isAuthenticated: true,
      });
    }

    return setAuthState(prev => ({
      ...prev,
      isPending: false,
    }));
  }, []);

  useEffect(() => {
    if (authState.accessToken && !tokenRefreshInterval) {
      const tokenRefreshInterval = setInterval(
        refreshToken,
        (authState.expiresIn - 60 * 10) * 1000
      );
      setTokenRefreshInterval(tokenRefreshInterval);
    }
  }, [authState.accessToken, tokenRefreshInterval]);

  return (
    <PingContext.Provider
      value={{
        authState,
        pingAuth: {
          getAuthTokens,
          revokeAccessToken,
          getUser,
          signOut,
          clear,
        },
      }}
    >
      {children}
    </PingContext.Provider>
  );
};

PingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
