import useSWR from 'swr';
import { useCurrentSession } from '../currentSession';

export const useGetLatestTenantTermAndCondition = (id, options) => {
  const { getV1 } = useCurrentSession();

  return useSWR(
    { url: `/terms_and_conditions/latest/${id}/` },
    ({ url, params }) => getV1(url, { params }).then(res => res.data),
    options,
  );
};
