import React from 'react';

export const getActualGeoNameField = value =>
  value.place_name ?? value.text ?? value.name ?? value.label ?? '';

export const getZipcodesLabel = zips => {
  let label = zips.slice(0, 3).join(', ');
  if (zips.length > 3) {
    label += ` + ${zips.length - 3} more...`;
  }
  label += ' (Zip Codes)';
  return label;
};

export const getUnlockedContent = (option, unlockedContent) => {
  switch (option.display_name) {
    case 'Max Impressions':
    case 'Auto Bid':
    case 'Max ROAS':
      return unlockedContent.maxCpm(false);
    case 'Manual Bid':
      return unlockedContent.maxCpm(true);
    case 'Max Outcomes':
      return (
        <>
          {unlockedContent.eventPicker()}
          {unlockedContent.maxCpm(false)}
        </>
      )
    default:
      return null;
  }
};

export const getLockedStatus = (option, bidStrategyEventSet) => {
  switch (option.display_name) {
    case 'Max Outcomes':
      return !bidStrategyEventSet || bidStrategyEventSet.length === 0;
    default:
      return false;
  }
};

export const getLockedInfo = (option, lockedInfo) => {
  switch (option.display_name) {
    case 'Max Outcomes':
      return lockedInfo.trackingLockedInfo;
    default:
      return null;
  }
};
export const getTargetingValue = (key, targeting = []) =>
  targeting.find(t => t.key === key);
